import { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../helpers'
import { login } from '../../../redux/actions/auth/authActions'
import { FormControl, ControlType } from '../../pages/components/FormControl'
import _ from 'lodash'

const Login = () => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const dispatch = useDispatch()
  const location = useLocation()
  const error = useSelector((state) => state.auth.error)

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const urlParams = new URLSearchParams(location.search)
  const returnUrl = urlParams.get('returnUrl')
  const ForgotPasswordLink = returnUrl ? `/auth/forgot-password?returnUrl=${encodeURIComponent(returnUrl)}` : '/auth/forgot-password'

  const validationAllComponents = async () => {
    let isValid = true

    await _.map(componentRefs.current, async (ref) => {
      if (!(await ref.validateData())) {
        isValid = false
      }
    })

    return isValid
  }

  const handleLogin = async (event) => {
    event.preventDefault()
    setLoading(true)

    try {
      const isValid = await validationAllComponents()
      if (!isValid) return

      await dispatch(login(email, password, returnUrl))
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <form className='form w-100' onSubmit={handleLogin}>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          <FormattedMessage id='Login' />
        </h1>
      </div>
      <div className='row g-3 mb-9'>
        <div className='col-md-6'>
          <a className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'>
            <img alt='Logo' src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')} className='h-15px me-3' />
            <FormattedMessage id='Login.Google' />
          </a>
        </div>

        <div className='col-md-6'>
          <a className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'>
            <img alt='Logo' src={toAbsoluteUrl('/media/svg/brand-logos/facebook-2.svg')} className='h-15px me-3' />
            <FormattedMessage id='Login.Facebook' />
          </a>
        </div>
      </div>

      <div className='separator separator-content my-14'>
        <span className='w-150px text-gray-500 fw-semibold fs-7'>
          <FormattedMessage id='Login.OrWithEmail' />
        </span>
      </div>

      {error && (
        <div className='alert alert-danger'>
          <div className='alert-text font-weight-bold'>{error}</div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <FormControl
          ref={(ref) => (componentRefs.current.email = ref)}
          controlType={ControlType.Input}
          label={intl.formatMessage({ id: 'Login.Email' })}
          value={email}
          isRequired={true}
          hideLabel={true}
          onChange={(e) => setEmail(e.target.value)}
          className={'form-control mb-3 py-3 px-4'}
        />
      </div>

      <div className='fv-row mb-3'>
        <FormControl
          ref={(ref) => (componentRefs.current.password = ref)}
          controlType={ControlType.Input}
          label={intl.formatMessage({ id: 'Login.Password' })}
          value={password}
          isRequired={true}
          hideLabel={true}
          onChange={(e) => setPassword(e.target.value)}
          className={'form-control mb-3 py-3 px-4'}
          type='password'
        />
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
        <Link to={ForgotPasswordLink} className='link-primary'>
          <FormattedMessage id='Login.ForgotPassword' />?
        </Link>
      </div>

      <div className='d-grid mb-10'>
        <button type='submit' className='btn btn-primary' disabled={loading}>
          {!loading && (
            <span className='indicator-label'>
              <FormattedMessage id='Login' />
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              <FormattedMessage id='Confirm.PleaseWait' />
              ...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}

export default Login
