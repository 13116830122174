import React, { useEffect, useState } from 'react'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import { useIntl, FormattedMessage } from 'react-intl'
import { getCurrentPromotions } from '../../../../redux/actions/eco/promotionActions'
import { getProductPromotions, deleteProductPromotion } from '../../../../redux/actions/eco/productPromotionActions'
import _ from 'lodash'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import TableSearch from '../../components/TableSearch'
import PromotionModal from '../Promotion/PromotionModal'
import ProductPromotionModel from './ProductPromotionModel'
import Table from '../../components/Table'
import ProductFilter from './ProductFilter'
import ConfirmRemove from '../../components/Confirm'

const ProductPromotion = () => {
  const intl = useIntl()
  const [isSelectedAll, setIsSelectedAll] = useState(false)
  const [addPromotion, setAddPromotion] = useState(false)
  const [addProduct, setAddProduct] = useState(false)
  const [confirmDeleteProduct, setConfirmDeleteProduct] = useState(false)
  const [selectedPromotion, setSelectedPromotion] = useState(null)
  //promotion

  const [promotions, setPromotions] = useState([])
  const [promotionsOriginals, setPromotionsOriginals] = useState([])
  const [isLoadingPromotion, setIsLoadingPromotion] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  //productPromotion
  const [productPromotions, setProductPromotions] = useState([])
  const [originProductPromotions, setOriginProductPromotions] = useState([])
  const [isLoadingProductPromotion, setIsLoadingProductPromotion] = useState(false)

  useEffect(() => {
    fetchPromotion()
  }, [])

  useEffect(() => {
    fetchProductPromotion()
  }, [selectedPromotion])

  const fetchPromotion = async () => {
    setIsLoadingPromotion(true)
    try {
      const { result } = await getCurrentPromotions()
      if (result) {
        setPromotions(result)
        setPromotionsOriginals(result)
        setSelectedPromotion(result && result[0])
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingPromotion(false)
    }
  }

  const fetchProductPromotion = async (productCode, productName, supplierId, categoryId, subCategoryId, groupId) => {
    setIsLoadingProductPromotion(true)
    try {
      const { result } = await getProductPromotions({
        pageIndex: currentPage,
        pageSize: pageSize,
        promotionId: selectedPromotion?.id,
        productCode: productCode ?? null,
        productName: productName ?? null,
        supplierId: supplierId ?? null,
        categoryId: categoryId ?? null,
        subCategoryId: subCategoryId ?? null,
        groupId: groupId ?? null,
      })

      if (result) {
        setTotalPages(result.totalCount)
        setProductPromotions(result.items)
        setOriginProductPromotions(result.items)
        setIsSelectedAll(false)
      } else {
        setProductPromotions([])
        setOriginProductPromotions([])
        setTotalPages(0)
        setIsSelectedAll(false)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingProductPromotion(false)
    }
  }

  const removeProductPromotions = async () => {
    try {
      const { error } = await deleteProductPromotion(productPromotions.filter((x) => x.isSelected).map((x) => x.id))
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        fetchProductPromotion()
        setConfirmDeleteProduct(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectedAll = () => {
    setProductPromotions(
      productPromotions.map((item) => ({
        ...item,
        isSelected: !isSelectedAll,
      }))
    )

    setIsSelectedAll(!isSelectedAll)
  }

  const handleSelected = (id) => {
    setProductPromotions((prevProducts) => {
      const updatedProducts = [...prevProducts]
      const selectedItem = updatedProducts.find((item) => item.id === id)

      selectedItem.isSelected = !selectedItem.isSelected

      if (updatedProducts.every((x) => x.isSelected === selectedItem.isSelected)) {
        setIsSelectedAll(selectedItem.isSelected)
      } else {
        setIsSelectedAll(false)
      }
      return updatedProducts
    })
  }

  const columns = [
    {
      Header: (
        <div className='form-check form-check-sm form-check-custom form-check-solid justify-content-center'>
          <input onChange={handleSelectedAll} className='form-check-input pointer' type='checkbox' checked={isSelectedAll} value='1' />
        </div>
      ),
      accessor: 'isSelected',
      width: 25,
      disableSort: true,
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Title.Product' })}`,
      accessor: 'productName',
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.Supplier' })}`,
      accessor: 'supplierName',
      width: 80,
    },
  ]

  const rows = productPromotions.map((item) => {
    return {
      id: item.id,
      isSelected: (
        <div className='form-check form-check-sm form-check-custom form-check-solid justify-content-center'>
          <input onChange={() => handleSelected(item.id)} className='form-check-input pointer' checked={item.isSelected} type='checkbox' value='1' />
        </div>
      ),
      productName: (
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-45px me-5'>
            <img src={DEFAULT_VALUE.BASE_URL + item.imageUrl} alt='' />
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <a href='#' className='text-gray-900 fw-bold text-hover-primary fs-6'>
              {item.productName}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.productCode}</span>
          </div>
        </div>
      ),
      supplierName: item.supplierName,
    }
  })

  return (
    <div className='d-flex flex-column flex-lg-row'>
      <div className='flex-column flex-lg-row-auto col-lg-4 col-xxl-4 min-w-450px'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title fw-bold text-dark'>{intl.formatMessage({ id: 'Menu.Eco.Promotion' })}</h3>

            <div className='card-toolbar'>
              <a className='btn btn-sm btn-success me-2' onClick={() => setAddPromotion(true)} title={intl.formatMessage({ id: 'AddNew' })}>
                <i className='fa-solid fa-plus'></i>
                {intl.formatMessage({ id: 'AddNew' })}
              </a>

              <TableSearch onSearch={setPromotions} originalData={promotionsOriginals} />
            </div>
          </div>

          <div className='card-body pt-5'>
            <div
              className='scroll-y me-5 pe-5 h-200px h-lg-auto'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-offset='0px'
            >
              {promotions?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className='d-flex align-items-center'>
                      {
                        <div
                          className={`flex-grow-1 py-2 me-1 pointer rounded ${selectedPromotion?.id === item.id ? 'selected' : ''}`}
                          onClick={() => setSelectedPromotion(item)}
                        >
                          <span className={`text-gray-900 text-hover-primary ms-4`}>{item.name}</span>
                        </div>
                      }
                    </div>
                    <div className='separator my-2'></div>
                  </React.Fragment>
                )
              })}
            </div>
            {isLoadingPromotion && <Loading />}
          </div>
        </div>
      </div>

      <div className='flex-lg-row-fluid ms-4'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title fw-bold text-dark'>{selectedPromotion?.name ?? intl.formatMessage({ id: 'Menu.Eco.Product' })}</h3>

            <div className='card-toolbar gap-3'>
              {selectedPromotion && (
                <a className='btn btn-success btn-sm' onClick={() => setAddProduct(true)} title={intl.formatMessage({ id: 'AddNew' })}>
                  <i className='fa-solid fa-plus me-1'></i>
                  <FormattedMessage id='AddNew' />
                </a>
              )}
              {productPromotions && productPromotions.some((item) => item.isSelected) && (
                <a onClick={() => setConfirmDeleteProduct(true)} className='btn btn-danger btn-sm' title={intl.formatMessage({ id: 'Delete' })}>
                  <i className='fa-solid fa-trash'></i>
                  <FormattedMessage id='Delete' />
                </a>
              )}
              <ProductFilter fetchData={fetchProductPromotion} />
              <TableSearch onSearch={setProductPromotions} originalData={originProductPromotions} />
            </div>
          </div>
          <div className='card-body py-2 pt-0'>
            <div
              className='scroll-y h-200px h-lg-auto'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-offset='0px'
            >
              <Table columns={columns} data={rows} />
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                itemsPerPage={pageSize}
                onPageChange={(e) => setCurrentPage(e)}
                onItemsPerPageChange={(e) => setPageSize(e)}
              />
            </div>
            {isLoadingProductPromotion && <Loading />}
          </div>
        </div>
      </div>
      {addPromotion && <PromotionModal closeModal={() => setAddPromotion(false)} fetchPromotions={fetchPromotion} />}
      {addProduct && (
        <ProductPromotionModel promotionId={selectedPromotion?.id} closeModal={() => setAddProduct(false)} fetchProductPromotion={fetchProductPromotion} />
      )}
      {confirmDeleteProduct && (
        <ConfirmRemove
          onConfirm={removeProductPromotions}
          onCancel={() => setConfirmDeleteProduct(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}
    </div>
  )
}

export default ProductPromotion
