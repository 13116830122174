import { useState, useEffect } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import Table from '../../components/Table'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import { useRef } from 'react'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { ControlType, FormControl } from '../../components/FormControl'
import Loading from '../../components/Loading'
import { add, update } from '../../../../redux/actions/aff/LevelActions'

const LevelModal = ({ item, closeModal, fetchItems, currentLevel }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const [isLoading, setIsLoading] = useState(false)
  const [isEdit] = useState(!_.isEmpty(item))

  const [levelCode, setLevelCode] = useState(item?.levelCode ?? null)
  const [levelNumber, setLevelNumber] = useState(item?.levelNumber ?? null)
  const [levelName, setLevelName] = useState(item?.levelName ?? null)
  const [description, setDescription] = useState(item?.description ?? null)
  const [totalAmount, setTotalAmount] = useState(item?.totalAmount ?? null)
  const [maintainForMonths, setMaintainForMonths] = useState(item?.maintainForMonths ?? null)
  const [monthInviteCount, setMonthInviteCount] = useState(item?.monthInviteCount ?? null)
  const [levelSelected, setLevelSelected] = useState(
    item?.levelDetails?.map((x) => {
      var level = currentLevel?.find((y) => y.id === x.levelInvite)
      return {
        value: x.levelInvite,
        label: `${level.levelNumber} - ${level?.fullName})`,
      }
    }) ?? []
  )
  const [levelDetails, setLevelDetails] = useState(
    item?.levelDetails?.map((x) => {
      var level = currentLevel?.find((y) => y.id === x.levelInvite)
      return {
        levelInvite: x.levelInvite,
        level: `${level.levelNumber} - ${level?.fullName})`,
        inviteTotal: x.inviteTotal,
      }
    }) ?? []
  )

  const [levelOptions] = useState(
    currentLevel?.map((x) => ({
      value: x.id,
      label: `${x.levelNumber} - ${x.fullName})`,
    }))
  )

  useEffect(() => {
    const selectedValue = levelSelected.map((x) => x.value)
    const removedRows = levelDetails.filter((row) => !selectedValue.includes(row.levelInvite))
   
    const addedRows = levelSelected
      .filter((item) => !levelDetails.find((row) => row.levelInvite === item.value))
      .map((item) => ({
        levelInvite: item.value,
        level: item.label,
        inviteTotal: 0,
      }))


    setLevelDetails((prevRows) => {
      let updatedRows = [...prevRows]
      removedRows.forEach((row) => {
        updatedRows = updatedRows.filter((r) => r.levelInvite !== row.levelInvite)
      })
      updatedRows.push(...addedRows)
      return updatedRows
    })
  }, [levelSelected])

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return
    setIsLoading(true)

    const payload = {
      id: item?.id,
      levelCode,
      levelName,
      description,
      totalAmount,
      maintainForMonths,
      monthInviteCount,
      isActive: true,
      levelNumber,
      levelDetails,
    }

    console.log(payload)

    const { error } = isEdit ? await update(payload) : await add(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      fetchItems()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
    setIsLoading(false)
    closeModal(false)
  }

  const handleCellValueChange = (cell, value) => {
    const updatedRows = [...levelDetails]
    const rowIndex = updatedRows.findIndex((row) => row.levelInvite === cell.row.original.levelInvite)
    if (rowIndex === -1) {
      return
    }
    updatedRows[rowIndex].inviteTotal = value
    setLevelDetails(updatedRows)
  }

  const columm = [
    {
      Header: `${intl.formatMessage({ id: 'Level.Column.LevelName' })}`,
      accessor: 'level',
      width: 200,
    },
    {
      Header: `${intl.formatMessage({ id: 'Level.Column.TotalRank' })}`,
      accessor: 'inviteTotal',
      width: 150,
      editable: true,
    },
  ]

  return (
    <Modal title={intl.formatMessage({ id: isEdit ? 'Update' : 'AddNew' })} closeModal={closeModal} modalSize='mw-950px'>
      <form id='kt_modal_add_Banner_form' className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_Banner_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
          <div className='col-md-4 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.levelNumber = ref)}
                controlType={ControlType.NumberBox}
                type='input'
                label={intl.formatMessage({ id: 'Level.Column.LevelNumber' })}
                value={levelNumber}
                onChange={(e) => setLevelNumber(e.target.value)}
                tabIndex={1}
                isRequired={true}
              />
            </div>

            <div className='col-md-8 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.levelCode = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Level.Column.LevelCode' })}
                value={levelCode}
                onChange={(e) => setLevelCode(e.target.value)}
                tabIndex={1}
                isRequired={true}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.levelName = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Level.Column.LevelName' })}
                value={levelName}
                onChange={(e) => setLevelName(e.target.value)}
                tabIndex={2}
                isRequired={true}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.description = ref)}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Level.Column.Description' })}
                value={description}
                rows={3}
                onChange={(e) => setDescription(e.target.value)}
                tabIndex={3}
              />
            </div>

            <div className='col-md-4 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.totalAmount = ref)}
                controlType={ControlType.NumberBox}
                type='input'
                label={intl.formatMessage({ id: 'Level.Column.TotalAmount' })}
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
                tabIndex={4}
                isRequired={true}
              />
            </div>

            <div className='col-md-4 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.maintainForMonths = ref)}
                controlType={ControlType.NumberBox}
                type='input'
                label={intl.formatMessage({ id: 'Level.Column.MaintainForMonths' })}
                value={maintainForMonths}
                onChange={(e) => setMaintainForMonths(e.target.value)}
                tabIndex={6}
                isRequired={true}
              />
            </div>

            <div className='col-md-4 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.monthInviteCount = ref)}
                controlType={ControlType.NumberBox}
                type='input'
                label={intl.formatMessage({ id: 'Level.Column.MonthInviteCount' })}
                value={monthInviteCount}
                onChange={(e) => setMonthInviteCount(e.target.value)}
                tabIndex={7}
                isRequired={true}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.levelSelected = ref)}
                tabIndex={9}
                controlType={ControlType.TagInput}
                onChange={(e) => setLevelSelected(e)}
                value={levelSelected}
                label={intl.formatMessage({ id: 'Level.Column.BranchDetail' })}
                options={levelOptions}
                menuPosition={'top'}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <Table columns={columm} data={levelDetails} onCellValueChange={handleCellValueChange} />
            </div>
          </div>
        </div>

        <div className='text-center p-4'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className='btn btn-light me-3' tabIndex={9}>
            <i className='fa fa-times'></i>
            <FormattedMessage id='Close' />
          </Button>
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className='btn btn-primary' tabIndex={10}>
            <i className='fa fa-save'></i>
            <FormattedMessage id='Save' />
          </Button>
        </div>

        {isLoading && <Loading />}
      </form>
    </Modal>
  )
}

export default LevelModal
