import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class BlogApi extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.BLOG)
    this.adapter = this.initAdapter(conf)
  }

  async getBlogs(payload) {
    return await this.adapter.postAsync('getBlogs', { payload })
  }

  async saveBlog(payload) {
    return await this.adapter.postAsync('saveBlog', { payload })
  }

  async updateBlog(payload) {
    return await this.adapter.putAsync('updateBlog', { payload })
  }

  async deleteBlog(blogId) {
    return await this.adapter.deleteAsync(`deleteBlog?id=${blogId}`)
  }

  async getBlogById(blogId) {
    return await this.adapter.getAsync(`getById?id=${blogId}`)
  }
}

export default BlogApi
