import { Link } from 'react-router-dom'
import { Icon, toAbsoluteUrl } from '../../../../helpers'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { getNotifications } from '../../../../redux/actions/eco/notificationActions'

const Notification = () => {
  const intl = useIntl()
  const [notifications, setNotifications] = useState([])
  const [countUnread, setCountUnread] = useState(0)
  const user = JSON.parse(localStorage.getItem(process.env.REACT_APP_AUTH_LOCAL_USER))

  const fetchNotifications = async () => {
    const { result } = await getNotifications(user.id)
    if (result) {
      setNotifications(result.notifications)
      setCountUnread(result.countUnread)
    }
  }

  useEffect(() => {
    fetchNotifications()
  }, [])

  return (
    <div className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px" data-kt-menu="true">
      <div className="d-flex flex-column bgi-no-repeat rounded-top" style={{ backgroundImage: `url('${toAbsoluteUrl('/media/patterns/pattern-1.jpg')}')` }}>
        <h3 className="text-white fw-bold px-8 my-4">
          {intl.formatMessage({ id: 'Notifications' })}
          <span className="badge badge-danger ms-1 mb-1 fs-8 ps-3 float-end">
            {countUnread} {intl.formatMessage({ id: 'News' })}
          </span>
        </h3>
      </div>

      <div className="scroll-y mh-325px my-5 px-8">
        {notifications.map((log, index) => (
          <div key={`log${index}`} className="d-flex flex-stack py-4">
            <div className="d-flex align-items-center me-2">
              {/*<span className={`w-70px badge badge-light-${'log.state'} me-4`}>{'log.code'}</span>*/}

              <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                {log.content}
              </a>

              <span className="badge badge-light fs-8">{log.sendDate}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="text-center border-top">
        <Link to="/" className="btn btn-color-gray-600 btn-active-color-primary">
          {intl.formatMessage({ id: 'ViewAll' })} <Icon iconName="arrow-right" className="fs-5" />
        </Link>
      </div>
    </div>
  )
}

export { Notification }
