/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import { DEFAULT_VALUE, ROLE_NAME } from '../../../../utilities/constant'
import { useNavigate } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import { getProducts, deleteProduct, approveProduct, rejectProduct } from '../../../../redux/actions/eco/productActions'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import Confirm from '../../components/Confirm'
import _ from 'lodash'
import { ProductStatusEnum, DiscountTypeEnum, getKeyByValue, getEnums } from '../../../../utilities/enums'
import NumberBox from '../../components/NumberBox'
import ExportExcel from '../../components/ExportExcel'
import TableSearch from '../../components/TableSearch'
import { ControlType, FormControl } from '../../components/FormControl'
import ProductFilter from './ProductFilter'
import { Auth } from '../../../auth/Auth'

const Product = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const currentUser = Auth.getUserData() || {}
  const componentRefs = useRef({})

  const [products, setProducts] = useState([])
  const [productsOriginals, setProductsOriginals] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [confirmRemove, setConfirmRemove] = useState(false)
  const [confirmReject, setConfirmReject] = useState(false)
  const [confirmApprove, setConfirmApprove] = useState(false)
  const [productSelected, setProductSelected] = useState(null)

  const [productStatus, setProductStatus] = useState(null)
  const [productStatusReload, setProductStatusReload] = useState(-1)

  const [status] = useState(() => getEnums(ProductStatusEnum, intl))

  useEffect(() => {
    fetchProduct()
  }, [currentPage, pageSize, productStatus, productStatusReload])

  const fetchProduct = async (productCode, productName, supplierId, categoryId, subCategoryId, groupId) => {
    setIsLoading(true)
    try {
      const { result } = await getProducts({
        pageIndex: currentPage,
        pageSize: pageSize,
        productStatus: productStatus ?? null,
        productCode: productCode ?? null,
        productName: productName ?? null,
        supplierId: supplierId ?? null,
        categoryId: categoryId ?? null,
        subCategoryId: subCategoryId ?? null,
        groupId: groupId ?? null,
      })

      if (result) {
        setProducts(result.items)
        setProductsOriginals(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleEdit = (item) => {
    navigate(`/eco/product/${item.id}/edit`)
  }

  const handleAddNew = () => {
    navigate(`/eco/product/${DEFAULT_VALUE.GUID_EMPTY}/edit`)
  }

  const handleConfirmRemove = (item) => {
    setConfirmRemove(true)
    setProductSelected(item)
  }

  const handleConfirmReject = (item) => {
    setConfirmReject(true)
    setProductSelected(item)
  }

  const handleConfirmApprove = (item) => {
    if (item.productStatus === ProductStatusEnum.PendingApproval) {
      setConfirmApprove(true)
      setProductSelected(item)
    }
  }

  const handleRemove = async () => {
    if (productSelected.productStatus === ProductStatusEnum.PendingApproval) return
    setIsLoading(true)
    try {
      const { error } = await deleteProduct(productSelected.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        setProducts(products.filter((item) => item.id !== productSelected.id))
        setConfirmRemove(false)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleReject = async () => {
    if (productSelected.productStatus !== ProductStatusEnum.PendingApproval || currentUser.roleName !== ROLE_NAME.ADMIN) return
    setIsLoading(true)
    try {
      const { error } = await rejectProduct(productSelected.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
        setConfirmReject(false)
        setProductStatusReload(ProductStatusEnum.Rejected)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleApprove = async () => {
    if (productSelected.productStatus !== ProductStatusEnum.PendingApproval || currentUser.roleName !== ROLE_NAME.ADMIN) return
    setIsLoading(true)
    try {
      const { error } = await approveProduct(productSelected.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
        setConfirmApprove(false)
        setProductStatusReload(ProductStatusEnum.PendingApproval)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'Product.Title.Media' })}`,
      accessor: 'image',
      width: 66,
      className: 'min-w-55px',
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.ProductName' })}`,
      accessor: 'productName',
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.DiscountedPrice' })}`,
      accessor: 'basePrice',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.Inventory' })}`,
      accessor: 'qtyRemain',
      width: 60,
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.Supplier' })}`,
      accessor: 'supplierName',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Action' })}`,
      accessor: 'action',
      width: 60,
    },
  ]
  const rows = products.map((item) => {
    return {
      image: (
        <div className='symbol symbol-50px d-flex justify-content-center align-items-center'>
          <img className='symbol-label' src={DEFAULT_VALUE.BASE_URL + item.logoUrl} alt='' />
        </div>
      ),
      productName: (
        <div>
          <div>
            <b>
              <FormattedMessage id='Product.Column.ProductCode' />:{' '}
            </b>
            {item.productCode}
          </div>
          <div>
            <b>
              <FormattedMessage id='Product.Column.ProductName' />:{' '}
            </b>
            {item.productName}
          </div>
          <div>
            <b>
              <FormattedMessage id='Product.Column.Status' />:{' '}
            </b>
            <span
              onClick={() => handleConfirmApprove(item)}
              className={`badge badge-light-${
                item.productStatus === ProductStatusEnum.PendingApproval
                  ? 'primary'
                  : item.productStatus === ProductStatusEnum.Approved
                  ? 'success'
                  : 'secondary'
              } me-1 fs-7 cursor-pointer`}
            >
              <FormattedMessage id={`Product.Title.${getKeyByValue(ProductStatusEnum, item.productStatus)}`} />
            </span>
          </div>
        </div>
      ),
      basePrice: (
        <div>
          <div>
            <b>
              <FormattedMessage id='Product.Column.BasePrice' />
              :&nbsp;
            </b>
            <NumberBox type='text' value={item.basePrice} className='' suffix='đ' />
          </div>
          <div>
            <b>
              <FormattedMessage className='bold' id='Product.Column.DiscountedPrice' />
              :&nbsp;
            </b>
            {item.discountType === DiscountTypeEnum.FixedPrice ? (
              <NumberBox type='text' value={item.discountedPrice} className='' suffix='đ' />
            ) : item.discountType === DiscountTypeEnum.Percentage ? (
              <NumberBox type='text' value={item.discountedPercent} className='' suffix='%' />
            ) : (
              ''
            )}
          </div>
          <div>
            <b>
              <FormattedMessage id='Product.Column.CommissionMoney' />
              :&nbsp;
            </b>
            <NumberBox type='text' value={item.commissionMoney} className='' suffix='đ' />
          </div>
        </div>
      ),
      qtyRemain: item.qtyRemain,
      supplierName: item.supplierName,
      action: (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          {item.productStatus === ProductStatusEnum.PendingApproval && currentUser.roleName === ROLE_NAME.ADMIN && (
            <>
              <a onClick={() => handleConfirmApprove(item)} className='btn btn-icon btn-primary btn-sm' title={intl.formatMessage({ id: 'Approve' })}>
                <i className='fa fa-check' />
              </a>

              <a onClick={() => handleConfirmReject(item)} className='btn btn-icon btn-danger btn-sm' title={intl.formatMessage({ id: 'Ignore' })}>
                <i className='fa fa-xmark' />
              </a>
            </>
          )}

          <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm' title={intl.formatMessage({ id: 'Edit' })}>
            <i className='fa-solid fa-pen-to-square' />
          </a>

          {item.productStatus === ProductStatusEnum.PendingApproval && (
            <a onClick={() => handleConfirmRemove(item)} className='btn btn-icon btn-light-danger btn-sm' title={intl.formatMessage({ id: 'Delete' })}>
              <i className='fa-solid fa-trash' />
            </a>
          )}
        </div>
      ),
    }
  })

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='card-toolbar gap-3'>
          <a onClick={() => handleAddNew()} className='btn btn-sm btn-success'>
            <i className='ki-outline ki-plus fs-2'></i> {intl.formatMessage({ id: 'AddNew' })}
          </a>

          <ExportExcel fileName={'product'} columns={columns} data={rows} />

          <ProductFilter fetchData={fetchProduct} />
        </div>

        <div className='card-toolbar gap-3'>
          <div className='min-w-200px'>
            <FormControl
              ref={(ref) => (componentRefs.current.productStatus = ref)}
              controlType={ControlType.Select}
              label={intl.formatMessage({ id: 'Status' })}
              hideLabel={true}
              value={productStatus}
              onChange={(e) => setProductStatus(e.value)}
              options={status}
            />
          </div>

          <TableSearch onSearch={setProducts} originalData={productsOriginals} />
        </div>
      </div>
      <div className='card-body py-2 pt-0'>
        <div className='table-responsive'>
          <Table columns={columns} data={rows} />

          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={(e) => setCurrentPage(e)}
            onItemsPerPageChange={(e) => setPageSize(e)}
          />

          {isLoading && <Loading />}
        </div>
      </div>
      {confirmRemove && (
        <Confirm
          onConfirm={handleRemove}
          onCancel={() => setConfirmRemove(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Remove' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}
      {confirmReject && (
        <Confirm
          onConfirm={handleReject}
          onCancel={() => setConfirmReject(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Reject' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Ignore' })}
        />
      )}
      {confirmApprove && (
        <Confirm
          onConfirm={handleApprove}
          onCancel={() => setConfirmApprove(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Approval' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Approve' })}
        />
      )}
    </div>
  )
}

export default Product
