import React, { useEffect, useState } from 'react'
import { Icon } from '../../../../helpers'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import { useIntl } from 'react-intl'
import { getAll, deleteCategory } from '../../../../redux/actions/eco/categoryActions'
import { deleteSubCategory, getByCategoryId } from '../../../../redux/actions/eco/subCategoryActions'
import _ from 'lodash'
import CategoryModal from './CategoryModal'
import ConfirmRemove from '../../components/Confirm'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import SubCategoryModal from './SubCategoryModal'
import GroupModal from './GroupModal'
import Table from '../../components/Table'
import TableSearch from '../../components/TableSearch'

const Category = () => {
  const intl = useIntl()

  //category
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [categories, setCategories] = useState([])
  const [categoriesOriginal, setCategoriesOriginal] = useState([])
  const [isLoadingCategory, setIsLoadingCategory] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [category, setCategory] = useState(null)
  const [isEditCategory, setIsEditCategory] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)

  //subCategory
  const [isEditSubCategory, setIsEditSubCategory] = useState(false)
  const [subCategories, setSubCategories] = useState([])
  const [subCategoriesOriginal, setSubCategoriesOriginal] = useState([])
  const [subCategory, setSubCategory] = useState(null)
  const [isLoadingSubCategory, setIsLoadingSubCategory] = useState(false)
  const [confirmVisibleSub, setConfirmVisibleSub] = useState(false)

  //Group
  const [group, setGroup] = useState(null)
  const [isEditGroup, setIsEditGroup] = useState(false)

  useEffect(() => {
    fetchCategory()
  }, [])

  const fetchCategory = async () => {
    setIsLoadingCategory(true)
    try {
      const { result } = await getAll()
      if (result) {
        setCategories(result)
        setCategoriesOriginal(result)
        handleSelectedCategory(result && result[0])
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingCategory(false)
    }
  }

  const fetchSubCategory = async () => {
    getSubCategoriesByCategoryId(selectedCategory?.id)
  }

  const getNumberCyclically = (currentIndex) => {
    return (currentIndex % 15) + 1
  }

  //#region category
  const addCategory = () => {
    setIsEditCategory(true)
    setCategory({ isAdded: true })
  }

  const confirmRemoveCategory = (item) => {
    setConfirmVisible(true)
    setCategory(item)
  }

  const handleCancelRemoveCategory = () => {
    setConfirmVisible(false)
  }

  const handleRemoveCategory = async () => {
    setIsLoadingCategory(true)
    try {
      const { error } = await deleteCategory(category.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        setCategories(categories.filter((item) => item.id !== category.id))
        setSubCategories(subCategories.filter((item) => item.categoryId !== category.id))
        setConfirmVisible(false)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingCategory(false)
    }
  }

  const handleEditCategory = (item) => {
    setCategory(item)
    setIsEditCategory(true)
  }

  const handleSelectedCategory = (item) => {
    setSelectedCategory(item)
    getSubCategoriesByCategoryId(item.id)
  }
  //#endregion

  //#region subCategory
  const addSubCategory = () => {
    setIsEditSubCategory(true)
    setSubCategory({ categoryId: selectedCategory?.id, categoryName: selectedCategory?.categoryName, isAdded: true })
  }

  const getSubCategoriesByCategoryId = async (categoryId) => {
    try {
      const { result } = await getByCategoryId({ categoryId: categoryId, pageIndex: currentPage, pageSize: pageSize })
      if (result) {
        setTotalPages(result.totalCount)
        setSubCategories(result.items)
        setSubCategoriesOriginal(result.items)
      }
    } catch (error) {
      console.error('Error fetching initial roles:', error)
    }
  }

  const handleEditSubCategory = (item) => {
    setSubCategory({ ...item, categoryName: selectedCategory?.categoryName })
    setIsEditSubCategory(true)
  }

  const confirmRemoveSubCategory = (item) => {
    setConfirmVisibleSub(true)
    setSubCategory(item)
  }

  const handleRemoveSubCategory = async () => {
    setIsLoadingSubCategory(true)
    try {
      const { error } = await deleteSubCategory(subCategory.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        fetchSubCategory()
        setConfirmVisibleSub(false)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoadingSubCategory(false)
    }
  }
  //#endregion

  //Group
  const handleEditGroup = (item, parentName) => {
    setGroup({ ...item, categoryName: selectedCategory?.categoryName, parentName: parentName })
    setIsEditGroup(true)
  }

  const subcategoryColumns = [
    {
      Header: `${intl.formatMessage({ id: 'SubCategory.Column.SubCategoryName' })}`,
      accessor: 'subCategoryName',
      width: 150,
    },
    {
      Header: `${intl.formatMessage({ id: 'SubCategory.Column.GroupProductName' })}`,
      accessor: 'groupProductName',
    },
    {
      Header: `${intl.formatMessage({ id: 'Action' })}`,
      accessor: 'action',
      width: 60,
    },
  ]

  const subcategoryRows = subCategories.map((item) => {
    return {
      subCategoryName: item.subCategoryName,
      groupProductName: (
        <span>
          {item.groups.map((group) => {
            return (
              <span
                key={`group-${group.id}`}
                className='badge badge-light-success me-1 fs-7 cursor-pointer'
                onClick={() => handleEditGroup(group, item.subCategoryName)}
              >
                {group.subCategoryName}
              </span>
            )
          })}
        </span>
      ),
      action: (
        <div className='d-flex align-items-center justify-content-center'>
          <a onClick={() => handleEditSubCategory(item)} className='btn btn-icon btn-light-success btn-sm me-1'>
            <i className='fa-solid fa-pen-to-square'></i>
          </a>
          <a onClick={() => confirmRemoveSubCategory(item)} className='btn btn-icon btn-light-danger btn-sm'>
            <i className='fa-solid fa-trash'></i>
          </a>
        </div>
      ),
    }
  })

  return (
    <div className='d-flex flex-column flex-lg-row'>
      {/*category */}
      <div className='flex-column flex-lg-row-auto col-sm-4 min-w-450px'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title fw-bold text-dark'>{intl.formatMessage({ id: 'Menu.Eco.Category' })}</h3>

            <div className='card-toolbar'>
              <a className='btn btn-sm btn-success me-2' onClick={() => addCategory()} title={intl.formatMessage({ id: 'AddNew' })}>
                <i className='fa-solid fa-plus'></i>
                {intl.formatMessage({ id: 'AddNew' })}
              </a>

              <TableSearch onSearch={setCategories} originalData={categoriesOriginal} />
            </div>
          </div>
          <div className='card-body pt-5'>
            <div
              className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
              data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
              data-kt-scroll-offset='0px'
            >
              {categories?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className='d-flex align-items-center'>
                      {
                        <>
                          <div className='symbol symbol-50px me-5'>
                            <img className={`symbol-label bg-${getNumberCyclically(index)}`} src={DEFAULT_VALUE.BASE_URL + item.logo} />
                          </div>
                          <div
                            className={`flex-grow-1 py-2 me-1 pointer rounded ${selectedCategory?.id === item.id ? 'selected' : ''}`}
                            onClick={() => handleSelectedCategory(item)}
                          >
                            <span className={`text-gray-900 text-hover-primary ms-4`}>{item.categoryName}</span>
                          </div>
                          <div className='d-flex align-items-center'>
                            <a onClick={() => handleEditCategory(item)} className='btn btn-icon btn-light-success btn-sm me-1'>
                              <i className='fa-solid fa-pen-to-square'></i>
                            </a>
                            <a onClick={() => confirmRemoveCategory(item)} className='btn btn-icon btn-light-danger btn-sm'>
                              <i className='fa-solid fa-trash'></i>
                            </a>
                          </div>
                        </>
                      }
                    </div>
                    <div className='separator my-2'></div>
                  </React.Fragment>
                )
              })}
            </div>
            {isLoadingCategory && <Loading />}
          </div>
        </div>
      </div>

      {/* Subcategory */}
      <div className='flex-lg-row-fluid ms-4'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title fw-bold text-dark'>{selectedCategory?.categoryName ?? intl.formatMessage({ id: 'Menu.Eco.SubCategory' })}</h3>
            
            <div className='card-toolbar'>
              {selectedCategory && (
                <a className='btn btn-sm btn-success me-2' onClick={() => addSubCategory()} title={intl.formatMessage({ id: 'AddNew' })}>
                  <i className='fa-solid fa-plus'></i>
                  {intl.formatMessage({ id: 'AddNew' })}
                </a>
              )}

              <TableSearch onSearch={setSubCategories} originalData={subCategoriesOriginal} />
            </div>
          </div>
          
          <div className='card-body py-2 pt-0'>
            <Table columns={subcategoryColumns} data={subcategoryRows} />
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              itemsPerPage={pageSize}
              onPageChange={(e) => setCurrentPage(e)}
              onItemsPerPageChange={(e) => setPageSize(e)}
            />
            {isLoadingSubCategory && <Loading />}
          </div>
        </div>
      </div>

      {isEditCategory && <CategoryModal editCategory={category} closeModal={setIsEditCategory} fetchCategory={fetchCategory} />}
      {confirmVisible && (
        <ConfirmRemove
          onConfirm={handleRemoveCategory}
          onCancel={handleCancelRemoveCategory}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}

      {isEditSubCategory && <SubCategoryModal editSubCategory={subCategory} closeModal={setIsEditSubCategory} fetchSubCategory={fetchSubCategory} />}
      {confirmVisibleSub && (
        <ConfirmRemove
          onConfirm={handleRemoveSubCategory}
          onCancel={() => setConfirmVisibleSub(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}

      {isEditGroup && <GroupModal group={group} closeModal={setIsEditGroup} fetchSubCategory={fetchSubCategory} />}
    </div>
  )
}

export default Category
