import { toAbsoluteUrl } from '../../../helpers'
import AreaChart from '../components/AreaChart'
import BarChart from '../components/BarChart'
import PieChart from '../components/PieChart'

const Dashboard = () => {
  const dataPie = {
    series: [30, 15, 55],
    options: {
      chart: {
        type: 'pie',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '25%',
          },
        },
      },
      labels: ['Shoes - $7,660', 'Clothers - $2,820', 'Others - $45,257'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  }

  const dataColumnChart = {
    series: [
      {
        name: 'Sales',
        data: [30, 60, 53, 45, 60, 75, 53],
      },
    ],
    options: {
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        //height: height,
        toolbar: {
          show: true,
        },
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ['100%'],
          borderRadius: 6,
        },
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        show: true,
        width: 9,
        colors: ['transparent'],
      },
      xaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
          tickPlacement: 'between',
        },
        labels: {
          show: false,
          style: {
            //colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
          style: {
            //colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      fill: {
        type: 'solid',
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        x: {
          formatter: function (val) {
            return 'Feb: ' + val
          },
        },
        y: {
          formatter: function (val) {
            return val + '%'
          },
        },
      },
      //colors: [baseColor, secondaryColor],
      grid: {
        padding: {
          left: 10,
          right: 10,
        },
        //borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    },
  }

  const dataAreaChart = {
    series: [
      {
        name: 'Sales',
        data: [18, 50, 18, 20, 20, 18, 18, 22, 22, 20, 20, 18, 18, 20, 20, 18, 18, 20, 20, 22],
      },
    ],
    options: {
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        //height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 80, 100],
        },
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        //colors: [baseColor],
      },
      xaxis: {
        categories: [
          '',
          'Apr 01',
          'Apr 02',
          'Apr 03',
          'Apr 04',
          'Apr 05',
          'Apr 06',
          'Apr 07',
          'Apr 08',
          'Apr 09',
          'Apr 10',
          'Apr 11',
          'Apr 12',
          'Apr 13',
          'Apr 14',
          'Apr 15',
          'Apr 16',
          'Apr 17',
          'Apr 18',
          '',
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 6,
        labels: {
          rotate: 0,
          rotateAlways: true,
          style: {
            //colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          position: 'front',
          stroke: {
            //color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        tickAmount: 4,
        max: 50, // số max của series data
        min: 10, // số min của series data
        labels: {
          style: {
            //colors: labelColor,
            fontSize: '12px',
          },
          formatter: function (val) {
            return '$' + val + 'K'
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return '$' + val + 'K'
          },
        },
      },
      //colors: [lightColor],
      grid: {
        //borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        //strokeColor: baseColor,
        strokeWidth: 3,
      },
    },
  }

  const dataDiscountChart = {
    series: [
      {
        name: 'Sales',
        data: [34.5, 34.5, 35, 35, 35.5, 35.5, 35, 35, 35.5, 35.5, 35, 35, 34.5, 34.5, 35, 35, 35.5, 35.5, 35],
      },
    ],
    options: {
      series: [
        {
          name: 'Sales',
          data: [34.5, 34.5, 35, 35, 35.5, 35.5, 35, 35, 35.5, 35.5, 35, 35, 34.5, 34.5, 35, 35, 35.5, 35.5, 35],
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: 'area',
        //height: height,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {},
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 80, 100],
        },
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        //colors: [baseColor],
      },
      xaxis: {
        categories: [
          '',
          'Apr 02',
          'Apr 03',
          'Apr 04',
          'Apr 05',
          'Apr 06',
          'Apr 07',
          'Apr 08',
          'Apr 09',
          'Apr 10',
          'Apr 11',
          'Apr 12',
          'Apr 13',
          'Apr 14',
          'Apr 17',
          'Apr 18',
          'Apr 19',
          'Apr 21',
          '',
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickAmount: 6,
        labels: {
          rotate: 0,
          rotateAlways: true,
          style: {
            //colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          position: 'front',
          stroke: {
            //color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        max: 36.3, // số max của series data
        min: 33, // số min của series data
        tickAmount: 6,
        labels: {
          style: {
            //colors: labelColor,
            fontSize: '12px',
          },
          formatter: function (val) {
            return '$' + parseInt(10 * val)
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return '$' + parseInt(10 * val)
          },
        },
      },
      //colors: [lightColor],
      grid: {
        //borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        //strokeColor: baseColor,
        strokeWidth: 3,
      },
    },
  }

  return (
    <>
      <div>
        {/*begin::App*/}
        <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
          {/*begin::Page*/}
          <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
            {/*begin::Wrapper*/}
            <div className="app-wrapper flex-row flex-row-fluid" id="kt_app_wrapper">
              {/*begin::Main*/}
              <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                {/*begin::Content wrapper*/}
                <div className="d-flex flex-column flex-column-fluid">
                  {/*begin::Content*/}
                  <div id="kt_app_content" className="app-content flex-column-fluid">
                    {/*begin::Content container*/}
                    <div id="kt_app_content_container" className="app-container container-fluid">
                      {/*begin::Row*/}
                      <div className="row g-5 g-xl-10 mb-xl-10">
                        {/*begin::Col*/}
                        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                          {/*begin::Card widget 4*/}
                          <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                            {/*begin::Header*/}
                            <div className="card-header pt-5">
                              {/*begin::Title*/}
                              <div className="card-title d-flex flex-column">
                                {/*begin::Info*/}
                                <div className="d-flex align-items-center">
                                  {/*begin::Currency*/}
                                  <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">$</span>
                                  {/*end::Currency*/}
                                  {/*begin::Amount*/}
                                  <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">69,700</span>
                                  {/*end::Amount*/}
                                  {/*begin::Badge*/}
                                  <span className="badge badge-light-success fs-base">
                                    <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                    2.2%
                                  </span>
                                  {/*end::Badge*/}
                                </div>
                                {/*end::Info*/}
                                {/*begin::Subtitle*/}
                                <span className="text-gray-400 pt-1 fw-semibold fs-6">Expected Earnings</span>
                                {/*end::Subtitle*/}
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Header*/}
                            {/*begin::Card body*/}
                            <div className="card-body">
                              {/*begin::Chart*/}
                              <div className="d-flex flex-center me-5 pt-2">
                                <PieChart dataPie={dataPie} width={350} />
                              </div>
                              {/*end::Chart*/}
                            </div>
                            {/*end::Card body*/}
                          </div>
                          {/*end::Card widget 4*/}
                          {/*begin::Card widget 5*/}
                          <div className="card card-flush h-md-50 mb-xl-10">
                            {/*begin::Header*/}
                            <div className="card-header pt-5">
                              {/*begin::Title*/}
                              <div className="card-title d-flex flex-column">
                                {/*begin::Info*/}
                                <div className="d-flex align-items-center">
                                  {/*begin::Amount*/}
                                  <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">1,836</span>
                                  {/*end::Amount*/}
                                  {/*begin::Badge*/}
                                  <span className="badge badge-light-danger fs-base">
                                    <i className="ki-outline ki-arrow-down fs-5 text-danger ms-n1" />
                                    2.2%
                                  </span>
                                  {/*end::Badge*/}
                                </div>
                                {/*end::Info*/}
                                {/*begin::Subtitle*/}
                                <span className="text-gray-400 pt-1 fw-semibold fs-6">Orders This Month</span>
                                {/*end::Subtitle*/}
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Header*/}
                            {/*begin::Card body*/}
                            <div className="card-body d-flex align-items-end pt-0">
                              {/*begin::Progress*/}
                              <div className="d-flex align-items-center flex-column mt-3 w-100">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                  <span className="fw-bolder fs-6 text-dark">1,048 to Goal</span>
                                  <span className="fw-bold fs-6 text-gray-400">62%</span>
                                </div>
                                <div className="h-8px mx-3 w-100 bg-light-success rounded">
                                  <div
                                    className="bg-success rounded h-8px"
                                    role="progressbar"
                                    style={{ width: '62%' }}
                                    aria-valuenow={50}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                  />
                                </div>
                              </div>
                              {/*end::Progress*/}
                            </div>
                            {/*end::Card body*/}
                          </div>
                          {/*end::Card widget 5*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                          {/*begin::Card widget 6*/}
                          <div className="card card-flush h-md-50 mb-5 mb-xl-10">
                            {/*begin::Header*/}
                            <div className="card-header pt-5">
                              {/*begin::Title*/}
                              <div className="card-title d-flex flex-column">
                                {/*begin::Info*/}
                                <div className="d-flex align-items-center">
                                  {/*begin::Currency*/}
                                  <span className="fs-4 fw-semibold text-gray-400 me-1 align-self-start">$</span>
                                  {/*end::Currency*/}
                                  {/*begin::Amount*/}
                                  <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">2,420</span>
                                  {/*end::Amount*/}
                                  {/*begin::Badge*/}
                                  <span className="badge badge-light-success fs-base">
                                    <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                    2.6%
                                  </span>
                                  {/*end::Badge*/}
                                </div>
                                {/*end::Info*/}
                                {/*begin::Subtitle*/}
                                <span className="text-gray-400 pt-1 fw-semibold fs-6">Average Daily Sales</span>
                                {/*end::Subtitle*/}
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Header*/}
                            {/*begin::Card body*/}
                            <div className="card-body">
                              {/*begin::Chart*/}
                              <BarChart dataColumn={dataColumnChart} height={170} />
                              {/*end::Chart*/}
                            </div>
                            {/*end::Card body*/}
                          </div>
                          {/*end::Card widget 6*/}
                          {/*begin::Card widget 7*/}
                          <div className="card card-flush h-md-50 mb-xl-10">
                            {/*begin::Header*/}
                            <div className="card-header pt-5">
                              {/*begin::Title*/}
                              <div className="card-title d-flex flex-column">
                                {/*begin::Amount*/}
                                <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">6.3k</span>
                                {/*end::Amount*/}
                                {/*begin::Subtitle*/}
                                <span className="text-gray-400 pt-1 fw-semibold fs-6">New Customers This Month</span>
                                {/*end::Subtitle*/}
                              </div>
                              {/*end::Title*/}
                            </div>
                            {/*end::Header*/}
                            {/*begin::Card body*/}
                            <div className="card-body d-flex flex-column justify-content-end pe-0">
                              {/*begin::Title*/}
                              <span className="fs-6 fw-bolder text-gray-800 d-block mb-2">Today’s Heroes</span>
                              {/*end::Title*/}
                              {/*begin::Users group*/}
                              <div className="symbol-group symbol-hover flex-nowrap">
                                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Alan Warden">
                                  <span className="symbol-label bg-warning text-inverse-warning fw-bold">A</span>
                                </div>
                                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Michael Eberon">
                                  <span className="symbol-label bg-danger text-inverse-danger fw-bold">D</span>
                                </div>
                                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">
                                  <span className="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                                </div>
                                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Melody Macy">
                                  <span className="symbol-label bg-danger text-inverse-danger fw-bold">XC</span>
                                </div>
                                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Perry Matthew">
                                  <span className="symbol-label bg-danger text-inverse-danger fw-bold">P</span>
                                </div>
                                <div className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Barry Walter">
                                  <span className="symbol-label bg-danger text-inverse-danger fw-bold">F</span>
                                </div>
                                <a href="#" className="symbol symbol-35px symbol-circle" data-bs-toggle="modal" data-bs-target="#kt_modal_view_users">
                                  <span className="symbol-label bg-light text-gray-400 fs-8 fw-bold">+42</span>
                                </a>
                              </div>
                              {/*end::Users group*/}
                            </div>
                            {/*end::Card body*/}
                          </div>
                          {/*end::Card widget 7*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
                          {/*begin::Chart widget 3*/}
                          <div className="card card-flush overflow-hidden h-md-100">
                            {/*begin::Header*/}
                            <div className="card-header py-5">
                              {/*begin::Title*/}
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-dark">Sales This Months</span>
                                <span className="text-gray-400 mt-1 fw-semibold fs-6">Users from all channels</span>
                              </h3>
                              {/*end::Title*/}
                              {/*begin::Toolbar*/}
                              <div className="card-toolbar">
                                {/*begin::Menu*/}
                                <button
                                  className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                  data-kt-menu-trigger="click"
                                  data-kt-menu-placement="bottom-end"
                                  data-kt-menu-overflow="true"
                                >
                                  <i className="ki-outline ki-dots-square fs-1" />
                                </button>
                                {/*begin::Menu 2*/}
                                <div
                                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                  data-kt-menu="true"
                                >
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu separator*/}
                                  <div className="separator mb-3 opacity-75" />
                                  {/*end::Menu separator*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">
                                      New Ticket
                                    </a>
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">
                                      New Customer
                                    </a>
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                    {/*begin::Menu item*/}
                                    <a href="#" className="menu-link px-3">
                                      <span className="menu-title">New Group</span>
                                      <span className="menu-arrow" />
                                    </a>
                                    {/*end::Menu item*/}
                                    {/*begin::Menu sub*/}
                                    <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                      {/*begin::Menu item*/}
                                      <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                          Admin Group
                                        </a>
                                      </div>
                                      {/*end::Menu item*/}
                                      {/*begin::Menu item*/}
                                      <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                          Staff Group
                                        </a>
                                      </div>
                                      {/*end::Menu item*/}
                                      {/*begin::Menu item*/}
                                      <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                          Member Group
                                        </a>
                                      </div>
                                      {/*end::Menu item*/}
                                    </div>
                                    {/*end::Menu sub*/}
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">
                                      New Contact
                                    </a>
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu separator*/}
                                  <div className="separator mt-3 opacity-75" />
                                  {/*end::Menu separator*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <div className="menu-content px-3 py-3">
                                      <a className="btn btn-primary btn-sm px-4" href="#">
                                        Generate Reports
                                      </a>
                                    </div>
                                  </div>
                                  {/*end::Menu item*/}
                                </div>
                                {/*end::Menu 2*/}
                                {/*end::Menu*/}
                              </div>
                              {/*end::Toolbar*/}
                            </div>
                            {/*end::Header*/}
                            {/*begin::Card body*/}
                            <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
                              {/*begin::Statistics*/}
                              <div className="px-9 mb-5">
                                {/*begin::Statistics*/}
                                <div className="d-flex mb-2">
                                  <span className="fs-4 fw-semibold text-gray-400 me-1">$</span>
                                  <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">14,094</span>
                                </div>
                                {/*end::Statistics*/}
                                {/*begin::Description*/}
                                <span className="fs-6 fw-semibold text-gray-400">Another $48,346 to Goal</span>
                                {/*end::Description*/}
                              </div>
                              {/*end::Statistics*/}
                              {/*begin::Chart*/}
                              <AreaChart dataArea={dataAreaChart} height={400} />
                              {/*end::Chart*/}
                            </div>
                            {/*end::Card body*/}
                          </div>
                          {/*end::Chart widget 3*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <div className="row gy-5 g-xl-10">
                        {/*begin::Col*/}
                        <div className="col-xl-6 mb-xl-10">
                          {/*begin::Table widget 2*/}
                          <div className="card h-md-100">
                            {/*begin::Header*/}
                            <div className="card-header align-items-center border-0">
                              {/*begin::Title*/}
                              <h3 className="fw-bold text-gray-900 m-0">Recent Orders</h3>
                              {/*end::Title*/}
                              {/*begin::Menu*/}
                              <button
                                className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                data-kt-menu-trigger="click"
                                data-kt-menu-placement="bottom-end"
                                data-kt-menu-overflow="true"
                              >
                                <i className="ki-outline ki-dots-square fs-1" />
                              </button>
                              {/*begin::Menu 2*/}
                              <div
                                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                data-kt-menu="true"
                              >
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                  <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu separator*/}
                                <div className="separator mb-3 opacity-75" />
                                {/*end::Menu separator*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                  <a href="#" className="menu-link px-3">
                                    New Ticket
                                  </a>
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                  <a href="#" className="menu-link px-3">
                                    New Customer
                                  </a>
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                  {/*begin::Menu item*/}
                                  <a href="#" className="menu-link px-3">
                                    <span className="menu-title">New Group</span>
                                    <span className="menu-arrow" />
                                  </a>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu sub*/}
                                  <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                    {/*begin::Menu item*/}
                                    <div className="menu-item px-3">
                                      <a href="#" className="menu-link px-3">
                                        Admin Group
                                      </a>
                                    </div>
                                    {/*end::Menu item*/}
                                    {/*begin::Menu item*/}
                                    <div className="menu-item px-3">
                                      <a href="#" className="menu-link px-3">
                                        Staff Group
                                      </a>
                                    </div>
                                    {/*end::Menu item*/}
                                    {/*begin::Menu item*/}
                                    <div className="menu-item px-3">
                                      <a href="#" className="menu-link px-3">
                                        Member Group
                                      </a>
                                    </div>
                                    {/*end::Menu item*/}
                                  </div>
                                  {/*end::Menu sub*/}
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                  <a href="#" className="menu-link px-3">
                                    New Contact
                                  </a>
                                </div>
                                {/*end::Menu item*/}
                                {/*begin::Menu separator*/}
                                <div className="separator mt-3 opacity-75" />
                                {/*end::Menu separator*/}
                                {/*begin::Menu item*/}
                                <div className="menu-item px-3">
                                  <div className="menu-content px-3 py-3">
                                    <a className="btn btn-primary btn-sm px-4" href="#">
                                      Generate Reports
                                    </a>
                                  </div>
                                </div>
                                {/*end::Menu item*/}
                              </div>
                              {/*end::Menu 2*/}
                              {/*end::Menu*/}
                            </div>
                            {/*end::Header*/}
                            {/*begin::Body*/}
                            <div className="card-body pt-2">
                              {/*begin::Nav*/}
                              <ul className="nav nav-pills nav-pills-custom mb-3">
                                {/*begin::Item*/}
                                <li className="nav-item mb-3 me-3 me-lg-6">
                                  {/*begin::Link*/}
                                  <a
                                    className="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden active w-80px h-85px py-4"
                                    data-bs-toggle="pill"
                                    href="#kt_stats_widget_2_tab_1"
                                  >
                                    {/*begin::Icon*/}
                                    <div className="nav-icon">
                                      <img alt="" src={toAbsoluteUrl('/media/svg/products-categories/t-shirt.svg')} className />
                                    </div>
                                    {/*end::Icon*/}
                                    {/*begin::Subtitle*/}
                                    <span className="nav-text text-gray-700 fw-bold fs-6 lh-1">T-shirt</span>
                                    {/*end::Subtitle*/}
                                    {/*begin::Bullet*/}
                                    <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" />
                                    {/*end::Bullet*/}
                                  </a>
                                  {/*end::Link*/}
                                </li>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <li className="nav-item mb-3 me-3 me-lg-6">
                                  {/*begin::Link*/}
                                  <a
                                    className="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-80px h-85px py-4"
                                    data-bs-toggle="pill"
                                    href="#kt_stats_widget_2_tab_2"
                                  >
                                    {/*begin::Icon*/}
                                    <div className="nav-icon">
                                      <img alt="" src={toAbsoluteUrl('/media/svg/products-categories/gaming.svg')} className />
                                    </div>
                                    {/*end::Icon*/}
                                    {/*begin::Subtitle*/}
                                    <span className="nav-text text-gray-700 fw-bold fs-6 lh-1">Gaming</span>
                                    {/*end::Subtitle*/}
                                    {/*begin::Bullet*/}
                                    <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" />
                                    {/*end::Bullet*/}
                                  </a>
                                  {/*end::Link*/}
                                </li>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <li className="nav-item mb-3 me-3 me-lg-6">
                                  {/*begin::Link*/}
                                  <a
                                    className="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-80px h-85px py-4"
                                    data-bs-toggle="pill"
                                    href="#kt_stats_widget_2_tab_3"
                                  >
                                    {/*begin::Icon*/}
                                    <div className="nav-icon">
                                      <img alt="" src={toAbsoluteUrl('/media/svg/products-categories/watch.svg')} className />
                                    </div>
                                    {/*end::Icon*/}
                                    {/*begin::Subtitle*/}
                                    <span className="nav-text text-gray-600 fw-bold fs-6 lh-1">Watch</span>
                                    {/*end::Subtitle*/}
                                    {/*begin::Bullet*/}
                                    <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" />
                                    {/*end::Bullet*/}
                                  </a>
                                  {/*end::Link*/}
                                </li>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <li className="nav-item mb-3 me-3 me-lg-6">
                                  {/*begin::Link*/}
                                  <a
                                    className="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-80px h-85px py-4"
                                    data-bs-toggle="pill"
                                    href="#kt_stats_widget_2_tab_4"
                                  >
                                    {/*begin::Icon*/}
                                    <div className="nav-icon">
                                      <img alt="" src={toAbsoluteUrl('/media/svg/products-categories/gloves.svg')} className="nav-icon" />
                                    </div>
                                    {/*end::Icon*/}
                                    {/*begin::Subtitle*/}
                                    <span className="nav-text text-gray-600 fw-bold fs-6 lh-1">Gloves</span>
                                    {/*end::Subtitle*/}
                                    {/*begin::Bullet*/}
                                    <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" />
                                    {/*end::Bullet*/}
                                  </a>
                                  {/*end::Link*/}
                                </li>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <li className="nav-item mb-3">
                                  {/*begin::Link*/}
                                  <a
                                    className="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-80px h-85px py-4"
                                    data-bs-toggle="pill"
                                    href="#kt_stats_widget_2_tab_5"
                                  >
                                    {/*begin::Icon*/}
                                    <div className="nav-icon">
                                      <img alt="" src={toAbsoluteUrl('/media/svg/products-categories/shoes.svg')} className="nav-icon" />
                                    </div>
                                    {/*end::Icon*/}
                                    {/*begin::Subtitle*/}
                                    <span className="nav-text text-gray-600 fw-bold fs-6 lh-1">Shoes</span>
                                    {/*end::Subtitle*/}
                                    {/*begin::Bullet*/}
                                    <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" />
                                    {/*end::Bullet*/}
                                  </a>
                                  {/*end::Link*/}
                                </li>
                                {/*end::Item*/}
                              </ul>
                              {/*end::Nav*/}
                              {/*begin::Tab Content*/}
                              <div className="tab-content">
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade show active" id="kt_stats_widget_2_tab_1">
                                  {/*begin::Table container*/}
                                  <div className="table-responsive">
                                    {/*begin::Table*/}
                                    <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                      {/*begin::Table head*/}
                                      <thead>
                                        <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                                          <th className="ps-0 w-50px">ITEM</th>
                                          <th className="min-w-125px" />
                                          <th className="text-end min-w-100px">QTY</th>
                                          <th className="pe-0 text-end min-w-100px">PRICE</th>
                                          <th className="pe-0 text-end min-w-100px">TOTAL PRICE</th>
                                        </tr>
                                      </thead>
                                      {/*end::Table head*/}
                                      {/*begin::Table body*/}
                                      <tbody>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/210.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              Elephant 1802
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-2347</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x1</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$72.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$126.00</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/215.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              Red Laga
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-1321</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x2</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$45.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$76.00</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/209.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              RiseUP
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-4312</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x3</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$84.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$168.00</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                      {/*end::Table body*/}
                                    </table>
                                    {/*end::Table*/}
                                  </div>
                                  {/*end::Table container*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_stats_widget_2_tab_2">
                                  {/*begin::Table container*/}
                                  <div className="table-responsive">
                                    {/*begin::Table*/}
                                    <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                      {/*begin::Table head*/}
                                      <thead>
                                        <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                                          <th className="ps-0 w-50px">ITEM</th>
                                          <th className="min-w-125px" />
                                          <th className="text-end min-w-100px">QTY</th>
                                          <th className="pe-0 text-end min-w-100px">PRICE</th>
                                          <th className="pe-0 text-end min-w-100px">TOTAL PRICE</th>
                                        </tr>
                                      </thead>
                                      {/*end::Table head*/}
                                      {/*begin::Table body*/}
                                      <tbody>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/197.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              Elephant 1802
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-4312</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x1</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$32.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$312.00</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/178.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              Red Laga
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-3122</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x2</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$53.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$62.00</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/22.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              RiseUP
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-1142</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x3</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$74.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$139.00</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                      {/*end::Table body*/}
                                    </table>
                                    {/*end::Table*/}
                                  </div>
                                  {/*end::Table container*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_stats_widget_2_tab_3">
                                  {/*begin::Table container*/}
                                  <div className="table-responsive">
                                    {/*begin::Table*/}
                                    <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                      {/*begin::Table head*/}
                                      <thead>
                                        <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                                          <th className="ps-0 w-50px">ITEM</th>
                                          <th className="min-w-125px" />
                                          <th className="text-end min-w-100px">QTY</th>
                                          <th className="pe-0 text-end min-w-100px">PRICE</th>
                                          <th className="pe-0 text-end min-w-100px">TOTAL PRICE</th>
                                        </tr>
                                      </thead>
                                      {/*end::Table head*/}
                                      {/*begin::Table body*/}
                                      <tbody>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/1.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              Elephant 1324
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-1523</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x1</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$43.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$231.00</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/24.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              Red Laga
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-5314</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x2</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$71.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$53.00</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/71.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              RiseUP
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-4222</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x3</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$23.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$213.00</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                      {/*end::Table body*/}
                                    </table>
                                    {/*end::Table*/}
                                  </div>
                                  {/*end::Table container*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_stats_widget_2_tab_4">
                                  {/*begin::Table container*/}
                                  <div className="table-responsive">
                                    {/*begin::Table*/}
                                    <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                      {/*begin::Table head*/}
                                      <thead>
                                        <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                                          <th className="ps-0 w-50px">ITEM</th>
                                          <th className="min-w-125px" />
                                          <th className="text-end min-w-100px">QTY</th>
                                          <th className="pe-0 text-end min-w-100px">PRICE</th>
                                          <th className="pe-0 text-end min-w-100px">TOTAL PRICE</th>
                                        </tr>
                                      </thead>
                                      {/*end::Table head*/}
                                      {/*begin::Table body*/}
                                      <tbody>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/41.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              Elephant 2635
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-1523</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x1</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$65.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$163.00</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/63.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              Red Laga
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-2745</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x2</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$64.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$73.00</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/59.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              RiseUP
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-5173</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x3</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$54.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$173.00</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                      {/*end::Table body*/}
                                    </table>
                                    {/*end::Table*/}
                                  </div>
                                  {/*end::Table container*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_stats_widget_2_tab_5">
                                  {/*begin::Table container*/}
                                  <div className="table-responsive">
                                    {/*begin::Table*/}
                                    <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                      {/*begin::Table head*/}
                                      <thead>
                                        <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                                          <th className="ps-0 w-50px">ITEM</th>
                                          <th className="min-w-125px" />
                                          <th className="text-end min-w-100px">QTY</th>
                                          <th className="pe-0 text-end min-w-100px">PRICE</th>
                                          <th className="pe-0 text-end min-w-100px">TOTAL PRICE</th>
                                        </tr>
                                      </thead>
                                      {/*end::Table head*/}
                                      {/*begin::Table body*/}
                                      <tbody>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/10.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              Nike
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-2163</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x1</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$64.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$287.00</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/96.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              Adidas
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-2162</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x2</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$76.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$51.00</span>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <img src="assets/media/stock/ecommerce/13.png" className="w-50px ms-n1" alt="" />
                                          </td>
                                          <td className="ps-0">
                                            <a href="#" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0">
                                              Puma
                                            </a>
                                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-1537</span>
                                          </td>
                                          <td>
                                            <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">x3</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$27.00</span>
                                          </td>
                                          <td className="text-end pe-0">
                                            <span className="text-gray-800 fw-bold d-block fs-6">$167.00</span>
                                          </td>
                                        </tr>
                                      </tbody>
                                      {/*end::Table body*/}
                                    </table>
                                    {/*end::Table*/}
                                  </div>
                                  {/*end::Table container*/}
                                </div>
                                {/*end::Tap pane*/}
                              </div>
                              {/*end::Tab Content*/}
                            </div>
                            {/*end: Card Body*/}
                          </div>
                          {/*end::Table widget 2*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-xl-6 mb-5 mb-xl-10">
                          {/*begin::Chart widget 4*/}
                          <div className="card card-flush overflow-hidden h-md-100">
                            {/*begin::Header*/}
                            <div className="card-header py-5">
                              {/*begin::Title*/}
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-dark">Discounted Product Sales</span>
                                <span className="text-gray-400 mt-1 fw-semibold fs-6">Users from all channels</span>
                              </h3>
                              {/*end::Title*/}
                              {/*begin::Toolbar*/}
                              <div className="card-toolbar">
                                {/*begin::Menu*/}
                                <button
                                  className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                  data-kt-menu-trigger="click"
                                  data-kt-menu-placement="bottom-end"
                                  data-kt-menu-overflow="true"
                                >
                                  <i className="ki-outline ki-dots-square fs-1" />
                                </button>
                                {/*begin::Menu 2*/}
                                <div
                                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                  data-kt-menu="true"
                                >
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu separator*/}
                                  <div className="separator mb-3 opacity-75" />
                                  {/*end::Menu separator*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">
                                      New Ticket
                                    </a>
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">
                                      New Customer
                                    </a>
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                    {/*begin::Menu item*/}
                                    <a href="#" className="menu-link px-3">
                                      <span className="menu-title">New Group</span>
                                      <span className="menu-arrow" />
                                    </a>
                                    {/*end::Menu item*/}
                                    {/*begin::Menu sub*/}
                                    <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                      {/*begin::Menu item*/}
                                      <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                          Admin Group
                                        </a>
                                      </div>
                                      {/*end::Menu item*/}
                                      {/*begin::Menu item*/}
                                      <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                          Staff Group
                                        </a>
                                      </div>
                                      {/*end::Menu item*/}
                                      {/*begin::Menu item*/}
                                      <div className="menu-item px-3">
                                        <a href="#" className="menu-link px-3">
                                          Member Group
                                        </a>
                                      </div>
                                      {/*end::Menu item*/}
                                    </div>
                                    {/*end::Menu sub*/}
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <a href="#" className="menu-link px-3">
                                      New Contact
                                    </a>
                                  </div>
                                  {/*end::Menu item*/}
                                  {/*begin::Menu separator*/}
                                  <div className="separator mt-3 opacity-75" />
                                  {/*end::Menu separator*/}
                                  {/*begin::Menu item*/}
                                  <div className="menu-item px-3">
                                    <div className="menu-content px-3 py-3">
                                      <a className="btn btn-primary btn-sm px-4" href="#">
                                        Generate Reports
                                      </a>
                                    </div>
                                  </div>
                                  {/*end::Menu item*/}
                                </div>
                                {/*end::Menu 2*/}
                                {/*end::Menu*/}
                              </div>
                              {/*end::Toolbar*/}
                            </div>
                            {/*end::Header*/}
                            {/*begin::Card body*/}
                            <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
                              {/*begin::Info*/}
                              <div className="px-9 mb-5">
                                {/*begin::Statistics*/}
                                <div className="d-flex align-items-center mb-2">
                                  {/*begin::Currency*/}
                                  <span className="fs-4 fw-semibold text-gray-400 align-self-start me-1">$</span>
                                  {/*end::Currency*/}
                                  {/*begin::Value*/}
                                  <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">3,706</span>
                                  {/*end::Value*/}
                                  {/*begin::Label*/}
                                  <span className="badge badge-light-success fs-base">
                                    <i className="ki-outline ki-arrow-down fs-5 text-success ms-n1" />
                                    4.5%
                                  </span>
                                  {/*end::Label*/}
                                </div>
                                {/*end::Statistics*/}
                                {/*begin::Description*/}
                                <span className="fs-6 fw-semibold text-gray-400">Total Discounted Sales This Month</span>
                                {/*end::Description*/}
                              </div>
                              {/*end::Info*/}
                              {/*begin::Chart*/}
                              <AreaChart dataArea={dataDiscountChart} height={300} />
                              {/*end::Chart*/}
                            </div>
                            {/*end::Card body*/}
                          </div>
                          {/*end::Chart widget 4*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <div className="row gy-5 g-xl-10">
                        {/*begin::Col*/}
                        <div className="col-xl-4 mb-xl-10">
                          {/*begin::Engage widget 1*/}
                          <div className="card h-md-100" dir="ltr">
                            <div className="card-header border-0 pt-5">
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-dark">Birthday today</span>
                                <span className="text-muted mt-1 fw-semibold fs-7">Total 3 employees</span>
                              </h3>
                            </div>

                            <div className="card-body pt-7 px-0">
                              {/*begin::Nav*/}
                              <ul
                                className="nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5"
                                role="tablist"
                              >
                                {/*begin::Nav item*/}
                                <li className="nav-item p-0 ms-0" role="presentation">
                                  {/*begin::Date*/}
                                  <a
                                    className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                                    data-bs-toggle="tab"
                                    href="#kt_timeline_widget_3_tab_content_1"
                                    aria-selected="false"
                                    role="tab"
                                    tabIndex={-1}
                                  >
                                    <span className="fs-7 fw-semibold">Fr</span>
                                    <span className="fs-6 fw-bold">20</span>
                                  </a>
                                  {/*end::Date*/}
                                </li>
                                {/*end::Nav item*/}
                                {/*begin::Nav item*/}
                                <li className="nav-item p-0 ms-0" role="presentation">
                                  {/*begin::Date*/}
                                  <a
                                    className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger active"
                                    data-bs-toggle="tab"
                                    href="#kt_timeline_widget_3_tab_content_2"
                                    aria-selected="false"
                                    role="tab"
                                    tabIndex={-1}
                                  >
                                    <span className="fs-7 fw-semibold">Sa</span>
                                    <span className="fs-6 fw-bold">21</span>
                                  </a>
                                  {/*end::Date*/}
                                </li>
                                {/*end::Nav item*/}
                                {/*begin::Nav item*/}
                                <li className="nav-item p-0 ms-0" role="presentation">
                                  {/*begin::Date*/}
                                  <a
                                    className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                                    data-bs-toggle="tab"
                                    href="#kt_timeline_widget_3_tab_content_3"
                                    aria-selected="false"
                                    role="tab"
                                    tabIndex={-1}
                                  >
                                    <span className="fs-7 fw-semibold">Su</span>
                                    <span className="fs-6 fw-bold">22</span>
                                  </a>
                                  {/*end::Date*/}
                                </li>
                                {/*end::Nav item*/}
                                {/*begin::Nav item*/}
                                <li className="nav-item p-0 ms-0" role="presentation">
                                  {/*begin::Date*/}
                                  <a
                                    className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                                    data-bs-toggle="tab"
                                    href="#kt_timeline_widget_3_tab_content_4"
                                    aria-selected="false"
                                    role="tab"
                                    tabIndex={-1}
                                  >
                                    <span className="fs-7 fw-semibold">Tu</span>
                                    <span className="fs-6 fw-bold">23</span>
                                  </a>
                                  {/*end::Date*/}
                                </li>
                                {/*end::Nav item*/}
                                {/*begin::Nav item*/}
                                <li className="nav-item p-0 ms-0" role="presentation">
                                  {/*begin::Date*/}
                                  <a
                                    className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                                    data-bs-toggle="tab"
                                    href="#kt_timeline_widget_3_tab_content_5"
                                    aria-selected="false"
                                    role="tab"
                                    tabIndex={-1}
                                  >
                                    <span className="fs-7 fw-semibold">Tu</span>
                                    <span className="fs-6 fw-bold">24</span>
                                  </a>
                                  {/*end::Date*/}
                                </li>
                                {/*end::Nav item*/}
                                {/*begin::Nav item*/}
                                <li className="nav-item p-0 ms-0" role="presentation">
                                  {/*begin::Date*/}
                                  <a
                                    className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                                    data-bs-toggle="tab"
                                    href="#kt_timeline_widget_3_tab_content_6"
                                    aria-selected="false"
                                    role="tab"
                                    tabIndex={-1}
                                  >
                                    <span className="fs-7 fw-semibold">We</span>
                                    <span className="fs-6 fw-bold">25</span>
                                  </a>
                                  {/*end::Date*/}
                                </li>
                                {/*end::Nav item*/}
                                {/*begin::Nav item*/}
                                <li className="nav-item p-0 ms-0" role="presentation">
                                  {/*begin::Date*/}
                                  <a
                                    className="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger"
                                    data-bs-toggle="tab"
                                    href="#kt_timeline_widget_3_tab_content_7"
                                    aria-selected="false"
                                    role="tab"
                                    tabIndex={-1}
                                  >
                                    <span className="fs-7 fw-semibold">Th</span>
                                    <span className="fs-6 fw-bold">26</span>
                                  </a>
                                  {/*end::Date*/}
                                </li>
                                {/*end::Nav item*/}
                              </ul>
                              {/*end::Nav*/}
                              {/*begin::Tab Content (ishlamayabdi)*/}
                              <div className="tab-content mb-2 px-9">
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_1" role="tabpanel">
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">
                                        Lead by
                                        {/*begin::Name*/}
                                        <a href="#" className="text-primary opacity-75-hover fw-semibold">
                                          Peter Marcus
                                        </a>
                                        {/*end::Name*/}
                                      </div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Dashboard UI/UX Design Review </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Robert Fox</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_2" role="tabpanel">
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_3" role="tabpanel">
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_4" role="tabpanel">
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Dashboard UI/UX Design Review </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_5" role="tabpanel">
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_6" role="tabpanel">
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_7" role="tabpanel">
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_8" role="tabpanel">
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade" id="kt_timeline_widget_3_tab_content_9" role="tabpanel">
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-success"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade active show" id="kt_timeline_widget_3_tab_content_10" role="tabpanel">
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-warning"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </div>
                                {/*end::Tap pane*/}
                                {/*begin::Tap pane*/}
                                <div className="tab-pane fade " id="kt_timeline_widget_3_tab_content_11" role="tabpanel">
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Dashboard UI/UX Design Review </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">Project Manager</div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-danger"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">Marketing Campaign Discussion </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">
                                        Lead by
                                        {/*begin::Name*/}
                                        <a href="#" className="text-primary opacity-75-hover fw-semibold">
                                          Peter Marcus
                                        </a>
                                        {/*end::Name*/}
                                      </div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                    {/*begin::Action*/}
                                    <a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">
                                      View
                                    </a>
                                    {/*end::Action*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                  {/*begin::Wrapper*/}
                                  <div className="d-flex align-items-center mb-6">
                                    {/*begin::Bullet*/}
                                    <span
                                      data-kt-element="bullet"
                                      className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-primary"
                                    />
                                    {/*end::Bullet*/}
                                    {/*begin::Info*/}
                                    <div className="flex-grow-1 me-5">
                                      {/*begin::Time*/}
                                      <div className="text-gray-800 fw-semibold fs-2">Robert Fox</div>
                                      {/*end::Time*/}
                                      {/*begin::Description*/}
                                      <div className="text-gray-700 fw-semibold fs-6">9 Degree Project Estimation Meeting </div>
                                      {/*end::Description*/}
                                      {/*begin::Link*/}
                                      <div className="text-gray-500 fw-semibold fs-7">
                                        Lead by
                                        {/*begin::Name*/}
                                        <a href="#" className="text-primary opacity-75-hover fw-semibold">
                                          Lead by Bob
                                        </a>
                                        {/*end::Name*/}
                                      </div>
                                      {/*end::Link*/}
                                    </div>
                                    {/*end::Info*/}
                                    {/*begin::Action*/}
                                    <a href="#" className="btn btn-sm btn-light" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">
                                      View
                                    </a>
                                    {/*end::Action*/}
                                  </div>
                                  {/*end::Wrapper*/}
                                </div>
                                {/*end::Tap pane*/}
                              </div>
                              {/*end::Tab Content*/}
                              {/*begin::Action*/}
                              <div className="float-end d-none">
                                <a href="#" className="btn btn-sm btn-light me-2" data-bs-toggle="modal" data-bs-target="#kt_modal_create_project">
                                  Add Lesson
                                </a>
                                <a href="#" className="btn btn-sm btn-danger" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">
                                  Call Sick for Today
                                </a>
                              </div>
                              {/*end::Action*/}
                            </div>
                          </div>
                          {/*end::Engage widget 1*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-xl-8 mb-5 mb-xl-10">
                          {/*begin::Table Widget 4*/}
                          <div className="card card-flush h-xl-100">
                            {/*begin::Card header*/}
                            <div className="card-header pt-7">
                              {/*begin::Title*/}
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-gray-800">Product Orders</span>
                                <span className="text-gray-400 mt-1 fw-semibold fs-6">Avg. 57 orders per day</span>
                              </h3>
                              {/*end::Title*/}
                              {/*begin::Actions*/}
                              <div className="card-toolbar">
                                {/*begin::Filters*/}
                                <div className="d-flex flex-stack flex-wrap gap-4">
                                  {/*begin::Destination*/}
                                  <div className="d-flex align-items-center fw-bold">
                                    {/*begin::Label*/}
                                    <div className="text-gray-400 fs-7 me-2">Cateogry</div>
                                    {/*end::Label*/}
                                    {/*begin::Select*/}
                                    <select
                                      className="form-select form-select-transparent text-graY-800 fs-base lh-1 fw-bold py-0 ps-3 w-auto"
                                      data-control="select2"
                                      data-hide-search="true"
                                      data-dropdown-css-className="w-150px"
                                      data-placeholder="Select an option"
                                    >
                                      <option />
                                      <option value="Show All" selected="selected">
                                        Show All
                                      </option>
                                      <option value="a">Category A</option>
                                      <option value="b">Category A</option>
                                    </select>
                                    {/*end::Select*/}
                                  </div>
                                  {/*end::Destination*/}
                                  {/*begin::Status*/}
                                  <div className="d-flex align-items-center fw-bold">
                                    {/*begin::Label*/}
                                    <div className="text-gray-400 fs-7 me-2">Status</div>
                                    {/*end::Label*/}
                                    {/*begin::Select*/}
                                    <select
                                      className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                      data-control="select2"
                                      data-hide-search="true"
                                      data-dropdown-css-className="w-150px"
                                      data-placeholder="Select an option"
                                      data-kt-table-widget-4="filter_status"
                                    >
                                      <option />
                                      <option value="Show All" selected="selected">
                                        Show All
                                      </option>
                                      <option value="Shipped">Shipped</option>
                                      <option value="Confirmed">Confirmed</option>
                                      <option value="Rejected">Rejected</option>
                                      <option value="Pending">Pending</option>
                                    </select>
                                    {/*end::Select*/}
                                  </div>
                                  {/*end::Status*/}
                                  {/*begin::Search*/}
                                  <div className="position-relative my-1">
                                    <i className="ki-outline ki-magnifier fs-2 position-absolute top-50 translate-middle-y ms-4" />
                                    <input type="text" data-kt-table-widget-4="search" className="form-control w-150px fs-7 ps-12" placeholder="Search" />
                                  </div>
                                  {/*end::Search*/}
                                </div>
                                {/*begin::Filters*/}
                              </div>
                              {/*end::Actions*/}
                            </div>
                            {/*end::Card header*/}
                            {/*begin::Card body*/}
                            <div className="card-body pt-2">
                              {/*begin::Table*/}
                              <table className="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_4_table">
                                {/*begin::Table head*/}
                                <thead>
                                  {/*begin::Table row*/}
                                  <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                    <th className="min-w-100px">Order ID</th>
                                    <th className="text-end min-w-100px">Created</th>
                                    <th className="text-end min-w-125px">Customer</th>
                                    <th className="text-end min-w-100px">Total</th>
                                    <th className="text-end min-w-100px">Profit</th>
                                    <th className="text-end min-w-50px">Status</th>
                                    <th className="text-end" />
                                  </tr>
                                  {/*end::Table row*/}
                                </thead>
                                {/*end::Table head*/}
                                {/*begin::Table body*/}
                                <tbody className="fw-bold text-gray-600">
                                  <tr data-kt-table-widget-4="subtable_template" className="d-none">
                                    <td colSpan={2}>
                                      <div className="d-flex align-items-center gap-3">
                                        <a href="#" className="symbol symbol-50px bg-secondary bg-opacity-25 rounded">
                                          <img src data-kt-src-path="assets/media/stock/ecommerce/" alt="" data-kt-table-widget-4="template_image" />
                                        </a>
                                        <div className="d-flex flex-column text-muted">
                                          <a href="#" className="text-gray-800 text-hover-primary fw-bold" data-kt-table-widget-4="template_name">
                                            Product name
                                          </a>
                                          <div className="fs-7" data-kt-table-widget-4="template_description">
                                            Product description
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <div className="text-gray-800 fs-7">Cost</div>
                                      <div className="text-muted fs-7 fw-bold" data-kt-table-widget-4="template_cost">
                                        1
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <div className="text-gray-800 fs-7">Qty</div>
                                      <div className="text-muted fs-7 fw-bold" data-kt-table-widget-4="template_qty">
                                        1
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <div className="text-gray-800 fs-7">Total</div>
                                      <div className="text-muted fs-7 fw-bold" data-kt-table-widget-4="template_total">
                                        name
                                      </div>
                                    </td>
                                    <td className="text-end">
                                      <div className="text-gray-800 fs-7 me-3">On hand</div>
                                      <div className="text-muted fs-7 fw-bold" data-kt-table-widget-4="template_stock">
                                        32
                                      </div>
                                    </td>
                                    <td />
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="#" className="text-gray-800 text-hover-primary">
                                        #XGY-346
                                      </a>
                                    </td>
                                    <td className="text-end">7 min ago</td>
                                    <td className="text-end">
                                      <a href="#" className="text-gray-600 text-hover-primary">
                                        Albert Flores
                                      </a>
                                    </td>
                                    <td className="text-end">$630.00</td>
                                    <td className="text-end">
                                      <span className="text-gray-800 fw-bolder">$86.70</span>
                                    </td>
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-warning">Pending</span>
                                    </td>
                                    <td className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                        data-kt-table-widget-4="expand_row"
                                      >
                                        <i className="ki-outline ki-plus fs-4 m-0 toggle-off" />
                                        <i className="ki-outline ki-minus fs-4 m-0 toggle-on" />
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="#" className="text-gray-800 text-hover-primary">
                                        #YHD-047
                                      </a>
                                    </td>
                                    <td className="text-end">52 min ago</td>
                                    <td className="text-end">
                                      <a href="#" className="text-gray-600 text-hover-primary">
                                        Jenny Wilson
                                      </a>
                                    </td>
                                    <td className="text-end">$25.00</td>
                                    <td className="text-end">
                                      <span className="text-gray-800 fw-bolder">$4.20</span>
                                    </td>
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-primary">Confirmed</span>
                                    </td>
                                    <td className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                        data-kt-table-widget-4="expand_row"
                                      >
                                        <i className="ki-outline ki-plus fs-4 m-0 toggle-off" />
                                        <i className="ki-outline ki-minus fs-4 m-0 toggle-on" />
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="#" className="text-gray-800 text-hover-primary">
                                        #SRR-678
                                      </a>
                                    </td>
                                    <td className="text-end">1 hour ago</td>
                                    <td className="text-end">
                                      <a href="#" className="text-gray-600 text-hover-primary">
                                        Robert Fox
                                      </a>
                                    </td>
                                    <td className="text-end">$1,630.00</td>
                                    <td className="text-end">
                                      <span className="text-gray-800 fw-bolder">$203.90</span>
                                    </td>
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-warning">Pending</span>
                                    </td>
                                    <td className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                        data-kt-table-widget-4="expand_row"
                                      >
                                        <i className="ki-outline ki-plus fs-4 m-0 toggle-off" />
                                        <i className="ki-outline ki-minus fs-4 m-0 toggle-on" />
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="#" className="text-gray-800 text-hover-primary">
                                        #PXF-534
                                      </a>
                                    </td>
                                    <td className="text-end">3 hour ago</td>
                                    <td className="text-end">
                                      <a href="#" className="text-gray-600 text-hover-primary">
                                        Cody Fisher
                                      </a>
                                    </td>
                                    <td className="text-end">$119.00</td>
                                    <td className="text-end">
                                      <span className="text-gray-800 fw-bolder">$12.00</span>
                                    </td>
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-success">Shipped</span>
                                    </td>
                                    <td className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                        data-kt-table-widget-4="expand_row"
                                      >
                                        <i className="ki-outline ki-plus fs-4 m-0 toggle-off" />
                                        <i className="ki-outline ki-minus fs-4 m-0 toggle-on" />
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="#" className="text-gray-800 text-hover-primary">
                                        #XGD-249
                                      </a>
                                    </td>
                                    <td className="text-end">2 day ago</td>
                                    <td className="text-end">
                                      <a href="#" className="text-gray-600 text-hover-primary">
                                        Arlene McCoy
                                      </a>
                                    </td>
                                    <td className="text-end">$660.00</td>
                                    <td className="text-end">
                                      <span className="text-gray-800 fw-bolder">$52.26</span>
                                    </td>
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-success">Shipped</span>
                                    </td>
                                    <td className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                        data-kt-table-widget-4="expand_row"
                                      >
                                        <i className="ki-outline ki-plus fs-4 m-0 toggle-off" />
                                        <i className="ki-outline ki-minus fs-4 m-0 toggle-on" />
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="#" className="text-gray-800 text-hover-primary">
                                        #SKP-035
                                      </a>
                                    </td>
                                    <td className="text-end">2 day ago</td>
                                    <td className="text-end">
                                      <a href="#" className="text-gray-600 text-hover-primary">
                                        Eleanor Pena
                                      </a>
                                    </td>
                                    <td className="text-end">$290.00</td>
                                    <td className="text-end">
                                      <span className="text-gray-800 fw-bolder">$29.00</span>
                                    </td>
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-danger">Rejected</span>
                                    </td>
                                    <td className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                        data-kt-table-widget-4="expand_row"
                                      >
                                        <i className="ki-outline ki-plus fs-4 m-0 toggle-off" />
                                        <i className="ki-outline ki-minus fs-4 m-0 toggle-on" />
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <a href="#" className="text-gray-800 text-hover-primary">
                                        #SKP-567
                                      </a>
                                    </td>
                                    <td className="text-end">7 min ago</td>
                                    <td className="text-end">
                                      <a href="#" className="text-gray-600 text-hover-primary">
                                        Dan Wilson
                                      </a>
                                    </td>
                                    <td className="text-end">$590.00</td>
                                    <td className="text-end">
                                      <span className="text-gray-800 fw-bolder">$50.00</span>
                                    </td>
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-success">Shipped</span>
                                    </td>
                                    <td className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px"
                                        data-kt-table-widget-4="expand_row"
                                      >
                                        <i className="ki-outline ki-plus fs-4 m-0 toggle-off" />
                                        <i className="ki-outline ki-minus fs-4 m-0 toggle-on" />
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                                {/*end::Table body*/}
                              </table>
                              {/*end::Table*/}
                            </div>
                            {/*end::Card body*/}
                          </div>
                          {/*end::Table Widget 4*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                      {/*begin::Row*/}
                      <div className="row gy-5 g-xl-10">
                        {/*begin::Col*/}
                        <div className="col-xl-4">
                          {/*begin::List widget 5*/}
                          <div className="card card-flush h-xl-100">
                            {/*begin::Header*/}
                            <div className="card-header pt-7">
                              {/*begin::Title*/}
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-dark">Product Delivery</span>
                                <span className="text-gray-400 mt-1 fw-semibold fs-6">1M Products Shipped so far</span>
                              </h3>
                              {/*end::Title*/}
                              {/*begin::Toolbar*/}
                              <div className="card-toolbar">
                                <a href="#" className="btn btn-sm btn-light">
                                  Order Details
                                </a>
                              </div>
                              {/*end::Toolbar*/}
                            </div>
                            {/*end::Header*/}
                            {/*begin::Body*/}
                            <div className="card-body">
                              {/*begin::Scroll*/}
                              <div className="hover-scroll-overlay-y pe-6 me-n6" style={{ height: '415px' }}>
                                {/*begin::Item*/}
                                <div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">
                                  {/*begin::Info*/}
                                  <div className="d-flex flex-stack mb-3">
                                    {/*begin::Wrapper*/}
                                    <div className="me-3">
                                      {/*begin::Icon*/}
                                      <img src={toAbsoluteUrl('/media/stock/ecommerce/210.png')} className="w-50px ms-n1 me-1" alt="" />
                                      {/*end::Icon*/}
                                      {/*begin::Title*/}
                                      <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                                        Elephant 1802
                                      </a>
                                      {/*end::Title*/}
                                    </div>
                                    {/*end::Wrapper*/}
                                    {/*begin::Action*/}
                                    <div className="m-0">
                                      {/*begin::Menu*/}
                                      <button
                                        className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-placement="bottom-end"
                                        data-kt-menu-overflow="true"
                                      >
                                        <i className="ki-outline ki-dots-square fs-1" />
                                      </button>
                                      {/*begin::Menu 2*/}
                                      <div
                                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                        data-kt-menu="true"
                                      >
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu separator*/}
                                        <div className="separator mb-3 opacity-75" />
                                        {/*end::Menu separator*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Ticket
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Customer
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                          {/*begin::Menu item*/}
                                          <a href="#" className="menu-link px-3">
                                            <span className="menu-title">New Group</span>
                                            <span className="menu-arrow" />
                                          </a>
                                          {/*end::Menu item*/}
                                          {/*begin::Menu sub*/}
                                          <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Admin Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Staff Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Member Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                          </div>
                                          {/*end::Menu sub*/}
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Contact
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu separator*/}
                                        <div className="separator mt-3 opacity-75" />
                                        {/*end::Menu separator*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <div className="menu-content px-3 py-3">
                                            <a className="btn btn-primary btn-sm px-4" href="#">
                                              Generate Reports
                                            </a>
                                          </div>
                                        </div>
                                        {/*end::Menu item*/}
                                      </div>
                                      {/*end::Menu 2*/}
                                      {/*end::Menu*/}
                                    </div>
                                    {/*end::Action*/}
                                  </div>
                                  {/*end::Info*/}
                                  {/*begin::Customer*/}
                                  <div className="d-flex flex-stack">
                                    {/*begin::Name*/}
                                    <span className="text-gray-400 fw-bold">
                                      To:
                                      <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                                        Jason Bourne
                                      </a>
                                    </span>
                                    {/*end::Name*/}
                                    {/*begin::Label*/}
                                    <span className="badge badge-light-success">Delivered</span>
                                    {/*end::Label*/}
                                  </div>
                                  {/*end::Customer*/}
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">
                                  {/*begin::Info*/}
                                  <div className="d-flex flex-stack mb-3">
                                    {/*begin::Wrapper*/}
                                    <div className="me-3">
                                      {/*begin::Icon*/}
                                      <img src={toAbsoluteUrl('/media/stock/ecommerce/209.png')} className="w-50px ms-n1 me-1" alt="" />
                                      {/*end::Icon*/}
                                      {/*begin::Title*/}
                                      <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                                        RiseUP
                                      </a>
                                      {/*end::Title*/}
                                    </div>
                                    {/*end::Wrapper*/}
                                    {/*begin::Action*/}
                                    <div className="m-0">
                                      {/*begin::Menu*/}
                                      <button
                                        className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-placement="bottom-end"
                                        data-kt-menu-overflow="true"
                                      >
                                        <i className="ki-outline ki-dots-square fs-1" />
                                      </button>
                                      {/*begin::Menu 2*/}
                                      <div
                                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                        data-kt-menu="true"
                                      >
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu separator*/}
                                        <div className="separator mb-3 opacity-75" />
                                        {/*end::Menu separator*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Ticket
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Customer
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                          {/*begin::Menu item*/}
                                          <a href="#" className="menu-link px-3">
                                            <span className="menu-title">New Group</span>
                                            <span className="menu-arrow" />
                                          </a>
                                          {/*end::Menu item*/}
                                          {/*begin::Menu sub*/}
                                          <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Admin Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Staff Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Member Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                          </div>
                                          {/*end::Menu sub*/}
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Contact
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu separator*/}
                                        <div className="separator mt-3 opacity-75" />
                                        {/*end::Menu separator*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <div className="menu-content px-3 py-3">
                                            <a className="btn btn-primary btn-sm px-4" href="#">
                                              Generate Reports
                                            </a>
                                          </div>
                                        </div>
                                        {/*end::Menu item*/}
                                      </div>
                                      {/*end::Menu 2*/}
                                      {/*end::Menu*/}
                                    </div>
                                    {/*end::Action*/}
                                  </div>
                                  {/*end::Info*/}
                                  {/*begin::Customer*/}
                                  <div className="d-flex flex-stack">
                                    {/*begin::Name*/}
                                    <span className="text-gray-400 fw-bold">
                                      To:
                                      <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                                        Marie Durant
                                      </a>
                                    </span>
                                    {/*end::Name*/}
                                    {/*begin::Label*/}
                                    <span className="badge badge-light-primary">Shipping</span>
                                    {/*end::Label*/}
                                  </div>
                                  {/*end::Customer*/}
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">
                                  {/*begin::Info*/}
                                  <div className="d-flex flex-stack mb-3">
                                    {/*begin::Wrapper*/}
                                    <div className="me-3">
                                      {/*begin::Icon*/}
                                      <img src={toAbsoluteUrl('/media/stock/ecommerce/214.png')} className="w-50px ms-n1 me-1" alt="" />
                                      {/*end::Icon*/}
                                      {/*begin::Title*/}
                                      <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                                        Yellow Stone
                                      </a>
                                      {/*end::Title*/}
                                    </div>
                                    {/*end::Wrapper*/}
                                    {/*begin::Action*/}
                                    <div className="m-0">
                                      {/*begin::Menu*/}
                                      <button
                                        className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-placement="bottom-end"
                                        data-kt-menu-overflow="true"
                                      >
                                        <i className="ki-outline ki-dots-square fs-1" />
                                      </button>
                                      {/*begin::Menu 2*/}
                                      <div
                                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                        data-kt-menu="true"
                                      >
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu separator*/}
                                        <div className="separator mb-3 opacity-75" />
                                        {/*end::Menu separator*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Ticket
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Customer
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                          {/*begin::Menu item*/}
                                          <a href="#" className="menu-link px-3">
                                            <span className="menu-title">New Group</span>
                                            <span className="menu-arrow" />
                                          </a>
                                          {/*end::Menu item*/}
                                          {/*begin::Menu sub*/}
                                          <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Admin Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Staff Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Member Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                          </div>
                                          {/*end::Menu sub*/}
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Contact
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu separator*/}
                                        <div className="separator mt-3 opacity-75" />
                                        {/*end::Menu separator*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <div className="menu-content px-3 py-3">
                                            <a className="btn btn-primary btn-sm px-4" href="#">
                                              Generate Reports
                                            </a>
                                          </div>
                                        </div>
                                        {/*end::Menu item*/}
                                      </div>
                                      {/*end::Menu 2*/}
                                      {/*end::Menu*/}
                                    </div>
                                    {/*end::Action*/}
                                  </div>
                                  {/*end::Info*/}
                                  {/*begin::Customer*/}
                                  <div className="d-flex flex-stack">
                                    {/*begin::Name*/}
                                    <span className="text-gray-400 fw-bold">
                                      To:
                                      <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                                        Dan Wilson
                                      </a>
                                    </span>
                                    {/*end::Name*/}
                                    {/*begin::Label*/}
                                    <span className="badge badge-light-danger">Confirmed</span>
                                    {/*end::Label*/}
                                  </div>
                                  {/*end::Customer*/}
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">
                                  {/*begin::Info*/}
                                  <div className="d-flex flex-stack mb-3">
                                    {/*begin::Wrapper*/}
                                    <div className="me-3">
                                      {/*begin::Icon*/}
                                      <img src={toAbsoluteUrl('/media/stock/ecommerce/211.png')} className="w-50px ms-n1 me-1" alt="" />
                                      {/*end::Icon*/}
                                      {/*begin::Title*/}
                                      <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                                        Elephant 1802
                                      </a>
                                      {/*end::Title*/}
                                    </div>
                                    {/*end::Wrapper*/}
                                    {/*begin::Action*/}
                                    <div className="m-0">
                                      {/*begin::Menu*/}
                                      <button
                                        className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-placement="bottom-end"
                                        data-kt-menu-overflow="true"
                                      >
                                        <i className="ki-outline ki-dots-square fs-1" />
                                      </button>
                                      {/*begin::Menu 2*/}
                                      <div
                                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                        data-kt-menu="true"
                                      >
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu separator*/}
                                        <div className="separator mb-3 opacity-75" />
                                        {/*end::Menu separator*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Ticket
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Customer
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                          {/*begin::Menu item*/}
                                          <a href="#" className="menu-link px-3">
                                            <span className="menu-title">New Group</span>
                                            <span className="menu-arrow" />
                                          </a>
                                          {/*end::Menu item*/}
                                          {/*begin::Menu sub*/}
                                          <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Admin Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Staff Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Member Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                          </div>
                                          {/*end::Menu sub*/}
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Contact
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu separator*/}
                                        <div className="separator mt-3 opacity-75" />
                                        {/*end::Menu separator*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <div className="menu-content px-3 py-3">
                                            <a className="btn btn-primary btn-sm px-4" href="#">
                                              Generate Reports
                                            </a>
                                          </div>
                                        </div>
                                        {/*end::Menu item*/}
                                      </div>
                                      {/*end::Menu 2*/}
                                      {/*end::Menu*/}
                                    </div>
                                    {/*end::Action*/}
                                  </div>
                                  {/*end::Info*/}
                                  {/*begin::Customer*/}
                                  <div className="d-flex flex-stack">
                                    {/*begin::Name*/}
                                    <span className="text-gray-400 fw-bold">
                                      To:
                                      <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                                        Lebron Wayde
                                      </a>
                                    </span>
                                    {/*end::Name*/}
                                    {/*begin::Label*/}
                                    <span className="badge badge-light-success">Delivered</span>
                                    {/*end::Label*/}
                                  </div>
                                  {/*end::Customer*/}
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">
                                  {/*begin::Info*/}
                                  <div className="d-flex flex-stack mb-3">
                                    {/*begin::Wrapper*/}
                                    <div className="me-3">
                                      {/*begin::Icon*/}
                                      <img src={toAbsoluteUrl('/media/stock/ecommerce/215.png')} className="w-50px ms-n1 me-1" alt="" />
                                      {/*end::Icon*/}
                                      {/*begin::Title*/}
                                      <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                                        RiseUP
                                      </a>
                                      {/*end::Title*/}
                                    </div>
                                    {/*end::Wrapper*/}
                                    {/*begin::Action*/}
                                    <div className="m-0">
                                      {/*begin::Menu*/}
                                      <button
                                        className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-placement="bottom-end"
                                        data-kt-menu-overflow="true"
                                      >
                                        <i className="ki-outline ki-dots-square fs-1" />
                                      </button>
                                      {/*begin::Menu 2*/}
                                      <div
                                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                        data-kt-menu="true"
                                      >
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu separator*/}
                                        <div className="separator mb-3 opacity-75" />
                                        {/*end::Menu separator*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Ticket
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Customer
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                          {/*begin::Menu item*/}
                                          <a href="#" className="menu-link px-3">
                                            <span className="menu-title">New Group</span>
                                            <span className="menu-arrow" />
                                          </a>
                                          {/*end::Menu item*/}
                                          {/*begin::Menu sub*/}
                                          <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Admin Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Staff Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Member Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                          </div>
                                          {/*end::Menu sub*/}
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Contact
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu separator*/}
                                        <div className="separator mt-3 opacity-75" />
                                        {/*end::Menu separator*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <div className="menu-content px-3 py-3">
                                            <a className="btn btn-primary btn-sm px-4" href="#">
                                              Generate Reports
                                            </a>
                                          </div>
                                        </div>
                                        {/*end::Menu item*/}
                                      </div>
                                      {/*end::Menu 2*/}
                                      {/*end::Menu*/}
                                    </div>
                                    {/*end::Action*/}
                                  </div>
                                  {/*end::Info*/}
                                  {/*begin::Customer*/}
                                  <div className="d-flex flex-stack">
                                    {/*begin::Name*/}
                                    <span className="text-gray-400 fw-bold">
                                      To:
                                      <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                                        Ana Simmons
                                      </a>
                                    </span>
                                    {/*end::Name*/}
                                    {/*begin::Label*/}
                                    <span className="badge badge-light-primary">Shipping</span>
                                    {/*end::Label*/}
                                  </div>
                                  {/*end::Customer*/}
                                </div>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <div className="border border-dashed border-gray-300 rounded px-7 py-3">
                                  {/*begin::Info*/}
                                  <div className="d-flex flex-stack mb-3">
                                    {/*begin::Wrapper*/}
                                    <div className="me-3">
                                      {/*begin::Icon*/}
                                      <img src={toAbsoluteUrl('/media/stock/ecommerce/192.png')} className="w-50px ms-n1 me-1" alt="" />
                                      {/*end::Icon*/}
                                      {/*begin::Title*/}
                                      <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                                        Yellow Stone
                                      </a>
                                      {/*end::Title*/}
                                    </div>
                                    {/*end::Wrapper*/}
                                    {/*begin::Action*/}
                                    <div className="m-0">
                                      {/*begin::Menu*/}
                                      <button
                                        className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
                                        data-kt-menu-trigger="click"
                                        data-kt-menu-placement="bottom-end"
                                        data-kt-menu-overflow="true"
                                      >
                                        <i className="ki-outline ki-dots-square fs-1" />
                                      </button>
                                      {/*begin::Menu 2*/}
                                      <div
                                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                                        data-kt-menu="true"
                                      >
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">Quick Actions</div>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu separator*/}
                                        <div className="separator mb-3 opacity-75" />
                                        {/*end::Menu separator*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Ticket
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Customer
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                                          {/*begin::Menu item*/}
                                          <a href="#" className="menu-link px-3">
                                            <span className="menu-title">New Group</span>
                                            <span className="menu-arrow" />
                                          </a>
                                          {/*end::Menu item*/}
                                          {/*begin::Menu sub*/}
                                          <div className="menu-sub menu-sub-dropdown w-175px py-4">
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Admin Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Staff Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                            {/*begin::Menu item*/}
                                            <div className="menu-item px-3">
                                              <a href="#" className="menu-link px-3">
                                                Member Group
                                              </a>
                                            </div>
                                            {/*end::Menu item*/}
                                          </div>
                                          {/*end::Menu sub*/}
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <a href="#" className="menu-link px-3">
                                            New Contact
                                          </a>
                                        </div>
                                        {/*end::Menu item*/}
                                        {/*begin::Menu separator*/}
                                        <div className="separator mt-3 opacity-75" />
                                        {/*end::Menu separator*/}
                                        {/*begin::Menu item*/}
                                        <div className="menu-item px-3">
                                          <div className="menu-content px-3 py-3">
                                            <a className="btn btn-primary btn-sm px-4" href="#">
                                              Generate Reports
                                            </a>
                                          </div>
                                        </div>
                                        {/*end::Menu item*/}
                                      </div>
                                      {/*end::Menu 2*/}
                                      {/*end::Menu*/}
                                    </div>
                                    {/*end::Action*/}
                                  </div>
                                  {/*end::Info*/}
                                  {/*begin::Customer*/}
                                  <div className="d-flex flex-stack">
                                    {/*begin::Name*/}
                                    <span className="text-gray-400 fw-bold">
                                      To:
                                      <a href="#" className="text-gray-800 text-hover-primary fw-bold">
                                        Kevin Leonard
                                      </a>
                                    </span>
                                    {/*end::Name*/}
                                    {/*begin::Label*/}
                                    <span className="badge badge-light-danger">Confirmed</span>
                                    {/*end::Label*/}
                                  </div>
                                  {/*end::Customer*/}
                                </div>
                                {/*end::Item*/}
                              </div>
                              {/*end::Scroll*/}
                            </div>
                            {/*end::Body*/}
                          </div>
                          {/*end::List widget 5*/}
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-xl-8">
                          {/*begin::Table Widget 5*/}
                          <div className="card card-flush h-xl-100">
                            {/*begin::Card header*/}
                            <div className="card-header pt-7">
                              {/*begin::Title*/}
                              <h3 className="card-title align-items-start flex-column">
                                <span className="card-label fw-bold text-dark">Stock Report</span>
                                <span className="text-gray-400 mt-1 fw-semibold fs-6">Total 2,356 Items in the Stock</span>
                              </h3>
                              {/*end::Title*/}
                              {/*begin::Actions*/}
                              <div className="card-toolbar">
                                {/*begin::Filters*/}
                                <div className="d-flex flex-stack flex-wrap gap-4">
                                  {/*begin::Destination*/}
                                  <div className="d-flex align-items-center fw-bold">
                                    {/*begin::Label*/}
                                    <div className="text-muted fs-7 me-2">Cateogry</div>
                                    {/*end::Label*/}
                                    {/*begin::Select*/}
                                    <select
                                      className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                      data-control="select2"
                                      data-hide-search="true"
                                      data-dropdown-css-className="w-150px"
                                      data-placeholder="Select an option"
                                    >
                                      <option />
                                      <option value="Show All" selected="selected">
                                        Show All
                                      </option>
                                      <option value="a">Category A</option>
                                      <option value="b">Category B</option>
                                    </select>
                                    {/*end::Select*/}
                                  </div>
                                  {/*end::Destination*/}
                                  {/*begin::Status*/}
                                  <div className="d-flex align-items-center fw-bold">
                                    {/*begin::Label*/}
                                    <div className="text-muted fs-7 me-2">Status</div>
                                    {/*end::Label*/}
                                    {/*begin::Select*/}
                                    <select
                                      className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto"
                                      data-control="select2"
                                      data-hide-search="true"
                                      data-dropdown-css-className="w-150px"
                                      data-placeholder="Select an option"
                                      data-kt-table-widget-5="filter_status"
                                    >
                                      <option />
                                      <option value="Show All" selected="selected">
                                        Show All
                                      </option>
                                      <option value="In Stock">In Stock</option>
                                      <option value="Out of Stock">Out of Stock</option>
                                      <option value="Low Stock">Low Stock</option>
                                    </select>
                                    {/*end::Select*/}
                                  </div>
                                  {/*end::Status*/}
                                  {/*begin::Search*/}
                                  <a href="#" className="btn btn-light btn-sm">
                                    View Stock
                                  </a>
                                  {/*end::Search*/}
                                </div>
                                {/*begin::Filters*/}
                              </div>
                              {/*end::Actions*/}
                            </div>
                            {/*end::Card header*/}
                            {/*begin::Card body*/}
                            <div className="card-body">
                              {/*begin::Table*/}
                              <table className="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_5_table">
                                {/*begin::Table head*/}
                                <thead>
                                  {/*begin::Table row*/}
                                  <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                    <th className="min-w-150px">Item</th>
                                    <th className="text-end pe-3 min-w-100px">Product ID</th>
                                    <th className="text-end pe-3 min-w-150px">Date Added</th>
                                    <th className="text-end pe-3 min-w-100px">Price</th>
                                    <th className="text-end pe-3 min-w-100px">Status</th>
                                    <th className="text-end pe-0 min-w-75px">Qty</th>
                                  </tr>
                                  {/*end::Table row*/}
                                </thead>
                                {/*end::Table head*/}
                                {/*begin::Table body*/}
                                <tbody className="fw-bold text-gray-600">
                                  <tr>
                                    {/*begin::Item*/}
                                    <td>
                                      <a href="#" className="text-dark text-hover-primary">
                                        Macbook Air M1
                                      </a>
                                    </td>
                                    {/*end::Item*/}
                                    {/*begin::Product ID*/}
                                    <td className="text-end">#XGY-356</td>
                                    {/*end::Product ID*/}
                                    {/*begin::Date added*/}
                                    <td className="text-end">02 Apr, 2023</td>
                                    {/*end::Date added*/}
                                    {/*begin::Price*/}
                                    <td className="text-end">$1,230</td>
                                    {/*end::Price*/}
                                    {/*begin::Status*/}
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                                    </td>
                                    {/*end::Status*/}
                                    {/*begin::Qty*/}
                                    <td className="text-end" data-order={58}>
                                      <span className="text-dark fw-bold">58 PCS</span>
                                    </td>
                                    {/*end::Qty*/}
                                  </tr>
                                  <tr>
                                    {/*begin::Item*/}
                                    <td>
                                      <a href="#" className="text-dark text-hover-primary">
                                        Surface Laptop 4
                                      </a>
                                    </td>
                                    {/*end::Item*/}
                                    {/*begin::Product ID*/}
                                    <td className="text-end">#YHD-047</td>
                                    {/*end::Product ID*/}
                                    {/*begin::Date added*/}
                                    <td className="text-end">01 Apr, 2023</td>
                                    {/*end::Date added*/}
                                    {/*begin::Price*/}
                                    <td className="text-end">$1,060</td>
                                    {/*end::Price*/}
                                    {/*begin::Status*/}
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-danger">Out of Stock</span>
                                    </td>
                                    {/*end::Status*/}
                                    {/*begin::Qty*/}
                                    <td className="text-end" data-order={0}>
                                      <span className="text-dark fw-bold">0 PCS</span>
                                    </td>
                                    {/*end::Qty*/}
                                  </tr>
                                  <tr>
                                    {/*begin::Item*/}
                                    <td>
                                      <a href="#" className="text-dark text-hover-primary">
                                        Logitech MX 250
                                      </a>
                                    </td>
                                    {/*end::Item*/}
                                    {/*begin::Product ID*/}
                                    <td className="text-end">#SRR-678</td>
                                    {/*end::Product ID*/}
                                    {/*begin::Date added*/}
                                    <td className="text-end">24 Mar, 2023</td>
                                    {/*end::Date added*/}
                                    {/*begin::Price*/}
                                    <td className="text-end">$64</td>
                                    {/*end::Price*/}
                                    {/*begin::Status*/}
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                                    </td>
                                    {/*end::Status*/}
                                    {/*begin::Qty*/}
                                    <td className="text-end" data-order={290}>
                                      <span className="text-dark fw-bold">290 PCS</span>
                                    </td>
                                    {/*end::Qty*/}
                                  </tr>
                                  <tr>
                                    {/*begin::Item*/}
                                    <td>
                                      <a href="#" className="text-dark text-hover-primary">
                                        AudioEngine HD3
                                      </a>
                                    </td>
                                    {/*end::Item*/}
                                    {/*begin::Product ID*/}
                                    <td className="text-end">#PXF-578</td>
                                    {/*end::Product ID*/}
                                    {/*begin::Date added*/}
                                    <td className="text-end">24 Mar, 2023</td>
                                    {/*end::Date added*/}
                                    {/*begin::Price*/}
                                    <td className="text-end">$1,060</td>
                                    {/*end::Price*/}
                                    {/*begin::Status*/}
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-danger">Out of Stock</span>
                                    </td>
                                    {/*end::Status*/}
                                    {/*begin::Qty*/}
                                    <td className="text-end" data-order={46}>
                                      <span className="text-dark fw-bold">46 PCS</span>
                                    </td>
                                    {/*end::Qty*/}
                                  </tr>
                                  <tr>
                                    {/*begin::Item*/}
                                    <td>
                                      <a href="#" className="text-dark text-hover-primary">
                                        HP Hyper LTR
                                      </a>
                                    </td>
                                    {/*end::Item*/}
                                    {/*begin::Product ID*/}
                                    <td className="text-end">#PXF-778</td>
                                    {/*end::Product ID*/}
                                    {/*begin::Date added*/}
                                    <td className="text-end">16 Jan, 2023</td>
                                    {/*end::Date added*/}
                                    {/*begin::Price*/}
                                    <td className="text-end">$4500</td>
                                    {/*end::Price*/}
                                    {/*begin::Status*/}
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                                    </td>
                                    {/*end::Status*/}
                                    {/*begin::Qty*/}
                                    <td className="text-end" data-order={78}>
                                      <span className="text-dark fw-bold">78 PCS</span>
                                    </td>
                                    {/*end::Qty*/}
                                  </tr>
                                  <tr>
                                    {/*begin::Item*/}
                                    <td>
                                      <a href="#" className="text-dark text-hover-primary">
                                        Dell 32 UltraSharp
                                      </a>
                                    </td>
                                    {/*end::Item*/}
                                    {/*begin::Product ID*/}
                                    <td className="text-end">#XGY-356</td>
                                    {/*end::Product ID*/}
                                    {/*begin::Date added*/}
                                    <td className="text-end">22 Dec, 2023</td>
                                    {/*end::Date added*/}
                                    {/*begin::Price*/}
                                    <td className="text-end">$1,060</td>
                                    {/*end::Price*/}
                                    {/*begin::Status*/}
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-warning">Low Stock</span>
                                    </td>
                                    {/*end::Status*/}
                                    {/*begin::Qty*/}
                                    <td className="text-end" data-order={8}>
                                      <span className="text-dark fw-bold">8 PCS</span>
                                    </td>
                                    {/*end::Qty*/}
                                  </tr>
                                  <tr>
                                    {/*begin::Item*/}
                                    <td>
                                      <a href="#" className="text-dark text-hover-primary">
                                        Google Pixel 6 Pro
                                      </a>
                                    </td>
                                    {/*end::Item*/}
                                    {/*begin::Product ID*/}
                                    <td className="text-end">#XVR-425</td>
                                    {/*end::Product ID*/}
                                    {/*begin::Date added*/}
                                    <td className="text-end">27 Dec, 2023</td>
                                    {/*end::Date added*/}
                                    {/*begin::Price*/}
                                    <td className="text-end">$1,060</td>
                                    {/*end::Price*/}
                                    {/*begin::Status*/}
                                    <td className="text-end">
                                      <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                                    </td>
                                    {/*end::Status*/}
                                    {/*begin::Qty*/}
                                    <td className="text-end" data-order={124}>
                                      <span className="text-dark fw-bold">124 PCS</span>
                                    </td>
                                    {/*end::Qty*/}
                                  </tr>
                                </tbody>
                                {/*end::Table body*/}
                              </table>
                              {/*end::Table*/}
                            </div>
                            {/*end::Card body*/}
                          </div>
                          {/*end::Table Widget 5*/}
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                    </div>
                    {/*end::Content container*/}
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Content wrapper*/}
              </div>
              {/*end:::Main*/}
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Page*/}
        </div>
        {/*end::App*/}
        {/*begin::Drawers*/}
        {/*begin::Activities drawer*/}
        <div
          id="kt_activities"
          className="bg-body"
          data-kt-drawer="true"
          data-kt-drawer-name="activities"
          data-kt-drawer-activate="true"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'300px', 'lg': '900px'}"
          data-kt-drawer-direction="end"
          data-kt-drawer-toggle="#kt_activities_toggle"
          data-kt-drawer-close="#kt_activities_close"
        >
          <div className="card shadow-none border-0 rounded-0">
            {/*begin::Header*/}
            <div className="card-header" id="kt_activities_header">
              <h3 className="card-title fw-bold text-dark">Activity Logs</h3>
              <div className="card-toolbar">
                <button type="button" className="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_activities_close">
                  <i className="ki-outline ki-cross fs-1" />
                </button>
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body position-relative" id="kt_activities_body">
              {/*begin::Content*/}
              <div
                id="kt_activities_scroll"
                className="position-relative scroll-y me-n5 pe-5"
                data-kt-scroll="true"
                data-kt-scroll-height="auto"
                data-kt-scroll-wrappers="#kt_activities_body"
                data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer"
                data-kt-scroll-offset="5px"
              >
                {/*begin::Timeline items*/}
                <div className="timeline">
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                      <div className="symbol-label bg-light">
                        <i className="ki-outline ki-message-text-2 fs-2 text-gray-500" />
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="pe-3 mb-5">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">There are 2 new tasks for you in “AirPlus Mobile App” project:</div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">Added at 4:23 PM by</div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <div
                            className="symbol symbol-circle symbol-25px"
                            data-bs-toggle="tooltip"
                            data-bs-boundary="window"
                            data-bs-placement="top"
                            title="Nina Nilson"
                          >
                            <img src="assets/media/avatars/300-14.jpg" alt="img" />
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                      {/*begin::Timeline details*/}
                      <div className="overflow-auto pb-5">
                        {/*begin::Record*/}
                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">
                          {/*begin::Title*/}
                          <a href="#" className="fs-5 text-dark text-hover-primary fw-semibold w-375px min-w-200px">
                            Meeting with customer
                          </a>
                          {/*end::Title*/}
                          {/*begin::Label*/}
                          <div className="min-w-175px pe-2">
                            <span className="badge badge-light text-muted">Application Design</span>
                          </div>
                          {/*end::Label*/}
                          {/*begin::Users*/}
                          <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">
                            {/*begin::User*/}
                            <div className="symbol symbol-circle symbol-25px">
                              <img src="assets/media/avatars/300-2.jpg" alt="img" />
                            </div>
                            {/*end::User*/}
                            {/*begin::User*/}
                            <div className="symbol symbol-circle symbol-25px">
                              <img src="assets/media/avatars/300-14.jpg" alt="img" />
                            </div>
                            {/*end::User*/}
                            {/*begin::User*/}
                            <div className="symbol symbol-circle symbol-25px">
                              <div className="symbol-label fs-8 fw-semibold bg-primary text-inverse-primary">A</div>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Users*/}
                          {/*begin::Progress*/}
                          <div className="min-w-125px pe-2">
                            <span className="badge badge-light-primary">In Progress</span>
                          </div>
                          {/*end::Progress*/}
                          {/*begin::Action*/}
                          <a href="#" className="btn btn-sm btn-light btn-active-light-primary">
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Record*/}
                        {/*begin::Record*/}
                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-0">
                          {/*begin::Title*/}
                          <a href="#" className="fs-5 text-dark text-hover-primary fw-semibold w-375px min-w-200px">
                            Project Delivery Preparation
                          </a>
                          {/*end::Title*/}
                          {/*begin::Label*/}
                          <div className="min-w-175px">
                            <span className="badge badge-light text-muted">CRM System Development</span>
                          </div>
                          {/*end::Label*/}
                          {/*begin::Users*/}
                          <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px">
                            {/*begin::User*/}
                            <div className="symbol symbol-circle symbol-25px">
                              <img src="assets/media/avatars/300-20.jpg" alt="img" />
                            </div>
                            {/*end::User*/}
                            {/*begin::User*/}
                            <div className="symbol symbol-circle symbol-25px">
                              <div className="symbol-label fs-8 fw-semibold bg-success text-inverse-primary">B</div>
                            </div>
                            {/*end::User*/}
                          </div>
                          {/*end::Users*/}
                          {/*begin::Progress*/}
                          <div className="min-w-125px">
                            <span className="badge badge-light-success">Completed</span>
                          </div>
                          {/*end::Progress*/}
                          {/*begin::Action*/}
                          <a href="#" className="btn btn-sm btn-light btn-active-light-primary">
                            View
                          </a>
                          {/*end::Action*/}
                        </div>
                        {/*end::Record*/}
                      </div>
                      {/*end::Timeline details*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-outline ki-flag fs-2 text-gray-500" />
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n2">
                      {/*begin::Timeline heading*/}
                      <div className="overflow-auto pe-3">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">Invitation for crafting engaging designs that speak human workshop</div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">Sent at 4:23 PM by</div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <div
                            className="symbol symbol-circle symbol-25px"
                            data-bs-toggle="tooltip"
                            data-bs-boundary="window"
                            data-bs-placement="top"
                            title="Alan Nilson"
                          >
                            <img src="assets/media/avatars/300-1.jpg" alt="img" />
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-outline ki-disconnect fs-2 text-gray-500" />
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="mb-5 pe-3">
                        {/*begin::Title*/}
                        <a href="#" className="fs-5 fw-semibold text-gray-800 text-hover-primary mb-2">
                          3 New Incoming Project Files:
                        </a>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">Sent at 10:30 PM by</div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <div
                            className="symbol symbol-circle symbol-25px"
                            data-bs-toggle="tooltip"
                            data-bs-boundary="window"
                            data-bs-placement="top"
                            title="Jan Hummer"
                          >
                            <img src="assets/media/avatars/300-23.jpg" alt="img" />
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                      {/*begin::Timeline details*/}
                      <div className="overflow-auto pb-5">
                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">
                          {/*begin::Item*/}
                          <div className="d-flex flex-aligns-center pe-10 pe-lg-20">
                            {/*begin::Icon*/}
                            <img alt="" className="w-30px me-3" src="assets/media/svg/files/pdf.svg" />
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="ms-1 fw-semibold">
                              {/*begin::Desc*/}
                              <a href="#" className="fs-6 text-hover-primary fw-bold">
                                Finance KPI App Guidelines
                              </a>
                              {/*end::Desc*/}
                              {/*begin::Number*/}
                              <div className="text-gray-400">1.9mb</div>
                              {/*end::Number*/}
                            </div>
                            {/*begin::Info*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-aligns-center pe-10 pe-lg-20">
                            {/*begin::Icon*/}
                            <img alt="../../demo52/dist/apps/projects/project.html" className="w-30px me-3" src="assets/media/svg/files/doc.svg" />
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="ms-1 fw-semibold">
                              {/*begin::Desc*/}
                              <a href="#" className="fs-6 text-hover-primary fw-bold">
                                Client UAT Testing Results
                              </a>
                              {/*end::Desc*/}
                              {/*begin::Number*/}
                              <div className="text-gray-400">18kb</div>
                              {/*end::Number*/}
                            </div>
                            {/*end::Info*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="d-flex flex-aligns-center">
                            {/*begin::Icon*/}
                            <img alt="../../demo52/dist/apps/projects/project.html" className="w-30px me-3" src="assets/media/svg/files/css.svg" />
                            {/*end::Icon*/}
                            {/*begin::Info*/}
                            <div className="ms-1 fw-semibold">
                              {/*begin::Desc*/}
                              <a href="#" className="fs-6 text-hover-primary fw-bold">
                                Finance Reports
                              </a>
                              {/*end::Desc*/}
                              {/*begin::Number*/}
                              <div className="text-gray-400">20mb</div>
                              {/*end::Number*/}
                            </div>
                            {/*end::Icon*/}
                          </div>
                          {/*end::Item*/}
                        </div>
                      </div>
                      {/*end::Timeline details*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-outline ki-abstract-26 fs-2 text-gray-500" />
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="pe-3 mb-5">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">
                          Task
                          <a href="#" className="text-primary fw-bold me-1">
                            #45890
                          </a>
                          merged with
                          <a href="#" className="text-primary fw-bold me-1">
                            #45890
                          </a>
                          in “Ads Pro Admin Dashboard project:
                        </div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">Initiated at 4:23 PM by</div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <div
                            className="symbol symbol-circle symbol-25px"
                            data-bs-toggle="tooltip"
                            data-bs-boundary="window"
                            data-bs-placement="top"
                            title="Nina Nilson"
                          >
                            <img src="assets/media/avatars/300-14.jpg" alt="img" />
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-outline ki-pencil fs-2 text-gray-500" />
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="pe-3 mb-5">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">3 new application design concepts added:</div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">Created at 4:23 PM by</div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <div
                            className="symbol symbol-circle symbol-25px"
                            data-bs-toggle="tooltip"
                            data-bs-boundary="window"
                            data-bs-placement="top"
                            title="Marcus Dotson"
                          >
                            <img src="assets/media/avatars/300-2.jpg" alt="img" />
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                      {/*begin::Timeline details*/}
                      <div className="overflow-auto pb-5">
                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                          {/*begin::Item*/}
                          <div className="overlay me-10">
                            {/*begin::Image*/}
                            <div className="overlay-wrapper">
                              <img alt="img" className="rounded w-150px" src="assets/media/stock/600x400/img-29.jpg" />
                            </div>
                            {/*end::Image*/}
                            {/*begin::Link*/}
                            <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                              <a href="#" className="btn btn-sm btn-primary btn-shadow">
                                Explore
                              </a>
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="overlay me-10">
                            {/*begin::Image*/}
                            <div className="overlay-wrapper">
                              <img alt="img" className="rounded w-150px" src="assets/media/stock/600x400/img-31.jpg" />
                            </div>
                            {/*end::Image*/}
                            {/*begin::Link*/}
                            <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                              <a href="#" className="btn btn-sm btn-primary btn-shadow">
                                Explore
                              </a>
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Item*/}
                          {/*begin::Item*/}
                          <div className="overlay">
                            {/*begin::Image*/}
                            <div className="overlay-wrapper">
                              <img alt="img" className="rounded w-150px" src="assets/media/stock/600x400/img-40.jpg" />
                            </div>
                            {/*end::Image*/}
                            {/*begin::Link*/}
                            <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                              <a href="#" className="btn btn-sm btn-primary btn-shadow">
                                Explore
                              </a>
                            </div>
                            {/*end::Link*/}
                          </div>
                          {/*end::Item*/}
                        </div>
                      </div>
                      {/*end::Timeline details*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-outline ki-sms fs-2 text-gray-500" />
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="pe-3 mb-5">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">
                          New case
                          <a href="#" className="text-primary fw-bold me-1">
                            #67890
                          </a>
                          is assigned to you in Multi-platform Database Design project
                        </div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="overflow-auto pb-5">
                          {/*begin::Wrapper*/}
                          <div className="d-flex align-items-center mt-1 fs-6">
                            {/*begin::Info*/}
                            <div className="text-muted me-2 fs-7">Added at 4:23 PM by</div>
                            {/*end::Info*/}
                            {/*begin::User*/}
                            <a href="#" className="text-primary fw-bold me-1">
                              Alice Tan
                            </a>
                            {/*end::User*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-outline ki-pencil fs-2 text-gray-500" />
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mb-10 mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="pe-3 mb-5">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">You have received a new order:</div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">Placed at 5:05 AM by</div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <div
                            className="symbol symbol-circle symbol-25px"
                            data-bs-toggle="tooltip"
                            data-bs-boundary="window"
                            data-bs-placement="top"
                            title="Robert Rich"
                          >
                            <img src="assets/media/avatars/300-4.jpg" alt="img" />
                          </div>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                      {/*begin::Timeline details*/}
                      <div className="overflow-auto pb-5">
                        {/*begin::Notice*/}
                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                          {/*begin::Icon*/}
                          <i className="ki-outline ki-devices-2 fs-2tx text-primary me-4" />
                          {/*end::Icon*/}
                          {/*begin::Wrapper*/}
                          <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                            {/*begin::Content*/}
                            <div className="mb-3 mb-md-0 fw-semibold">
                              <h4 className="text-gray-900 fw-bold">Database Backup Process Completed!</h4>
                              <div className="fs-6 text-gray-700 pe-7">Login into Admin Dashboard to make sure the data integrity is OK</div>
                            </div>
                            {/*end::Content*/}
                            {/*begin::Action*/}
                            <a href="#" className="btn btn-primary px-6 align-self-center text-nowrap">
                              Proceed
                            </a>
                            {/*end::Action*/}
                          </div>
                          {/*end::Wrapper*/}
                        </div>
                        {/*end::Notice*/}
                      </div>
                      {/*end::Timeline details*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                  {/*begin::Timeline item*/}
                  <div className="timeline-item">
                    {/*begin::Timeline line*/}
                    <div className="timeline-line w-40px" />
                    {/*end::Timeline line*/}
                    {/*begin::Timeline icon*/}
                    <div className="timeline-icon symbol symbol-circle symbol-40px">
                      <div className="symbol-label bg-light">
                        <i className="ki-outline ki-basket fs-2 text-gray-500" />
                      </div>
                    </div>
                    {/*end::Timeline icon*/}
                    {/*begin::Timeline content*/}
                    <div className="timeline-content mt-n1">
                      {/*begin::Timeline heading*/}
                      <div className="pe-3 mb-5">
                        {/*begin::Title*/}
                        <div className="fs-5 fw-semibold mb-2">
                          New order
                          <a href="#" className="text-primary fw-bold me-1">
                            #67890
                          </a>
                          is placed for Workshow Planning &amp; Budget Estimation
                        </div>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="d-flex align-items-center mt-1 fs-6">
                          {/*begin::Info*/}
                          <div className="text-muted me-2 fs-7">Placed at 4:23 PM by</div>
                          {/*end::Info*/}
                          {/*begin::User*/}
                          <a href="#" className="text-primary fw-bold me-1">
                            Jimmy Bold
                          </a>
                          {/*end::User*/}
                        </div>
                        {/*end::Description*/}
                      </div>
                      {/*end::Timeline heading*/}
                    </div>
                    {/*end::Timeline content*/}
                  </div>
                  {/*end::Timeline item*/}
                </div>
                {/*end::Timeline items*/}
              </div>
              {/*end::Content*/}
            </div>
            {/*end::Body*/}
            {/*begin::Footer*/}
            <div className="card-footer py-5 text-center" id="kt_activities_footer">
              <a href="../../demo52/dist/pages/user-profile/activity.html" className="btn btn-bg-body text-primary">
                View All Activities
                <i className="ki-outline ki-arrow-right fs-3 text-primary" />
              </a>
            </div>
            {/*end::Footer*/}
          </div>
        </div>
        {/*end::Activities drawer*/}
        {/*begin::Chat drawer*/}
        <div
          id="kt_drawer_chat"
          className="bg-body"
          data-kt-drawer="true"
          data-kt-drawer-name="chat"
          data-kt-drawer-activate="true"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'300px', 'md': '500px'}"
          data-kt-drawer-direction="end"
          data-kt-drawer-toggle="#kt_drawer_chat_toggle"
          data-kt-drawer-close="#kt_drawer_chat_close"
        >
          {/*begin::Messenger*/}
          <div className="card w-100 border-0 rounded-0" id="kt_drawer_chat_messenger">
            {/*begin::Card header*/}
            <div className="card-header pe-5" id="kt_drawer_chat_messenger_header">
              {/*begin::Title*/}
              <div className="card-title">
                {/*begin::User*/}
                <div className="d-flex justify-content-center flex-column me-3">
                  <a href="#" className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                    Brian Cox
                  </a>
                  {/*begin::Info*/}
                  <div className="mb-0 lh-1">
                    <span className="badge badge-success badge-circle w-10px h-10px me-1" />
                    <span className="fs-7 fw-semibold text-muted">Active</span>
                  </div>
                  {/*end::Info*/}
                </div>
                {/*end::User*/}
              </div>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Menu*/}
                <div className="me-0">
                  <button className="btn btn-sm btn-icon btn-active-color-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                    <i className="ki-outline ki-dots-square fs-2" />
                  </button>
                  {/*begin::Menu 3*/}
                  <div
                    className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                    data-kt-menu="true"
                  >
                    {/*begin::Heading*/}
                    <div className="menu-item px-3">
                      <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Contacts</div>
                    </div>
                    {/*end::Heading*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a href="#" className="menu-link px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_users_search">
                        Add Contact
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3">
                      <a href="#" className="menu-link flex-stack px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">
                        Invite Contacts
                        <span className="ms-2" data-bs-toggle="tooltip" title="Specify a contact email to send an invitation">
                          <i className="ki-outline ki-information fs-7" />
                        </span>
                      </a>
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                      <a href="#" className="menu-link px-3">
                        <span className="menu-title">Groups</span>
                        <span className="menu-arrow" />
                      </a>
                      {/*begin::Menu sub*/}
                      <div className="menu-sub menu-sub-dropdown w-175px py-4">
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3" data-bs-toggle="tooltip" title="Coming soon">
                            Create Group
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3" data-bs-toggle="tooltip" title="Coming soon">
                            Invite Members
                          </a>
                        </div>
                        {/*end::Menu item*/}
                        {/*begin::Menu item*/}
                        <div className="menu-item px-3">
                          <a href="#" className="menu-link px-3" data-bs-toggle="tooltip" title="Coming soon">
                            Settings
                          </a>
                        </div>
                        {/*end::Menu item*/}
                      </div>
                      {/*end::Menu sub*/}
                    </div>
                    {/*end::Menu item*/}
                    {/*begin::Menu item*/}
                    <div className="menu-item px-3 my-1">
                      <a href="#" className="menu-link px-3" data-bs-toggle="tooltip" title="Coming soon">
                        Settings
                      </a>
                    </div>
                    {/*end::Menu item*/}
                  </div>
                  {/*end::Menu 3*/}
                </div>
                {/*end::Menu*/}
                {/*begin::Close*/}
                <div className="btn btn-sm btn-icon btn-active-color-primary" id="kt_drawer_chat_close">
                  <i className="ki-outline ki-cross-square fs-2" />
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body" id="kt_drawer_chat_messenger_body">
              {/*begin::Messages*/}
              <div
                className="scroll-y me-n5 pe-5"
                data-kt-element="messages"
                data-kt-scroll="true"
                data-kt-scroll-activate="true"
                data-kt-scroll-height="auto"
                data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
                data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body"
                data-kt-scroll-offset="0px"
              >
                {/*begin::Message(in)*/}
                <div className="d-flex justify-content-start mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-start">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-3">
                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">
                          Brian Cox
                        </a>
                        <span className="text-muted fs-7 mb-1">2 mins</span>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">
                      How likely are you to recommend our company to your friends and family ?
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(in)*/}
                {/*begin::Message(out)*/}
                <div className="d-flex justify-content-end mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-end">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Details*/}
                      <div className="me-3">
                        <span className="text-muted fs-7 mb-1">5 mins</span>
                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">
                          You
                        </a>
                      </div>
                      {/*end::Details*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                      </div>
                      {/*end::Avatar*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end" data-kt-element="message-text">
                      Hey there, we’re just writing to let you know that you’ve been subscribed to a repository on GitHub.
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(out)*/}
                {/*begin::Message(in)*/}
                <div className="d-flex justify-content-start mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-start">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-3">
                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">
                          Brian Cox
                        </a>
                        <span className="text-muted fs-7 mb-1">1 Hour</span>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">
                      Ok, Understood!
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(in)*/}
                {/*begin::Message(out)*/}
                <div className="d-flex justify-content-end mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-end">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Details*/}
                      <div className="me-3">
                        <span className="text-muted fs-7 mb-1">2 Hours</span>
                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">
                          You
                        </a>
                      </div>
                      {/*end::Details*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                      </div>
                      {/*end::Avatar*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end" data-kt-element="message-text">
                      You’ll receive notifications for all issues, pull requests!
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(out)*/}
                {/*begin::Message(in)*/}
                <div className="d-flex justify-content-start mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-start">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-3">
                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">
                          Brian Cox
                        </a>
                        <span className="text-muted fs-7 mb-1">3 Hours</span>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">
                      You can unwatch this repository immediately by clicking here:
                      <a href="https://keenthemes.com">Keenthemes.com</a>
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(in)*/}
                {/*begin::Message(out)*/}
                <div className="d-flex justify-content-end mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-end">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Details*/}
                      <div className="me-3">
                        <span className="text-muted fs-7 mb-1">4 Hours</span>
                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">
                          You
                        </a>
                      </div>
                      {/*end::Details*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                      </div>
                      {/*end::Avatar*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end" data-kt-element="message-text">
                      Most purchased Business courses during this sale!
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(out)*/}
                {/*begin::Message(in)*/}
                <div className="d-flex justify-content-start mb-10">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-start">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-3">
                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">
                          Brian Cox
                        </a>
                        <span className="text-muted fs-7 mb-1">5 Hours</span>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">
                      Company BBQ to celebrate the last quater achievements and goals. Food and drinks provided
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(in)*/}
                {/*begin::Message(template for out)*/}
                <div className="d-flex justify-content-end mb-10 d-none" data-kt-element="template-out">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-end">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Details*/}
                      <div className="me-3">
                        <span className="text-muted fs-7 mb-1">Just now</span>
                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">
                          You
                        </a>
                      </div>
                      {/*end::Details*/}
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                      </div>
                      {/*end::Avatar*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end" data-kt-element="message-text" />
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(template for out)*/}
                {/*begin::Message(template for in)*/}
                <div className="d-flex justify-content-start mb-10 d-none" data-kt-element="template-in">
                  {/*begin::Wrapper*/}
                  <div className="d-flex flex-column align-items-start">
                    {/*begin::User*/}
                    <div className="d-flex align-items-center mb-2">
                      {/*begin::Avatar*/}
                      <div className="symbol symbol-35px symbol-circle">
                        <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                      </div>
                      {/*end::Avatar*/}
                      {/*begin::Details*/}
                      <div className="ms-3">
                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">
                          Brian Cox
                        </a>
                        <span className="text-muted fs-7 mb-1">Just now</span>
                      </div>
                      {/*end::Details*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::Text*/}
                    <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">
                      Right before vacation season we have the next Big Deal for you.
                    </div>
                    {/*end::Text*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Message(template for in)*/}
              </div>
              {/*end::Messages*/}
            </div>
            {/*end::Card body*/}
            {/*begin::Card footer*/}
            <div className="card-footer pt-4" id="kt_drawer_chat_messenger_footer">
              {/*begin::Input*/}
              <textarea className="form-control form-control-flush mb-3" rows={1} data-kt-element="input" placeholder="Type a message" defaultValue={''} />
              {/*end::Input*/}
              {/*begin:Toolbar*/}
              <div className="d-flex flex-stack">
                {/*begin::Actions*/}
                <div className="d-flex align-items-center me-2">
                  <button className="btn btn-sm btn-icon btn-active-light-primary me-1" type="button" data-bs-toggle="tooltip" title="Coming soon">
                    <i className="ki-outline ki-paper-clip fs-3" />
                  </button>
                  <button className="btn btn-sm btn-icon btn-active-light-primary me-1" type="button" data-bs-toggle="tooltip" title="Coming soon">
                    <i className="ki-outline ki-cloud-add fs-3" />
                  </button>
                </div>
                {/*end::Actions*/}
                {/*begin::Send*/}
                <button className="btn btn-primary" type="button" data-kt-element="send">
                  Send
                </button>
                {/*end::Send*/}
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Card footer*/}
          </div>
          {/*end::Messenger*/}
        </div>
        {/*end::Chat drawer*/}
        {/*begin::Chat drawer*/}
        <div
          id="kt_shopping_cart"
          className="bg-body"
          data-kt-drawer="true"
          data-kt-drawer-name="cart"
          data-kt-drawer-activate="true"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'300px', 'md': '500px'}"
          data-kt-drawer-direction="end"
          data-kt-drawer-toggle="#kt_drawer_shopping_cart_toggle"
          data-kt-drawer-close="#kt_drawer_shopping_cart_close"
        >
          {/*begin::Messenger*/}
          <div className="card card-flush w-100 rounded-0">
            {/*begin::Card header*/}
            <div className="card-header">
              {/*begin::Title*/}
              <h3 className="card-title text-gray-900 fw-bold">Shopping Cart</h3>
              {/*end::Title*/}
              {/*begin::Card toolbar*/}
              <div className="card-toolbar">
                {/*begin::Close*/}
                <div className="btn btn-sm btn-icon btn-active-light-primary" id="kt_drawer_shopping_cart_close">
                  <i className="ki-outline ki-cross fs-2" />
                </div>
                {/*end::Close*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className="card-body hover-scroll-overlay-y h-400px pt-5">
              {/*begin::Item*/}
              <div className="d-flex flex-stack">
                {/*begin::Wrapper*/}
                <div className="d-flex flex-column me-3">
                  {/*begin::Section*/}
                  <div className="mb-3">
                    <a href="#" className="text-gray-800 text-hover-primary fs-4 fw-bold">
                      Iblender
                    </a>
                    <span className="text-gray-400 fw-semibold d-block">The best kitchen gadget in 2022</span>
                  </div>
                  {/*end::Section*/}
                  {/*begin::Section*/}
                  <div className="d-flex align-items-center">
                    <span className="fw-bold text-gray-800 fs-5">$ 350</span>
                    <span className="text-muted mx-2">for</span>
                    <span className="fw-bold text-gray-800 fs-5 me-3">5</span>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2">
                      <i className="ki-outline ki-minus fs-4" />
                    </a>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon w-25px h-25px">
                      <i className="ki-outline ki-plus fs-4" />
                    </a>
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Pic*/}
                <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                  <img src="assets/media/stock/600x400/img-1.jpg" alt="" />
                </div>
                {/*end::Pic*/}
              </div>
              {/*end::Item*/}
              {/*begin::Separator*/}
              <div className="separator separator-dashed my-6" />
              {/*end::Separator*/}
              {/*begin::Item*/}
              <div className="d-flex flex-stack">
                {/*begin::Wrapper*/}
                <div className="d-flex flex-column me-3">
                  {/*begin::Section*/}
                  <div className="mb-3">
                    <a href="#" className="text-gray-800 text-hover-primary fs-4 fw-bold">
                      SmartCleaner
                    </a>
                    <span className="text-gray-400 fw-semibold d-block">Smart tool for cooking</span>
                  </div>
                  {/*end::Section*/}
                  {/*begin::Section*/}
                  <div className="d-flex align-items-center">
                    <span className="fw-bold text-gray-800 fs-5">$ 650</span>
                    <span className="text-muted mx-2">for</span>
                    <span className="fw-bold text-gray-800 fs-5 me-3">4</span>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2">
                      <i className="ki-outline ki-minus fs-4" />
                    </a>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon w-25px h-25px">
                      <i className="ki-outline ki-plus fs-4" />
                    </a>
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Pic*/}
                <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                  <img src="assets/media/stock/600x400/img-3.jpg" alt="" />
                </div>
                {/*end::Pic*/}
              </div>
              {/*end::Item*/}
              {/*begin::Separator*/}
              <div className="separator separator-dashed my-6" />
              {/*end::Separator*/}
              {/*begin::Item*/}
              <div className="d-flex flex-stack">
                {/*begin::Wrapper*/}
                <div className="d-flex flex-column me-3">
                  {/*begin::Section*/}
                  <div className="mb-3">
                    <a href="#" className="text-gray-800 text-hover-primary fs-4 fw-bold">
                      CameraMaxr
                    </a>
                    <span className="text-gray-400 fw-semibold d-block">Professional camera for edge</span>
                  </div>
                  {/*end::Section*/}
                  {/*begin::Section*/}
                  <div className="d-flex align-items-center">
                    <span className="fw-bold text-gray-800 fs-5">$ 150</span>
                    <span className="text-muted mx-2">for</span>
                    <span className="fw-bold text-gray-800 fs-5 me-3">3</span>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2">
                      <i className="ki-outline ki-minus fs-4" />
                    </a>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon w-25px h-25px">
                      <i className="ki-outline ki-plus fs-4" />
                    </a>
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Pic*/}
                <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                  <img src="assets/media/stock/600x400/img-8.jpg" alt="" />
                </div>
                {/*end::Pic*/}
              </div>
              {/*end::Item*/}
              {/*begin::Separator*/}
              <div className="separator separator-dashed my-6" />
              {/*end::Separator*/}
              {/*begin::Item*/}
              <div className="d-flex flex-stack">
                {/*begin::Wrapper*/}
                <div className="d-flex flex-column me-3">
                  {/*begin::Section*/}
                  <div className="mb-3">
                    <a href="#" className="text-gray-800 text-hover-primary fs-4 fw-bold">
                      $D Printer
                    </a>
                    <span className="text-gray-400 fw-semibold d-block">Manfactoring unique objekts</span>
                  </div>
                  {/*end::Section*/}
                  {/*begin::Section*/}
                  <div className="d-flex align-items-center">
                    <span className="fw-bold text-gray-800 fs-5">$ 1450</span>
                    <span className="text-muted mx-2">for</span>
                    <span className="fw-bold text-gray-800 fs-5 me-3">7</span>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2">
                      <i className="ki-outline ki-minus fs-4" />
                    </a>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon w-25px h-25px">
                      <i className="ki-outline ki-plus fs-4" />
                    </a>
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Pic*/}
                <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                  <img src="assets/media/stock/600x400/img-26.jpg" alt="" />
                </div>
                {/*end::Pic*/}
              </div>
              {/*end::Item*/}
              {/*begin::Separator*/}
              <div className="separator separator-dashed my-6" />
              {/*end::Separator*/}
              {/*begin::Item*/}
              <div className="d-flex flex-stack">
                {/*begin::Wrapper*/}
                <div className="d-flex flex-column me-3">
                  {/*begin::Section*/}
                  <div className="mb-3">
                    <a href="#" className="text-gray-800 text-hover-primary fs-4 fw-bold">
                      MotionWire
                    </a>
                    <span className="text-gray-400 fw-semibold d-block">Perfect animation tool</span>
                  </div>
                  {/*end::Section*/}
                  {/*begin::Section*/}
                  <div className="d-flex align-items-center">
                    <span className="fw-bold text-gray-800 fs-5">$ 650</span>
                    <span className="text-muted mx-2">for</span>
                    <span className="fw-bold text-gray-800 fs-5 me-3">7</span>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2">
                      <i className="ki-outline ki-minus fs-4" />
                    </a>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon w-25px h-25px">
                      <i className="ki-outline ki-plus fs-4" />
                    </a>
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Pic*/}
                <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                  <img src="assets/media/stock/600x400/img-21.jpg" alt="" />
                </div>
                {/*end::Pic*/}
              </div>
              {/*end::Item*/}
              {/*begin::Separator*/}
              <div className="separator separator-dashed my-6" />
              {/*end::Separator*/}
              {/*begin::Item*/}
              <div className="d-flex flex-stack">
                {/*begin::Wrapper*/}
                <div className="d-flex flex-column me-3">
                  {/*begin::Section*/}
                  <div className="mb-3">
                    <a href="#" className="text-gray-800 text-hover-primary fs-4 fw-bold">
                      Samsung
                    </a>
                    <span className="text-gray-400 fw-semibold d-block">Profile info,Timeline etc</span>
                  </div>
                  {/*end::Section*/}
                  {/*begin::Section*/}
                  <div className="d-flex align-items-center">
                    <span className="fw-bold text-gray-800 fs-5">$ 720</span>
                    <span className="text-muted mx-2">for</span>
                    <span className="fw-bold text-gray-800 fs-5 me-3">6</span>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2">
                      <i className="ki-outline ki-minus fs-4" />
                    </a>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon w-25px h-25px">
                      <i className="ki-outline ki-plus fs-4" />
                    </a>
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Pic*/}
                <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                  <img src="assets/media/stock/600x400/img-34.jpg" alt="" />
                </div>
                {/*end::Pic*/}
              </div>
              {/*end::Item*/}
              {/*begin::Separator*/}
              <div className="separator separator-dashed my-6" />
              {/*end::Separator*/}
              {/*begin::Item*/}
              <div className="d-flex flex-stack">
                {/*begin::Wrapper*/}
                <div className="d-flex flex-column me-3">
                  {/*begin::Section*/}
                  <div className="mb-3">
                    <a href="#" className="text-gray-800 text-hover-primary fs-4 fw-bold">
                      $D Printer
                    </a>
                    <span className="text-gray-400 fw-semibold d-block">Manfactoring unique objekts</span>
                  </div>
                  {/*end::Section*/}
                  {/*begin::Section*/}
                  <div className="d-flex align-items-center">
                    <span className="fw-bold text-gray-800 fs-5">$ 430</span>
                    <span className="text-muted mx-2">for</span>
                    <span className="fw-bold text-gray-800 fs-5 me-3">8</span>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon-success btn-icon w-25px h-25px me-2">
                      <i className="ki-outline ki-minus fs-4" />
                    </a>
                    <a href="#" className="btn btn-sm btn-light-success btn-icon w-25px h-25px">
                      <i className="ki-outline ki-plus fs-4" />
                    </a>
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Pic*/}
                <div className="symbol symbol-70px symbol-2by3 flex-shrink-0">
                  <img src="assets/media/stock/600x400/img-27.jpg" alt="" />
                </div>
                {/*end::Pic*/}
              </div>
              {/*end::Item*/}
            </div>
            {/*end::Card body*/}
            {/*begin::Card footer*/}
            <div className="card-footer">
              {/*begin::Item*/}
              <div className="d-flex flex-stack">
                <span className="fw-bold text-gray-600">Total</span>
                <span className="text-gray-800 fw-bolder fs-5">$ 1840.00</span>
              </div>
              {/*end::Item*/}
              {/*begin::Item*/}
              <div className="d-flex flex-stack">
                <span className="fw-bold text-gray-600">Sub total</span>
                <span className="text-primary fw-bolder fs-5">$ 246.35</span>
              </div>
              {/*end::Item*/}
              {/*end::Action*/}
              <div className="d-flex justify-content-end mt-9">
                <a href="#" className="btn btn-primary d-flex justify-content-end">
                  Pleace Order
                </a>
              </div>
              {/*end::Action*/}
            </div>
            {/*end::Card footer*/}
          </div>
          {/*end::Messenger*/}
        </div>
        {/*end::Chat drawer*/}
        {/*end::Drawers*/}
        {/*begin::Scrolltop*/}
        <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
          <i className="ki-outline ki-arrow-up" />
        </div>
        {/*end::Scrolltop*/}
        {/*begin::Modals*/}
        {/*begin::Modal - View Users*/}
        <div className="modal fade" id="kt_modal_view_users" tabIndex={-1} aria-hidden="true">
          {/*begin::Modal dialog*/}
          <div className="modal-dialog mw-650px">
            {/*begin::Modal content*/}
            <div className="modal-content">
              {/*begin::Modal header*/}
              <div className="modal-header pb-0 border-0 justify-content-end">
                {/*begin::Close*/}
                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                  <i className="ki-outline ki-cross fs-1" />
                </div>
                {/*end::Close*/}
              </div>
              {/*begin::Modal header*/}
              {/*begin::Modal body*/}
              <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                {/*begin::Heading*/}
                <div className="text-center mb-13">
                  {/*begin::Title*/}
                  <h1 className="mb-3">Browse Users</h1>
                  {/*end::Title*/}
                  {/*begin::Description*/}
                  <div className="text-muted fw-semibold fs-5">
                    If you need more info, please check out our
                    <a href="#" className="link-primary fw-bold">
                      Users Directory
                    </a>
                    .
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Heading*/}
                {/*begin::Users*/}
                <div className="mb-15">
                  {/*begin::List*/}
                  <div className="mh-375px scroll-y me-n7 pe-7">
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Emma Smith
                            <span className="badge badge-light fs-8 fw-semibold ms-2">Art Director</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">smith@kpmg.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$23,000</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-danger text-danger fw-semibold">M</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Melody Macy
                            <span className="badge badge-light fs-8 fw-semibold ms-2">Marketing Analytic</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">melody@altbox.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$50,500</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Max Smith
                            <span className="badge badge-light fs-8 fw-semibold ms-2">Software Enginer</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">max@kt.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$75,900</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-5.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Sean Bean
                            <span className="badge badge-light fs-8 fw-semibold ms-2">Web Developer</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">sean@dellito.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$10,500</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Brian Cox
                            <span className="badge badge-light fs-8 fw-semibold ms-2">UI/UX Designer</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">brian@exchange.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$20,000</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-warning text-warning fw-semibold">C</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Mikaela Collins
                            <span className="badge badge-light fs-8 fw-semibold ms-2">Head Of Marketing</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">mik@pex.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$9,300</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-9.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Francis Mitcham
                            <span className="badge badge-light fs-8 fw-semibold ms-2">Software Arcitect</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">f.mit@kpmg.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$15,000</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-danger text-danger fw-semibold">O</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Olivia Wild
                            <span className="badge badge-light fs-8 fw-semibold ms-2">System Admin</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">olivia@corpmail.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$23,000</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-primary text-primary fw-semibold">N</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Neil Owen
                            <span className="badge badge-light fs-8 fw-semibold ms-2">Account Manager</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">owen.neil@gmail.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$45,800</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-23.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Dan Wilson
                            <span className="badge badge-light fs-8 fw-semibold ms-2">Web Desinger</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">dam@consilting.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$90,500</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-danger text-danger fw-semibold">E</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Emma Bold
                            <span className="badge badge-light fs-8 fw-semibold ms-2">Corporate Finance</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">emma@intenso.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$5,000</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-12.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Ana Crown
                            <span className="badge badge-light fs-8 fw-semibold ms-2">Customer Relationship</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">ana.cf@limtel.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$70,000</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-5">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-info text-info fw-semibold">A</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-6">
                          {/*begin::Name*/}
                          <a href="#" className="d-flex align-items-center fs-5 fw-bold text-dark text-hover-primary">
                            Robert Doe
                            <span className="badge badge-light fs-8 fw-semibold ms-2">Marketing Executive</span>
                          </a>
                          {/*end::Name*/}
                          {/*begin::Email*/}
                          <div className="fw-semibold text-muted">robert@benko.com</div>
                          {/*end::Email*/}
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Stats*/}
                      <div className="d-flex">
                        {/*begin::Sales*/}
                        <div className="text-end">
                          <div className="fs-5 fw-bold text-dark">$45,500</div>
                          <div className="fs-7 text-muted">Sales</div>
                        </div>
                        {/*end::Sales*/}
                      </div>
                      {/*end::Stats*/}
                    </div>
                    {/*end::User*/}
                  </div>
                  {/*end::List*/}
                </div>
                {/*end::Users*/}
                {/*begin::Notice*/}
                <div className="d-flex justify-content-between">
                  {/*begin::Label*/}
                  <div className="fw-semibold">
                    <label className="fs-6">Adding Users by Team Members</label>
                    <div className="fs-7 text-muted">If you need more info, please check budget planning</div>
                  </div>
                  {/*end::Label*/}
                  {/*begin::Switch*/}
                  <label className="form-check form-switch form-check-custom form-check-solid">
                    <input className="form-check-input" type="checkbox" defaultValue defaultChecked="checked" />
                    <span className="form-check-label fw-semibold text-muted">Allowed</span>
                  </label>
                  {/*end::Switch*/}
                </div>
                {/*end::Notice*/}
              </div>
              {/*end::Modal body*/}
            </div>
            {/*end::Modal content*/}
          </div>
          {/*end::Modal dialog*/}
        </div>
        {/*end::Modal - View Users*/}
        {/*begin::Modal - Create Campaign*/}
        <div className="modal fade" id="kt_modal_create_campaign" tabIndex={-1} aria-hidden="true">
          {/*begin::Modal dialog*/}
          <div className="modal-dialog modal-fullscreen p-9">
            {/*begin::Modal content*/}
            <div className="modal-content modal-rounded">
              {/*begin::Modal header*/}
              <div className="modal-header py-7 d-flex justify-content-between">
                {/*begin::Modal title*/}
                <h2>Create Campaign</h2>
                {/*end::Modal title*/}
                {/*begin::Close*/}
                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                  <i className="ki-outline ki-cross fs-1" />
                </div>
                {/*end::Close*/}
              </div>
              {/*begin::Modal header*/}
              {/*begin::Modal body*/}
              <div className="modal-body scroll-y m-5">
                {/*begin::Stepper*/}
                <div className="stepper stepper-links d-flex flex-column" id="kt_modal_create_campaign_stepper">
                  {/*begin::Nav*/}
                  <div className="stepper-nav justify-content-center py-2">
                    {/*begin::Step 1*/}
                    <div className="stepper-item me-5 me-md-15 current" data-kt-stepper-element="nav">
                      <h3 className="stepper-title">Campaign Details</h3>
                    </div>
                    {/*end::Step 1*/}
                    {/*begin::Step 2*/}
                    <div className="stepper-item me-5 me-md-15" data-kt-stepper-element="nav">
                      <h3 className="stepper-title">Creative Uploads</h3>
                    </div>
                    {/*end::Step 2*/}
                    {/*begin::Step 3*/}
                    <div className="stepper-item me-5 me-md-15" data-kt-stepper-element="nav">
                      <h3 className="stepper-title">Audiences</h3>
                    </div>
                    {/*end::Step 3*/}
                    {/*begin::Step 4*/}
                    <div className="stepper-item me-5 me-md-15" data-kt-stepper-element="nav">
                      <h3 className="stepper-title">Budget Estimates</h3>
                    </div>
                    {/*end::Step 4*/}
                    {/*begin::Step 5*/}
                    <div className="stepper-item" data-kt-stepper-element="nav">
                      <h3 className="stepper-title">Completed</h3>
                    </div>
                    {/*end::Step 5*/}
                  </div>
                  {/*end::Nav*/}
                  {/*begin::Form*/}
                  <form className="mx-auto w-100 mw-600px pt-15 pb-10" noValidate="novalidate" id="kt_modal_create_campaign_stepper_form">
                    {/*begin::Step 1*/}
                    <div className="current" data-kt-stepper-element="content">
                      {/*begin::Wrapper*/}
                      <div className="w-100">
                        {/*begin::Heading*/}
                        <div className="pb-10 pb-lg-15">
                          {/*begin::Title*/}
                          <h2 className="fw-bold d-flex align-items-center text-dark">
                            Setup Campaign Details
                            <span className="ms-1" data-bs-toggle="tooltip" title="Campaign name will be used as reference within your campaign reports">
                              <i className="ki-outline ki-information-5 text-gray-500 fs-6" />
                            </span>
                          </h2>
                          {/*end::Title*/}
                          {/*begin::Notice*/}
                          <div className="text-muted fw-semibold fs-6">
                            If you need more info, please check out
                            <a href="#" className="link-primary fw-bold">
                              Help Page
                            </a>
                            .
                          </div>
                          {/*end::Notice*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Input group*/}
                        <div className="mb-10 fv-row">
                          {/*begin::Label*/}
                          <label className="required form-label mb-3">Campaign Name</label>
                          {/*end::Label*/}
                          {/*begin::Input*/}
                          <input type="text" className="form-control form-control-lg form-control-solid" name="campaign_name" placeholder defaultValue />
                          {/*end::Input*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10">
                          {/*begin::Label*/}
                          <label className="d-block fw-semibold fs-6 mb-5">
                            <span className="required">Company Logo</span>
                            <span className="ms-1" data-bs-toggle="tooltip" title="E.g. Select a logo to represent the company that's running the campaign.">
                              <i className="ki-outline ki-information-5 text-gray-500 fs-6" />
                            </span>
                          </label>
                          {/*end::Label*/}
                          {/*begin::Image input placeholder*/}
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                ".image-input-placeholder { background-image: url('assets/media/svg/files/blank-image.svg'); } [data-bs-theme=\"dark\"] .image-input-placeholder { background-image: url('assets/media/svg/files/blank-image-dark.svg'); }",
                            }}
                          />
                          {/*end::Image input placeholder*/}
                          {/*begin::Image input*/}
                          <div className="image-input image-input-empty image-input-outline image-input-placeholder" data-kt-image-input="true">
                            {/*begin::Preview existing avatar*/}
                            <div className="image-input-wrapper w-125px h-125px" />
                            {/*end::Preview existing avatar*/}
                            {/*begin::Label*/}
                            <label
                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                              data-kt-image-input-action="change"
                              data-bs-toggle="tooltip"
                              title="Change avatar"
                            >
                              <i className="ki-outline ki-pencil fs-7" />
                              {/*begin::Inputs*/}
                              <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                              <input type="hidden" name="avatar_remove" />
                              {/*end::Inputs*/}
                            </label>
                            {/*end::Label*/}
                            {/*begin::Cancel*/}
                            <span
                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                              data-kt-image-input-action="cancel"
                              data-bs-toggle="tooltip"
                              title="Cancel avatar"
                            >
                              <i className="ki-outline ki-cross fs-2" />
                            </span>
                            {/*end::Cancel*/}
                            {/*begin::Remove*/}
                            <span
                              className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                              data-kt-image-input-action="remove"
                              data-bs-toggle="tooltip"
                              title="Remove avatar"
                            >
                              <i className="ki-outline ki-cross fs-2" />
                            </span>
                            {/*end::Remove*/}
                          </div>
                          {/*end::Image input*/}
                          {/*begin::Hint*/}
                          <div className="form-text">Allowed file types: png, jpg, jpeg.</div>
                          {/*end::Hint*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="mb-10">
                          {/*begin::Label*/}
                          <label className="required fw-semibold fs-6 mb-5">Campaign Goal</label>
                          {/*end::Label*/}
                          {/*begin::Roles*/}
                          {/*begin::Input row*/}
                          <div className="d-flex fv-row">
                            {/*begin::Radio*/}
                            <div className="form-check form-check-custom form-check-solid">
                              {/*begin::Input*/}
                              <input
                                className="form-check-input me-3"
                                name="user_role"
                                type="radio"
                                defaultValue={0}
                                id="kt_modal_update_role_option_0"
                                defaultChecked="checked"
                              />
                              {/*end::Input*/}
                              {/*begin::Label*/}
                              <label className="form-check-label" htmlFor="kt_modal_update_role_option_0">
                                <div className="fw-bold text-gray-800">Get more visitors</div>
                                <div className="text-gray-600">Increase impression traffic onto the platform</div>
                              </label>
                              {/*end::Label*/}
                            </div>
                            {/*end::Radio*/}
                          </div>
                          {/*end::Input row*/}
                          <div className="separator separator-dashed my-5" />
                          {/*begin::Input row*/}
                          <div className="d-flex fv-row">
                            {/*begin::Radio*/}
                            <div className="form-check form-check-custom form-check-solid">
                              {/*begin::Input*/}
                              <input className="form-check-input me-3" name="user_role" type="radio" defaultValue={1} id="kt_modal_update_role_option_1" />
                              {/*end::Input*/}
                              {/*begin::Label*/}
                              <label className="form-check-label" htmlFor="kt_modal_update_role_option_1">
                                <div className="fw-bold text-gray-800">Get more messages on chat</div>
                                <div className="text-gray-600">Increase community interaction and communication</div>
                              </label>
                              {/*end::Label*/}
                            </div>
                            {/*end::Radio*/}
                          </div>
                          {/*end::Input row*/}
                          <div className="separator separator-dashed my-5" />
                          {/*begin::Input row*/}
                          <div className="d-flex fv-row">
                            {/*begin::Radio*/}
                            <div className="form-check form-check-custom form-check-solid">
                              {/*begin::Input*/}
                              <input className="form-check-input me-3" name="user_role" type="radio" defaultValue={2} id="kt_modal_update_role_option_2" />
                              {/*end::Input*/}
                              {/*begin::Label*/}
                              <label className="form-check-label" htmlFor="kt_modal_update_role_option_2">
                                <div className="fw-bold text-gray-800">Get more calls</div>
                                <div className="text-gray-600">Boost telecommunication feedback to provide precise and accurate information</div>
                              </label>
                              {/*end::Label*/}
                            </div>
                            {/*end::Radio*/}
                          </div>
                          {/*end::Input row*/}
                          <div className="separator separator-dashed my-5" />
                          {/*begin::Input row*/}
                          <div className="d-flex fv-row">
                            {/*begin::Radio*/}
                            <div className="form-check form-check-custom form-check-solid">
                              {/*begin::Input*/}
                              <input className="form-check-input me-3" name="user_role" type="radio" defaultValue={3} id="kt_modal_update_role_option_3" />
                              {/*end::Input*/}
                              {/*begin::Label*/}
                              <label className="form-check-label" htmlFor="kt_modal_update_role_option_3">
                                <div className="fw-bold text-gray-800">Get more likes</div>
                                <div className="text-gray-600">Increase positive interactivity on social media platforms</div>
                              </label>
                              {/*end::Label*/}
                            </div>
                            {/*end::Radio*/}
                          </div>
                          {/*end::Input row*/}
                          <div className="separator separator-dashed my-5" />
                          {/*begin::Input row*/}
                          <div className="d-flex fv-row">
                            {/*begin::Radio*/}
                            <div className="form-check form-check-custom form-check-solid">
                              {/*begin::Input*/}
                              <input className="form-check-input me-3" name="user_role" type="radio" defaultValue={4} id="kt_modal_update_role_option_4" />
                              {/*end::Input*/}
                              {/*begin::Label*/}
                              <label className="form-check-label" htmlFor="kt_modal_update_role_option_4">
                                <div className="fw-bold text-gray-800">Lead generation</div>
                                <div className="text-gray-600">Collect contact information for potential customers</div>
                              </label>
                              {/*end::Label*/}
                            </div>
                            {/*end::Radio*/}
                          </div>
                          {/*end::Input row*/}
                          {/*end::Roles*/}
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Step 1*/}
                    {/*begin::Step 2*/}
                    <div data-kt-stepper-element="content">
                      {/*begin::Wrapper*/}
                      <div className="w-100">
                        {/*begin::Heading*/}
                        <div className="pb-10 pb-lg-12">
                          {/*begin::Title*/}
                          <h1 className="fw-bold text-dark">Upload Files</h1>
                          {/*end::Title*/}
                          {/*begin::Description*/}
                          <div className="text-muted fw-semibold fs-4">
                            If you need more info, please check
                            <a href="#" className="link-primary">
                              Campaign Guidelines
                            </a>
                          </div>
                          {/*end::Description*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10">
                          {/*begin::Dropzone*/}
                          <div className="dropzone" id="kt_modal_create_campaign_files_upload">
                            {/*begin::Message*/}
                            <div className="dz-message needsclick">
                              {/*begin::Icon*/}
                              <i className="ki-outline ki-file-up fs-3hx text-primary" />
                              {/*end::Icon*/}
                              {/*begin::Info*/}
                              <div className="ms-4">
                                <h3 className="dfs-3 fw-bold text-gray-900 mb-1">Drop campaign files here or click to upload.</h3>
                                <span className="fw-semibold fs-4 text-muted">Upload up to 10 files</span>
                              </div>
                              {/*end::Info*/}
                            </div>
                          </div>
                          {/*end::Dropzone*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="mb-10">
                          {/*begin::Label*/}
                          <label className="fs-6 fw-semibold mb-2">Uploaded File</label>
                          {/*End::Label*/}
                          {/*begin::Files*/}
                          <div className="mh-300px scroll-y me-n7 pe-7">
                            {/*begin::File*/}
                            <div className="d-flex flex-stack py-4 border border-top-0 border-left-0 border-right-0 border-dashed">
                              <div className="d-flex align-items-center">
                                {/*begin::Avatar*/}
                                <div className="symbol symbol-35px">
                                  <img src="assets/media/svg/files/pdf.svg" alt="icon" />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::Details*/}
                                <div className="ms-6">
                                  <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                    Product Specifications
                                  </a>
                                  <div className="fw-semibold text-muted">230kb</div>
                                </div>
                                {/*end::Details*/}
                              </div>
                              {/*begin::Menu*/}
                              <div className="min-w-100px">
                                <select
                                  className="form-select form-select-solid form-select-sm"
                                  data-control="select2"
                                  data-hide-search="true"
                                  data-placeholder="Edit"
                                >
                                  <option />
                                  <option value={1}>Remove</option>
                                  <option value={2}>Modify</option>
                                  <option value={3}>Select</option>
                                </select>
                              </div>
                              {/*end::Menu*/}
                            </div>
                            {/*end::File*/}
                            {/*begin::File*/}
                            <div className="d-flex flex-stack py-4 border border-top-0 border-left-0 border-right-0 border-dashed">
                              <div className="d-flex align-items-center">
                                {/*begin::Avatar*/}
                                <div className="symbol symbol-35px">
                                  <img src="assets/media/svg/files/tif.svg" alt="icon" />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::Details*/}
                                <div className="ms-6">
                                  <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                    Campaign Creative Poster
                                  </a>
                                  <div className="fw-semibold text-muted">2.4mb</div>
                                </div>
                                {/*end::Details*/}
                              </div>
                              {/*begin::Menu*/}
                              <div className="min-w-100px">
                                <select
                                  className="form-select form-select-solid form-select-sm"
                                  data-control="select2"
                                  data-hide-search="true"
                                  data-placeholder="Edit"
                                >
                                  <option />
                                  <option value={1}>Remove</option>
                                  <option value={2}>Modify</option>
                                  <option value={3}>Select</option>
                                </select>
                              </div>
                              {/*end::Menu*/}
                            </div>
                            {/*end::File*/}
                            {/*begin::File*/}
                            <div className="d-flex flex-stack py-4 border border-top-0 border-left-0 border-right-0 border-dashed">
                              <div className="d-flex align-items-center">
                                {/*begin::Avatar*/}
                                <div className="symbol symbol-35px">
                                  <img src="assets/media/svg/files/folder-document.svg" alt="icon" />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::Details*/}
                                <div className="ms-6">
                                  <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                    Campaign Landing Page Source
                                  </a>
                                  <div className="fw-semibold text-muted">1.12mb</div>
                                </div>
                                {/*end::Details*/}
                              </div>
                              {/*begin::Menu*/}
                              <div className="min-w-100px">
                                <select
                                  className="form-select form-select-solid form-select-sm"
                                  data-control="select2"
                                  data-hide-search="true"
                                  data-placeholder="Edit"
                                >
                                  <option />
                                  <option value={1}>Remove</option>
                                  <option value={2}>Modify</option>
                                  <option value={3}>Select</option>
                                </select>
                              </div>
                              {/*end::Menu*/}
                            </div>
                            {/*end::File*/}
                            {/*begin::File*/}
                            <div className="d-flex flex-stack py-4 border border-top-0 border-left-0 border-right-0 border-dashed">
                              <div className="d-flex align-items-center">
                                {/*begin::Avatar*/}
                                <div className="symbol symbol-35px">
                                  <img src="assets/media/svg/files/css.svg" alt="icon" />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::Details*/}
                                <div className="ms-6">
                                  <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                    Landing Page Styling
                                  </a>
                                  <div className="fw-semibold text-muted">85kb</div>
                                </div>
                                {/*end::Details*/}
                              </div>
                              {/*begin::Menu*/}
                              <div className="min-w-100px">
                                <select
                                  className="form-select form-select-solid form-select-sm"
                                  data-control="select2"
                                  data-hide-search="true"
                                  data-placeholder="Edit"
                                >
                                  <option />
                                  <option value={1}>Remove</option>
                                  <option value={2}>Modify</option>
                                  <option value={3}>Select</option>
                                </select>
                              </div>
                              {/*end::Menu*/}
                            </div>
                            {/*end::File*/}
                            {/*begin::File*/}
                            <div className="d-flex flex-stack py-4 border border-top-0 border-left-0 border-right-0 border-dashed">
                              <div className="d-flex align-items-center">
                                {/*begin::Avatar*/}
                                <div className="symbol symbol-35px">
                                  <img src="assets/media/svg/files/ai.svg" alt="icon" />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::Details*/}
                                <div className="ms-6">
                                  <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                    Design Source Files
                                  </a>
                                  <div className="fw-semibold text-muted">48mb</div>
                                </div>
                                {/*end::Details*/}
                              </div>
                              {/*begin::Menu*/}
                              <div className="min-w-100px">
                                <select
                                  className="form-select form-select-solid form-select-sm"
                                  data-control="select2"
                                  data-hide-search="true"
                                  data-placeholder="Edit"
                                >
                                  <option />
                                  <option value={1}>Remove</option>
                                  <option value={2}>Modify</option>
                                  <option value={3}>Select</option>
                                </select>
                              </div>
                              {/*end::Menu*/}
                            </div>
                            {/*end::File*/}
                            {/*begin::File*/}
                            <div className="d-flex flex-stack py-4">
                              <div className="d-flex align-items-center">
                                {/*begin::Avatar*/}
                                <div className="symbol symbol-35px">
                                  <img src="assets/media/svg/files/doc.svg" alt="icon" />
                                </div>
                                {/*end::Avatar*/}
                                {/*begin::Details*/}
                                <div className="ms-6">
                                  <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                    Campaign Plan Document
                                  </a>
                                  <div className="fw-semibold text-muted">27kb</div>
                                </div>
                                {/*end::Details*/}
                              </div>
                              {/*begin::Menu*/}
                              <div className="min-w-100px">
                                <select
                                  className="form-select form-select-solid form-select-sm"
                                  data-control="select2"
                                  data-hide-search="true"
                                  data-placeholder="Edit"
                                >
                                  <option />
                                  <option value={1}>Remove</option>
                                  <option value={2}>Modify</option>
                                  <option value={3}>Select</option>
                                </select>
                              </div>
                              {/*end::Menu*/}
                            </div>
                            {/*end::File*/}
                          </div>
                          {/*end::Files*/}
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Step 2*/}
                    {/*begin::Step 3*/}
                    <div data-kt-stepper-element="content">
                      {/*begin::Wrapper*/}
                      <div className="w-100">
                        {/*begin::Heading*/}
                        <div className="pb-10 pb-lg-12">
                          {/*begin::Title*/}
                          <h1 className="fw-bold text-dark">Configure Audiences</h1>
                          {/*end::Title*/}
                          {/*begin::Description*/}
                          <div className="text-muted fw-semibold fs-4">
                            If you need more info, please check
                            <a href="#" className="link-primary">
                              Campaign Guidelines
                            </a>
                          </div>
                          {/*end::Description*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10">
                          {/*begin::Label*/}
                          <label className="fs-6 fw-semibold mb-2">
                            Gender
                            <span className="ms-1" data-bs-toggle="tooltip" title="Show your ads to either men or women, or select 'All' for both">
                              <i className="ki-outline ki-information-5 text-gray-500 fs-6" />
                            </span>
                          </label>
                          {/*End::Label*/}
                          {/*begin::Row*/}
                          <div className="row g-9" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button='true']">
                            {/*begin::Col*/}
                            <div className="col">
                              {/*begin::Option*/}
                              <label className="btn btn-outline btn-outline-dashed btn-active-light-primary active d-flex text-start p-6" data-kt-button="true">
                                {/*begin::Radio*/}
                                <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                  <input className="form-check-input" type="radio" name="campaign_gender" defaultValue={1} defaultChecked="checked" />
                                </span>
                                {/*end::Radio*/}
                                {/*begin::Info*/}
                                <span className="ms-5">
                                  <span className="fs-4 fw-bold text-gray-800 d-block">All</span>
                                </span>
                                {/*end::Info*/}
                              </label>
                              {/*end::Option*/}
                            </div>
                            {/*end::Col*/}
                            {/*begin::Col*/}
                            <div className="col">
                              {/*begin::Option*/}
                              <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6" data-kt-button="true">
                                {/*begin::Radio*/}
                                <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                  <input className="form-check-input" type="radio" name="campaign_gender" defaultValue={2} />
                                </span>
                                {/*end::Radio*/}
                                {/*begin::Info*/}
                                <span className="ms-5">
                                  <span className="fs-4 fw-bold text-gray-800 d-block">Male</span>
                                </span>
                                {/*end::Info*/}
                              </label>
                              {/*end::Option*/}
                            </div>
                            {/*end::Col*/}
                            {/*begin::Col*/}
                            <div className="col">
                              {/*begin::Option*/}
                              <label className="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6" data-kt-button="true">
                                {/*begin::Radio*/}
                                <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                  <input className="form-check-input" type="radio" name="campaign_gender" defaultValue={3} />
                                </span>
                                {/*end::Radio*/}
                                {/*begin::Info*/}
                                <span className="ms-5">
                                  <span className="fs-4 fw-bold text-gray-800 d-block">Female</span>
                                </span>
                                {/*end::Info*/}
                              </label>
                              {/*end::Option*/}
                            </div>
                            {/*end::Col*/}
                          </div>
                          {/*end::Row*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10">
                          {/*begin::Label*/}
                          <label className="fs-6 fw-semibold mb-2">
                            Age
                            <span
                              className="ms-1"
                              data-bs-toggle="tooltip"
                              title="Select the minimum and maximum age of the people who will find your ad relevant."
                            >
                              <i className="ki-outline ki-information-5 text-gray-500 fs-6" />
                            </span>
                          </label>
                          {/*End::Label*/}
                          {/*begin::Slider*/}
                          <div className="d-flex flex-stack">
                            <div id="kt_modal_create_campaign_age_min" className="fs-7 fw-semibold text-muted" />
                            <div id="kt_modal_create_campaign_age_slider" className="noUi-sm w-100 ms-5 me-8" />
                            <div id="kt_modal_create_campaign_age_max" className="fs-7 fw-semibold text-muted" />
                          </div>
                          {/*end::Slider*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10">
                          {/*begin::Label*/}
                          <label className="fs-6 fw-semibold mb-2">
                            Location
                            <span className="ms-1" data-bs-toggle="tooltip" title="Enter one or more location points for more specific targeting.">
                              <i className="ki-outline ki-information-5 text-gray-500 fs-6" />
                            </span>
                          </label>
                          {/*End::Label*/}
                          {/*begin::Tagify*/}
                          <input
                            className="form-control d-flex align-items-center"
                            defaultValue
                            id="kt_modal_create_campaign_location"
                            data-kt-flags-path="assets/media/flags/"
                          />
                          {/*end::Tagify*/}
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Step 3*/}
                    {/*begin::Step 4*/}
                    <div data-kt-stepper-element="content">
                      {/*begin::Wrapper*/}
                      <div className="w-100">
                        {/*begin::Heading*/}
                        <div className="pb-10 pb-lg-12">
                          {/*begin::Title*/}
                          <h1 className="fw-bold text-dark">Budget Estimates</h1>
                          {/*end::Title*/}
                          {/*begin::Description*/}
                          <div className="text-muted fw-semibold fs-4">
                            If you need more info, please check
                            <a href="#" className="link-primary">
                              Campaign Guidelines
                            </a>
                          </div>
                          {/*end::Description*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10">
                          {/*begin::Label*/}
                          <label className="fs-6 fw-semibold mb-2">
                            Campaign Duration
                            <span className="ms-1" data-bs-toggle="tooltip" title="Choose how long you want your ad to run for">
                              <i className="ki-outline ki-information-5 text-gray-500 fs-6" />
                            </span>
                          </label>
                          {/*end::Label*/}
                          {/*begin::Duration option*/}
                          <div className="d-flex gap-9 mb-7">
                            {/*begin::Button*/}
                            <button
                              type="button"
                              className="btn btn-outline btn-outline-dashed btn-active-light-primary active"
                              id="kt_modal_create_campaign_duration_all"
                            >
                              Continuous duration
                              <br />
                              <span className="fs-7">Your ad will run continuously for a daily budget.</span>
                            </button>
                            {/*end::Button*/}
                            {/*begin::Button*/}
                            <button
                              type="button"
                              className="btn btn-outline btn-outline-dashed btn-active-light-primary btn-outline-default"
                              id="kt_modal_create_campaign_duration_fixed"
                            >
                              Fixed duration
                              <br />
                              <span className="fs-7">Your ad will run on the specified dates only.</span>
                            </button>
                            {/*end::Button*/}
                          </div>
                          {/*end::Duration option*/}
                          {/*begin::Datepicker*/}
                          <input className="form-control form-control-solid d-none" placeholder="Pick date & time" id="kt_modal_create_campaign_datepicker" />
                          {/*end::Datepicker*/}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="fv-row mb-10">
                          {/*begin::Label*/}
                          <label className="fs-6 fw-semibold mb-2">
                            Daily Budget
                            <span
                              className="ms-1"
                              data-bs-toggle="tooltip"
                              title="Choose the budget allocated for each day. Higher budget will generate better results"
                            >
                              <i className="ki-outline ki-information-5 text-gray-500 fs-6" />
                            </span>
                          </label>
                          {/*end::Label*/}
                          {/*begin::Slider*/}
                          <div className="d-flex flex-column text-center">
                            <div className="d-flex align-items-start justify-content-center mb-7">
                              <span className="fw-bold fs-4 mt-1 me-2">$</span>
                              <span className="fw-bold fs-3x" id="kt_modal_create_campaign_budget_label" />
                              <span className="fw-bold fs-3x">.00</span>
                            </div>
                            <div id="kt_modal_create_campaign_budget_slider" className="noUi-sm" />
                          </div>
                          {/*end::Slider*/}
                        </div>
                        {/*end::Input group*/}
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Step 4*/}
                    {/*begin::Step 5*/}
                    <div data-kt-stepper-element="content">
                      {/*begin::Wrapper*/}
                      <div className="w-100">
                        {/*begin::Heading*/}
                        <div className="pb-12 text-center">
                          {/*begin::Title*/}
                          <h1 className="fw-bold text-dark">Campaign Created!</h1>
                          {/*end::Title*/}
                          {/*begin::Description*/}
                          <div className="fw-semibold text-muted fs-4">You will receive an email with with the summary of your newly created campaign!</div>
                          {/*end::Description*/}
                        </div>
                        {/*end::Heading*/}
                        {/*begin::Actions*/}
                        <div className="d-flex flex-center pb-20">
                          <button id="kt_modal_create_campaign_create_new" type="button" className="btn btn-lg btn-light me-3" data-kt-element="complete-start">
                            Create New Campaign
                          </button>
                          <a href className="btn btn-lg btn-primary" data-bs-toggle="tooltip" title="Coming Soon">
                            View Campaign
                          </a>
                        </div>
                        {/*end::Actions*/}
                        {/*begin::Illustration*/}
                        <div className="text-center px-4">
                          <img src="assets/media/illustrations/sketchy-1/9.png" alt="" className="mww-100 mh-350px" />
                        </div>
                        {/*end::Illustration*/}
                      </div>
                    </div>
                    {/*end::Step 5*/}
                    {/*begin::Actions*/}
                    <div className="d-flex flex-stack pt-10">
                      {/*begin::Wrapper*/}
                      <div className="me-2">
                        <button type="button" className="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
                          <i className="ki-outline ki-arrow-left fs-3 me-1" />
                          Back
                        </button>
                      </div>
                      {/*end::Wrapper*/}
                      {/*begin::Wrapper*/}
                      <div>
                        <button type="button" className="btn btn-lg btn-primary" data-kt-stepper-action="submit">
                          <span className="indicator-label">
                            Submit
                            <i className="ki-outline ki-arrow-right fs-3 ms-2 me-0" />
                          </span>
                          <span className="indicator-progress">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        </button>
                        <button type="button" className="btn btn-lg btn-primary" data-kt-stepper-action="next">
                          Continue
                          <i className="ki-outline ki-arrow-right fs-3 ms-1 me-0" />
                        </button>
                      </div>
                      {/*end::Wrapper*/}
                    </div>
                    {/*end::Actions*/}
                  </form>
                  {/*end::Form*/}
                </div>
                {/*end::Stepper*/}
              </div>
              {/*begin::Modal body*/}
            </div>
          </div>
        </div>
        {/*end::Modal - Create Campaign*/}
        {/*begin::Modal - Users Search*/}
        <div className="modal fade" id="kt_modal_users_search" tabIndex={-1} aria-hidden="true">
          {/*begin::Modal dialog*/}
          <div className="modal-dialog modal-dialog-centered mw-650px">
            {/*begin::Modal content*/}
            <div className="modal-content">
              {/*begin::Modal header*/}
              <div className="modal-header pb-0 border-0 justify-content-end">
                {/*begin::Close*/}
                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                  <i className="ki-outline ki-cross fs-1" />
                </div>
                {/*end::Close*/}
              </div>
              {/*begin::Modal header*/}
              {/*begin::Modal body*/}
              <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                {/*begin::Content*/}
                <div className="text-center mb-13">
                  <h1 className="mb-3">Search Users</h1>
                  <div className="text-muted fw-semibold fs-5">Invite Collaborators To Your Project</div>
                </div>
                {/*end::Content*/}
                {/*begin::Search*/}
                <div
                  id="kt_modal_users_search_handler"
                  data-kt-search-keypress="true"
                  data-kt-search-min-length={2}
                  data-kt-search-enter="enter"
                  data-kt-search-layout="inline"
                >
                  {/*begin::Form*/}
                  <form data-kt-search-element="form" className="w-100 position-relative mb-5" autoComplete="off">
                    {/*begin::Hidden input(Added to disable form autocomplete)*/}
                    <input type="hidden" />
                    {/*end::Hidden input*/}
                    {/*begin::Icon*/}
                    <i className="ki-outline ki-magnifier fs-2 fs-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y" />
                    {/*end::Icon*/}
                    {/*begin::Input*/}
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid px-15"
                      name="search"
                      defaultValue
                      placeholder="Search by username, full name or email..."
                      data-kt-search-element="input"
                    />
                    {/*end::Input*/}
                    {/*begin::Spinner*/}
                    <span className="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5" data-kt-search-element="spinner">
                      <span className="spinner-border h-15px w-15px align-middle text-muted" />
                    </span>
                    {/*end::Spinner*/}
                    {/*begin::Reset*/}
                    <span
                      className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-5 d-none"
                      data-kt-search-element="clear"
                    >
                      <i className="ki-outline ki-cross fs-2 fs-lg-1 me-0" />
                    </span>
                    {/*end::Reset*/}
                  </form>
                  {/*end::Form*/}
                  {/*begin::Wrapper*/}
                  <div className="py-5">
                    {/*begin::Suggestions*/}
                    <div data-kt-search-element="suggestions">
                      {/*begin::Heading*/}
                      <h3 className="fw-semibold mb-5">Recently searched:</h3>
                      {/*end::Heading*/}
                      {/*begin::Users*/}
                      <div className="mh-375px scroll-y me-n7 pe-7">
                        {/*begin::User*/}
                        <a href="#" className="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1">
                          {/*begin::Avatar*/}
                          <div className="symbol symbol-35px symbol-circle me-5">
                            <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                          </div>
                          {/*end::Avatar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold">
                            <span className="fs-6 text-gray-800 me-2">Emma Smith</span>
                            <span className="badge badge-light">Art Director</span>
                          </div>
                          {/*end::Info*/}
                        </a>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <a href="#" className="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1">
                          {/*begin::Avatar*/}
                          <div className="symbol symbol-35px symbol-circle me-5">
                            <span className="symbol-label bg-light-danger text-danger fw-semibold">M</span>
                          </div>
                          {/*end::Avatar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold">
                            <span className="fs-6 text-gray-800 me-2">Melody Macy</span>
                            <span className="badge badge-light">Marketing Analytic</span>
                          </div>
                          {/*end::Info*/}
                        </a>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <a href="#" className="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1">
                          {/*begin::Avatar*/}
                          <div className="symbol symbol-35px symbol-circle me-5">
                            <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                          </div>
                          {/*end::Avatar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold">
                            <span className="fs-6 text-gray-800 me-2">Max Smith</span>
                            <span className="badge badge-light">Software Enginer</span>
                          </div>
                          {/*end::Info*/}
                        </a>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <a href="#" className="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1">
                          {/*begin::Avatar*/}
                          <div className="symbol symbol-35px symbol-circle me-5">
                            <img alt="Pic" src="assets/media/avatars/300-5.jpg" />
                          </div>
                          {/*end::Avatar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold">
                            <span className="fs-6 text-gray-800 me-2">Sean Bean</span>
                            <span className="badge badge-light">Web Developer</span>
                          </div>
                          {/*end::Info*/}
                        </a>
                        {/*end::User*/}
                        {/*begin::User*/}
                        <a href="#" className="d-flex align-items-center p-3 rounded bg-state-light bg-state-opacity-50 mb-1">
                          {/*begin::Avatar*/}
                          <div className="symbol symbol-35px symbol-circle me-5">
                            <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                          </div>
                          {/*end::Avatar*/}
                          {/*begin::Info*/}
                          <div className="fw-semibold">
                            <span className="fs-6 text-gray-800 me-2">Brian Cox</span>
                            <span className="badge badge-light">UI/UX Designer</span>
                          </div>
                          {/*end::Info*/}
                        </a>
                        {/*end::User*/}
                      </div>
                      {/*end::Users*/}
                    </div>
                    {/*end::Suggestions*/}
                    {/*begin::Results(add d-none to below element to hide the users list by default)*/}
                    <div data-kt-search-element="results" className="d-none">
                      {/*begin::Users*/}
                      <div className="mh-375px scroll-y me-n7 pe-7">
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={0}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='0']"
                                defaultValue={0}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Emma Smith
                              </a>
                              <div className="fw-semibold text-muted">smith@kpmg.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2} selected="selected">
                                Owner
                              </option>
                              <option value={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={1}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='1']"
                                defaultValue={1}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-danger text-danger fw-semibold">M</span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Melody Macy
                              </a>
                              <div className="fw-semibold text-muted">melody@altbox.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1} selected="selected">
                                Guest
                              </option>
                              <option value={2}>Owner</option>
                              <option value={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={2}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='2']"
                                defaultValue={2}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Max Smith
                              </a>
                              <div className="fw-semibold text-muted">max@kt.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2}>Owner</option>
                              <option value={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={3}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='3']"
                                defaultValue={3}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img alt="Pic" src="assets/media/avatars/300-5.jpg" />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Sean Bean
                              </a>
                              <div className="fw-semibold text-muted">sean@dellito.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2} selected="selected">
                                Owner
                              </option>
                              <option value={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={4}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='4']"
                                defaultValue={4}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Brian Cox
                              </a>
                              <div className="fw-semibold text-muted">brian@exchange.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2}>Owner</option>
                              <option value={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={5}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='5']"
                                defaultValue={5}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-warning text-warning fw-semibold">C</span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Mikaela Collins
                              </a>
                              <div className="fw-semibold text-muted">mik@pex.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2} selected="selected">
                                Owner
                              </option>
                              <option value={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={6}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='6']"
                                defaultValue={6}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img alt="Pic" src="assets/media/avatars/300-9.jpg" />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Francis Mitcham
                              </a>
                              <div className="fw-semibold text-muted">f.mit@kpmg.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2}>Owner</option>
                              <option value={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={7}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='7']"
                                defaultValue={7}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-danger text-danger fw-semibold">O</span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Olivia Wild
                              </a>
                              <div className="fw-semibold text-muted">olivia@corpmail.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2} selected="selected">
                                Owner
                              </option>
                              <option value={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={8}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='8']"
                                defaultValue={8}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-primary text-primary fw-semibold">N</span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Neil Owen
                              </a>
                              <div className="fw-semibold text-muted">owen.neil@gmail.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1} selected="selected">
                                Guest
                              </option>
                              <option value={2}>Owner</option>
                              <option value={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={9}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='9']"
                                defaultValue={9}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img alt="Pic" src="assets/media/avatars/300-23.jpg" />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Dan Wilson
                              </a>
                              <div className="fw-semibold text-muted">dam@consilting.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2}>Owner</option>
                              <option value={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={10}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='10']"
                                defaultValue={10}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-danger text-danger fw-semibold">E</span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Emma Bold
                              </a>
                              <div className="fw-semibold text-muted">emma@intenso.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2} selected="selected">
                                Owner
                              </option>
                              <option value={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={11}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='11']"
                                defaultValue={11}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img alt="Pic" src="assets/media/avatars/300-12.jpg" />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Ana Crown
                              </a>
                              <div className="fw-semibold text-muted">ana.cf@limtel.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1} selected="selected">
                                Guest
                              </option>
                              <option value={2}>Owner</option>
                              <option value={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={12}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='12']"
                                defaultValue={12}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-info text-info fw-semibold">A</span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Robert Doe
                              </a>
                              <div className="fw-semibold text-muted">robert@benko.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2}>Owner</option>
                              <option value={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={13}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='13']"
                                defaultValue={13}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img alt="Pic" src="assets/media/avatars/300-13.jpg" />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                John Miller
                              </a>
                              <div className="fw-semibold text-muted">miller@mapple.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2}>Owner</option>
                              <option value={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={14}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='14']"
                                defaultValue={14}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-success text-success fw-semibold">L</span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Lucy Kunic
                              </a>
                              <div className="fw-semibold text-muted">lucy.m@fentech.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2} selected="selected">
                                Owner
                              </option>
                              <option value={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={15}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='15']"
                                defaultValue={15}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <img alt="Pic" src="assets/media/avatars/300-21.jpg" />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Ethan Wilder
                              </a>
                              <div className="fw-semibold text-muted">ethan@loop.com.au</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1} selected="selected">
                                Guest
                              </option>
                              <option value={2}>Owner</option>
                              <option value={3}>Can Edit</option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                        {/*begin::Separator*/}
                        <div className="border-bottom border-gray-300 border-bottom-dashed" />
                        {/*end::Separator*/}
                        {/*begin::User*/}
                        <div className="rounded d-flex flex-stack bg-active-lighten p-4" data-user-id={16}>
                          {/*begin::Details*/}
                          <div className="d-flex align-items-center">
                            {/*begin::Checkbox*/}
                            <label className="form-check form-check-custom form-check-solid me-5">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="users"
                                data-kt-check="true"
                                data-kt-check-target="[data-user-id='16']"
                                defaultValue={16}
                              />
                            </label>
                            {/*end::Checkbox*/}
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-35px symbol-circle">
                              <span className="symbol-label bg-light-primary text-primary fw-semibold">N</span>
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Details*/}
                            <div className="ms-5">
                              <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                                Neil Owen
                              </a>
                              <div className="fw-semibold text-muted">owen.neil@gmail.com</div>
                            </div>
                            {/*end::Details*/}
                          </div>
                          {/*end::Details*/}
                          {/*begin::Access menu*/}
                          <div className="ms-2 w-100px">
                            <select className="form-select form-select-solid form-select-sm" data-control="select2" data-hide-search="true">
                              <option value={1}>Guest</option>
                              <option value={2}>Owner</option>
                              <option value={3} selected="selected">
                                Can Edit
                              </option>
                            </select>
                          </div>
                          {/*end::Access menu*/}
                        </div>
                        {/*end::User*/}
                      </div>
                      {/*end::Users*/}
                      {/*begin::Actions*/}
                      <div className="d-flex flex-center mt-15">
                        <button type="reset" id="kt_modal_users_search_reset" data-bs-dismiss="modal" className="btn btn-active-light me-3">
                          Cancel
                        </button>
                        <button type="submit" id="kt_modal_users_search_submit" className="btn btn-primary">
                          Add Selected Users
                        </button>
                      </div>
                      {/*end::Actions*/}
                    </div>
                    {/*end::Results*/}
                    {/*begin::Empty*/}
                    <div data-kt-search-element="empty" className="text-center d-none">
                      {/*begin::Message*/}
                      <div className="fw-semibold py-10">
                        <div className="text-gray-600 fs-3 mb-2">No users found</div>
                        <div className="text-muted fs-6">Try to search by username, full name or email...</div>
                      </div>
                      {/*end::Message*/}
                      {/*begin::Illustration*/}
                      <div className="text-center px-5">
                        <img src="assets/media/illustrations/sketchy-1/1.png" alt="" className="w-100 h-200px h-sm-325px" />
                      </div>
                      {/*end::Illustration*/}
                    </div>
                    {/*end::Empty*/}
                  </div>
                  {/*end::Wrapper*/}
                </div>
                {/*end::Search*/}
              </div>
              {/*end::Modal body*/}
            </div>
            {/*end::Modal content*/}
          </div>
          {/*end::Modal dialog*/}
        </div>
        {/*end::Modal - Users Search*/}
        {/*begin::Modal - Invite Friends*/}
        <div className="modal fade" id="kt_modal_invite_friends" tabIndex={-1} aria-hidden="true">
          {/*begin::Modal dialog*/}
          <div className="modal-dialog mw-650px">
            {/*begin::Modal content*/}
            <div className="modal-content">
              {/*begin::Modal header*/}
              <div className="modal-header pb-0 border-0 justify-content-end">
                {/*begin::Close*/}
                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                  <i className="ki-outline ki-cross fs-1" />
                </div>
                {/*end::Close*/}
              </div>
              {/*begin::Modal header*/}
              {/*begin::Modal body*/}
              <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                {/*begin::Heading*/}
                <div className="text-center mb-13">
                  {/*begin::Title*/}
                  <h1 className="mb-3">Invite a Friend</h1>
                  {/*end::Title*/}
                  {/*begin::Description*/}
                  <div className="text-muted fw-semibold fs-5">
                    If you need more info, please check out
                    <a href="#" className="link-primary fw-bold">
                      FAQ Page
                    </a>
                    .
                  </div>
                  {/*end::Description*/}
                </div>
                {/*end::Heading*/}
                {/*begin::Google Contacts Invite*/}
                <div className="btn btn-light-primary fw-bold w-100 mb-8">
                  <img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" className="h-20px me-3" />
                  Invite Gmail Contacts
                </div>
                {/*end::Google Contacts Invite*/}
                {/*begin::Separator*/}
                <div className="separator d-flex flex-center mb-8">
                  <span className="text-uppercase bg-body fs-7 fw-semibold text-muted px-3">or</span>
                </div>
                {/*end::Separator*/}
                {/*begin::Textarea*/}
                <textarea className="form-control form-control-solid mb-8" rows={3} placeholder="Type or paste emails here" defaultValue={''} />
                {/*end::Textarea*/}
                {/*begin::Users*/}
                <div className="mb-10">
                  {/*begin::Heading*/}
                  <div className="fs-6 fw-semibold mb-2">Your Invitations</div>
                  {/*end::Heading*/}
                  {/*begin::List*/}
                  <div className="mh-300px scroll-y me-n7 pe-7">
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Emma Smith
                          </a>
                          <div className="fw-semibold text-muted">smith@kpmg.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2} selected="selected">
                            Owner
                          </option>
                          <option value={3}>Can Edit</option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-danger text-danger fw-semibold">M</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Melody Macy
                          </a>
                          <div className="fw-semibold text-muted">melody@altbox.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1} selected="selected">
                            Guest
                          </option>
                          <option value={2}>Owner</option>
                          <option value={3}>Can Edit</option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Max Smith
                          </a>
                          <div className="fw-semibold text-muted">max@kt.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2}>Owner</option>
                          <option value={3} selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-5.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Sean Bean
                          </a>
                          <div className="fw-semibold text-muted">sean@dellito.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2} selected="selected">
                            Owner
                          </option>
                          <option value={3}>Can Edit</option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Brian Cox
                          </a>
                          <div className="fw-semibold text-muted">brian@exchange.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2}>Owner</option>
                          <option value={3} selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-warning text-warning fw-semibold">C</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Mikaela Collins
                          </a>
                          <div className="fw-semibold text-muted">mik@pex.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2} selected="selected">
                            Owner
                          </option>
                          <option value={3}>Can Edit</option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-9.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Francis Mitcham
                          </a>
                          <div className="fw-semibold text-muted">f.mit@kpmg.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2}>Owner</option>
                          <option value={3} selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-danger text-danger fw-semibold">O</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Olivia Wild
                          </a>
                          <div className="fw-semibold text-muted">olivia@corpmail.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2} selected="selected">
                            Owner
                          </option>
                          <option value={3}>Can Edit</option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-primary text-primary fw-semibold">N</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Neil Owen
                          </a>
                          <div className="fw-semibold text-muted">owen.neil@gmail.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1} selected="selected">
                            Guest
                          </option>
                          <option value={2}>Owner</option>
                          <option value={3}>Can Edit</option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-23.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Dan Wilson
                          </a>
                          <div className="fw-semibold text-muted">dam@consilting.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2}>Owner</option>
                          <option value={3} selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-danger text-danger fw-semibold">E</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Emma Bold
                          </a>
                          <div className="fw-semibold text-muted">emma@intenso.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2} selected="selected">
                            Owner
                          </option>
                          <option value={3}>Can Edit</option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-12.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Ana Crown
                          </a>
                          <div className="fw-semibold text-muted">ana.cf@limtel.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1} selected="selected">
                            Guest
                          </option>
                          <option value={2}>Owner</option>
                          <option value={3}>Can Edit</option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-info text-info fw-semibold">A</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Robert Doe
                          </a>
                          <div className="fw-semibold text-muted">robert@benko.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2}>Owner</option>
                          <option value={3} selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-13.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            John Miller
                          </a>
                          <div className="fw-semibold text-muted">miller@mapple.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2}>Owner</option>
                          <option value={3} selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <span className="symbol-label bg-light-success text-success fw-semibold">L</span>
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Lucy Kunic
                          </a>
                          <div className="fw-semibold text-muted">lucy.m@fentech.com</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2} selected="selected">
                            Owner
                          </option>
                          <option value={3}>Can Edit</option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-21.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Ethan Wilder
                          </a>
                          <div className="fw-semibold text-muted">ethan@loop.com.au</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1} selected="selected">
                            Guest
                          </option>
                          <option value={2}>Owner</option>
                          <option value={3}>Can Edit</option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                    {/*begin::User*/}
                    <div className="d-flex flex-stack py-4">
                      {/*begin::Details*/}
                      <div className="d-flex align-items-center">
                        {/*begin::Avatar*/}
                        <div className="symbol symbol-35px symbol-circle">
                          <img alt="Pic" src="assets/media/avatars/300-21.jpg" />
                        </div>
                        {/*end::Avatar*/}
                        {/*begin::Details*/}
                        <div className="ms-5">
                          <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary mb-2">
                            Ethan Wilder
                          </a>
                          <div className="fw-semibold text-muted">ethan@loop.com.au</div>
                        </div>
                        {/*end::Details*/}
                      </div>
                      {/*end::Details*/}
                      {/*begin::Access menu*/}
                      <div className="ms-2 w-100px">
                        <select
                          className="form-select form-select-solid form-select-sm"
                          data-control="select2"
                          data-dropdown-parent="#kt_modal_invite_friends"
                          data-hide-search="true"
                        >
                          <option value={1}>Guest</option>
                          <option value={2}>Owner</option>
                          <option value={3} selected="selected">
                            Can Edit
                          </option>
                        </select>
                      </div>
                      {/*end::Access menu*/}
                    </div>
                    {/*end::User*/}
                  </div>
                  {/*end::List*/}
                </div>
                {/*end::Users*/}
                {/*begin::Notice*/}
                <div className="d-flex flex-stack">
                  {/*begin::Label*/}
                  <div className="me-5 fw-semibold">
                    <label className="fs-6">Adding Users by Team Members</label>
                    <div className="fs-7 text-muted">If you need more info, please check budget planning</div>
                  </div>
                  {/*end::Label*/}
                  {/*begin::Switch*/}
                  <label className="form-check form-switch form-check-custom form-check-solid">
                    <input className="form-check-input" type="checkbox" defaultValue={1} defaultChecked="checked" />
                    <span className="form-check-label fw-semibold text-muted">Allowed</span>
                  </label>
                  {/*end::Switch*/}
                </div>
                {/*end::Notice*/}
              </div>
              {/*end::Modal body*/}
            </div>
            {/*end::Modal content*/}
          </div>
          {/*end::Modal dialog*/}
        </div>
        {/*end::Modal - Invite Friend*/}
        {/*end::Modals*/}
        {/*begin::Javascript*/}
        {/*begin::Global Javascript Bundle(mandatory for all pages)*/}
        {/*end::Global Javascript Bundle*/}
        {/*begin::Vendors Javascript(used for this page only)*/}
        {/*end::Vendors Javascript*/}
        {/*begin::Custom Javascript(used for this page only)*/}
        {/*end::Custom Javascript*/}
        {/*end::Javascript*/}
        {/*end::Body*/}
      </div>{' '}
    </>
  )
}

export default Dashboard
