import { useIntl } from 'react-intl'

const ProductRating = () => {
  const intl = useIntl()

  return (
    <div className='card card-flush py-4'>
      <div className='card-header'>
        <div className='card-title'>
          <h2>{intl.formatMessage({ id: 'Product.Title.CustomerReviews' })}</h2>
        </div>
        <div className='card-toolbar'>
          <span className='fw-bold me-5'>Overall Rating:</span>
          <div className='rating'>
            <div className='rating-label checked'>
              <i className='ki-outline ki-star fs-2'></i>
            </div>
            <div className='rating-label checked'>
              <i className='ki-outline ki-star fs-2'></i>
            </div>
            <div className='rating-label checked'>
              <i className='ki-outline ki-star fs-2'></i>
            </div>
            <div className='rating-label checked'>
              <i className='ki-outline ki-star fs-2'></i>
            </div>
            <div className='rating-label'>
              <i className='ki-outline ki-star fs-2'></i>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body pt-0'>
        <table className='table table-row-dashed fs-6 gy-5 my-0' id='kt_ecommerce_add_product_reviews'>
          <thead>
            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
              <th className='w-10px pe-2'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    data-kt-check='true'
                    data-kt-check-target='#kt_ecommerce_add_product_reviews .form-check-input'
                    value='1'
                  />
                </div>
              </th>
              <th className='min-w-125px'>Rating</th>
              <th className='min-w-175px'>Customer</th>
              <th className='min-w-175px'>Comment</th>
              <th className='min-w-100px text-end fs-7'>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-5'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <div className='symbol-label bg-light-danger'>
                      <span className='text-danger'>M</span>
                    </div>
                  </div>
                  <span className='fw-bold'>Melody Macy</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>I like this design</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>Today</span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-5'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <span
                      className='symbol-label'
                      style={{
                        backgroundImage: 'url(assets/media/avatars/300-1.jpg)',
                      }}
                    ></span>
                  </div>
                  <span className='fw-bold'>Max Smith</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>Good product for outdoors or indoors</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>day ago</span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-4'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <span
                      className='symbol-label'
                      style={{
                        backgroundImage: 'url(assets/media/avatars/300-1.jpg)',
                      }}
                    ></span>
                  </div>
                  <span className='fw-bold'>Sean Bean</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>Awesome quality with great materials used, but could be more comfortable</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>11:20 PM</span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-5'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <span
                      className='symbol-label'
                      style={{
                        backgroundImage: 'url(assets/media/avatars/300-1.jpg)',
                      }}
                    ></span>
                  </div>
                  <span className='fw-bold'>Brian Cox</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>This is the best product I've ever used.</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>2 days ago</span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-3'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <div className='symbol-label bg-light-warning'>
                      <span className='text-warning'>C</span>
                    </div>
                  </div>
                  <span className='fw-bold'>Mikaela Collins</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>I thought it was just average, I prefer other brands</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>July 25</span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-5'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <span
                      className='symbol-label'
                      style={{
                        backgroundImage: 'url(assets/media/avatars/300-1.jpg)',
                      }}
                    ></span>
                  </div>
                  <span className='fw-bold'>Francis Mitcham</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>Beautifully crafted. Worth every penny.</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>July 24</span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-4'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <div className='symbol-label bg-light-danger'>
                      <span className='text-danger'>O</span>
                    </div>
                  </div>
                  <span className='fw-bold'>Olivia Wild</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>Awesome value for money. Shipping could be faster tho.</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>July 13</span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-5'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <div className='symbol-label bg-light-primary'>
                      <span className='text-primary'>N</span>
                    </div>
                  </div>
                  <span className='fw-bold'>Neil Owen</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>Excellent quality, I got it for my son's birthday and he loved it!</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>May 25</span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-5'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <span
                      className='symbol-label'
                      style={{
                        backgroundImage: 'url(assets/media/avatars/300-1.jpg)',
                      }}
                    ></span>
                  </div>
                  <span className='fw-bold'>Dan Wilson</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>I got this for Christmas last year, and it's still the best product I've ever used!</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>April 15</span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-5'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <div className='symbol-label bg-light-danger'>
                      <span className='text-danger'>E</span>
                    </div>
                  </div>
                  <span className='fw-bold'>Emma Bold</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>Was skeptical at first, but after using it for 3 months, I'm hooked! Will definately buy another!</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>April 3</span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-4'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <span
                      className='symbol-label'
                      style={{
                        backgroundImage: 'url(assets/media/avatars/300-1.jpg)',
                      }}
                    ></span>
                  </div>
                  <span className='fw-bold'>Ana Crown</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>Great product, too bad I missed out on the sale.</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>March 17</span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-5'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <div className='symbol-label bg-light-info'>
                      <span className='text-info'>A</span>
                    </div>
                  </div>
                  <span className='fw-bold'>Robert Doe</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>Got this on sale! Best decision ever!</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>March 12</span>
              </td>
            </tr>
            <tr>
              <td>
                <div className='form-check form-check-sm form-check-custom form-check-solid mt-1'>
                  <input className='form-check-input' type='checkbox' value='1' />
                </div>
              </td>
              <td data-order='rating-5'>
                <div className='rating'>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                  <div className='rating-label checked'>
                    <i className='ki-outline ki-star fs-6'></i>
                  </div>
                </div>
              </td>
              <td>
                <a href='../../demo52/dist/apps/inbox/reply.html' className='d-flex text-dark text-gray-800 text-hover-primary'>
                  <div className='symbol symbol-circle symbol-25px me-3'>
                    <span
                      className='symbol-label'
                      style={{
                        backgroundImage: 'url(assets/media/avatars/300-13.jpg)',
                      }}
                    ></span>
                  </div>
                  <span className='fw-bold'>John Miller</span>
                </a>
              </td>
              <td className='text-gray-600 fw-bold'>Firesale is on! Buy now! Totally worth it!</td>
              <td className='text-end'>
                <span className='fw-semibold text-muted'>March 11</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ProductRating
