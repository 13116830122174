import { useState, useRef, useEffect } from 'react'
import { Icon } from '../../../../helpers'
import { useIntl } from 'react-intl'
import { ControlType, FormControl } from '../../components/FormControl'

const SupplierFilter = ({ fetchData }) => {
  const intl = useIntl()
  const filterMenuRef = useRef(null)
  const componentRefs = useRef({})
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)

  const [companyName, setCompanyName] = useState('')
  const [businessCode, setBusinessCode] = useState('')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterMenuRef.current && !filterMenuRef.current.contains(event.target) && isOpenDropdown) {
        setIsOpenDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpenDropdown])

  const toggleFilter = () => {
    setIsOpenDropdown(!isOpenDropdown)
  }

  const handleApply = () => {
    setIsOpenDropdown(!isOpenDropdown)
    fetchData(companyName, businessCode, fullName, email, phoneNumber)
  }

  const handleReset = () => {
    setCompanyName('')
    setBusinessCode('')
    setFullName('')
    setEmail('')
    setPhoneNumber('')
  }

  return (
    <div style={{ position: 'relative', display: 'inline-block' }} ref={filterMenuRef}>
      <a onClick={toggleFilter} className='btn btn-sm btn-light-info me-3'>
        <Icon iconName='filter' className='fs-3' />
        {intl.formatMessage({ id: 'Filter' })}
      </a>

      {isOpenDropdown && (
        <div className='filter-options'>
          <div className='w-300px w-md-325px'>
            <div className='px-7 py-2 d-flex justify-content-between align-items-center'>
              <div className='fs-5 text-dark fw-bold'>{intl.formatMessage({ id: 'FilterOptions' })}</div>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-customers-modal-action='close'
                onClick={toggleFilter}
                style={{ cursor: 'pointer' }}
              >
                <Icon iconName='cross' className='fs-1' />
              </div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-4 gap-5'>
              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.businessCode = ref)}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({ id: 'Supplier.Label.BusinessCode' })}
                  value={businessCode}
                  onChange={(e) => setBusinessCode(e.target.value)}
                />
              </div>

              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.companyName = ref)}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({ id: 'Supplier.Column.CompanyName' })}
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>

              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.fullName = ref)}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({ id: 'Supplier.Column.FullName' })}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>

              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.phoneNumber = ref)}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({ id: 'Supplier.Column.PhoneNumber' })}
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>

              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.email = ref)}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({ id: 'Supplier.Column.Email' })}
                  value={email}
                  isEmail={true}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className='d-flex justify-content-end gap-3'>
                <a type='reset' className='btn btn-light btn-active-light-primary btn-sm' onClick={handleReset}>
                  <i className='fa fa-eraser' aria-hidden='true'></i>
                  {intl.formatMessage({ id: 'Reset' })}
                </a>
                <a type='submit' className='btn btn-success btn-sm' onClick={handleApply}>
                  <i className='fa fa-check' aria-hidden='true'></i>
                  {intl.formatMessage({ id: 'Apply' })}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SupplierFilter
