import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  getFeaturedProductsByType,
  deleteFeaturedProducts,
  modifyFromDate,
  modifyTodate,
  modifyOrder,
} from '../../../../redux/actions/eco/featuredProductActions'
import _ from 'lodash'
import ConfirmRemove from '../../components/Confirm'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import { FeaturedProductTypeEnum } from '../../../../utilities/enums'
import Table from '../../components/Table'
import FeaturedProductModel from './FeaturedProductModel'
import Pagination from '../../components/Pagination'
import { ControlType } from '../../components/FormControl'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import { formatString, formatDate } from '../../../../utilities/shareFunction'
import TableSearch from '../../components/TableSearch'
import ProductFilter from './ProductFilter'

const FeaturedProduct = () => {
  const intl = useIntl()

  const [featuredProducts, setFeaturedProducts] = useState([])
  const [featuredProductsOriginal, setFeaturedProductsOriginal] = useState([])
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [isSelectedAll, setIsSelectedAll] = useState(false)
  const [showFeaturedProductModel, setShowFeaturedProduct] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  //productgroup
  const [productGroups] = useState(
    Object.keys(FeaturedProductTypeEnum).map((key) => ({
      value: FeaturedProductTypeEnum[key],
      name: intl.formatMessage({ id: `FeatureProduct.${key}` }),
    }))
  )
  const [groupSelected, setGroupSelected] = useState(productGroups[0])

  useEffect(() => {
    getFeaturedProducts()
  }, [groupSelected])

  const getFeaturedProducts = async (productCode, productName, supplierId, categoryId, subCategoryId, groupId) => {
    try {
      const { result } = await getFeaturedProductsByType({
        pageIndex: currentPage,
        pageSize: pageSize,
        type: groupSelected?.value,
        productCode: productCode ?? null,
        productName: productName ?? null,
        supplierId: supplierId ?? null,
        categoryId: categoryId ?? null,
        subCategoryId: subCategoryId ?? null,
        groupId: groupId ?? null,
      })

      if (result) {
        setFeaturedProducts(result.items)
        setFeaturedProductsOriginal(result.items)
        setTotalPages(result.totalCount)
        setIsSelectedAll(false)
      } else {
        setFeaturedProducts([])
        setFeaturedProductsOriginal([])
        setTotalPages(0)
        setIsSelectedAll(false)
      }
    } catch (error) {
      console.error('Error fetching initial productPriority:', error)
    }
  }

  const removeFeaturedProducts = async () => {
    try {
      const { error } = await deleteFeaturedProducts(featuredProducts.filter((x) => x.isSelected).map((x) => x.id))
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        getFeaturedProducts()
        setConfirmDelete(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const updateFromDate = async (id, fromDate) => {
    try {
      const { error } = await modifyFromDate({ id, fromDate })
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        getFeaturedProducts()
        setConfirmDelete(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const updateTodate = async (id, todate) => {
    try {
      const { error } = await modifyTodate({ id, todate })
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        getFeaturedProducts()
        setConfirmDelete(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const updateOrder = async (id, order) => {
    try {
      const { error } = await modifyOrder({ id, order })
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        getFeaturedProducts()
        setConfirmDelete(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectedAll = () => {
    const updatedFeaturedProducts = featuredProducts.map((item) => ({
      ...item,
      isSelected: !isSelectedAll,
    }))

    setFeaturedProducts(updatedFeaturedProducts)
    setFeaturedProductsOriginal(updatedFeaturedProducts)
    setIsSelectedAll(!isSelectedAll)
  }

  const handleSelected = (id) => {
    const updatedFeaturedProducts = [...featuredProducts]
    const selectedItem = updatedFeaturedProducts.find((item) => item.id === id)
    selectedItem.isSelected = !selectedItem.isSelected

    if (updatedFeaturedProducts.every((x) => x.isSelected)) {
      setIsSelectedAll(true)
    } else {
      setIsSelectedAll(false)
    }

    setFeaturedProducts(updatedFeaturedProducts)
    setFeaturedProductsOriginal(updatedFeaturedProducts)
  }

  const featuredProductColumns = [
    {
      Header: (
        <div className='form-check form-check-sm form-check-custom form-check-solid justify-content-center'>
          <input onChange={handleSelectedAll} className='form-check-input pointer' type='checkbox' checked={isSelectedAll} value='1' />
        </div>
      ),
      accessor: 'isSelected',
      width: 25,
      disableSort: true,
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Title.Product' })}`,
      accessor: 'productName',
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.Supplier' })}`,
      accessor: 'supplierName',
      width: 80,
    },
    {
      Header: `${intl.formatMessage({ id: 'Banner.Column.FromDate' })}`,
      accessor: 'fromDate',
      width: 100,
      editable: ControlType.DatePicker,
    },
    {
      Header: `${intl.formatMessage({ id: 'Banner.Column.ToDate' })}`,
      accessor: 'toDate',
      width: 100,
      editable: ControlType.DatePicker,
    },
    {
      Header: `${intl.formatMessage({ id: 'FeatureProduct.Eco.Order' })}`,
      accessor: 'order',
      width: 60,
      editable: ControlType.NumberBox,
    },
  ]

  const featuredProductRows = featuredProducts.map((item) => {
    return {
      id: item.id,
      isSelected: (
        <div className='form-check form-check-sm form-check-custom form-check-solid justify-content-center'>
          <input onChange={() => handleSelected(item.id)} className='form-check-input pointer' checked={item.isSelected} type='checkbox' value='1' />
        </div>
      ),
      productName: (
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-45px me-5'>
            <img src={DEFAULT_VALUE.BASE_URL + item.imageUrl} alt='' />
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <a href='#' className='text-gray-900 fw-bold text-hover-primary fs-6'>
              {item.productName}
            </a>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{item.productCode}</span>
          </div>
        </div>
      ),
      supplierName: item.supplierName,
      fromDate: item.fromDate,
      toDate: item.toDate,
      order: item.order,
    }
  })

  const handleCellValueChange = (cell, value) => {
    let fromDate, toDate
    const updatedRows = [...featuredProducts]
    const rowIndex = updatedRows.findIndex((row) => row.id === cell.row.original.id)
    if (rowIndex === -1) return
    updatedRows[rowIndex][cell.column.id] = value

    switch (cell.column.id) {
      case 'fromDate':
        fromDate = new Date(value)
        toDate = new Date(updatedRows[rowIndex]['toDate'])

        if (_.isEmpty(value)) {
          showToast(
            formatString(`${intl.formatMessage({ id: 'Error.Invalid' })}`, `${intl.formatMessage({ id: 'Banner.Column.FromDate' })}`),
            TOAST_TYPES.ERROR
          )
          return
        }

        if (_.isEmpty(updatedRows[rowIndex]['toDate']) || toDate.getTime() - fromDate.getTime() > 0) {
          updateFromDate(cell.row.original.id, value)
        } else {
          showToast(formatString(`${intl.formatMessage({ id: 'Notify.DateEarlier' })}`, formatDate(toDate)), TOAST_TYPES.ERROR)
          return
        }
        break
      case 'toDate':
        toDate = new Date(value)
        fromDate = new Date(updatedRows[rowIndex]['fromDate'])
        if (_.isEmpty(value) || toDate.getTime() - fromDate.getTime() > 0) {
          updateTodate(cell.row.original.id, value)
        } else {
          showToast(formatString(`${intl.formatMessage({ id: 'Notify.DateLater' })}`, formatDate(fromDate)), TOAST_TYPES.ERROR)
          return
        }
        break
      case 'order':
        updateOrder(cell.row.original.id, value)
        break
      default:
        break
    }

    setFeaturedProducts(updatedRows)
  }

  return (
    <div className='d-flex flex-column flex-lg-row'>
      <div className='flex-column flex-lg-row-auto col-lg-4 col-xxl-3'>
        <div className='card card-flush'>
          <div className='card-header'>
            <h3 className='card-title fw-bold text-dark'>{intl.formatMessage({ id: 'ProductGroup.Eco.Title' })}</h3>
          </div>
          <div className='card-body pt-0'>
            <div
              className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-offset='0px'
            >
              {productGroups?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className='d-flex align-items-center'>
                      <div className={`flex-grow-1 py-2 pointer ${groupSelected?.value === item.value ? 'selected' : ''}`} style={{ borderRadius: '0.425rem' }}>
                        <a onClick={() => setGroupSelected(item)} className={`text-gray-900 text-hover-primary ms-4`}>
                          {item.name}
                        </a>
                      </div>
                    </div>
                    <div className='separator my-2'></div>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className='flex-lg-row-fluid ms-4'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title fw-bold text-dark'>
              {groupSelected?.name ? groupSelected?.name : intl.formatMessage({ id: 'FeatureProduct.Eco.Title' })}
            </h3>
            <div className='card-toolbar gap-3'>
              {groupSelected && (
                <a className='btn btn-success btn-sm' onClick={() => setShowFeaturedProduct(true)} title={intl.formatMessage({ id: 'AddNew' })}>
                  <i className='fa-solid fa-plus me-1'></i>
                  <FormattedMessage id='AddNew' />
                </a>
              )}
              
              {featuredProducts && featuredProducts.some((item) => item.isSelected) && (
                <a onClick={() => setConfirmDelete(true)} className='btn btn-danger btn-sm' title={intl.formatMessage({ id: 'Delete' })}>
                  <i className='fa-solid fa-trash'></i>
                  <FormattedMessage id='Delete' />
                </a>
              )}

              <ProductFilter fetchData={getFeaturedProducts} />

              <TableSearch onSearch={setFeaturedProducts} originalData={featuredProductsOriginal} />
            </div>
          </div>
          <div className='card-body py-2 pt-0'>
            <div
              className='scroll-y me-n5 pe-5 h-200px h-lg-auto position-static'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-offset='0px'
            >
              <Table columns={featuredProductColumns} data={featuredProductRows} onCellValueChange={handleCellValueChange} />
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                itemsPerPage={pageSize}
                onPageChange={(e) => setCurrentPage(e)}
                onItemsPerPageChange={(e) => setPageSize(e)}
              />
            </div>
          </div>
        </div>
      </div>
      {confirmDelete && (
        <ConfirmRemove
          onConfirm={removeFeaturedProducts}
          onCancel={() => setConfirmDelete(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}
      {showFeaturedProductModel && (
        <FeaturedProductModel type={groupSelected?.value} fetchFeaturedProducts={getFeaturedProducts} closeModal={() => setShowFeaturedProduct(false)} />
      )}
    </div>
  )
}

export default FeaturedProduct
