import React from 'react'
import PageTitle from './PageTitle'
import TopBar from './TopBar'

class TopNavbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      offset: { default: '200px', lg: '300px' },
    }
  }

  render() {
    return (
      <div id="kt_header" className="header" data-kt-sticky="true" data-kt-sticky-name="header" data-kt-sticky-offset={JSON.stringify(this.state.offset)}>
        <div className={'container-xxl d-flex align-items-center justify-content-between'} id="kt_header_container">
          <PageTitle />
          <TopBar />
        </div>
      </div>
    )
  }
}

export default TopNavbar
