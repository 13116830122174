import React from 'react';

const Pagination = ({ currentPage, totalPages, itemsPerPage, onPageChange, onItemsPerPageChange }) => {
    const lengthPage = totalPages / itemsPerPage > 1 ? Math.ceil(totalPages / itemsPerPage) : 1
    const pageNumbers = Array.from({ length: lengthPage }, (_, index) => index + 1)
    const startIndex = (currentPage - 1) * itemsPerPage + 1

    const handleItemsPerPageChange = (e) => {
        const newItemsPerPage = parseInt(e.target.value, 10);
        onItemsPerPageChange(newItemsPerPage);
    }

    return (
        <div className='d-flex flex-stack flex-wrap pt-4'>
            <div className='fs-6 fw-bold text-gray-700'>
                Showing {startIndex} to &nbsp;
                <span className='fs-6 fw-bold text-gray-700'>
                    <select className='form-pagination' value={itemsPerPage} onChange={handleItemsPerPageChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                    </select>
                </span>
                &nbsp; of {totalPages} entries
            </div>
            <ul className='pagination'>
                <li className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`} key='0' >
                    <a className='page-link' onClick={() => onPageChange(currentPage - 1)}>
                        <i className='previous'></i>
                    </a>
                </li>
                {pageNumbers.map((page) => (
                    <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                        <a className='page-link' onClick={() => onPageChange(page)}>{page}</a>
                    </li>
                ))}
                <li className={`page-item next ${currentPage === lengthPage ? 'disabled' : ''}`} key={'-1'}>
                    <a className='page-link' onClick={() => onPageChange(currentPage + 1)}>
                        <i className='next'></i>
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Pagination;