import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './redux/store/store'

import './assets/fonticon/fonticon.css'
import './assets/keenicons/duotone/style.css'
import './assets/keenicons/outline/style.css'
import './assets/keenicons/solid/style.css'
import './assets/sass/style.scss'
import './assets/sass/plugins.scss'
import './assets/sass/style.react.scss'
import './assets/sass/common.scss'

import { Appi18nProvider } from './language/Appi18nProvider'
import { I18nProvider } from './language/i18nProvider'
import AppRoutes from './routes/AppRoutes'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Appi18nProvider>
            <I18nProvider>
                <AppRoutes />
            </I18nProvider>
        </Appi18nProvider>
    </Provider>
);
