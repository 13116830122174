import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class QuestionAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.QUESTION)
    this.adapter = this.initAdapter(conf)
  }

  async getQuestions(payload) {
    return await this.adapter.postAsync('getQuestions', { payload })
  }

  async getQuestionById(questionId) {
    return await this.adapter.getAsync(`getById?id=${questionId}`)
  }
}

export default QuestionAPI
