import React from 'react'
import { checkIsActive, Icon } from '../../../../../helpers'

const MenuItemWithSub = ({ children, ...props }) => {
    const { title, icon, hasBullet, to, currentLink } = props
    const isActive = checkIsActive(currentLink, to)
    return (
        <div className={`menu-item ${isActive ? 'here show' : ''} menu-accordion`} data-kt-menu-trigger='click'>
            <span className='menu-link'>
                {hasBullet && (
                    <span className='menu-bullet'>
                        <span className='bullet bullet-dot'></span>
                    </span>
                )}
                {icon && (
                    <span className='menu-icon'>
                        <Icon iconName={icon} className='fs-2' />
                    </span>
                )}
                <span className='menu-title'>{title}</span>
                <span className='menu-arrow'></span>
            </span>
            <div className={`menu-sub menu-sub-accordion ${isActive ? 'menu-active-bg' : ''}`}>
                {children}
            </div>
        </div>
    )
}

export default MenuItemWithSub
