import { Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../PageData'
import { useIntl } from 'react-intl'
import { getComponentByName } from '../../../../routes'

const SupplierPage = ({ supplierRoute }) => {
  const intl = useIntl()
  const routers = supplierRoute?.features?.filter((d) => d.isHidden)

  return (
    <Routes>
      <Route>
        <>
          {routers?.map((item) => {
            const Page = getComponentByName(item.component)
            const breadCrumbs = [
              { title: intl.formatMessage({ id: 'Menu.Eco.SupplierManagement' }), path: '#' },
              { title: intl.formatMessage({ id: 'Menu.Eco.Supplier' }), path: 'eco/supplier/list' },
            ]
            return (
              <Route
                key={item.id}
                path={item.path}
                element={
                  <>
                    <PageTitle breadcrumbs={breadCrumbs}>{intl.formatMessage({ id: item.title })}</PageTitle>
                    <Page />
                  </>
                }
              />
            )
          })}
        </>
      </Route>
    </Routes>
  )
}

export default SupplierPage
