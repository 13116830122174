import { useIntl } from 'react-intl'

const GetMessage = (messageKey) => {
  const intl = useIntl()
  return intl.formatMessage({ id: messageKey })
}

const validationErrorKeys = {
  required: 'Error.Required',
  invalid: 'Error.Invalid',
  isExisted: 'Error.IsExisted',
  confirmPasswordInvalid: 'Register.ConfirmPasswordInvalid'
}

const bindValidationMessages = () => {
  const errorMessages = {};
  Object.keys(validationErrorKeys).forEach((key) => {
    errorMessages[key] = GetMessage(validationErrorKeys[key])
  });
  return errorMessages
}

export { bindValidationMessages }
