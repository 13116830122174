import { LevelGroup as api } from '../../../services'

const getById = async (id) => {
  return await api.getById(id)
}

const getAll = async () => {
  return await api.getAll()
}

const add = async (payload) => {
  return await api.add(payload)
}

const update = async (payload) => {
  return await api.update(payload)
}

const softDelete = async (id) => {
  return await api.softDelete(id)
}

export { getById, getAll, add, update, softDelete }
