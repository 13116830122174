import { useState, useEffect, useRef } from 'react'
import Button from '../components/Button'
import Modal from '../components/Modal'
import { ControlType, FormControl } from '../components/FormControl'
import { showToast, TOAST_TYPES } from '../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { bindValidationMessages } from '../../../utilities/validationMessage'
import * as userApi from '../../../redux/actions/auth/userActions'

const UserEdit = (props) => {
  const { user: initialUser, roles } = props
  const intl = useIntl()
  const validationMessage = bindValidationMessages()

  const componentRefs = useRef({})
  const [user, setUser] = useState(initialUser)
  const [firstName, setFirstName] = useState(user?.firstName ?? '')
  const [lastName, setLastName] = useState(user?.lastName ?? '')
  const [email, setEmail] = useState(user?.email ?? '')
  const [userName, setUserName] = useState(user?.userName ?? '')
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber ?? '')
  const [roleId, setRoleId] = useState(user?.roleId ?? '')

  useEffect(() => {
    const updateUser = {
      firstName,
      lastName,
      userName,
      email,
      phoneNumber,
      roleId,
    }
    setUser({ ...user, ...updateUser })
  }, [firstName, lastName, userName, email, phoneNumber, roleId])

  const validationUserName = async () => {
    const { error, result } = await userApi.checkDuplicateUserName(user)
    if (!_.isEmpty(error) || result) {
      if (_.isEmpty(error)) {
        showToast(error.message, TOAST_TYPES.ERROR)
      }
      return { error: true, message: validationMessage.isExisted }
    }
  }

  const validationEmail = async () => {
    const { error, result } = await userApi.checkDuplicateEmail(user)
    if (!_.isEmpty(error) || result) {
      if (_.isEmpty(error)) {
        showToast(error.message, TOAST_TYPES.ERROR)
      }
      return { error: true, message: validationMessage.isExisted }
    }
  }

  const validationAllComponents = async () => {
    let isValid = true

    await _.map(componentRefs.current, async (ref) => {
      if (!(await ref.validateData())) {
        isValid = false
      }
    })

    return isValid
  }

  const handleSave = async (e) => {
    e.preventDefault()
    if (!(await validationAllComponents())) {
      return
    }

    const { error } = await userApi.saveUser(user)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      props.getUsers()
      props.closeModal()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: 'User.UserDetail' })} closeModal={props.closeModal} modalSize='mw-650px'>
      <form id='kt_modal_add_customer_form' className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_customer_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_customer_header'
          data-kt-scroll-wrappers='#kt_modal_add_customer_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row mb-6'>
            <div className='col-md-6'>
              <FormControl
                ref={(ref) => (componentRefs.current.firstName = ref)}
                tabIndex={1}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'User.FirstName' })}
                value={firstName}
                isRequired={true}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className='col-md-6'>
              <FormControl
                ref={(ref) => (componentRefs.current.lastName = ref)}
                tabIndex={2}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'User.LastName' })}
                value={lastName}
                isRequired={true}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className='row mb-6'>
            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.userName = ref)}
                tabIndex={3}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'User.Account' })}
                value={userName}
                isRequired={true}
                onChange={(e) => setUserName(e.target.value)}
                validator={validationUserName}
              />
            </div>
          </div>

          <div className='row mb-6'>
            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.email = ref)}
                tabIndex={3}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'User.Email' })}
                value={email}
                isRequired={true}
                isEmail={true}
                onChange={(e) => setEmail(e.target.value)}
                validator={validationEmail}
              />
            </div>
          </div>

          <div className='row mb-6'>
            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.phoneNumber = ref)}
                tabIndex={4}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'User.PhoneNumber' })}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>

          <div className='row mb-6'>
            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.roleId = ref)}
                tabIndex={5}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'User.Role' })}
                value={roleId}
                isRequired={true}
                onChange={(e) => setRoleId(e.value)}
                options={roles}
                displayValue='id'
                displayName='name'
              />
            </div>
          </div>

          <div className='text-center'>
            <Button
              onClick={() => props.closeModal()}
              title={intl.formatMessage({ id: 'Close' })}
              className={'btn btn-light me-3'}
              icon='fa-times'
              tabIndex={6}
            />
            <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} icon='fa-save' tabIndex={7} />
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default UserEdit
