import { Auth } from '../../components/auth/Auth'
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_REQUEST } from '../actions/auth/authActions'

const initialState = {
  user: null,
  loading: false,
  error: null,
  returnUrl: null,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, loading: true, error: null }
    case LOGIN_SUCCESS:
      return { ...state, user: action.payload, loading: false, error: null, returnUrl: action.returnUrl }
    case LOGIN_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case LOGOUT_REQUEST:
      return { ...state, user: null, loading: false, error: null }
    default:
      const user = Auth.getUserData()
      if (user) {
        return { ...state, user: user }
      }
      return state
  }
}
export default authReducer
