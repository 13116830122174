import React, { useState, useEffect, forwardRef } from 'react'
import { default as CustomSelect } from 'react-select'

const Select = forwardRef((props, ref) => {
  let { label, options, value, onChange, onBlur, className, tabIndex, displayValue, displayName, isValid, errorMessage, isDisabled, isClearable } = props
  const [selectedOption, setSelectedOption] = useState(null)

  displayValue = displayValue ?? 'value'
  displayName = displayName ?? 'name'
  isClearable = isClearable ?? true

  useEffect(() => {
    var option = options?.find((x) => x[displayValue] === value)
    if (option) {
      setSelectedOption(option)
    }
  }, [value, displayValue, options])

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption)
    onChange({ value: selectedOption && selectedOption[displayValue] })
  }

  return (
    <div>
      <CustomSelect
        ref={ref}
        tabIndex={tabIndex}
        value={selectedOption ?? ''}
        onChange={handleChange}
        onBlur={onBlur} // Call your handleBlur function
        options={options}
        isSearchable={true}
        placeholder={label}
        isClearable={isClearable}
        className={className ?? ''}
        getOptionLabel={(option) => option[displayName]}
        getOptionValue={(option) => option[displayValue]}
        menuPosition='fixed'
        data-isvalid={isValid}
        isDisabled={isDisabled}
      />
      {isValid === false && (
        <p className='mt-0 mb-0' style={{ color: 'red', whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </div>
  )
})

export default Select
