import { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import ExportExcel from '../../components/ExportExcel'
import Loading from '../../components/Loading'
import Pagination from '../../components/Pagination'
import Table from '../../components/Table'
import TableSearch from '../../components/TableSearch'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import _ from 'lodash'
import { getOrders, preparingGoods, waitForDelivery, complete } from '../../../../redux/actions/eco/orderActions'
import NumberBox from '../../components/NumberBox'
import Confirm from '../../components/Confirm'
import { OrderStatusEnum } from '../../../../utilities/enums'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import { statusColor, orderRoutes } from '.'
import '../../../../assets/sass/purchase.scss'

const Order = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [orders, setOrders] = useState([])
  const [orderOriginals, setOrderOriginals] = useState([])
  const [order, setOrder] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const [status, setStatus] = useState(OrderStatusEnum.WaitForConfirm)

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const fetchOrders = async () => {
    setIsLoading(true)
    try {
      const { result } = await getOrders({ orderStatus: status === OrderStatusEnum.All ? null : status, pageIndex: currentPage, pageSize: pageSize })
      if (result) {
        setOrders(result.items)
        setOrderOriginals(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [currentPage, pageSize, status])

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'Order.Column.Order' })}`,
      accessor: 'orderCode',
      width: '190px',
    },
    {
      Header: `${intl.formatMessage({ id: 'Order.Column.FullName' })}`,
      accessor: 'fullName',
      width: '50%',
    },
    {
      Header: `${intl.formatMessage({ id: 'Order.Column.PaymentStatusName' })}`,
      accessor: 'paymentStatusName',
      width: '50%',
    },
    {
      Header: `${intl.formatMessage({ id: 'Order.Column.Action' })}`,
      accessor: 'action',
      width: '100px',
    },
  ]

  const childColumns = [
    {
      Header: `${intl.formatMessage({ id: 'Product' })}`,
      accessor: 'product',
      width: '200px',
    },
    {
      Header: `${intl.formatMessage({ id: 'Price' })}`,
      accessor: 'basePrice',
      width: '100px',
    },
    {
      Header: `${intl.formatMessage({ id: 'Quantity' })}`,
      accessor: 'quantity',
      width: '100px',
    },
  ]

  const rows = orders.map((item) => {
    return {
      orderCode: (
        <>
          <div>{item.orderCode}</div>
          <div className={`badge bg-${statusColor[item.orderStatus]}`}>
            <FormattedMessage id={`Purchase.${item.orderStatusName}`} />
          </div>
        </>
      ),
      fullName: (
        <div>
          <div>
            <b>
              <FormattedMessage id='Affiliate.Column.AffiliateName' />:{' '}
            </b>
            {item.customer.fullName}
          </div>
          <div>
            <b>
              <FormattedMessage id='Order.Column.PhoneNumber' />:{' '}
            </b>
            {item.customer.phoneNumber}
          </div>
          <div>
            <b>
              <FormattedMessage id='Order.Column.OrderDate' />:{' '}
            </b>
            {item.orderDateStr}
          </div>
        </div>
      ),
      phoneNumber: item.phoneNumber,
      totalAmount: item.totalAmount,
      orderDate: item.orderDateStr,
      orderStatusName: item.orderStatusName,
      paymentStatusName: (
        <>
          <div>
            <b>
              <FormattedMessage id='Order.Column.PaymentMethodName' />:{' '}
            </b>
            <FormattedMessage id={`Enum.${item.paymentMethodName}`} />
          </div>
          <div>
            <b>
              <FormattedMessage id='Order.Column.PaymentStatusName' />:{' '}
            </b>
            <FormattedMessage id={`Enum.${item.paymentStatusName}`} />
          </div>
          <div>
            <b>
              <FormattedMessage id='Order.Column.TotalAmount' />:{' '}
            </b>
            <NumberBox type='text' value={item.totalAmount} className='' suffix='đ' />
          </div>
        </>
      ),
      paymentMethodName: <FormattedMessage id={`Enum.${item.paymentMethodName}`} />,
      action: (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          {item.orderStatus !== OrderStatusEnum.Complete && item.orderStatus !== OrderStatusEnum.Cancel && item.orderStatus !== OrderStatusEnum.ReturnRefund && (
            <a
              onClick={() => handleUpdateStatus(item)}
              className={`btn btn-icon btn-${statusColor[item.orderStatus]} btn-sm`}
              title={intl.formatMessage({ id: 'Approve' })}
            >
              <i className='fa fa-check' />
            </a>
          )}

          <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm me-1'>
            <i className='fa-solid fa-pen-to-square' />
          </a>
        </div>
      ),
      childs: item.orderDetails.map((x) => {
        return {
          product: (
            <div className='d-flex'>
              <img className='purchase-product-image' src={DEFAULT_VALUE.BASE_URL + x.productImage} alt='' />
              <div className='purchase-product-info'>
                <div className='purchase-product-name'>
                  <span className='purchase-product-middle' title={x.productName}>
                    {x.productName}
                  </span>
                </div>
                <div className='purchase-product-name'>
                  <span className='purchase-product-middle'>{x.productCode}</span>
                </div>
                <div className='purchase-product-name'>
                  <div className='purchase-product-middle'>{x.productPropertyName}</div>
                </div>
              </div>
            </div>
          ),
          basePrice: <NumberBox type='text' value={x.basePrice} className='' suffix='đ' />,
          quantity: <NumberBox type='text' value={x.quantity} className='' />,
        }
      }),
    }
  })

  const handleEdit = (item) => {
    navigate(`/eco/order/${item.id}`)
  }

  const handleUpdateStatus = (item) => {
    setConfirm(true)
    setOrder(item)
  }

  const updateStatus = async () => {
    const req = { orderId: order.id }

    const { error } =
      order.orderStatus === OrderStatusEnum.WaitForConfirm
        ? await preparingGoods(req)
        : order.orderStatus === OrderStatusEnum.PreparingGoods
        ? await waitForDelivery(req)
        : order.orderStatus === OrderStatusEnum.WaitForDelivery
        ? await complete(req)
        : null

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      setConfirm(false)
      fetchOrders()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleSearch = (query) => {
    query = query.trim().toLowerCase()
    if (!_.isEmpty(query)) {
      const filteredData = orderOriginals.filter((item) => item.link.toLowerCase().includes(query))
      setOrders(filteredData)
    } else {
      setOrders(orderOriginals)
    }
  }

  return (
    <div className='card card-flush mb-5 mb-xl-8'>
      <div className='purchase-right-nav'>
        <div className='purchase-right-wrap'>
          <section className='purchase-tablist'>
            {orderRoutes.map((d) => {
              return (
                <a
                  key={d.type}
                  onClick={() => setStatus(d.type)}
                  className={`purchase-col-all nav-link text-active-success ${status === d.type ? 'purchase-border-success active' : ''}`}
                >
                  <span className='purchase-text-all'>{intl.formatMessage({ id: d.title })}</span>
                </a>
              )
            })}
          </section>
        </div>
      </div>

      <div className='card-header border-0'>
        <div className='card-title'>
          <ExportExcel fileName={'orders'} columns={columns} data={rows} />
        </div>

        <div className='card-toolbar'>
          <TableSearch onSearch={handleSearch} originalData={orderOriginals} />
        </div>
      </div>

      <div className='card-body py-2 pt-0'>
        <div className='table-responsive'>
          <Table columns={columns} data={rows} hasChild={true} childColumns={childColumns} />
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={(e) => setCurrentPage(e)}
            onItemsPerPageChange={(e) => setPageSize(e)}
          />
          {isLoading && <Loading />}
        </div>
      </div>

      {confirm && (
        <Confirm
          onConfirm={updateStatus}
          onCancel={() => setConfirm(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Confirm' })}
        />
      )}
    </div>
  )
}

export default Order
