import Multiselect from 'multiselect-react-dropdown'
import { forwardRef, useEffect } from 'react'

const MultiSelect = forwardRef((props, ref) => {
  let {
    displayValue,
    selectedValues,
    options,
    setOptions,
    tabIndex,
    placeholder,
    isRequired,
    label,
    contentTooltip,
    isValid,
    errorMessage,
    className,
    ...restProps
  } = props

  useEffect(() => {
    if (options.length === 1) {
      setOptions(options)
    }
  }, [options, setOptions])

  return (
    <>
      <Multiselect
        ref={ref}
        displayValue={displayValue ?? 'name'}
        onRemove={(event) => {
          setOptions(event)
        }}
        onSelect={(event) => {
          setOptions(event)
        }}
        options={options}
        showCheckbox
        tabIndex={tabIndex ?? 0}
        selectedValues={selectedValues}
        placeholder={placeholder}
        className={className ?? ''}
        {...restProps}
      />
      {isValid === false && (
        <p className='mt-0 mb-0' style={{ color: 'red', whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default MultiSelect
