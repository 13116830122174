import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { logout } from '../../../redux/actions/auth/authActions'

class Logout extends Component {

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const returnUrl = urlParams.get('returnUrl');
        this.props.logout()
        if (_.isEmpty(this.props.user)) {
            window.location.href = returnUrl
        }
    }

    render() {
        return (
            <></>
        )
    }
}

const mapDispatchToProps = {
    logout
}

export default connect(null, mapDispatchToProps)(Logout)