import _ from 'lodash'

const SelectedTab = ({ currentTab, modules }) => {
  const selectedTab = _.find(modules, { id: currentTab })
  if (!selectedTab) {
    return <></>
  }

  const TabComponent = selectedTab.component
  return <TabComponent />
}

const TabsBase = ({ currentTab, modules }) => {
  return (
    <div className="d-flex h-100 flex-column">
      <div
        className="flex-column-fluid hover-scroll-y"
        data-kt-scroll="true"
        data-kt-scroll-activate="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-wrappers="#kt_aside_wordspace"
        data-kt-scroll-dependencies="#kt_aside_secondary_footer"
        data-kt-scroll-offset="0px"
      >
        <div className="tab-content">
          <div className="tab-pane fade active show" id={`kt_aside_nav_tab_${currentTab}`} role="tabpanel">
            <SelectedTab currentTab={currentTab} modules={modules} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TabsBase
