import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { Icon, toAbsoluteUrl } from '../../../../helpers'

const Search = ({ className = '', mobileToggleBtnClass = '' }) => {
  // const [menuState, setMenuState] = useState('main')
  // const element = useRef(null)
  // const wrapperElement = useRef(null)
  // const resultsElement = useRef(null)
  // const suggestionsElement = useRef(null)
  // const emptyElement = useRef(null)

  // const processs = (search) => {
  //   setTimeout(function () {
  //     const number = Math.floor(Math.random() * 6) + 1;

  //     suggestionsElement.current!.classList.add('d-none');

  //     if (number === 3) {
  //       resultsElement.current!.classList.add('d-none');
  //       emptyElement.current!.classList.remove('d-none');
  //     } else {
  //       resultsElement.current!.classList.remove('d-none');
  //       emptyElement.current!.classList.add('d-none');
  //     }

  //     search.complete();
  //   }, 1500);
  // };

  // const clear = (search) => {
  //   suggestionsElement.current!.classList.remove('d-none');
  //   resultsElement.current!.classList.add('d-none');
  //   emptyElement.current!.classList.add('d-none');
  // };

  // useEffect(() => {
  //   const searchObject = SearchComponent.createInsance('#kt_header_search');

  //   searchObject!.on('kt.search.process', processs);
  //   searchObject!.on('kt.search.clear', clear);
  // }, []);

  return (
    <div
      id="kt_header_search"
      className={clsx('d-flex align-items-center', className)}
      data-kt-search-keypress="true"
      data-kt-search-min-length="2"
      data-kt-search-enter="enter"
      data-kt-search-layout="menu"
      data-kt-search-responsive="lg"
      data-kt-menu-trigger="auto"
      data-kt-menu-permanent="true"
      data-kt-menu-placement="bottom-end"
      data-kt-search="true"
      // ref={element}
    >
      <div className={clsx('d-flex d-lg-none align-items-center')} data-kt-search-element="toggle">
        <div className={mobileToggleBtnClass}>
          <Icon iconName="magnifier" className="fs-1" />
        </div>
      </div>

      <form data-kt-search-element="form" className="d-none align-items-center d-lg-flex w-100 mb-5 mb-lg-0 position-relative" autoComplete="off">
        <Icon iconName="magnifier" className=" fs-2 text-gray-700 position-absolute top-50 translate-middle-y ms-4" />

        <input type="text" className="form-control bg-transparent ps-13 fs-7 h-40px" name="search" placeholder="Quick Search" data-kt-search-element="input" />

        <span className="position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5" data-kt-search-element="spinner">
          <span className="spinner-border h-15px w-15px align-middle text-gray-400" />
        </span>

        <span
          className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4"
          data-kt-search-element="clear"
        >
          <Icon iconName="cross" className="fs-2 text-lg-1 me-0" />
        </span>
      </form>

      <div data-kt-search-element="content" data-kt-menu="true" className="menu menu-sub menu-sub-dropdown w-300px w-md-350px py-7 px-7 overflow-hidden">
        {/* Rest of the code remains unchanged */}
      </div>
    </div>
  )
}

export { Search }
