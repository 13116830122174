import { useEffect, useState } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { useRef } from 'react'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { ControlType, FormControl } from '../../components/FormControl'
import { saveNews, updateNews } from '../../../../redux/actions/eco/newsActions'
import Loading from '../../components/Loading'
import { getIntoCombobox } from '../../../../redux/actions/gnr/danhMucActions'
import { TABLE_NAME } from '../../../../utilities/constant'
import { getNewsPromotions } from '../../../../redux/actions/eco/promotionActions'
import useGetFiles from '../../../../helpers/useGetFiles'

const NewsModal = ({ editNews, closeModal, fetchNews }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const [isLoading, setIsLoading] = useState(false)

  const [promotions, setPromotions] = useState([])

  const [title, setTitle] = useState(editNews?.title ?? null)
  const [content, setContent] = useState(editNews?.content ?? null)
  const [promotionId, setPromotionId] = useState(editNews?.promotionId ?? null)
  const [publishDate, setPublishDate] = useState(editNews?.publishDate != null ? new Date(editNews?.publishDate) : new Date())
  const [isActive, setIsActive] = useState(editNews?.isActive ?? true)
  const [banner, setBanner] = useGetFiles(TABLE_NAME.NEWS, editNews?.id)

  useEffect(() => {
    const fetchDataCombobox = async () => {
      try {
        const { result } = await getNewsPromotions(editNews?.id)
        setPromotions(result ?? [])
      } catch (error) {
        console.log(error)
      }
    }
    fetchDataCombobox()
  }, [])

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return
    setIsLoading(true)
    const newsSave = {
      title,
      content,
      promotionId,
      publishDate,
      id: editNews?.id,
      isActive: editNews?.isActive ? isActive : true,
      banner: banner[0],
    }

    const { error } = editNews.isAdded ? await saveNews(newsSave) : await updateNews(newsSave)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      editNews.isAdded = false
      fetchNews()
      closeModal(false)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
    setIsLoading(false)
  }

  return (
    <Modal title={intl.formatMessage({ id: editNews.isAdded ? 'AddNew' : 'Update' })} closeModal={closeModal} modalSize='mw-75'>
      <form className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='d-flex gap-3'>
              <div className='col-6 mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.title = ref)}
                  tabIndex={1}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({ id: 'News.Label.Title' })}
                  value={title}
                  isRequired={true}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>

              <div className='col-6 mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.promotionId = ref)}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({ id: 'News.Promotion' })}
                  value={promotionId}
                  onChange={(e) => setPromotionId(e.value)}
                  options={promotions}
                  displayValue='id'
                  displayName='name'
                />
              </div>
            </div>
            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.content = ref)}
                controlType={ControlType.CkEditor}
                label={intl.formatMessage({ id: 'News.Label.Content' })}
                isRequired={true}
                value={content}
                onChange={(e) => setContent(e)}
              />
            </div>

            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.publishDate = ref)}
                controlType={ControlType.DatePicker}
                label={intl.formatMessage({ id: 'News.Label.PublishDate' })}
                value={publishDate}
                isRequired={true}
                onChange={(date) => setPublishDate(date)}
              />
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.banner = ref)}
                tabIndex={7}
                controlType={ControlType.AttachFile}
                label={intl.formatMessage({ id: 'News.Banner' })}
                value={banner}
                onChange={(e) => setBanner(e)}
                isRequired={true}
                isMultiple={false}
              />
            </div>

            {!editNews.isAdded && (
              <div className='mb-3 row'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  tabIndex={6}
                  controlType={ControlType.Checkbox}
                  label={intl.formatMessage({ id: 'News.Label.IsActive' })}
                  value={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  isFromSwitch={true}
                />
              </div>
            )}
          </div>
        </div>

        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} icon='fa-close' title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} />
          <Button onClick={(e) => handleSave(e)} icon='fa-save' title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} />
        </div>

        {isLoading && <Loading />}
      </form>
    </Modal>
  )
}

export default NewsModal
