import { useEffect, useState, useRef } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import { ControlType, FormControl } from '../../components/FormControl'
import { getCode, add } from '../../../../redux/actions/eco/warehouseReceiveActions'
import { validationAllComponents } from '../../../../utilities/shareFunction'

const ReceivingModel = ({ productId, supplierId, warehouseId, closeModal, reloadData }) => {
  const intl = useIntl()
  const componentRefs = useRef({})

  const [code, setCode] = useState(null)
  const [description, setDescription] = useState(null)
  const [receivedDate] = useState(new Date())
  const [quantity, setQuantity] = useState(null)

  useEffect(() => {
    fetchCode()
  }, [])

  const fetchCode = async () => {
    try {
      const { result } = await getCode()
      setCode(result ?? null)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSave = async () => {
    let isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const payload = {
      description,
      supplierId,
      warehouseId,
      warehouseReceiveDetails: [
        {
          productId,
          quantity,
        },
      ],
    }

    const { error } = await add(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      reloadData()
      closeModal()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: 'Menu.Eco.WarehouseReceiving' })} closeModal={closeModal} modalSize='mw-650px'>
      <form className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='0px'
        >
          <div className='col-12 mb-4'>
            <FormControl
              ref={(ref) => (componentRefs.current.code = ref)}
              tabIndex={1}
              controlType={ControlType.Input}
              label={intl.formatMessage({
                id: 'Receiving.Column.Code',
              })}
              value={code}
              readOnly={true}
            />
          </div>

          <div className='col-12 mb-4'>
            <FormControl
              ref={(ref) => (componentRefs.current.receivedDate = ref)}
              tabIndex={2}
              controlType={ControlType.DatePicker}
              label={intl.formatMessage({
                id: 'Receiving.Column.Date',
              })}
              value={receivedDate}
              readOnly={true}
            />
          </div>

          <div className='col-12 mb-4'>
            <FormControl
              ref={(ref) => (componentRefs.current.quantity = ref)}
              tabIndex={3}
              controlType={ControlType.NumberBox}
              label={intl.formatMessage({
                id: 'Receiving.Column.Quantity',
              })}
              value={quantity}
              isRequired={true}
              type='number'
              onChange={(e) => setQuantity(e.target.value)}
            />
          </div>

          <div className='col-12 mb-4'>
            <FormControl
              ref={(ref) => (componentRefs.current.description = ref)}
              tabIndex={3}
              controlType={ControlType.TextArea}
              label={intl.formatMessage({
                id: 'Receiving.Column.Description',
              })}
              value={description}
              rows={3}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <div className='d-flex justify-content-center'>
            <div className='text-center'>
              <a onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className='btn btn-primary btn-sm ms-2' tabIndex={5}>
                <i className='fa fa-save'></i>
                <FormattedMessage id='Save' />
              </a>

              <Button onClick={() => closeModal()} title={intl.formatMessage({ id: 'Close' })} className='btn btn-light ms-2' tabIndex={6}>
                <i className='fa fa-times'></i>
                <FormattedMessage id='Close' />
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ReceivingModel
