import { useEffect, useState } from 'react'
import { getFiles } from '../redux/actions/gnr/fileAttachmentActions'

const useGetFiles = (tableName, tablePrimaryId, type) => {
  const [files, setFiles] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { result } = await getFiles({ tableName, tablePrimaryId, type })
        if (result) {
          setFiles((prevFiles) => [...prevFiles, ...result])
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [tableName, tablePrimaryId, type])

  return [files, setFiles]
}

export default useGetFiles
