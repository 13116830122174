import { useState, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormControl, ControlType } from '../../pages/components/FormControl'
import _ from 'lodash'
import { toAbsoluteUrl } from '../../../helpers'
import { resetPassword } from '../../../redux/actions/auth/authActions'

const ForgotPassword = () => {
  const intl = useIntl()
  const location = useLocation()
  const componentRefs = useRef({})

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [isSubmited, setIsSubmited] = useState(false)
  const [emailConfirm, setEmailConfirm] = useState({})
  const [error, setError] = useState('')

  const urlParams = new URLSearchParams(location.search)
  const returnUrl = urlParams.get('returnUrl')
  const loginLink = returnUrl ? `/auth/login?returnUrl=${encodeURIComponent(returnUrl)}` : '/auth/login'

  const validationAllComponents = async () => {
    let isValid = true

    await _.map(componentRefs.current, async (ref) => {
      if (!(await ref.validateData())) {
        isValid = false
      }
    })

    return isValid
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)

    try {
      const isValid = await validationAllComponents()
      if (!isValid) return
      const { error, result } = await resetPassword({ email, returnUrl })
      if (error) {
        setError(error.message)
      } else {
        setEmailConfirm(result)
        setIsSubmited(true)
      }
    } catch (error) {
      console.error('An error occurred:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {isSubmited ? (
        <>
          <div className='d-flex flex-column flex-center text-center'>
            <div className='mb-14'>
              <a href='index.html' className=''>
                <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-40px' />
              </a>
            </div>
            <h1 className='fw-bolder text-gray-900 mb-5'>
              <FormattedMessage id='Login.ResetPasswordConfirmation' />
            </h1>
            <div className='fs-6 fw-semibold text-gray-500 mb-10'>
              <p>
                <FormattedMessage id='Login.EmailConfirmationSent' />{' '}
                <a href='#' className='link-primary fw-semibold'>
                  {emailConfirm?.email}
                </a>{' '}
                {emailConfirm?.Message}
                <FormattedMessage id='Login.ResetPasswordConfirmationMessage' />
              </p>
              <p>
                <FormattedMessage id='Login.EmailConfirmationGuide' />
              </p>
            </div>
            <div className='mb-11'>
              <Link to='/auth/login' className='btn btn-primary me-4'>
                <FormattedMessage id='Login' />
              </Link>
            </div>
          </div>
        </>
      ) : (
        <form className='form w-100' onSubmit={handleSubmit} noValidate>
          <div className='text-center mb-10'>
            <h1 className='text-gray-900 fw-bolder mb-3'>
              <FormattedMessage id='Login.ForgotPassword' />
            </h1>
            <div className='text-gray-500 fw-semibold fs-6'>
              <FormattedMessage id='Login.EmailReset' />
            </div>
          </div>

          {error && (
            <div className='alert alert-danger'>
              <div className='alert-text font-weight-bold'>{error}</div>
            </div>
          )}

          <div className='fv-row mb-8'>
            <FormControl
              ref={(ref) => (componentRefs.current.email = ref)}
              controlType={ControlType.Input}
              label={intl.formatMessage({ id: 'Login.Email' })}
              value={email}
              isEmail={true}
              isRequired={true}
              hideLabel={true}
              onChange={(e) => setEmail(e.target.value)}
              className={'form-control mb-3 py-3 px-4'}
            />
          </div>
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4' disabled={loading}>
              {!loading && (
                <span className='indicator-label'>
                  <FormattedMessage id='Submit' />
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  <FormattedMessage id='Confirm.PleaseWait' />
                  ...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to={loginLink} className='btn btn-light me-4'>
              <FormattedMessage id='Confirm.Button.Cancel' />
            </Link>
          </div>
        </form>
      )}
    </>
  )
}

export default ForgotPassword
