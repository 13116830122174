import { Banner as BannerAPI } from '../../../services'

// Async actions

const getBanners = async (payload) => {
  return await BannerAPI.getBanners(payload)
}

const getBannerById = async (bannerId) => {
  return await BannerAPI.getBannerById(bannerId)
}

const saveBanner = async (payload) => {
  return await BannerAPI.saveBanner(payload)
}

const updateBanner = async (payload) => {
  return await BannerAPI.updateBanner(payload)
}

const deleteBanner = async (bannerId) => {
  return await BannerAPI.deleteBanner(bannerId)
}

export { saveBanner, updateBanner, getBannerById, deleteBanner, getBanners }
