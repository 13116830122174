import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class OrderAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.ORDER)
    this.adapter = this.initAdapter(conf)
  }

  async getOrders(payload) {
    return await this.adapter.postAsync('getOrders', { payload })
  }

  async getOrderById(orderId) {
    return await this.adapter.getAsync(`getById?id=${orderId}`)
  }

  async preparingGoods(payload) {
    return await this.adapter.putAsync('preparingGoods', { payload })
  }

  async waitForDelivery(payload) {
    return await this.adapter.putAsync('waitForDelivery', { payload })
  }

  async complete(payload) {
    return await this.adapter.putAsync('complete', { payload })
  }
}

export default OrderAPI
