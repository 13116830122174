import { CONFIG_TYPE } from '../constants'
import { Auth } from '../../components/auth/Auth'

export default class Config {
  constructor() {
    const endpoint = process.env.REACT_APP_API_BASE_URL
    const token = Auth.getToken()
    this.conf = {
      endpoint,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      timeout: 30000,
      direct: endpoint,
      publicUrl: endpoint,
      type: '',
      prefix: '',
    }
  }

  getFileAttachmentConfig() {
    const endpoint = this.conf.endpoint
    const token = Auth.getToken()
    const conf = {
      endpoint,
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      timeout: 30000,
      type: CONFIG_TYPE.FILE_ATTACHMENT,
      prefix: 'fileAttachment/',
    }
    return conf
  }

  getConfig(configType) {
    const config = { ...this.conf }
    config.type = configType
    config.prefix = configType + '/'
    return config
  }
}
