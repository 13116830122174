import { createSlice } from '@reduxjs/toolkit'

const signalRSlice = createSlice({
  name: 'signalR',
  initialState: {
    connection: null,
  },
  reducers: {
    setConnection: (state, action) => {
      state.connection = action.payload
    },
  },
})

export const { setConnection } = signalRSlice.actions
export default signalRSlice.reducer
