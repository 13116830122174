import { useState } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import { useRef } from 'react'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { ControlType, FormControl } from '../../components/FormControl'
import { saveCategory, updateCategory } from '../../../../redux/actions/eco/categoryActions'
import useGetFiles from '../../../../helpers/useGetFiles'
import { FILE_TYPE, TABLE_NAME } from '../../../../utilities/constant'

const CategoryModal = ({ editCategory, closeModal, fetchCategory }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
console.log(editCategory)
  const [categoryName, setCategoryName] = useState(editCategory?.categoryName ?? null)
  const [description, setDescription] = useState(editCategory?.description ?? null)
  const [isActive, setIsActive] = useState(editCategory?.isActive ?? false)
  const [logo, setLogo] = useGetFiles(TABLE_NAME.CATEGORY, editCategory?.id, FILE_TYPE.CATEGORY_LOGO)
  const [banner, setBanner] = useGetFiles(TABLE_NAME.CATEGORY, editCategory?.id, FILE_TYPE.CATEGORY_BANNER)

  const handleChangeLogo = (file) => {
    setLogo(file)
  }

  const handleChangeThumbnail = (file) => {
    setBanner(file)
  }

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const categorySave = {
      categoryName,
      logo: logo[0],
      banner: banner[0],
      description,
      id: editCategory?.id,
      isActive: editCategory.isAdded ? true : isActive,
    }

    const { error } = editCategory.isAdded ? await saveCategory(categorySave) : await updateCategory(categorySave)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      closeModal(false)
      fetchCategory()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: editCategory.isAdded ? 'AddNew' : 'Update' })} closeModal={closeModal} modalSize='mw-650px'>
      <form id='kt_modal_add_Category_form' className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_Category_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_Category_header'
          data-kt-scroll-wrappers='#kt_modal_add_Category_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.categoryName = ref)}
                tabIndex={1}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Category.Column.CategoryName' })}
                value={categoryName}
                isRequired={true}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.description = ref)}
                tabIndex={2}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Category.Column.Description' })}
                value={description}
                rows={3}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.logo = ref)}
                tabIndex={4}
                controlType={ControlType.AttachFile}
                onChange={handleChangeLogo}
                value={logo}
                isMultiple={false}
                isRequired={true}
                label={intl.formatMessage({
                  id: 'SubCategory.Column.Logo',
                })}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.banner = ref)}
                tabIndex={4}
                controlType={ControlType.AttachFile}
                onChange={handleChangeThumbnail}
                value={banner}
                isMultiple={false}
                isRequired={true}
                label={intl.formatMessage({
                  id: 'SubCategory.Column.Banner',
                })}
              />
            </div>

            {!editCategory.isAdded && (
              <div className='col-md-12 mb-3 p-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  tabIndex={3}
                  controlType={ControlType.Checkbox}
                  label={intl.formatMessage({ id: 'Category.Column.IsActvive' })}
                  value={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  isFromSwitch={true}
                />
              </div>
            )}
          </div>
        </div>

        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={4}>
            <i className='fa fa-times'></i>
            <FormattedMessage id='Close' />
          </Button>
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={5}>
            <i className='fa fa-save'></i>
            <FormattedMessage id='Save' />
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default CategoryModal
