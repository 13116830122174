import { User as UserAPI } from '../../../services'

const getAllUser = async (payload) => {
  return await UserAPI.getAllUser(payload)
}

const saveUser = async (payload) => {
  return await UserAPI.saveUser(payload)
}

const resetPassword = async (payload) => {
  return await UserAPI.resetPassword(payload)
}

const checkDuplicateEmail = async (payload) => {
  return await UserAPI.checkDuplicateEmail(payload)
}

const checkDuplicateUserName = async (payload) => {
  return await UserAPI.checkDuplicateUserName(payload)
}

const getUserById = async (payload) => {
  return await UserAPI.getUserById(payload)
}

const changePassword = async (payload) => {
  return await UserAPI.changePassword(payload)
}

export { getAllUser, saveUser, resetPassword, checkDuplicateEmail, checkDuplicateUserName, getUserById, changePassword }
