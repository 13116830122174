import { useState } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import { useRef } from 'react'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { ControlType, FormControl } from '../../components/FormControl'
import { savePromotion, updatePromotion } from '../../../../redux/actions/eco/promotionActions'
import Loading from '../../components/Loading'
import { DiscountTypeEnum, PromotionTypeEnum } from '../../../../utilities/enums'
import { saveCategory, updateCategory } from '../../../../redux/actions/eco/categoryActions'
import useGetFiles from '../../../../helpers/useGetFiles'
import { FILE_TYPE, TABLE_NAME } from '../../../../utilities/constant'

const PromotionModal = ({ promotion, closeModal, fetchPromotions }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const [isLoading, setIsLoading] = useState(false)
  const [discountTypes] = useState(
    Object.keys(DiscountTypeEnum)
      .filter((key) => DiscountTypeEnum[key] === DiscountTypeEnum.FixedPrice 
        || DiscountTypeEnum[key] === DiscountTypeEnum.Percentage
        || DiscountTypeEnum[key] === DiscountTypeEnum.Bogo)
      .map((key) => ({
        value: DiscountTypeEnum[key],
        name: <FormattedMessage id={`Enum.${key}`} />,
      }))
  )

  const [name, setName] = useState(promotion?.name)
  const [description, setDescription] = useState(promotion?.description)
  const [discountType, setDiscountType] = useState(promotion?.discountType)
  const [discountedPrice, setDiscountedPrice] = useState(promotion?.discountedPrice ?? 0)
  const [discountedPercent, setDiscountedPercent] = useState(promotion?.discountedPercent ?? 0)
  const [fromDate, setFromDate] = useState(promotion ? new Date(promotion?.fromDate) : new Date())
  const [toDate, setToDate] = useState(promotion ? new Date(promotion.toDate) : null)
  const [promotionType, setPromotionType] = useState(promotion?.promotionType ?? PromotionTypeEnum.ProductPromotions)
  const [isActive, setIsActive] = useState(promotion?.isActive ?? true)
  const [banner, setBanner] = useGetFiles(TABLE_NAME.PROMOTION, promotion?.id, FILE_TYPE.PROMOTION_BANNER)

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return
    setIsLoading(true)

    const payload = {
      id: promotion?.id,
      name,
      description,
      fromDate,
      toDate,
      discountType,
      discountedPrice,
      discountedPercent,
      promotionType,
      isActive,
      banner: banner[0],
    }

    const { error } = promotion ? await updatePromotion(payload) : await savePromotion(payload)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      fetchPromotions()
      closeModal()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
    setIsLoading(false)
  }

  return (
    <Modal title={intl.formatMessage({ id: promotion ? 'Update' : 'AddNew' })} modalSize='mw-650px' closeModal={closeModal}>
      <form id='add-promotion-form' className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='add-promotion-scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.name = ref)}
                tabIndex={2}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Promotion.Label.Title' })}
                value={name}
                isRequired={true}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.description = ref)}
                tabIndex={1}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Promotion.Label.Description' })}
                value={description}
                rows={3}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className='d-flex mb-3'>
              <div className='col-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.fromDate = ref)}
                  tabIndex={2}
                  controlType={ControlType.DatetimePicker}
                  label={intl.formatMessage({ id: 'Promotion.Label.StartDate' })}
                  value={fromDate}
                  isRequired={true}
                  onChange={(date) => setFromDate(date)}
                />
              </div>
              <div className='col-6 ps-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.toDate = ref)}
                  tabIndex={2}
                  controlType={ControlType.DatetimePicker}
                  label={intl.formatMessage({ id: 'Promotion.Label.EndDate' })}
                  value={toDate}
                  isRequired={true}
                  onChange={(date) => setToDate(date)}
                />
              </div>
            </div>

            <div className='d-flex mb-3'>
              <div className={`${discountType !== DiscountTypeEnum.Percentage && discountType !== DiscountTypeEnum.FixedPrice ? 'col-12' : 'col-6'}`}>
                <FormControl
                  ref={(ref) => (componentRefs.current.discountType = ref)}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({ id: 'Promotion.Label.DiscountType' })}
                  value={discountType}
                  isRequired={true}
                  onChange={(e) => setDiscountType(e.value)}
                  options={discountTypes}
                />
              </div>

              <div className={`col-6 ps-4 ${discountType === DiscountTypeEnum.Percentage ? '' : 'd-none'}`}>
                <label className='form-label'>
                  <FormattedMessage id='Product.Column.DiscountedPercent' />
                </label>
                <div className='d-flex flex-column text-center border'>
                  <div className='d-flex align-items-start justify-content-center'>
                    <span
                      className='fw-bold bordered'
                      style={{ fontSize: '1.7rem' }}
                      contentEditable='true'
                      onBlur={(e) => setDiscountedPercent(e.target.textContent)}
                    >
                      {discountedPercent}
                    </span>
                    <span className='fw-bold fs-4 mt-1 ms-2'>%</span>
                  </div>
                </div>
              </div>

              <div className={`col-6 ps-4 ${discountType === DiscountTypeEnum.FixedPrice ? '' : 'd-none'}`}>
                <FormControl
                  ref={(ref) => (componentRefs.current.discountedPrice = ref)}
                  tabIndex={6}
                  controlType={ControlType.NumberBox}
                  label={intl.formatMessage({
                    id: 'Product.Column.DiscountedPrice',
                  })}
                  value={discountedPrice}
                  type='number'
                  onChange={(e) => setDiscountedPrice(e.target.value)}
                />
              </div>
            </div>

            <div className='col-12 mb-3'>
              <label className='fs-6 fw-semibold'>
                <FormattedMessage id='Promotion.Label.PromotionType' />
              </label>
              <div className='row row-cols-3'>
                {Object.keys(PromotionTypeEnum).map((key) => {
                  return (
                    <div key={key} className='pe-2 pt-2'>
                      <label
                        className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-4 ${
                          promotionType === PromotionTypeEnum[key] ? 'active' : ''
                        }`}
                        onChange={(e) => setPromotionType(parseInt(e.target.value))}
                      >
                        <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start me-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='discount_option'
                            value={PromotionTypeEnum[key]}
                            checked={promotionType === PromotionTypeEnum[key]}
                          />
                        </span>

                        <span className='fw-bold d-block'>
                          <FormattedMessage id={`Enum.${key}`} />
                        </span>
                      </label>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.banner = ref)}
                tabIndex={4}
                controlType={ControlType.AttachFile}
                onChange={(e) => setBanner(e)}
                value={banner}
                isMultiple={false}
                isRequired={true}
                label={intl.formatMessage({
                  id: 'SubCategory.Column.Banner',
                })}
              />
            </div>

            {promotion && (
              <div className='col-md-12 mb-3 p-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  tabIndex={6}
                  controlType={ControlType.Checkbox}
                  label={intl.formatMessage({ id: 'Banner.Label.IsActive' })}
                  value={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  isFromSwitch={true}
                />
              </div>
            )}
          </div>
        </div>
        <div className='text-center p-4'>
          <Button onClick={() => closeModal()} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={8} icon='fa fa-close' />
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={9} icon='fa fa-save' />
        </div>
        {isLoading && <Loading />}
      </form>
    </Modal>
  )
}

export default PromotionModal
