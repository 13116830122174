import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class WarehouseDispatchApi extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.WAREHOUSE_DISPATCH)
    this.adapter = this.initAdapter(conf)
  }

  async getById(payload) {
    return await this.adapter.getAsync('getById', { payload })
  }

  async getByProductId(payload) {
    return await this.adapter.getAsync('getByProductId', { payload })
  }

  async getBySupplierId(payload) {
    return await this.adapter.getAsync('getBySupplierId', { payload })
  }

  async add(payload) {
    return await this.adapter.postAsync('add', { payload })
  }
}

export default WarehouseDispatchApi
