import React, { forwardRef } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { uploadImageCKEditor } from '../../../redux/actions/gnr/fileAttachmentActions'

const CkEditor = forwardRef((props, ref) => {
  const { onChange, value, tabIndex, onBlur, readOnly, isValid, errorMessage } = props

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const formData = new FormData()
            formData.append('file', file)
            uploadImageCKEditor(formData, resolve, reject)
          })
        })
      },
    }
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader)
    }
  }

  const editorConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'blockQuote',
        'link',
        'numberedList',
        'bulletedList',
        'imageUpload',
        'insertTable',
        'mediaEmbed',
        '|',
        'undo',
        'redo',
        'alignment',
        'code',
        'codeBlock',
        'fontColor',
        'fontFamily',
        'fontSize',
        'fontBackgroundColor',
        'highlight',
        'horizontalLine',
        'htmlEmbed',
        'imageInsert',
      ],
    },
    image: {
      toolbar: ['imageTextAlternative', 'toggleImageCaption', 'imageStyle:inline', 'imageStyle:block', 'imageStyle:slide'],
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
    extraPlugins: [uploadPlugin],
  }

  return (
    <>
      <CKEditor
        config={editorConfig}
        editor={ClassicEditor}
        data={value}
        onChange={(event, editor) => {
          const data = editor.getData()
          onChange(data)
        }}
        tabIndex={tabIndex ?? 0}
        onBlur={onBlur}
        data-isvalid={isValid}
        readOnly={readOnly}
        ref={ref}
      />
      {isValid === false && (
        <p className="mt-0 mb-0" style={{ color: 'red', whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default CkEditor
