import { Coupon as CouponAPI } from '../../../services'

// Async actions

const getCoupons = async (payload) => {
  return await CouponAPI.getCoupons(payload)
}

const getCouponById = async (couponId) => {
  return await CouponAPI.getCouponById(couponId)
}

const saveCoupon = async (payload) => {
  return await CouponAPI.saveCoupon(payload)
}

const updateCoupon = async (payload) => {
  return await CouponAPI.updateCoupon(payload)
}

const deleteCoupon = async (couponId) => {
  return await CouponAPI.deleteCoupon(couponId)
}

const getNewCouponCode = async () => {
  return await CouponAPI.getNewCouponCode()
}

export { saveCoupon, updateCoupon, getCouponById, deleteCoupon, getCoupons, getNewCouponCode }
