import { FeaturedProduct as FeaturedProductAPI } from '../../../services'

// Async actions

const getFeaturedProductById = async (id) => {
  return await FeaturedProductAPI.getFeaturedProductById(id)
}

const getFeaturedProductsByType = async (payload) => {
  return await FeaturedProductAPI.getFeaturedProductsByType(payload)
}

const addFeaturedProducts = async (payload) => {
  return await FeaturedProductAPI.addFeaturedProducts(payload)
}

const deleteFeaturedProducts = async (payload) => {
  return await FeaturedProductAPI.deleteFeaturedProducts(payload)
}

const getProducts = async (payload) => {
  return await FeaturedProductAPI.getProducts(payload)
}

const modifyFromDate = async (payload) => {
  return await FeaturedProductAPI.modifyFromDate(payload)
}

const modifyTodate = async (payload) => {
  return await FeaturedProductAPI.modifyTodate(payload)
}

const modifyOrder = async (payload) => {
  return await FeaturedProductAPI.modifyOrder(payload)
}

export { getFeaturedProductById, getFeaturedProductsByType, addFeaturedProducts, deleteFeaturedProducts, getProducts, modifyFromDate, modifyTodate, modifyOrder }
