import { useEffect, useState, useRef } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import { ControlType, FormControl } from '../../components/FormControl'
import { addProductPromotion, getProducts } from '../../../../redux/actions/eco/productPromotionActions'
import { getSupplierApprovedByUserId } from '../../../../redux/actions/eco/supplierActions'
import { getCategoriesDropdown } from '../../../../redux/actions/eco/categoryActions'
import { getSubCategoriesDropdown } from '../../../../redux/actions/eco/subCategoryActions'

const ProductPromotionModel = ({ promotionId, fetchProductPromotion, closeModal }) => {
  const intl = useIntl()
  const componentRefs = useRef({})

  const [productCode, setProductCode] = useState(null)
  const [productName, setProductName] = useState(null)
  const [supplierId, setSupplierId] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [subCategoryId, setSubCategoryId] = useState(null)
  const [groupId, setGroupId] = useState(null)

  const [suppliers, setSuppliers] = useState([])
  const [categories, setCategories] = useState([])
  const [allSubCategories, setAllSubCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [groups, setGroups] = useState([])
  const [products, setProducts] = useState([])
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [isSelectedAll, setIsSelectedAll] = useState(false)

  useEffect(() => {
    fetchCombobox()
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [currentPage, pageSize])

  useEffect(() => {
    let data = allSubCategories.filter((x) => x.fkId === categoryId && !x.parentId)
    setSubCategories(data)
    if (!data.some((x) => x.value === subCategoryId)) {
      setGroups([])
      componentRefs.current.subCategoryId.clearValue()
      componentRefs.current.groupId.clearValue()
    }
  }, [categoryId])

  useEffect(() => {
    let data = allSubCategories.filter((x) => x.fkId === categoryId && x.parentId === subCategoryId)
    setGroups(data)
    if (!data.some((x) => x.value === groupId)) {
      componentRefs.current.groupId.clearValue()
    }
  }, [subCategoryId])

  const fetchProducts = async () => {
    try {
      const { result: productResult } = await getProducts({
        productCode: productCode,
        productName: productName,
        supplierId: supplierId,
        pageIndex: currentPage,
        pageSize: pageSize,
        categoryId: categoryId ?? null,
        subCategoryId: subCategoryId ?? null,
        groupId: groupId ?? null,
        promotionId: promotionId,
      })

      if (productResult) {
        setProducts(productResult.items)
        setTotalPages(productResult.totalCount)
      } else {
        setProducts([])
        setTotalPages(0)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchCombobox = async () => {
    try {
      const { result: supplierResult } = await getSupplierApprovedByUserId()
      if (supplierResult) {
        setSuppliers(supplierResult)
      }

      //Category
      const { result: categoryResult } = await getCategoriesDropdown()
      if (categoryResult) {
        setCategories(categoryResult)
      }

      //SubCategory
      const { result: subCategoryResult } = await getSubCategoriesDropdown()
      if (subCategoryResult) {
        setAllSubCategories(subCategoryResult)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectedAll = () => {
    setProducts(
      products.map((item) => ({
        ...item,
        isSelected: !isSelectedAll,
      }))
    )

    setIsSelectedAll(!isSelectedAll)
  }

  const handleSelected = (id) => {
    setProducts((prevProducts) => {
      const updatedProducts = [...prevProducts]
      const selectedItem = updatedProducts.find((item) => item.id === id)
      selectedItem.isSelected = !selectedItem.isSelected

      if (updatedProducts.every((x) => x.isSelected === selectedItem.isSelected)) {
        setIsSelectedAll(selectedItem.isSelected)
      } else {
        setIsSelectedAll(false)
      }
      return updatedProducts
    })
  }

  const productColumns = [
    {
      Header: (
        <div className='form-check form-check-sm form-check-custom form-check-solid justify-content-center'>
          <input onChange={handleSelectedAll} className='form-check-input pointer' type='checkbox' checked={isSelectedAll} value='1' />
        </div>
      ),
      accessor: 'isSelected',
      width: 25,
      disableSort: true,
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.ProductCode' })}`,
      accessor: 'productCode',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.ProductName' })}`,
      accessor: 'productName',
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.Supplier' })}`,
      accessor: 'supplierName',
      width: 100,
    },
  ]

  const productRows = products.map((item) => {
    return {
      isSelected: (
        <div className='form-check form-check-sm form-check-custom form-check-solid justify-content-center'>
          <input onChange={() => handleSelected(item.id)} className='form-check-input pointer' checked={item.isSelected} type='checkbox' value='1' />
        </div>
      ),
      productCode: item.productCode,
      productName: item.productName,
      supplierName: item.supplierName,
    }
  })

  const handleSave = async () => {
    const productIds = products.filter((x) => x.isSelected).map((x) => x.id)
    const { error } = await addProductPromotion({ productIds, promotionId })
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      closeModal()
      fetchProductPromotion()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: 'Product.Title.List' })} closeModal={closeModal} modalSize='mw-950px'>
      <div className='card-body pt-0'>
        <div
          className='scroll-y me-n5 pe-5 h-400px h-lg-auto'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
          data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
          data-kt-scroll-offset='0px'
        >
          <div className='d-flex mb-4 gap-2'>
            <div className='col-4'>
              <FormControl
                ref={(ref) => (componentRefs.current.categoryId = ref)}
                tabIndex={9}
                controlType={ControlType.Select}
                label={intl.formatMessage({
                  id: 'Product.Column.Category',
                })}
                value={categoryId}
                options={categories}
                isClearable={false}
                onChange={(e) => setCategoryId(e.value)}
              />
            </div>

            <div className='col-4'>
              <FormControl
                ref={(ref) => (componentRefs.current.subCategoryId = ref)}
                tabIndex={10}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Product.Column.SubCategory' })}
                value={subCategoryId}
                options={subCategories}
                isClearable={false}
                onChange={(e) => setSubCategoryId(e.value)}
              />
            </div>

            <div className='col-4'>
              <FormControl
                ref={(ref) => (componentRefs.current.groupId = ref)}
                tabIndex={10}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Product.Column.Group' })}
                value={groupId}
                options={groups}
                isClearable={false}
                onChange={(e) => setGroupId(e.value)}
              />
            </div>
          </div>

          <div className='d-flex mb-4'>
            <div className='col-4'>
              <FormControl
                ref={(ref) => (componentRefs.current.productCode = ref)}
                tabIndex={1}
                controlType={ControlType.Input}
                label={intl.formatMessage({
                  id: 'Product.Column.ProductCode',
                })}
                value={productCode}
                onChange={(e) => setProductCode(e.target.value)}
              />
            </div>

            <div className='col-4 ms-2'>
              <FormControl
                ref={(ref) => (componentRefs.current.productName = ref)}
                tabIndex={2}
                controlType={ControlType.Input}
                label={intl.formatMessage({
                  id: 'Product.Column.ProductName',
                })}
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>

            <div className='col-4 ms-2'>
              <FormControl
                ref={(ref) => (componentRefs.current.basePrice = ref)}
                tabIndex={3}
                controlType={ControlType.Select}
                label={intl.formatMessage({
                  id: 'Product.Column.Supplier',
                })}
                value={supplierId}
                options={suppliers}
                onChange={(e) => setSupplierId(e.value)}
              />
            </div>
          </div>

          <div className='d-flex justify-content-center mb-4'>
            <div className='text-center'>
              <Button onClick={fetchProducts} title={intl.formatMessage({ id: 'Search' })} className='btn btn-success' tabIndex={4}>
                <i className='fa fa-search'></i>
                <FormattedMessage id='Search' />
              </Button>

              <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className='btn btn-primary ms-2' tabIndex={5}>
                <i className='fa fa-save'></i>
                <FormattedMessage id='Save' />
              </Button>

              <Button onClick={() => closeModal()} title={intl.formatMessage({ id: 'Close' })} className='btn btn-light ms-2' tabIndex={6}>
                <i className='fa fa-times'></i>
                <FormattedMessage id='Close' />
              </Button>
            </div>
          </div>

          <Table columns={productColumns} data={productRows} />
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={(e) => setCurrentPage(e)}
            onItemsPerPageChange={(e) => setPageSize(e)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ProductPromotionModel
