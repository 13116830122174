import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class NotificationAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.NOTIFICATION)
    this.adapter = this.initAdapter(conf)
  }

  async getByToUserId(toUserId) {
    return await this.adapter.getAsync(`getByToUserId?toUserId=${toUserId}`)
  }

  async saveNotification(payload) {
    return await this.adapter.postAsync('saveNotification', { payload })
  }

  async updateNotification(notificationId) {
    return await this.adapter.putAsync(`updateNotification?notificationId=${notificationId}`)
  }
}

export default NotificationAPI
