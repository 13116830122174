import BaseApi from '../base/baseApi'
import Config from '../base/config'

class FileAttachmentAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getFileAttachmentConfig()
    this.adapter = this.initAdapter(conf)
  }

  async getFiles(payload) {
    return await this.adapter.getAsync('getFiles', { payload })
  }

  async uploadFiles(payload) {
    return await this.adapter.postFilesAsync('uploadFiles', { payload })
  }

  async downloadFile(filePath, fileNameLocal, fileNameServer) {
    return await this.adapter.downloadFileAsync(`downloadFile?filePath=${filePath}&fileNameLocal=${fileNameLocal}&fileNameServer=${fileNameServer}`, {
      fileNameLocal,
    })
  }

  async uploadImageCKEditor(payload, resolve, reject) {
    return await this.adapter.postCKEditorImagesAsync('uploadImageCKEditor', { payload }, resolve, reject)
  }
}

export default FileAttachmentAPI
