import { useIntl, FormattedMessage } from 'react-intl'
import Modal from '../../components/Modal'
import { useEffect, useRef, useState } from 'react'
import { ControlType, FormControl } from '../../components/FormControl'
import Button from '../../components/Button'
import _ from 'lodash'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import { customerRegister, updateAffiliate } from '../../../../redux/actions/aff/affiliateActions'
import Loading from '../../components/Loading'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { getDistrictsByProvinceId, getProvinces, getWardsByDistrictId } from '../../../../redux/actions/gnr/provinceActions'
import { GenderEnum } from '../../../../utilities/enums'

const CustomerModal = ({ editAffiliate, closeModal, fetchAffiliates }) => {
  const intl = useIntl()
  const componentRefs = useRef({})

  const [affiliateName, setAffiliateName] = useState(editAffiliate?.affiliateName ?? null)
  const [birthday, setBirthday] = useState(editAffiliate?.birthday != null ? new Date(editAffiliate?.birthday) : null)
  const [genderId, setGenderId] = useState(editAffiliate?.genderId ?? null)
  const [isVietnamese, setIsVietnamese] = useState(editAffiliate?.isVietnamese ?? true)
  const [phone, setPhone] = useState(editAffiliate?.phone ?? null)
  const [email, setEmail] = useState(editAffiliate?.email ?? null)
  const [address, setAddress] = useState(editAffiliate?.address ?? null)
  const [isActive, setIsActive] = useState(editAffiliate?.isActive ?? true)

  const [isLoading, setIsLoading] = useState(false)
  const [genders] = useState(
    Object.keys(GenderEnum).map((key) => ({
      value: GenderEnum[key],
      name: <FormattedMessage id={`Enum.${key}`} />,
    }))
  )

  const handleSave = async (e) => {
    e.preventDefault()

    if (!(await validationAllComponents(componentRefs))) {
      return
    }
    setIsLoading(true)

    try {
      const affSave = {
        affiliateName,
        birthday,
        genderId,
        isVietnamese,
        phone,
        email,
        address,
        id: editAffiliate?.id,
        isActive: editAffiliate.isAdded ? true : isActive,
        isAffiliate: false,
      }

      const { error } = editAffiliate.isAdded ? await customerRegister(affSave) : await updateAffiliate(affSave)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        fetchAffiliates()
        closeModal(false)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
        console.log(error)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: editAffiliate.isAdded ? 'AddNew' : 'Update' })} closeModal={closeModal} modalSize='mw-650px'>
      <form id='kt_modal_add_form' className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.affiliateName = ref)}
                tabIndex={1}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.Label.AffiliateName' })}
                value={affiliateName}
                isRequired={true}
                onChange={(e) => setAffiliateName(e.target.value)}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.birthday = ref)}
                tabIndex={2}
                controlType={ControlType.DatePicker}
                label={intl.formatMessage({ id: 'Affiliate.Label.Birthday' })}
                value={birthday}
                isRequired={true}
                onChange={(date) => setBirthday(date)}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.genderId = ref)}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Affiliate.Label.Gender' })}
                value={genderId}
                isRequired={true}
                onChange={(e) => setGenderId(e.value)}
                options={genders}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.phone = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.Label.Phone' })}
                value={phone}
                isRequired={true}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.email = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.Label.Email' })}
                value={email}
                isEmail={true}
                isRequired={true}
                onChange={(e) => setEmail(e.target.value)}
                readOnly={!editAffiliate.isAdded}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.address = ref)}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Warehouse.Label.Street' })}
                value={address}
                isRequired={true}
                onChange={(e) => setAddress(e.target.value)}
                rows={2}
              />
            </div>

            <div className='col-md-6 mb-3 p-0'>
              <FormControl
                ref={(ref) => (componentRefs.current.isVietnamese = ref)}
                tabIndex={6}
                controlType={ControlType.Checkbox}
                label={intl.formatMessage({ id: 'Affiliate.Label.IsVietnamese' })}
                value={isVietnamese}
                onChange={(e) => setIsVietnamese(e.target.checked)}
              />
            </div>

            {!editAffiliate.isAdded && (
              <div className='col-md-6 mb-3 p-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  tabIndex={6}
                  controlType={ControlType.Checkbox}
                  label={intl.formatMessage({ id: 'Affiliate.Label.IsActive' })}
                  value={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  isFromSwitch={true}
                />
              </div>
            )}
          </div>
        </div>

        <div className='text-center p-4'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={8}>
            <i className='fa fa-times'></i>
            <FormattedMessage id='Close' />
          </Button>

          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={9}>
            <i className='fa fa-save'></i>
            <FormattedMessage id='Save' />
          </Button>
        </div>

        {isLoading && <Loading />}
      </form>
    </Modal>
  )
}

export default CustomerModal
