import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { DEFAULT_VALUE, GUID_NEWID } from '../../../../utilities/constant'
import _ from 'lodash'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import Loading from '../../components/Loading'
import ConfirmRemove from '../../components/Confirm'
import Checkbox from '../../components/Checkbox'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import BannerModal from './BannerModal'
import { deleteBanner, getBanners } from '../../../../redux/actions/eco/bannerActions'
import ExportExcel from '../../components/ExportExcel'
import TableSearch from '../../components/TableSearch'
import { getCurrentPromotions } from '../../../../redux/actions/eco/promotionActions'
import { BANNER_POSITION_ENUM } from '../../../../utilities/constant'

const Banner = () => {
  const intl = useIntl()
  const [banners, setBanners] = useState([])
  const [bannerOriginals, setBannerOriginals] = useState([])
  const [banner, setBanner] = useState(null)
  const [promotions, setPromotions] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const [isEdit, setIsEdit] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)

  const positions = BANNER_POSITION_ENUM.map((el) => {
    return { ...el, name: intl.formatMessage({ id: el.name }) }
  })

  const fetchBanners = async () => {
    setIsLoading(true)
    try {
      const { result } = await getBanners({ pageIndex: currentPage, pageSize: pageSize })
      if (result) {
        setBanners(result.items)
        setBannerOriginals(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const fetchPromotions = async () => {
    try {
      const { result } = await getCurrentPromotions()
      if (result) {
        setPromotions(
          result.map((item) => ({
            value: item.id,
            name: item.name,
          }))
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchBanners()
    fetchPromotions()
  }, [])

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'Banner.Label.Position' })}`,
      accessor: 'positionId',
      width: 80,
    },
    {
      Header: `${intl.formatMessage({ id: 'Banner.Column.Title' })}`,
      accessor: 'title',
    },
    {
      Header: `${intl.formatMessage({ id: 'Banner.Column.Promotion' })}`,
      accessor: 'promotion',
    },
    {
      Header: `${intl.formatMessage({ id: 'Banner.Column.FromDate' })}`,
      accessor: 'fromDate',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Banner.Column.ToDate' })}`,
      accessor: 'toDate',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Banner.Column.Sort' })}`,
      accessor: 'sort',
      width: 50,
    },
    {
      Header: `${intl.formatMessage({ id: 'Banner.Column.IsActive' })}`,
      accessor: 'isActive',
      width: 50,
    },
    {
      Header: `${intl.formatMessage({ id: 'Banner.Column.Action' })}`,
      accessor: 'action',
      width: 100,
    },
  ]

  const rows = banners.map((item) => {
    return {
      positionId: positions.find((x) => x.value === item.positionId)?.name,
      promotion: item.promotionName,
      title: item.title,
      fromDate: item.fromDateStr,
      toDate: item.toDateStr,
      sort: item.sort,
      isActive: <Checkbox checked={item.isActive} readOnly className='justify-content-center' />,
      action: (
        <div className='d-flex justify-content-center flex-shrink-0'>
          <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm me-1'>
            <i className='fa-solid fa-pen-to-square' />
          </a>
          <a onClick={() => confirmRemove(item)} className='btn btn-icon btn-light-danger btn-sm'>
            <i className='fa-solid fa-trash' />
          </a>
        </div>
      ),
    }
  })

  const handleCreate = () => {
    setIsEdit(true)
    setBanner({ id: GUID_NEWID(), isAdded: true })
  }

  const handleEdit = (item) => {
    setBanner(item)
    setIsEdit(true)
  }

  const confirmRemove = (item) => {
    setConfirmVisible(true)
    setBanner(item)
  }

  const handleRemove = async () => {
    setIsLoading(true)

    try {
      const { error } = await deleteBanner(banner.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        setBanners(banners.filter((item) => item.id !== banner.id))
        setBannerOriginals(banners.filter((item) => item.id !== banner.id))
        setConfirmVisible(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='card-title'>
          <a onClick={() => handleCreate()} className='btn btn-sm btn-success me-3'>
            <i className='ki-outline ki-plus fs-2'></i> {intl.formatMessage({ id: 'AddNew' })}
          </a>

          <ExportExcel fileName={'banners'} columns={columns} data={rows} />
        </div>

        <div className='card-toolbar'>
          <TableSearch onSearch={setBanners} originalData={bannerOriginals} />
        </div>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <Table columns={columns} data={rows} />
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={(e) => setCurrentPage(e)}
            onItemsPerPageChange={(e) => setPageSize(e)}
          />
          {isLoading && <Loading />}
        </div>
      </div>

      {isEdit && <BannerModal editBanner={banner} closeModal={setIsEdit} fetchBanners={fetchBanners} promotions={promotions} />}

      {confirmVisible && (
        <ConfirmRemove
          onConfirm={handleRemove}
          onCancel={() => setConfirmVisible(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}
    </div>
  )
}

export default Banner
