import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { DEFAULT_VALUE, GUID_NEWID } from '../../../../utilities/constant'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import _ from 'lodash'
import ExportExcel from '../../components/ExportExcel'
import TableSearch from '../../components/TableSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import WarehouseModal from './WarehouseModal'
import UseAdminWarehouseModal from './UseAdminWarehouseModal'
import { Icon } from '../../../../helpers'
import ConfirmRemove from '../../components/Confirm'
import { deleteWarehouse, getWarehouses } from '../../../../redux/actions/eco/warehouseActions'
import { Auth } from '../../../../components/auth/Auth'
import { ROLE_NAME, LEVEL_WAREHOUSE } from '../../../../utilities/constant'

const Warehouse = () => {
  const intl = useIntl()
  const currentUser = Auth.getUserData() || {}

  const [warehouses, setWarehouses] = useState([])
  const [warehouseOriginals, setWarehouseOriginals] = useState([])
  const [warehouse, setWarehouse] = useState(null)
  const [wareHouseIds, setWareHouseIds] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const [isEdit, setIsEdit] = useState(false)
  const [isEditAdmin, setIsEditAdmin] = useState(false)
  const [confirmRemove, setConfirmRemove] = useState(false)

  const fetchWarehouses = async () => {
    setIsLoading(true)
    try {
      const { result } = await getWarehouses({ pageIndex: currentPage, pageSize: pageSize, supplierId: currentUser.supplierId })
      const data = result?.items
      if (data) {
        setWarehouses(data)
        setWarehouseOriginals(data)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchWarehouses()
  }, [currentPage, pageSize])

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'Warehouse.Column.Code' })}`,
      accessor: 'code',
      width: 66,
    },
    {
      Header: `${intl.formatMessage({ id: 'Warehouse.Column.Name' })}`,
      accessor: 'name',
      width: 150,
    },
    {
      Header: `${intl.formatMessage({ id: 'Warehouse.Column.WarehouseInfomation' })}`,
      accessor: 'warehouseInfomation',
      width: 250,
    },
    {
      Header: `${intl.formatMessage({ id: 'Warehouse.Column.Action' })}`,
      accessor: 'action',
      width: 50,
    },
  ]

  const rows = warehouses?.map((item) => {
    return {
      code: item.code,
      name: item.name,
      warehouseInfomation: (
        <div>
          <div>
            <b className='text-red'> {item.isPrimary ? intl.formatMessage({ id: 'Warehouse.Column.IsPrimary' }) : ''} </b>
          </div>
          <div>
            <b>{intl.formatMessage({ id: 'Warehouse.Column.Type' })} : </b>
            {item.level === LEVEL_WAREHOUSE.ADMIN
              ? intl.formatMessage({ id: 'Warehouse.Value.AdminWarehouse' })
              : intl.formatMessage({ id: 'Warehouse.Value.SupplierWarehouse' })}
          </div>
          <div>
            <b>{intl.formatMessage({ id: 'Warehouse.Column.Address' })} : </b>
            {item.fullAddress}
          </div>
        </div>
      ),
      action: (
        <div className='d-flex justify-content-center gap-2'>
          <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm'>
            <i className='fa-solid fa-pen-to-square' />
          </a>
          <a onClick={() => handleConfirmRemove(item)} className='btn btn-icon btn-light-danger btn-sm'>
            <i className='fa-solid fa-trash' />
          </a>
        </div>
      ),
    }
  })

  const handleCreate = () => {
    setIsEdit(true)
    setWarehouse({ id: GUID_NEWID(), isAdded: true })
  }

  const handleCreateAdmin = () => {
    setIsEditAdmin(true)
    setWarehouse({ isAdded: true })
    const wareHouseAdminIds = _.map(
      _.filter(warehouses, (x) => x.level === LEVEL_WAREHOUSE.ADMIN),
      'id'
    )
    setWareHouseIds(wareHouseAdminIds)
  }

  const handleEdit = (item) => {
    setWarehouse(item)
    setIsEdit(true)
  }

  const handleConfirmRemove = (item) => {
    setConfirmRemove(true)
    setWarehouse(item)
  }

  const handleRemove = async () => {
    setIsLoading(true)

    try {
      const { error } = await deleteWarehouse(warehouse.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        var currentWarehouse = warehouses.filter((item) => item.id !== warehouse.id)
        setWarehouses(currentWarehouse)
        setWarehouseOriginals(currentWarehouse)
        setConfirmRemove(false)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='card-title gap-3'>
          <a onClick={() => handleCreate()} className='btn btn-sm btn-success'>
            <Icon iconName='plus' className='fs-3' />
            {intl.formatMessage({ id: 'AddNew' })}
          </a>
          {currentUser.roleName !== ROLE_NAME.ADMIN && (
            <a onClick={() => handleCreateAdmin()} className='btn btn-sm btn-success'>
              <Icon iconName='plus' className='fs-3' />
              {intl.formatMessage({ id: 'UseAdminWarehouse' })}
            </a>
          )}
          <ExportExcel fileName={'warehouses'} columns={columns} data={rows} numberOfLastColumnRemove={1} />
        </div>

        <div className='card-toolbar'>
          <TableSearch onSearch={setWarehouses} originalData={warehouseOriginals} />
        </div>
      </div>

      <div className='card-body py-2 pt-0'>
        <div className='table-responsive'>
          <Table columns={columns} data={rows} />
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={(e) => setCurrentPage(e)}
            onItemsPerPageChange={(e) => setPageSize(e)}
          />
          {isLoading && <Loading />}
        </div>
      </div>

      {isEdit && <WarehouseModal editWarehouse={warehouse} closeModal={setIsEdit} fetchWarehouses={fetchWarehouses} />}
      {isEditAdmin && (
        <UseAdminWarehouseModal editWarehouse={warehouse} wareHouseIds={wareHouseIds} closeModal={setIsEditAdmin} fetchWarehouses={fetchWarehouses} />
      )}

      {confirmRemove && (
        <ConfirmRemove
          onConfirm={handleRemove}
          onCancel={() => setConfirmRemove(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}
    </div>
  )
}

export default Warehouse
