import React, { useState, useEffect } from 'react'
import { useTable, useSortBy } from 'react-table'
import { ControlType } from './FormControl'
import DatePicker from './DatePicker'
import NumberBox from './NumberBox'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

const Table = ({ columns, data, fixedIndexColumns, onCellValueChange, hasChild, childColumns }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy)
  const [expandedId, setExpandedId] = useState(null)

  useEffect(() => {
    setExpandedId(null)
  }, [columns, data])

  const toggleExpand = (id) => {
    if (expandedId === id) {
      setExpandedId(null)
    } else {
      setExpandedId(id)
    }
  }

  const loadEditColumn = (cell, controlType) => {
    switch (controlType) {
      case ControlType.DatePicker:
        return (
          <DatePicker
            onChange={(date) => onCellValueChange(cell, date?.toISOString())}
            className='form-control form-control-sm'
            selectedDate={!_.isEmpty(cell.value) && new Date(cell.value)}
          />
        )
      case ControlType.NumberBox:
        return <NumberBox onChange={(e) => onCellValueChange(cell, e.target.value)} className='form-control form-control-sm' value={cell.value} />
      default:
        return <input className='form-control form-control-sm' value={cell.value} onChange={(e) => onCellValueChange(cell, e.target.value)} />
    }
  }

  return (
    <table
      {...getTableProps()}
      className='table dataTable no-footer align-middle table-bordered border-secondary table-sm table-striped table-hover'
      style={{ tableLayout: 'fixed' }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-success'>
            {hasChild && <th width='40px'></th>}
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.disableSort ? undefined : column.getSortByToggleProps())}
                className={`text-light text-center vertical-align ${column.className ?? ''} ${
                  column.isSorted ? (column.isSortedDesc ? 'table-sort-desc' : 'table-sort-asc') : 'sorting_disabled'
                }`}
                width={column.width}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows && rows.length > 0 ? (
          rows.map((row) => {
            prepareRow(row)
            return (
              <React.Fragment key={row.Id}>
                <tr {...row.getRowProps()}>
                  {hasChild && !_.isEmpty(row.original.childs) && (
                    <td className='ps-4'>
                      <i className={`fa ${expandedId === row.id ? 'fa-minus' : 'fa-plus'} cursor-pointer`} onClick={() => toggleExpand(row.id)}></i>{' '}
                    </td>
                  )}
                  {row.cells.map((cell, cellIndex) => (
                    <td {...cell.getCellProps()} className={`${fixedIndexColumns && fixedIndexColumns.includes(cellIndex) ? 'sticky-column' : ''}`}>
                      {columns[cellIndex].editable ? loadEditColumn(cell, columns[cellIndex].editable) : cell.render('Cell')}
                    </td>
                  ))}
                </tr>
                {hasChild && !_.isEmpty(row.original.childs) && (
                  <tr hidden={expandedId !== row.id}>
                    <td colSpan={row.cells.length + 1}>
                      <ChildTable columns={childColumns} data={row.original.childs} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )
          })
        ) : (
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              <FormattedMessage id='NoResult' />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

const ChildTable = ({ columns, data }) => {
  const { headerGroups, rows, prepareRow } = useTable({ columns, data }, useSortBy)

  return (
    <table className='table table-sm table-row-dashed'>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className='text-uppercase'>
            {headerGroup.headers.map((column) => (
              <th className={`vertical-align`} width={column.width}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows && rows.length > 0 ? (
          rows.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            )
          })
        ) : (
          <tr>
            <td colSpan={columns.length}>
              <FormattedMessage id='NoResult' />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default Table
