import React from 'react'

const Button = (props) => {
  const { type, className, onClick, children, title, tabIndex, disabled  } = props
  return (
    <button
      onClick={onClick}
      type={type}
      className={className ? `${className} btn-sm` : 'btn btn-default btn-sm'}
      tabIndex={tabIndex ?? 0}
      title={title}
      disabled={disabled ?? false}
    >
      {props.icon && <i className={`fa-solid ${props.icon}`}></i>}
      {children ?? title}
    </button>
  )
}

export default Button