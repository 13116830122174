import { Notification as NotificationAPI } from '../../../services'

// Async actions

const getNotifications = async (toUserId) => {
  return await NotificationAPI.getByToUserId(toUserId)
}

const saveNotification = async (payload) => {
  return await NotificationAPI.saveNotification(payload)
}

const updateNotification = async (id) => {
  return await NotificationAPI.updateNotification(id)
}

export { saveNotification, updateNotification, getNotifications }
