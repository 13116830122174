import { SubCategory as SubCategoryAPI } from '../../../services'

// Async actions

const getById = async (subCategoryId) => {
  return await SubCategoryAPI.getById(subCategoryId)
}

const getByCategoryIds = async (categoryIds) => {
  return await SubCategoryAPI.getByCategoryIds(categoryIds)
}

const getSubCategoriesDropdown = async () => {
  return await SubCategoryAPI.getSubCategoriesDropdown()
}

const getByCategoryIdDropdown = async (categoryId) => {
  return await SubCategoryAPI.getByCategoryIdDropdown(categoryId)
}

const getSubCategories = async (payload) => {
  return await SubCategoryAPI.getSubCategories(payload)
}

const saveSubCategory = async (payload) => {
  return await SubCategoryAPI.saveSubCategory(payload)
}

const updateSubCategory = async (payload) => {
  return await SubCategoryAPI.updateSubCategory(payload)
}

const deleteSubCategory = async (subCategoryId) => {
  return await SubCategoryAPI.deleteSubCategory(subCategoryId)
}

const getByCategoryId = async (payload) => {
  return await SubCategoryAPI.getByCategoryId(payload)
}

const getGroups = async (categoryId, parentId) => {
  return await SubCategoryAPI.getGroups(categoryId, parentId)
}

const updateGroup = async (payload) => {
  return await SubCategoryAPI.updateGroup(payload)
}

export {
  getById,
  getByCategoryIds,
  getSubCategoriesDropdown,
  getByCategoryIdDropdown,
  getSubCategories,
  saveSubCategory,
  updateSubCategory,
  deleteSubCategory,
  getByCategoryId,
  getGroups,
  updateGroup,
}
