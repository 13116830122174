import { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import ExportExcel from '../../components/ExportExcel'
import TableSearch from '../../components/TableSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import { getBySupplierId } from '../../../../redux/actions/eco/warehouseReceiveActions'
import ReceivingModel from './ReceivingModel'
import ReceivingFilter from './ReceivingFilter'

const WarehouseReceiving = () => {
  const intl = useIntl()

  const [warehouseReceives, setWarehouseReceives] = useState([])
  const [warehouseReceivesOriginal, setWarehouseReceivesOriginal] = useState([])
  const [pageIndex, setPageIndex] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const [showReceivingModel, setShowReceivingModel] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async (receiveCode, productCode, productName) => {
    try {
      const { result } = await getBySupplierId(receiveCode, productCode, productName, pageIndex, pageSize)
      if (result) {
        setWarehouseReceives(result.items)
        setWarehouseReceivesOriginal(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'SerialNumber' })}`,
      accessor: 'sno',
      width: 40,
    },
    {
      Header: `${intl.formatMessage({ id: 'Receiving.Column.Code' })}`,
      accessor: 'code',
      width: 80,
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Title.Product' })}`,
      accessor: 'productName',
    },
    {
      Header: `${intl.formatMessage({ id: 'Receiving.Column.Date' })}`,
      accessor: 'receivedDate',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Receiving.Column.Quantity' })}`,
      accessor: 'quantity',
      width: 60,
    },
    {
      Header: `${intl.formatMessage({ id: 'Receiving.Column.Description' })}`,
      accessor: 'description',
    },
  ]
  const rows = warehouseReceives?.map((item, index) => {
    return {
      sno: index + 1,
      code: item.code,
      receivedDate: item.receivedDateStr,
      quantity: item.quantity,
      description: item.description,
      productName: (
        <div>
          <div>
            <b>
              <FormattedMessage id='Product.Column.ProductCode' />:{' '}
            </b>
            {item.productCode}
          </div>
          <div>
            <b>
              <FormattedMessage id='Product.Column.ProductName' />:{' '}
            </b>
            {item.productName}
          </div>
        </div>
      ),
    }
  })

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='card-toolbar gap-3'>
          {/* <a onClick={() => setShowReceivingModel(true)} className='btn btn-sm btn-success'>
            <i className='ki-outline ki-plus fs-2'></i> {intl.formatMessage({ id: 'Menu.Eco.WarehouseReceiving' })}
          </a> */}

          <ExportExcel fileName={'product'} columns={columns} data={rows} />

          <ReceivingFilter fetchData={fetchData} />
        </div>

        <div className='card-toolbar gap-3'>
          <TableSearch onSearch={setWarehouseReceives} originalData={warehouseReceivesOriginal} />
        </div>
      </div>

      <div className='card-body pt-0'>
        <Table columns={columns} data={rows} />

        <Pagination
          totalPages={totalPages}
          pageIndex={pageIndex}
          itemsPerPage={pageSize}
          onPageChange={(e) => setPageIndex(e)}
          onItemsPerPageChange={(e) => setPageSize(e)}
        />
      </div>

      {showReceivingModel && (
        <ReceivingModel
          closeModal={() => setShowReceivingModel(false)}
          // productId={productId}
          // supplierId={supplierId}
          // warehouseId={warehouseId}
          reloadData={() => fetchData()}
        />
      )}
    </div>
  )
}

export default WarehouseReceiving
