import LevelGroup from "../components/pages/eco/settings/LevelGroup"

export const CONFIG_TYPE = {
  CUSTOMER: 'customer',
  DANHMUC: 'danhMuc',
  FILE_ATTACHMENT: 'FileAttachment',
  BANNER: 'banner',
  QUESTION: 'question',
  COUPON: 'coupon',
  NEWS: 'news',
  SUPPLIER: 'supplier',
  AFFILIATE: 'affiliate',
  PRODUCT: 'product',
  CATEGORY: 'category',
  SUBCATEGORY: 'subCategory',
  ROLE: 'role',
  USER: 'user',
  FEATURE: 'feature',
  ACCOUNT: 'account',
  ORDER: 'order',
  NOTIFICATION: 'notification',
  WAREHOUSE:'warehouse',
  WAREHOUSE_RECEIVE:'warehouseReceive',
  WAREHOUSE_DISPATCH:'warehouseDispatch',
  PRODUCTGROUP: 'productGroup',
  FEATUREDPRODUCT: 'featuredProduct',
  BLOG:'blog',
  PROMOTION: 'promotion',
  PRODUCT_PROMOTION: 'productPromotion',
  PROVINCE: 'province',
  LEVEL: 'level',
  LEVEL_GROUP: 'levelGroup',
  PRODUCT_PROPERTY: 'productProperty',
}

export const PRAGMA = 'pragma'
export const REMOVE_PRAGMA = 'remove-pragma'
export const CACHE_CONTROL_FIELD = 'cache-control'
