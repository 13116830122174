import { useState } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import { useRef } from 'react'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { ControlType, FormControl } from '../../components/FormControl'
import { insertStandardProductProperty, updateStandardProductProperty } from '../../../../redux/actions/gnr/productPropertyActions'

const ProductPropertyModal = ({ item, closeModal, fetchItems }) => {
  const intl = useIntl()
  const componentRefs = useRef({})

  const [name, setName] = useState(item?.name ?? null)
  const [description, setDescription] = useState(item?.description ?? null)

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const payload = {
      id: item?.id,
      name,
      description,
      isActive: true,
    }

    const { error } = _.isEmpty(item?.id) ? await insertStandardProductProperty(payload) : await updateStandardProductProperty(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      closeModal(false)
      fetchItems()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: _.isEmpty(item?.id) ? 'AddNew' : 'Update' })} closeModal={closeModal} modalSize='mw-650px'>
      <form className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.name = ref)}
                tabIndex={1}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Product.Column.Property' })}
                value={name}
                isRequired={true}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.description = ref)}
                tabIndex={2}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Description' })}
                value={description}
                rows={3}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={4}>
            <i className='fa fa-times'></i>
            <FormattedMessage id='Close' />
          </Button>
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={5}>
            <i className='fa fa-save'></i>
            <FormattedMessage id='Save' />
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default ProductPropertyModal
