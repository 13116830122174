import ReactApexChart from 'react-apexcharts'

const AreaChart = ({ dataArea, height }) => {
  return (
    <div id="areaChart">
      <ReactApexChart options={dataArea.options} series={dataArea.series} type="area" height={height} />
    </div>
  )
}

export default AreaChart
