import SupplierForm from './SupplierForm'

const SupplierRegister = () => {
  return (
    <div className="d-flex flex-column flex-root">
      <div className="docs-page d-flex flex-row flex-column-fluid">
        <div className="docs-wrapper d-flex flex-column flex-row-fluid">
          <div className="docs-header align-items-stretch mb-2 mb-lg-10"></div>

          <div className="docs-content d-flex flex-column flex-column-fluid">
            <div className="container-xxl">
              <div className="row g-5 g-xxl-8">
                <div className="col-xl-6 p-0 ms-0">
                  <div className="container">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-body pb-0">
                        <h2>
                          <strong>TRỞ THÀNH NHÀ CUNG CẤP VỚI GREEN24H</strong>
                        </h2>
                        <p>
                          <strong>GIỚI THIỆU VỀ GREEN24H</strong>
                        </p>
                        <ul>
                          <li>Green24h là nền tảng cung cấp nguồn hàng từ nhà sản xuất đến với người có nhu cầu bán hàng mà không cần bỏ vốn.&nbsp;</li>
                          <li>
                            Green24h giúp phát triển thị trường, tăng doanh số và quảng bá thương hiệu cho nhà cung cấp, các nhãn hàng thông qua hàng chục ngàn
                            cộng tác viên bán hàng trên toàn quốc của Green24h.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-body pb-0">
                        <p>
                          <strong>ĐIỀU KIỆN TRỞ THÀNH NHÀ CUNG CẤP CỦA GREEN24H</strong>
                        </p>
                        <p>
                          <strong>*Đối tượng: Green24h </strong>phù hợp với quý đối tác là Nhà sản xuất, Nhà phân phối hoặc Xưởng sản xuất...
                        </p>
                        <p>
                          <strong>*Phí thành viên nhà cung cấp trên Green24h:</strong>
                        </p>
                        <p>
                          <strong>1. Supplier Membership</strong>&nbsp;là chính sách thành viên dành riêng cho nhà cung cấp Green24h, phân loại và có sự khác
                          biệt đối với từng nhóm nhà cung cấp
                        </p>
                        <p>Supplier Membership có 2 loại:</p>
                        <p>+ Nhà cung cấp Xác thực</p>
                        <p>+ Nhà cung cấp Cơ bản</p>
                        <p>
                          <strong>2. Phí dịch vụ</strong>&nbsp;là chi phí hoa hồng đối tác dành cho Green24h và Cộng tác viên bán hàng khi bán được đơn
                          hàng.&nbsp;Phí dịch vụ tùy thuộc vào các ngành hàng khác nhau và sẽ ở mức từ 30% trở lên.
                        </p>
                      </div>
                    </div>

                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-body pb-0">
                        <p>
                          <strong>THÔNG TIN GÓI SUPPLIER MEMBERSHIP</strong>
                        </p>
                        <p>
                          <strong>1. Nhà cung cấp Xác Thực</strong>
                        </p>
                        <p>
                          <strong>-&nbsp;</strong>&nbsp;Nhóm Nhà cung cấp được Xác thực bởi Green24h và được truyền thông nổi bật trên sàn, tạo sự tin tưởng cho
                          cộng tác viên yên tâm bán hàng
                        </p>
                        <p>
                          <strong>- Yêu cầu:</strong>
                        </p>
                        <p>
                          + Đối tác là&nbsp;Nhà sản xuất, nhà nhập khẩu, nhà phân phối, công ty thương mại...có giấy phép đăng kí kinh doanh đối với ngành hàng
                          muốn hợp tác
                        </p>
                        <p>+ Đối tác cung cấp được tất cả giấy tờ sản phẩm cần thiết theo ngành hàng theo yêu cầu từ Green24h</p>
                        <p>+ Đối tác&nbsp;cam kết giá niêm yết tại Green24h không bị chênh lệch quá 10% với các kênh TMĐT khác.</p>
                        <p>+ Phí dịch vụ &gt;=30%</p>
                        <p>
                          <strong>- Quyền lợi:&nbsp;</strong>
                        </p>
                        <p>
                          + Được hiển thị nổi bật với huy hiệu Tick xanh Xác thực (đính trực tiếp trên sản phẩm và kho hàng) + Được hiển thị trong Bộ sưu tập
                          Nhà cung cấp Xác thực + Có cơ hội được gán thêm danh hiệu Uy tín và thêm vào Bộ sưu tập Nhà cung cấp Uy Tín dựa vào đánh giá của Cộng
                          tác viên và Green24h + Truyền thông trên Fanpage &amp; Group Cộng tác viên hơn 163.000 thành viên + Tham gia các chiến dịch khuyến mãi
                          hàng tháng
                          <br />
                          &nbsp;
                        </p>
                        <p>
                          <strong>2. Nhà cung cấp Cơ bản</strong>
                        </p>
                        <p>- Nhóm Nhà cung cấp đủ điều kiện cơ bản hợp tác với Green24h.</p>
                        <p>
                          <strong>- Yêu cầu</strong>
                        </p>
                        <p>+ Đối tác là Cá nhân (chỉ áp dụng ngành hàng thời trang), Hộ kinh doanh, Công ty tại Việt Nam</p>
                        <p>+ Đối tác cung cấp được giấy tờ sản phẩm theo yêu cầu từ Green24h</p>
                        <p>+ Phí dịch vụ &gt;=30%</p>
                        <p>
                          <strong>- Quyền lợi</strong>
                        </p>
                        <p>
                          + Hiển thị trong Bộ Sưu tập Nhà cung cấp mới gia nhập hệ thống + Có cơ hội tham gia các chiến dịch khuyến mãi hàng tháng&nbsp;
                          <br />
                          &nbsp;
                        </p>
                        <p>
                          <strong>"ƯU ĐÃI GÓI SUPPLIER MEMBERSHIP DÀNH RIÊNG CHO ANH CHỊ ĐĂNG KÝ LÀ NHÀ CUNG CẤP GREEN24H"</strong>
                        </p>
                        <p>Từ 01/2023 - 12/2023:</p>
                        <p>
                          <strong>Giá 3,499,000 VND/năm, ưu đãi chỉ&nbsp;còn 1,500,000 VNĐ/năm dành cho nhà cung cấp Cơ bản</strong>
                        </p>
                        <p>
                          <strong>Giá 5,490,000 VND/năm, ưu đãi chỉ còn 2,500,000 VNĐ/năm dành cho nhà cung cấp Xác thực</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 p-0 ms-0">
                  <div className="container">
                    <div className="card mb-5 mb-xxl-8">
                      <div className="card-body pb-0">
                        <SupplierForm />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupplierRegister
