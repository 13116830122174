import { useEffect, useState, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import { Icon } from '../../../../helpers'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import { getSuppliers, approveSupplier, ignoreSupplier } from '../../../../redux/actions/eco/supplierActions'
import { useNavigate } from 'react-router-dom'
import ExportExcel from '../../components/ExportExcel'
import SupplierFilter from './SupplierFilter'
import TableSearch from '../../components/TableSearch'
import { SupplierStatusEnum, getKeyByValue } from '../../../../utilities/enums'
import Confirm from '../../components/Confirm'
import { ControlType, FormControl } from '../../components/FormControl'

const Supplier = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const componentRefs = useRef({})

  const [suppliers, setSuppliers] = useState([])
  const [suppliersOriginal, setSuppliersOriginal] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [supplierSelectedId, setSupplierSelectedId] = useState(null)
  const [confirmApprove, setConfirmApprove] = useState(false)
  const [confirmReject, setConfirmReject] = useState(false)

  const [approveStatusId, setApproveStatusId] = useState(null)
  const [status] = useState(
    Object.keys(SupplierStatusEnum).map((key) => ({
      value: SupplierStatusEnum[key],
      name: <FormattedMessage id={`Enum.${key}`} />,
    }))
  )

  const fetchSuppliers = async (companyName, businessCode, fullName, email, phoneNumber) => {
    setIsLoading(true)
    try {
      const { result } = await getSuppliers({
        pageIndex: currentPage,
        pageSize: pageSize,
        approveStatusId,
        companyName,
        businessCode,
        fullName,
        email,
        phoneNumber,
      })

      if (result) {
        setSuppliers(result.items)
        setSuppliersOriginal(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchSuppliers()
  }, [currentPage, pageSize, approveStatusId])

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'Supplier.Label.ContactInfo' })}`,
      accessor: 'fullName',
    },
    {
      Header: `${intl.formatMessage({ id: 'Supplier.Column.CompanyName' })}`,
      accessor: 'companyName',
    },
    {
      Header: `${intl.formatMessage({ id: 'Supplier.Column.PartnerTypeName' })}`,
      accessor: 'partnerTypeName',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Supplier.Column.BusinessIndustryName' })}`,
      accessor: 'businessIndustryName',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Action' })}`,
      accessor: 'action',
      width: 80,
    },
  ]

  const rows = suppliers.map((item) => {
    return {
      fullName: (
        <div>
          <div>
            <b>
              <FormattedMessage id='Supplier.Column.FullName' />:{' '}
            </b>
            {item.fullName}
          </div>
          <div>
            <b>
              <FormattedMessage id='Supplier.Column.Email' />:{' '}
            </b>
            {item.email}
          </div>
          <div>
            <b>
              <FormattedMessage id='Supplier.Column.PhoneNumber' />:{' '}
            </b>
            {item.phoneNumber}
          </div>
        </div>
      ),
      companyName: (
        <div>
          <div>
            <b>
              <FormattedMessage id='Supplier.Column.BusinessCode' />:{' '}
            </b>
            {item.businessCode}
          </div>
          <div>
            <b>
              <FormattedMessage id='Supplier.Column.CompanyName' />:{' '}
            </b>
            {item.companyName}
          </div>
          <div>
            <b>
              <FormattedMessage id='Supplier.Column.ApproveStatusName' />:{' '}
            </b>
            <span
              onClick={() => handleConfirmApprove(item)}
              className={`badge badge-light-${
                item.approveStatusId === SupplierStatusEnum.PendingApproval
                  ? 'primary'
                  : item.approveStatusId === SupplierStatusEnum.Approved
                  ? 'success'
                  : 'secondary'
              } me-1 fs-7 cursor-pointer`}
            >
              <FormattedMessage id={`Enum.${getKeyByValue(SupplierStatusEnum, item.approveStatusId)}`} />
            </span>
          </div>
        </div>
      ),
      partnerTypeName: item.partnerTypeName,
      businessIndustryName: item.businessIndustryName,
      action: (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          {item.approveStatusId === SupplierStatusEnum.PendingApproval && (
            <>
              <a onClick={() => handleConfirmApprove(item)} className='btn btn-icon btn-primary btn-sm' title={intl.formatMessage({ id: 'Approve' })}>
                <i className='fa fa-check' />
              </a>

              <a onClick={() => handleconfirmReject(item)} className='btn btn-icon btn-danger btn-sm' title={intl.formatMessage({ id: 'Ignore' })}>
                <i className='fa fa-xmark' />
              </a>
            </>
          )}

          <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm' title={intl.formatMessage({ id: 'Edit' })}>
            <i className='fa-solid fa-pen-to-square' />
          </a>
        </div>
      ),
    }
  })

  const handleConfirmApprove = (item) => {
    if (item.approveStatusId === SupplierStatusEnum.PendingApproval) {
      setConfirmApprove(true)
      setSupplierSelectedId(item.id)
    }
  }

  const handleconfirmReject = (item) => {
    if (item.approveStatusId === SupplierStatusEnum.PendingApproval) {
      setConfirmReject(true)
      setSupplierSelectedId(item.id)
    }
  }

  const handleApprove = async () => {
    setIsLoading(true)

    try {
      const { error } = await approveSupplier(supplierSelectedId)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
        fetchSuppliers()
        setConfirmApprove(false)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleIgnore = async () => {
    setIsLoading(true)
    try {
      const { error } = await ignoreSupplier(supplierSelectedId)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
        fetchSuppliers()
        setConfirmReject(false)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCreate = () => {
    navigate(`/eco/supplier/${DEFAULT_VALUE.AddNew}/add`)
  }

  const handleEdit = (item) => {
    navigate(`/eco/supplier/${item.id}/edit`)
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='card-title gap-3'>
          <a onClick={() => handleCreate()} className='btn btn-sm btn-success'>
            <Icon iconName='plus' className='fs-3' />
            {intl.formatMessage({ id: 'AddNew' })}
          </a>

          <ExportExcel fileName={'suppliers'} columns={columns} data={rows} numberOfLastColumnRemove={1} />

          <SupplierFilter fetchData={fetchSuppliers} />
        </div>

        <div className='card-toolbar gap-3'>
          <div className='min-w-200px'>
            <FormControl
              ref={(ref) => (componentRefs.current.approveStatusId = ref)}
              controlType={ControlType.Select}
              label={intl.formatMessage({ id: 'Status' })}
              hideLabel={true}
              value={approveStatusId}
              onChange={(e) => setApproveStatusId(e.value)}
              options={status}
            />
          </div>

          <TableSearch onSearch={setSuppliers} originalData={suppliersOriginal} />
        </div>
      </div>
      <div className='card-body py-2 pt-0'>
        <div className='table-responsive'>
          <Table columns={columns} data={rows} />

          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={(e) => setCurrentPage(e)}
            onItemsPerPageChange={(e) => setPageSize(e)}
          />

          {isLoading && <Loading />}
        </div>
      </div>

      {confirmApprove && (
        <Confirm
          onConfirm={handleApprove}
          onCancel={() => setConfirmApprove(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Approve' })}`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Confirm' })}
        />
      )}

      {confirmReject && (
        <Confirm
          onConfirm={handleIgnore}
          onCancel={() => setConfirmReject(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Reject' })}`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Confirm' })}
        />
      )}
    </div>
  )
}

export default Supplier
