import { Blog as BlogApi } from '../../../services'

// Async actions

const getBlogs = async (payload) => {
  return await BlogApi.getBlogs(payload)
}

const getBlogById = async (blogId) => {
  return await BlogApi.getBlogById(blogId)
}

const saveBlog = async (payload) => {
  return await BlogApi.saveBlog(payload)
}

const updateBlog = async (payload) => {
  return await BlogApi.updateBlog(payload)
}

const deleteBlog = async (blogId) => {
  return await BlogApi.deleteBlog(blogId)
}

export { getBlogs, getBlogById, saveBlog, updateBlog, deleteBlog }
