import AffiliateForm from './AffiliateForm'

const AffiliateRegister = () => {
  return (
    <div className="d-flex flex-column flex-root">
      <div className="docs-wrapper d-flex flex-column flex-row-fluid">
        <div className="docs-header align-items-stretch mb-2 mb-lg-10"></div>

        <div className="docs-content d-flex flex-column flex-column-fluid">
          <div className="container-xxl">
            <div className="row g-5 g-xxl-8">
              <div className="col-xl-3 p-0 ms-0"></div>

              <div className="col-xl-6 p-0 ms-0">
                <div className="container">
                  <div className="card mb-5 mb-xxl-8">
                    <div className="card-body pb-0">
                      <AffiliateForm />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-3 p-0 ms-0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AffiliateRegister
