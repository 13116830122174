import { Supplier as SupplierAPI } from '../../../services'

// Async actions

const getSuppliers = async (payload) => {
  return await SupplierAPI.getSuppliers(payload)
}

const getSupplierApprovedByUserId = async (payload) => {
  return await SupplierAPI.getSupplierApprovedByUserId(payload)
}

const getSupplierById = async (supplierId) => {
  return await SupplierAPI.getSupplierById(supplierId)
}

const saveSupplier = async (payload) => {
  return await SupplierAPI.saveSupplier(payload)
}

const updateSupplier = async (payload) => {
  return await SupplierAPI.updateSupplier(payload)
}

const approveSupplier = async (supplierId) => {
  return await SupplierAPI.approveSupplier(supplierId)
}

const ignoreSupplier = async (supplierId) => {
  return await SupplierAPI.ignoreSupplier(supplierId)
}

export { saveSupplier, getSupplierApprovedByUserId, updateSupplier, getSupplierById, approveSupplier, getSuppliers, ignoreSupplier }
