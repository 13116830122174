import { IntlProvider } from 'react-intl'
import { useLang } from './Appi18nProvider'
import { languages } from './constant'

const I18nProvider = ({ children }) => {
  const locale = useLang()
  const messages = languages.find((x) => x.lang === locale)?.messages

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export { I18nProvider }
