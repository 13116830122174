import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class ProductPromotionAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.PRODUCT_PROMOTION)
    this.adapter = this.initAdapter(conf)
  }

  async getProductPromotions(payload) {
    return await this.adapter.postAsync('getProductPromotions', { payload })
  }

  async addProductPromotion(payload) {
    return await this.adapter.postAsync('addProductPromotion', { payload })
  }

  async deleteProductPromotion(payload) {
    return await this.adapter.deleteAsync('deleteProductPromotion', { payload })
  }

  async getProducts(payload) {
    return await this.adapter.getAsync('getProducts', { payload })
  }
}

export default ProductPromotionAPI
