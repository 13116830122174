import ReactApexChart from 'react-apexcharts'

const PieChart = ({ dataPie, width }) => {
  return (
    <div id="pieChart">
      <ReactApexChart options={dataPie.options} series={dataPie.series} type="pie" width={width} />
    </div>
  )
}

export default PieChart
