import React, { forwardRef, useEffect, useState } from 'react'
import { CloseButton, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { downloadFile, uploadFiles } from '../../../redux/actions/gnr/fileAttachmentActions'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import '../../../assets/sass/dropzone-customize.scss'
import { FormattedMessage, useIntl } from 'react-intl'

const AttachFile = forwardRef((props, ref) => {
  const intl = useIntl()
  const { onChange, maxFileSize, value, accept, maxFileCount, tabIndex, onBlur, isValid, errorMessage, isMultiple, hidden, isDropzone } = props
  const [files, setFiles] = useState([])

  useEffect(() => {
    setFiles(value)
  }, [value])

  const rerender = (newFiles) => {
    setFiles(newFiles)
    onChange && onChange(newFiles)
  }

  const handleDelete = (e, i) => {
    const newFiles = [...files]
    newFiles.splice(i, 1)
    setFiles(newFiles)
    onBlur()
  }

  const handleDropzoneClick = () => {
    ref.current.click()
  }

  const handleOnChange = async (e) => {
    let selectedFiles = e.target.files
    if (selectedFiles) {
      const formData = new FormData()
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('files', selectedFiles[i])
      }

      const response = await uploadFiles(formData)
      if (response) {
        rerender([...files, ...response])
      }
    }
  }

  const renderTooltip = (props) => <Tooltip {...props}>{intl.formatMessage({ id: 'FileExceeds' })}</Tooltip>

  const validate = (file) => {
    if (maxFileSize && maxFileSize > 0 && file.size > maxFileSize) {
      return (
        <OverlayTrigger placement='top' overlay={renderTooltip}>
          <span>{String.fromCharCode(9888)}</span>
        </OverlayTrigger>
      )
    }
  }

  const handleDownloadFile = async (e, item) => {
    await downloadFile(item.filePath, item.fileNameLocal, item.fileNameServer)
  }

  const getTableBodyAsReactElement = () => {
    if (files) {
      return (
        <ul className={`file-files mt-3 ${isDropzone ? 'd-none' : ''}`}>
          {files.map((item, i) => (
            <li key={item.fileNameServer} className='file-item mb-3'>
              <a className='download-file pointer' onClick={(e) => handleDownloadFile(e, item)}>
                {i + 1}. {item.fileNameLocal}
              </a>
              <CloseButton className='btn btn-icon btn-danger btn-sm ms-2 w-25px h-25px' onClick={(e) => handleDelete(e, i)}></CloseButton>
              {validate(item)}
            </li>
          ))}
        </ul>
      )
    }
  }

  const renderDropzone = () => {
    return (
      <div className='mt-2'>
        <div className='dropzone' onClick={handleDropzoneClick}>
          {files && files.length > 0 ? (
            files.map((file, index) => (
              <div
                key={index}
                className='dz-preview dz-processing dz-image-preview dz-error dz-complete'
                onClick={(event) => {
                  event.stopPropagation()
                }}
              >
                <div className='dz-image'>
                  <img
                    className='img-fluid'
                    data-dz-thumbnail=''
                    alt={file.fileNameLocal}
                    src={DEFAULT_VALUE.BASE_URL + file.filePath + DEFAULT_VALUE.SLASH + file.fileNameServer}
                  />
                </div>
                <div className='dz-details'>
                  <div className='dz-size'>
                    <span data-dz-size={file.fileSize}>
                      <strong>{file.fileSize}</strong> KB
                    </span>
                  </div>
                  <div className='dz-filename'>
                    <span data-dz-name={file.fileNameLocal}>{file.fileNameLocal}</span>
                  </div>
                </div>
                {/* 
                <div className='dz-error-message'>
                <span data-dz-errormessage=''>{file.errorMessage}</span>
              </div> 
              <div className='dz-error-mark'>
                  <SVG src={toAbsoluteUrl('/media/svg/icons/times.svg')} />
                </div>
              */}
                <a
                  className='dz-remove bg-danger'
                  onClick={(e) => {
                    e.stopPropagation()
                    handleDelete(e, index)
                  }}
                ></a>
              </div>
            ))
          ) : (
            // Message for no files
            <div className='dz-message needsclick align-items-center'>
              <i className='ki-duotone ki-file-up text-primary fs-3x'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <div className='ms-4'>
                <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                  <FormattedMessage id='DropFile' />
                </h3>
              </div>
            </div>
          )}
        </div>
        <div className='text-muted fs-7'>
          <FormattedMessage id='Product.SetProductImage' />
        </div>
      </div>
    )
  }

  const renderFileInput = () => {
    if (!(maxFileCount > 0) || files.length < maxFileCount) {
      return (
        <input
          type='file'
          onChange={handleOnChange}
          accept={accept}
          className={'form-control mb-3 mb-lg-0'}
          multiple={isMultiple ?? true}
          ref={ref}
          onBlur={onBlur}
          data-isvalid={isValid}
          tabIndex={tabIndex}
          hidden={hidden}
        />
      )
    }
  }

  return (
    <>
      {renderFileInput()}
      {getTableBodyAsReactElement()}
      {isDropzone && renderDropzone()}
      {isValid === false && (
        <p className='mt-0 mb-0' style={{ color: 'red', whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default AttachFile
