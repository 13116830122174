import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import { Icon } from '../../../../helpers'
import Loading from '../../components/Loading'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import CustomerModal from './CustomerModal'
import { getAffiliates } from '../../../../redux/actions/aff/affiliateActions'
import ExportExcel from '../../components/ExportExcel'
import TableSearch from '../../components/TableSearch'

const Customer = () => {
  const intl = useIntl()
  const [affiliates, setAffiliates] = useState([])
  const [affiliatesOriginal, setAffiliatesOriginal] = useState([])
  const [affiliate, setAffiliate] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const [isEdit, setIsEdit] = useState(false)

  const fetchAffiliates = async () => {
    setIsLoading(true)
    try {
      const { result } = await getAffiliates({ pageIndex: currentPage, pageSize: pageSize, isAffiliate: 0 })
      if (result) {
        setAffiliates(result.items)
        setAffiliatesOriginal(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAffiliates()
  }, [currentPage, pageSize])

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'Affiliate.Column.AffiliateName' })}`,
      accessor: 'affiliateName',
      width: 120,
    },
    {
      Header: `${intl.formatMessage({ id: 'Affiliate.Column.Email' })}`,
      accessor: 'email',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Affiliate.Column.Phone' })}`,
      accessor: 'phone',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Affiliate.Column.Address' })}`,
      accessor: 'address',
    },
    {
      Header: `${intl.formatMessage({ id: 'Affiliate.Column.RegisterDate' })}`,
      accessor: 'registerDate',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Affiliate.Column.Action' })}`,
      accessor: 'action',
      width: 80,
    },
  ]

  const rows = affiliates.map((item) => {
    return {
      affiliateName: item.affiliateName,
      email: item.email,
      phone: item.phone,
      address: item.address,
      registerDate: item.registerDateStr,
      action: (
        <div className='d-flex justify-content-center flex-shrink-0'>
          <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm me-1'>
            <i className='fa-solid fa-pen-to-square' />
          </a>
        </div>
      ),
    }
  })

  const handleCreate = () => {
    setIsEdit(true)
    setAffiliate({ id: DEFAULT_VALUE.GUID_RANDOM, isAdded: true })
  }

  const handleEdit = (item) => {
    setAffiliate(item)
    setIsEdit(true)
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='card-toolbar gap-3'>
          <a onClick={() => handleCreate()} className='btn btn-sm btn-success'>
            <Icon iconName='plus' className='fs-3' />
            {intl.formatMessage({ id: 'AddNew' })}
          </a>

          <ExportExcel fileName={'Customer'} columns={columns} data={rows} />
        </div>

        <div className='card-toolbar gap-3'>
          <TableSearch onSearch={setAffiliates} originalData={affiliatesOriginal} />
        </div>
      </div>

      <div className='card-body py-2 pt-0'>
        <div className='table-responsive'>
          <Table columns={columns} data={rows} />

          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={(e) => setCurrentPage(e)}
            onItemsPerPageChange={(e) => setPageSize(e)}
          />

          {isLoading && <Loading />}
        </div>
      </div>

      {isEdit && <CustomerModal editAffiliate={affiliate} closeModal={setIsEdit} fetchAffiliates={fetchAffiliates} />}
    </div>
  )
}

export default Customer
