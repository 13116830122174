import { Category as CategoryAPI } from '../../../services'

// Async actions

const getById = async (categoryId) => {
  return await CategoryAPI.getById(categoryId)
}

const getAll = async () => {
  return await CategoryAPI.getAll()
}

const getCategoriesDropdown = async (payload) => {
  return await CategoryAPI.getCategoriesDropdown(payload)
}

const getCategories = async (payload) => {
  return await CategoryAPI.getCategories(payload)
}

const saveCategory = async (payload) => {
  return await CategoryAPI.saveCategory(payload)
}

const updateCategory = async (payload) => {
  return await CategoryAPI.updateCategory(payload)
}

const deleteCategory = async (categoryId) => {
  return await CategoryAPI.deleteCategory(categoryId)
}

export { getById, getAll, getCategoriesDropdown, getCategories, saveCategory, updateCategory, deleteCategory }
