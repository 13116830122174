import Product from './Product/Product'
import ProductEdit from './Product/ProductEdit'
import Supplier from './Supplier/Supplier'
import SupplierCreate from './Supplier/SupplierCreate'
import SupplierEdit from './Supplier/SupplierEdit'
import Banner from './Banner/Banner'
import Affiliate from './Affiliate/Affiliate'
import News from './News/News'
import Question from './Question/Question'
import Coupon from './Coupon/Coupon'
import Order from './Order/Order'
import Category from './Category/Category'
import Customer from './Customer/Customer'
import Warehouse from './Warehouse/Warehouse'
import WarehouseDispatch from './Warehouse/WarehouseDispatch'
import WarehouseReceiving from './Warehouse/WarehouseReceiving'
import FeaturedProduct from './Product/FeaturedProduct'
import Promotion from './Promotion/Promotion'
import ProductPromotion from './Product/ProductPromotion'
import Blog from './Blog/Blog'
import Level from './settings/Level'
import LevelGroup from './settings/LevelGroup'
import ProductProperty from './settings/ProductProperty'

export const ecoComponent = {
  Product,
  ProductEdit,
  Supplier,
  SupplierCreate, 
  SupplierEdit,
  Banner,
  Affiliate,
  News,
  Question,
  Coupon,
  Order,
  Category,
  Customer,
  Warehouse,
  WarehouseDispatch,
  WarehouseReceiving,
  FeaturedProduct,
  Promotion,
  ProductPromotion,
  Blog,
  Level,
  LevelGroup,
  ProductProperty
}
