import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class CategoryAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.CATEGORY)
    this.adapter = this.initAdapter(conf)
  }

  async getAll() {
    return await this.adapter.getAsync(`getAll`)
  }

  async getById(categoryId) {
    return await this.adapter.getAsync(`getById?categoryId=${categoryId}`)
  }

  async getCategoriesDropdown(payload) {
    return await this.adapter.getAsync('getCategoriesDropdown', { payload })
  }

  async getCategories(payload) {
    return await this.adapter.postAsync('getCategories', { payload })
  }

  async saveCategory(payload) {
    return await this.adapter.postAsync('saveCategory', { payload })
  }

  async updateCategory(payload) {
    return await this.adapter.putAsync('updateCategory', { payload })
  }

  async deleteCategory(categoryId) {
    return await this.adapter.deleteAsync(`deleteCategory?categoryId=${categoryId}`)
  }
}

export default CategoryAPI
