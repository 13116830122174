import { useIntl, FormattedMessage } from 'react-intl'
import Modal from '../../components/Modal'
import { useEffect, useRef, useState } from 'react'
import { ControlType, FormControl } from '../../components/FormControl'
import Button from '../../components/Button'
import _ from 'lodash'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import { saveAffiliate, updateAffiliate, getByAffiliateCode, ignoreAffiliate, approveAffiliate } from '../../../../redux/actions/aff/affiliateActions'
import { bindValidationMessages } from '../../../../utilities/validationMessage'
import { AffiliateStatusEnum } from '../../../../utilities/enums'
import Confirm from '../../components/Confirm'
import Loading from '../../components/Loading'
import { GenderEnum } from '../../../../utilities/enums'

const AffiliateModal = ({ editAffiliate, closeModal, fetchAffiliates }) => {
  const intl = useIntl()
  const componentRefs = useRef({})

  const validationMessage = bindValidationMessages()

  const [approveStatusId, setApproveStatusId] = useState(editAffiliate?.approveStatusId ?? null)
  const [affiliateName, setAffiliateName] = useState(editAffiliate?.affiliateName ?? null)
  const [birthday, setBirthday] = useState(editAffiliate?.birthday != null ? new Date(editAffiliate?.birthday) : null)
  const [genderId, setGenderId] = useState(editAffiliate?.genderId ?? null)
  const [isVietnamese, setIsVietnamese] = useState(editAffiliate?.isVietnamese ?? true)
  const [phone, setPhone] = useState(editAffiliate?.phone ?? null)
  const [email, setEmail] = useState(editAffiliate?.email ?? null)
  const [address, setAddress] = useState(editAffiliate?.address ?? null)
  const [affiliateParentCode, setAffiliateParentCode] = useState(editAffiliate?.affiliateParentCode ?? null)
  const [parrentAfffiliateId, setParrentAfffiliateId] = useState(editAffiliate?.parrentAfffiliateId ?? null)
  const [affiliateParentName, setAffiliateParentName] = useState(editAffiliate?.affiliateParentName ?? null)
  const [isActive, setIsActive] = useState(editAffiliate?.isActive ?? true)

  const [confirmApproveVisible, setConfirmApproveVisible] = useState(false)
  const [confirmIgnoreVisible, setConfirmIgnoreVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [genders] = useState(
    Object.keys(GenderEnum).map((key) => ({
      value: GenderEnum[key],
      name: <FormattedMessage id={`Enum.${key}`} />,
    }))
  )

  const validationAffiliate = async () => {
    const { result } = await getByAffiliateCode(editAffiliate?.id, affiliateParentCode)

    if (_.isEmpty(result)) {
      setParrentAfffiliateId(null)
      setAffiliateParentName('')
      return { error: true, message: validationMessage.invalid }
    } else {
      const aff = result
      setParrentAfffiliateId(aff.id)
      setAffiliateParentName(aff.affiliateName)
    }
  }

  const validationAllComponents = async () => {
    let isValid = true

    await _.map(componentRefs.current, async (ref) => {
      if (!(await ref.validateData())) {
        isValid = false
      }
    })

    return isValid
  }

  const handleSave = async (e) => {
    e.preventDefault()

    if (!(await validationAllComponents())) {
      return
    }
    setIsLoading(true)

    try {
      const affSave = {
        affiliateName,
        birthday,
        genderId,
        isVietnamese,
        phone,
        email,
        address,
        parrentAfffiliateId,
        id: editAffiliate?.id,
        isActive: editAffiliate.isAdded ? true : isActive,
        isAffiliate: true,
      }

      const { error } = editAffiliate.isAdded ? await saveAffiliate(affSave) : await updateAffiliate(affSave)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        fetchAffiliates()
        closeModal()
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
        console.log(error)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const confirmApprove = (e) => {
    e.preventDefault()
    setConfirmApproveVisible(true)
  }

  const handleApprove = async () => {
    setIsLoading(true)

    try {
      const { error, result } = await approveAffiliate(editAffiliate?.id)
      if (_.isEmpty(error)) {
        setApproveStatusId(result.approveStatusId)
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        setConfirmApproveVisible(false)
        fetchAffiliates()
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const confirmIgnore = (e) => {
    e.preventDefault()
    setConfirmIgnoreVisible(true)
  }

  const handleIgnore = async () => {
    setIsLoading(true)

    try {
      const { error, result } = await ignoreAffiliate(editAffiliate?.id)
      if (_.isEmpty(error)) {
        setApproveStatusId(result.approveStatusId)
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        setConfirmIgnoreVisible(false)
        fetchAffiliates()
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    setConfirmApproveVisible(false)
    setConfirmIgnoreVisible(false)
  }

  return (
    <Modal title={intl.formatMessage({ id: editAffiliate.isAdded ? 'AddNew' : 'Update' })} closeModal={closeModal} modalSize='mw-650px'>
      <form id='kt_modal_add_form' className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.affiliateName = ref)}
                tabIndex={1}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.Label.AffiliateName' })}
                value={affiliateName}
                isRequired={true}
                onChange={(e) => setAffiliateName(e.target.value)}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.birthday = ref)}
                tabIndex={2}
                controlType={ControlType.DatePicker}
                label={intl.formatMessage({ id: 'Affiliate.Label.Birthday' })}
                value={birthday}
                isRequired={true}
                onChange={(date) => setBirthday(date)}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.genderId = ref)}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Affiliate.Label.Gender' })}
                value={genderId}
                isRequired={true}
                onChange={(e) => setGenderId(e.value)}
                options={genders}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.phone = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.Label.Phone' })}
                value={phone}
                isRequired={true}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.email = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.Label.Email' })}
                value={email}
                isEmail={true}
                isRequired={true}
                onChange={(e) => setEmail(e.target.value)}
                readOnly={!editAffiliate.isAdded}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.address = ref)}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Affiliate.Label.Address' })}
                value={address}
                isRequired={true}
                onChange={(e) => setAddress(e.target.value)}
                rows={3}
              />
            </div>

            <div className='col-md-12 mb-3 p-0'>
              <FormControl
                ref={(ref) => (componentRefs.current.isVietnamese = ref)}
                tabIndex={6}
                controlType={ControlType.Checkbox}
                label={intl.formatMessage({ id: 'Affiliate.Label.IsVietnamese' })}
                value={isVietnamese}
                onChange={(e) => setIsVietnamese(e.target.checked)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.affiliateParentCode = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.Label.AfflicateCode' })}
                value={affiliateParentCode}
                onChange={(e) => setAffiliateParentCode(e.target.value)}
                validator={validationAffiliate}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.affiliateParentName = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.Label.AffiliateParentName' })}
                value={affiliateParentName}
                readOnly={true}
              />
            </div>

            {!editAffiliate.isAdded && (
              <div className='col-md-12 mb-3 p-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  tabIndex={6}
                  controlType={ControlType.Checkbox}
                  label={intl.formatMessage({ id: 'Affiliate.Label.IsActive' })}
                  value={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  isFromSwitch={true}
                />
              </div>
            )}
          </div>
        </div>

        <div className='text-center p-4'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={8}>
            <i className='fa fa-times'></i>
            <FormattedMessage id='Close' />
          </Button>

          <Button
            onClick={(e) => handleSave(e)}
            title={intl.formatMessage({ id: editAffiliate.isAdded ? 'Save' : 'Update' })}
            className={'btn btn-primary me-3'}
            tabIndex={9}
          >
            <i className='fa fa-save'></i>
            <FormattedMessage id='Save' />
          </Button>

          {approveStatusId === AffiliateStatusEnum.PendingApproval && (
            <>
              <Button
                onClick={(e) => confirmApprove(e)}
                title={intl.formatMessage({ id: 'Approve' })}
                className='btn btn-success me-3'
                icon='fa-check-double'
              />
              <Button onClick={(e) => confirmIgnore(e)} title={intl.formatMessage({ id: 'Ignore' })} className='btn btn-danger' icon='fa-xmark' />
            </>
          )}
        </div>

        {isLoading && <Loading />}
      </form>
      {confirmApproveVisible && (
        <Confirm
          onConfirm={handleApprove}
          onCancel={handleCancel}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Confirm' })}
        />
      )}

      {confirmIgnoreVisible && (
        <Confirm
          onConfirm={handleIgnore}
          onCancel={handleCancel}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Ignore' })}
        />
      )}
    </Modal>
  )
}

export default AffiliateModal
