import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Auth } from '../../../components/auth/Auth'
import { getUserById } from '../../../redux/actions/auth/userActions'

const Overview = () => {
  const intl = useIntl()
  const currentUser = Auth.getUserData() || {}

  const [user, setUser] = useState({})

  useEffect(() => {
    fetchUser()
  }, [])

  const fetchUser = async () => {
    try {
      const { result } = await getUserById({ id: currentUser?.id })
      if (result) {
        setUser(result)
        console.log(result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>
            <FormattedMessage id='User.Profile.ProfileDetail' />
          </h3>
        </div>

        <Link to='/user/user-profile/settings' className='btn btn-primary align-self-center'>
          <FormattedMessage id='User.Profile.EditProfile' />
        </Link>
      </div>

      <div className='card-body p-9'>
        <div className='row mb-4'>
          <label className='col-lg-4 fw-bold text-muted'>
            <FormattedMessage id='User.Profile.FullName' />
          </label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>{user?.fullName}</span>
          </div>
        </div>

        <div className='row mb-4'>
          <label className='col-lg-4 fw-bold text-muted'>
            <FormattedMessage id='User.Profile.Position' />
          </label>

          <div className='col-lg-8'>
            <span className='fw-bolder fs-6 text-dark'>{user?.roleName}</span>
          </div>
        </div>

        <div className='row mb-4'>
          <label className='col-lg-4 fw-bold text-muted'>
            <FormattedMessage id='User.Profile.Email' />
          </label>

          <div className='col-lg-8 d-flex align-items-center'>
            <span className='fw-bolder fs-6 me-2'>{user?.email}</span>
          </div>
        </div>

        <div className='row mb-4'>
          <label className='col-lg-4 fw-bold text-muted'>
            <FormattedMessage id='User.Profile.PhoneNumber' />
          </label>

          <div className='col-lg-8 d-flex align-items-center'>
            <span className='fw-bolder fs-6 me-2'>{user?.PhoneNumber}</span>
          </div>
        </div>

        <div className='row mb-4'>
          <label className='col-lg-4 fw-bold text-muted'>
            <FormattedMessage id='User.Profile.Company' />
          </label>

          <div className='col-lg-8 fv-row'>
            <span className='fw-bold fs-6'>{user?.company}</span>
          </div>
        </div>

        <div className='row mb-4'>
          <label className='col-lg-4 fw-bold text-muted'>
            <FormattedMessage id='User.Profile.Website' />
          </label>

          <div className='col-lg-8'>
            <a href='/' className='fw-bold fs-6 text-dark text-hover-primary'>
              {user?.website}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview
