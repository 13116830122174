import { Warehouse as WarehouseApi } from '../../../services'

// Async actions

const getWarehouses = async (payload) => {
  return await WarehouseApi.getWarehouses(payload)
}

const getWarehouseById = async (warehouseId) => {
  return await WarehouseApi.getWarehouseById(warehouseId)
}

const saveWarehouse = async (payload) => {
  return await WarehouseApi.saveWarehouse(payload)
}

const updateWarehouse = async (payload) => {
  return await WarehouseApi.updateWarehouse(payload)
}

const getWarehousesBySupplierId = async (supplierId) => {
    return await WarehouseApi.getWarehousesBySupplierId({ supplierId })
}

const deleteWarehouse = async (warehouseId) => {
  return await WarehouseApi.deleteWarehouse(warehouseId)
}

const getAdminWarehouse = async (payload) => {
  return await WarehouseApi.getAdminWarehouse(payload)
}

const saveWarehouseSupplier = async (payload) => {
  return await WarehouseApi.saveWarehouseSupplier(payload)
}

const getWarehouseCode = async () => {
  return await WarehouseApi.getWarehouseCode()
}

export { getWarehousesBySupplierId, getWarehouses, getWarehouseById, saveWarehouse, updateWarehouse, deleteWarehouse, getAdminWarehouse, saveWarehouseSupplier, getWarehouseCode }
