import { Feature as FeatureAPI } from '../../../services'

const getAllModulesWithFeatures = async () => {
  return await FeatureAPI.getAllModulesWithFeatures()
}

const getFeauturesByUserId = async (payload) => {
  return await FeatureAPI.getFeauturesByUserId(payload)
}

export { getFeauturesByUserId, getAllModulesWithFeatures }


