/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import TableSearch from '../../components/TableSearch'
import Checkbox from '../../components/Checkbox'
import Table from '../../components/Table'
import Loading from '../../components/Loading'
import LevelModal from './LevelModal'
import Confirm from '../../components/Confirm'
import { getAll, softDelete } from '../../../../redux/actions/aff/LevelActions'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import NumberBox from '../../components/NumberBox'

const Level = () => {
  const intl = useIntl()

  const [isLoading, setIsLoading] = useState(false)
  const [items, setItems] = useState([])
  const [itemOriginals, setItemOriginals] = useState([])
  const [selectedItem, setSelectedItem] = useState({})

  const [isEdit, setIsEdit] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  useEffect(() => {
    fetchItems()
  }, [])

  const fetchItems = async () => {
    setIsLoading(true)
    try {
      const { result } = await getAll()
      if (result) {
        setItems(result)
        setItemOriginals(result)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCreate = () => {
    setSelectedItem(null)
    setIsEdit(true)
  }

  const handleEdit = (item) => {
    setSelectedItem(item)
    setIsEdit(true)
  }

  const handleConfirmRemove = (item) => {
    setSelectedItem(item)
    setConfirmDelete(true)
  }

  const handleRemove = async () => {
    setIsLoading(true)

    try {
      const { error } = await softDelete(selectedItem?.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        fetchItems()
        setConfirmDelete(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'Level.Column.LevelNumber' })}`,
      accessor: 'levelNumber',
      width: 40,
    },
    {
      Header: `${intl.formatMessage({ id: 'Level.Column.LevelName' })}`,
      accessor: 'fullName',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Level.Column.TotalAmount' })}`,
      accessor: 'totalAmount',
      width: 80,
    },
    {
      Header: `${intl.formatMessage({ id: 'Level.Column.MaintainForMonths' })}`,
      accessor: 'maintainForMonths',
      width: 60,
    },
    {
      Header: `${intl.formatMessage({ id: 'Level.Column.MonthInviteCount' })}`,
      accessor: 'monthInviteCount',
      width: 60,
    },
    {
      Header: `${intl.formatMessage({ id: 'Action' })}`,
      accessor: 'action',
      width: 60,
    },
  ]

  const rows = items.map((item) => {
    return {
      levelNumber: `${intl.formatMessage({ id: 'Level.Column.LevelNumber' })} ${item.levelNumber}`,
      levelCode: item.levelCode,
      levelName: item.levelName,
      fullName: item.fullName,
      description: item.description,
      totalAmount: <NumberBox type='text' value={item.totalAmount} className='' suffix='đ' />,
      maintainForMonths: item.maintainForMonths,
      monthInviteCount: item.monthInviteCount,
      isActive: <Checkbox checked={item.isActive} readOnly className='justify-content-center' />,
      action: (
        <div className='d-flex justify-content-center flex-shrink-0'>
          <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm me-1'>
            <i className='fa-solid fa-pen-to-square' />
          </a>
          <a onClick={() => handleConfirmRemove(item)} className='btn btn-icon btn-light-danger btn-sm'>
            <i className='fa-solid fa-trash' />
          </a>
        </div>
      ),
    }
  })

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='card-title'>
          <a onClick={() => handleCreate()} className='btn btn-sm btn-success me-3'>
            <i className='ki-outline ki-plus fs-2'></i> {intl.formatMessage({ id: 'AddNew' })}
          </a>
        </div>
        <div className='card-toolbar'>
          <TableSearch onSearch={setItems} originalData={itemOriginals} />
        </div>
      </div>

      <div className='card-body py-2 pt-0'>
        <div className='table-responsive'>
          <Table columns={columns} data={rows} />
          {isLoading && <Loading />}
        </div>
      </div>

      {isEdit && <LevelModal item={selectedItem} closeModal={setIsEdit} fetchItems={fetchItems} currentLevel={items} />}

      {confirmDelete && (
        <Confirm
          onConfirm={handleRemove}
          onCancel={() => setConfirmDelete(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}
    </div>
  )
}

export default Level
