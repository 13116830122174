import ReactApexChart from 'react-apexcharts'

const BarChart = ({ dataColumn, height }) => {
  return (
    <div id="columnChart">
      <ReactApexChart options={dataColumn.options} series={dataColumn.series} type="bar" height={height} />
    </div>
  )
}

export default BarChart
