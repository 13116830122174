import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import TableSearch from '../../components/TableSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import _ from 'lodash'
import { getQuestions } from '../../../../redux/actions/eco/questionActions'

const Question = () => {
  const intl = useIntl()
  const [questions, setQuestions] = useState([])
  const [questionOriginals, setQuestionOriginals] = useState([])
  const [question, setQuestion] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const fetchQuestions = async () => {
    setIsLoading(true)
    try {
      const { result } = await getQuestions({ pageIndex: currentPage, pageSize: pageSize })
      if (result) {
        setQuestions(result.items)
        setQuestionOriginals(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchQuestions()
  }, [currentPage, pageSize])

  const columns = getQuestionColumns(intl)
  const rows = questions.map((item) => {
    return {
      fullName: item.fullName,
      email: item.email,
      phoneNumber: item.phoneNumber,
      questionDate: item.questionDateStr,
      questionContent: item.questionContent,
      action: (
        <div className="d-flex justify-content-center flex-shrink-0">
          <a onClick={() => handleEdit(item)} className="btn btn-icon btn-light-success btn-sm me-1">
            <i className="fa-solid fa-pen-to-square" />
          </a>
        </div>
      ),
    }
  })

  const handleEdit = (item) => {
    setQuestion(item)
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize)
  }

  const handleSearch = (query) => {
    query = query.trim().toLowerCase()
    if (!_.isEmpty(query)) {
      const filteredData = questionOriginals.filter((item) => item.link.toLowerCase().includes(query))
      setQuestions(filteredData)
    } else {
      setQuestions(questionOriginals)
    }
  }

  return (
    <div className="card mb-5 mb-xl-8">
      <div className="card-header border-0 pt-5">
        <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to add a Question">
          <TableSearch onSearch={handleSearch} />
        </div>
      </div>

      <div className="card-body py-3">
        <div className="table-responsive">
          <Table columns={columns} data={rows} />
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handlePageSizeChange}
          />
          {isLoading && <Loading />}
        </div>
      </div>
    </div>
  )
}

const getQuestionColumns = (intl) => {
  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'Question.Column.FullName' })}`,
      accessor: 'fullName',
      width: '50px',
    },
    {
      Header: `${intl.formatMessage({ id: 'Question.Column.Email' })}`,
      accessor: 'email',
      width: '20px',
    },
    {
      Header: `${intl.formatMessage({ id: 'Question.Column.PhoneNumber' })}`,
      accessor: 'phoneNumber',
      width: '10px',
    },
    {
      Header: `${intl.formatMessage({ id: 'Question.Column.QuestionDate' })}`,
      accessor: 'questionDate',
      width: '10px',
    },
    {
      Header: `${intl.formatMessage({ id: 'Question.Column.QuestionContent' })}`,
      accessor: 'questionContent',
    },
    {
      Header: `${intl.formatMessage({ id: 'Question.Column.Action' })}`,
      accessor: 'action',
      width: '10px',
    },
  ]

  return columns
}

export default Question
