import { useState, useRef, useEffect } from 'react'
import { Icon } from '../../../../helpers'
import { useIntl } from 'react-intl'
import { ControlType, FormControl } from '../../components/FormControl'
import { getSupplierApprovedByUserId } from '../../../../redux/actions/eco/supplierActions'
import { getCategoriesDropdown } from '../../../../redux/actions/eco/categoryActions'
import { getSubCategoriesDropdown } from '../../../../redux/actions/eco/subCategoryActions'

const ProductFilter = ({ fetchData }) => {
  const intl = useIntl()
  const filterMenuRef = useRef(null)
  const componentRefs = useRef({})
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)

  const [categoryId, setCategoryId] = useState(null)
  const [subCategoryId, setSubCategoryId] = useState(null)
  const [groupId, setGroupId] = useState(null)
  const [productCode, setProductCode] = useState(null)
  const [productName, setProductName] = useState(null)
  const [supplierId, setSupplierId] = useState(null)

  const [suppliers, setSuppliers] = useState([])
  const [categories, setCategories] = useState([])
  const [allSubCategories, setAllSubCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [groups, setGroups] = useState([])

  useEffect(() => {
    fetchCombobox()
  }, [])

  useEffect(() => {
    let data = allSubCategories.filter((x) => x.fkId === categoryId && !x.parentId)
    setSubCategories(data)
    if (!data.some((x) => x.value === subCategoryId)) {
      setGroups([])
      componentRefs.current.subCategoryId?.clearValue()
      componentRefs.current.groupId?.clearValue()
    }
  }, [categoryId])

  useEffect(() => {
    let data = allSubCategories.filter((x) => x.fkId === categoryId && x.parentId === subCategoryId)
    setGroups(data)
    if (!data.some((x) => x.value === groupId)) {
      componentRefs.current.groupId?.clearValue()
    }
  }, [subCategoryId])

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterMenuRef.current && !filterMenuRef.current.contains(event.target) && isOpenDropdown) {
        setIsOpenDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpenDropdown])

  const toggleFilter = () => {
    setIsOpenDropdown(!isOpenDropdown)
  }

  const fetchCombobox = async () => {
    try {
      const { result: supplierResult } = await getSupplierApprovedByUserId()
      if (supplierResult) {
        setSuppliers(supplierResult)
      }

      //Category
      const { result: categoryResult } = await getCategoriesDropdown()
      if (categoryResult) {
        setCategories(categoryResult)
      }

      //SubCategory
      const { result: subCategoryResult } = await getSubCategoriesDropdown()
      if (subCategoryResult) {
        setAllSubCategories(subCategoryResult)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleApply = () => {
    setIsOpenDropdown(!isOpenDropdown)
    fetchData(productCode, productName, supplierId, categoryId, subCategoryId, groupId)
  }

  const handleReset = () => {
    setProductCode('')
    setProductName('')

    componentRefs.current.categoryId.clearValue()
    componentRefs.current.subCategoryId.clearValue()
    componentRefs.current.groupId.clearValue()
    componentRefs.current.supplierId.clearValue()
  }

  return (
    <div style={{ position: 'relative', display: 'inline-block' }} ref={filterMenuRef}>
      <a onClick={toggleFilter} className='btn btn-sm btn-light-info'>
        <Icon iconName='filter' className='fs-3' />
        {intl.formatMessage({ id: 'Filter' })}
      </a>

      {isOpenDropdown && (
        <div className='filter-options'>
          <div className='w-300px w-md-325px'>
            <div className='px-7 py-2 d-flex justify-content-between align-items-center'>
              <div className='fs-5 text-dark fw-bold'>{intl.formatMessage({ id: 'FilterOptions' })}</div>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-customers-modal-action='close'
                onClick={toggleFilter}
                style={{ cursor: 'pointer' }}
              >
                <Icon iconName='cross' className='fs-1' />
              </div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-4 gap-5'>
              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.productCode = ref)}
                  tabIndex={1}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({
                    id: 'Product.Column.ProductCode',
                  })}
                  value={productCode}
                  onChange={(e) => setProductCode(e.target.value)}
                />
              </div>

              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.productName = ref)}
                  tabIndex={2}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({
                    id: 'Product.Column.ProductName',
                  })}
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </div>

              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.categoryId = ref)}
                  tabIndex={9}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({
                    id: 'Product.Column.Category',
                  })}
                  value={categoryId}
                  options={categories}
                  isClearable={false}
                  onChange={(e) => setCategoryId(e.value)}
                />
              </div>

              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.subCategoryId = ref)}
                  tabIndex={10}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({ id: 'Product.Column.SubCategory' })}
                  value={subCategoryId}
                  options={subCategories}
                  isClearable={false}
                  onChange={(e) => setSubCategoryId(e.value)}
                />
              </div>

              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.groupId = ref)}
                  tabIndex={10}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({ id: 'Product.Column.Group' })}
                  value={groupId}
                  options={groups}
                  isClearable={false}
                  onChange={(e) => setGroupId(e.value)}
                />
              </div>

              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.supplierId = ref)}
                  tabIndex={3}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({
                    id: 'Product.Column.Supplier',
                  })}
                  value={supplierId}
                  options={suppliers}
                  isClearable={false}
                  onChange={(e) => setSupplierId(e.value)}
                />
              </div>

              <div className='d-flex justify-content-end gap-3'>
                <a type='reset' className='btn btn-light btn-active-light-primary btn-sm' onClick={handleReset}>
                  <i className='fa fa-eraser' aria-hidden='true'></i>
                  {intl.formatMessage({ id: 'Reset' })}
                </a>
                <a type='submit' className='btn btn-success btn-sm' onClick={handleApply}>
                  <i className='fa fa-check' aria-hidden='true'></i>
                  {intl.formatMessage({ id: 'Apply' })}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductFilter
