import React, { forwardRef } from 'react'

const Input = forwardRef((props, ref) => {
  const { type, value, placeholder, onChange, className, tabIndex, onBlur, readOnly, isValid, errorMessage } = props
  return (
    <>
      <input
        ref={ref}
        type={type ?? 'text'}
        value={value ?? ''}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        className={className ?? 'form-control mb-3 mb-lg-0 px-2'}
        tabIndex={tabIndex ?? 0}
        readOnly={readOnly}
        data-isvalid={isValid}
      />
      {isValid === false && (
        <p className="mt-0 mb-0" style={{ color: 'red', whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default Input
