/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from 'react'
import BackButton from '../../components/BackButton'
import SaveButton from '../../components/SaveButton'
import { useIntl, FormattedMessage } from 'react-intl'
import { ControlType, FormControl } from '../../components/FormControl'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { getProductCode, updateProduct, getById, saveProduct, deleteProduct, approveProduct } from '../../../../redux/actions/eco/productActions'
import { getSupplierApprovedByUserId } from '../../../../redux/actions/eco/supplierActions'
import { getCategoriesDropdown } from '../../../../redux/actions/eco/categoryActions'
import { getSubCategoriesDropdown } from '../../../../redux/actions/eco/subCategoryActions'
import { getWarehousesBySupplierId } from '../../../../redux/actions/eco/warehouseActions'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import { useParams, useNavigate } from 'react-router-dom'
import useGetFiles from '../../../../helpers/useGetFiles'
import { DEFAULT_VALUE, FILE_TYPE, TABLE_NAME } from '../../../../utilities/constant'
import { bindValidationMessages } from '../../../../utilities/validationMessage'
import { formatString } from '../../../../utilities/shareFunction'
import { uploadFiles } from '../../../../redux/actions/gnr/fileAttachmentActions'
import _ from 'lodash'
import ProductRating from './ProductRating'
import Receiving from './Receiving'
import Dispatch from './Dispatch'
import ReceivingModel from './ReceivingModel'
import { DiscountTypeEnum, ProductStatusEnum } from '../../../../utilities/enums'
import Table from '../../components/Table'
import { Auth } from '../../../auth/Auth'
import { ROLE_NAME } from '../../../../utilities/constant'
import Confirm from '../../components/Confirm'
import Button from '../../components/Button'
import { getAllStandardProductProperty } from '../../../../redux/actions/gnr/productPropertyActions'

const ProductEdit = () => {
  const componentRefs = useRef({})
  const intl = useIntl()
  const { productId } = useParams()
  const navigate = useNavigate()
  const validationMessage = bindValidationMessages()
  const [isValid, setIsValid] = useState(true)
  const [errorMessageImageUrl, setErrorMessageImageUrl] = useState('')
  const currentUser = Auth.getUserData() || {}
  const [showReceivingModel, setShowReceivingModel] = useState(false)

  //dropdown
  const [suppliers, setSuppliers] = useState([])
  const [categories, setCategories] = useState([])
  const [allSubCategories, setAllSubCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [groups, setGroups] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [standardProductProperties, setStandardProductProperties] = useState([])
  const [confirmReject, setConfirmReject] = useState(false)
  const [confirmApprove, setConfirmApprove] = useState(false)
  const [status] = useState(
    Object.keys(ProductStatusEnum).map((key) => ({
      value: ProductStatusEnum[key],
      name: <FormattedMessage id={`Enum.${key}`} />,
    }))
  )

  //Product
  const [logo, setLogo] = useGetFiles(TABLE_NAME.PRODUCT, productId, FILE_TYPE.PRODUCT_MAIN_IMAGE)
  const [productCode, setProductCode] = useState(null)
  const [productName, setProductName] = useState(null)
  const [description, setDescription] = useState(null)
  const [usage, setUsage] = useState(null)
  const [productCertificate, setProductCertificate] = useGetFiles(TABLE_NAME.PRODUCT, productId, FILE_TYPE.PRODUCT_FILE_ATTACHMENT)
  const [productImages, setProductImages] = useGetFiles(TABLE_NAME.PRODUCT, productId, FILE_TYPE.PRODUCT_SUB_IMAGE)
  const [basePrice, setBasePrice] = useState(null)
  const [commissionMoney, setCommissionMoney] = useState(null)
  const [supplierCommission, setSupplierCommission] = useState(null)
  const [discountType, setDiscountType] = useState(DiscountTypeEnum.NoDiscount)
  const [discountedPrice, setDiscountedPrice] = useState(null)
  const [discountedPercent, setDiscountedPercent] = useState(0)
  const [warehouseId, setWarehouseId] = useState(null)
  const [qtyRemain, setQtyRemain] = useState(null)
  const [supplierId, setSupplierId] = useState(null)
  const [productStatus, setProductStatus] = useState(ProductStatusEnum.PendingApproval)
  const [categoryId, setCategoryId] = useState(null)
  const [subCategoryId, setSubCategoryId] = useState(null)
  const [groupSelected, setGroupSelected] = useState([])
  const [propertiesSelected, setPropertiesSelected] = useState([])

  const [countProperties, setCountProperties] = useState(1)
  const [propertyRows, setPropertyRows] = useState([])
  const [propertyColumns, setPropertyColumns] = useState([
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.Property' })}`,
      accessor: 'property',
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.PropertyValue' })} ${countProperties}`,
      accessor: 'propertyValue1',
      width: 50,
      editable: true,
    },
  ])

  useEffect(() => {
    const fetchData = async () => {
      await loadCombobox()
      await loadProduct()
    }
    fetchData()
  }, [productId])

  useEffect(() => {
    const findMaxProperties = (data) => {
      return data.reduce((max, item) => {
        const length = item.details ? item.details.length : 0
        return length > max ? length : max
      }, 0)
    }
    const max = findMaxProperties(propertiesSelected)
    if (countProperties < max) {
      handleAddPropertyColumns(max - countProperties)
    }

    const selectedIds = propertiesSelected.map((property) => property.value)
    const removedRows = propertyRows.filter((row) => !selectedIds.includes(row.id))
    const addedRows = propertiesSelected.filter((item) => !propertyRows.find((row) => row.id === item.value)).map((item) => createNewRow(item))

    setPropertyRows((prevRows) => {
      let updatedRows = [...prevRows]

      removedRows.forEach((row) => {
        updatedRows = updatedRows.filter((r) => r.id !== row.id)
      })

      updatedRows.push(...addedRows)

      return updatedRows
    })
  }, [propertiesSelected])

  useEffect(() => {
    let data = allSubCategories.filter((x) => x.fkId === categoryId && !x.parentId)
    setSubCategories(data)
    if (!data.some((x) => x.value === subCategoryId)) {
      componentRefs.current.subCategoryId.clearValue()
      componentRefs.current.groupSelected.clearValue()
      setGroups([])
    }
  }, [categoryId])

  useEffect(() => {
    let data = allSubCategories.filter((x) => x.fkId === categoryId && x.parentId === subCategoryId)
    setGroups(
      data.map((item) => ({
        value: item.value,
        label: item.name,
      }))
    )
    if (!groupSelected.some((selected) => data.some((item) => item.value === selected.value))) {
      componentRefs.current.groupSelected.clearValue()
    }
  }, [subCategoryId])

  const loadProduct = async () => {
    try {
      const { result: productResult } = await getById(productId)
      if (!_.isNil(productResult)) {
        setProductCode(productResult.productCode)
        setProductName(productResult.productName)
        setDescription(productResult.description)
        setUsage(productResult.usage)
        setBasePrice(productResult.basePrice)
        setCommissionMoney(productResult.commissionMoney)
        setSupplierCommission(productResult.supplierCommission)
        setDiscountType(productResult.discountType)
        setDiscountedPrice(productResult.discountedPrice)
        setDiscountedPercent(productResult.discountedPercent)
        setQtyRemain(productResult.qtyRemain)
        setSupplierId(productResult.supplierId)
        setProductStatus(productResult.productStatus)
        setWarehouseId(productResult.warehouseId)

        if (productResult && productResult.productPropperties && productResult.productPropperties.length > 0) {
          setCountProperties(productResult.productPropperties[0].productPropertyDetails.length)

          setPropertyColumns([
            {
              Header: `${intl.formatMessage({ id: 'Product.Column.Property' })}`,
              accessor: 'property',
            },
            ...productResult.productPropperties[0].productPropertyDetails.map((detail, index) => ({
              Header: `${intl.formatMessage({ id: 'Product.Column.PropertyValue' })} ${index + 1}`,
              accessor: `propertyValue${index + 1}`,
              width: 50,
              editable: true,
            })),
          ])

          setPropertiesSelected(
            productResult.productPropperties.map((item) => {
              return {
                value: item.id,
                label: item.name,
              }
            })
          )

          setPropertyRows(
            productResult.productPropperties.map((item) => {
              return {
                id: item.id,
                property: item.name,
                ...item.productPropertyDetails.reduce((acc, detail, index) => {
                  acc[`propertyValue${index + 1}`] = detail.value
                  return acc
                }, {}),
              }
            })
          )
        }

        setCategoryId(productResult.productCategory?.categoryId)
        setSubCategoryId(productResult.productCategory?.subCategoryId)
        setGroupSelected(
          productResult.productCategory?.groups?.map((x) => ({
            value: x.id,
            label: x.subCategoryName,
          }))
        )
      } else {
        if (productId !== DEFAULT_VALUE.GUID_EMPTY) {
          navigate(`/notFound`)
        }
        const { result: productCodeResult } = await getProductCode()
        if (productCodeResult) {
          setProductCode(productCodeResult)
        }
        setSupplierId(currentUser.roleName === ROLE_NAME.SUPPLIER ? currentUser.supplierId : null)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const loadCombobox = async () => {
    //suppliers
    const { result: supplierResult } = await getSupplierApprovedByUserId()
    if (supplierResult) {
      setSuppliers(supplierResult)
    }

    //Category
    const { result: categoryResult } = await getCategoriesDropdown()
    if (categoryResult) {
      setCategories(categoryResult)
    }

    //SubCategory
    const { result: subCategoryResult } = await getSubCategoriesDropdown()
    if (subCategoryResult) {
      setAllSubCategories(subCategoryResult)
    }

    //Warehouse
    const { result: warehouses } = await getWarehousesBySupplierId(currentUser.supplierId)
    if (warehouses) {
      setWarehouses(
        warehouses.map((item) => ({
          value: item.id,
          name: item.name,
        }))
      )
    }

    //Properties
    const { result: properties } = await getAllStandardProductProperty()
    if (properties) {
      setStandardProductProperties(
        properties.map((item) => {
          return {
            value: `${productId}-${item.name}`,
            label: item.name,
            details: item.productPropertyDetails,
          }
        })
      )
    }
  }

  const validateDataImage = (value, label) => {
    let hasValue = value !== null && value !== undefined && value !== '' && value.length > 0
    let error = false
    let message = []

    //Check required
    if (!hasValue) {
      error = true
      message = [...message, formatString(validationMessage.required, label)]
    }
    setIsValid(!error)
    setErrorMessageImageUrl(message.join('\n'))
    return !error
  }

  const handleChangeFileAttachment = (file) => {
    setProductCertificate(file)
  }

  const handleChangeImages = (file) => {
    setProductImages(file)
  }

  const handleSave = async () => {
    let isValid = await validationAllComponents(componentRefs)

    const isValidImageUrl = validateDataImage(logo, intl.formatMessage({ id: 'Product.Column.ImageUrl' }))
    isValid = isValidImageUrl && isValid

    if (!isValid) return

    const productSave = {
      id: productId,
      productCode,
      productName,
      description,
      basePrice,
      commissionMoney: currentUser.roleName !== ROLE_NAME.ADMIN && productId === DEFAULT_VALUE.GUID_EMPTY ? supplierCommission : commissionMoney,
      supplierCommission,
      discountType,
      discountedPrice,
      discountedPercent,
      supplierId,
      warehouseId,
      usage,
      productPropperties: propertyRows.map((item) => {
        return {
          productId: productId,
          name: item.property,
          productPropertyDetails: Object.entries(item)
            .filter(([key, propertyValue]) => key.startsWith('propertyValue'))
            .map(([key, propertyValue]) => ({
              value: propertyValue,
            })),
        }
      }),
      productStatus,
      productCategory: {
        categoryId,
        subCategoryId,
        groups: groupSelected.map((item) => ({
          id: item.value,
          subCategoryName: item.label,
        })),
      },
      logo: logo[0],
      productCertificate: productCertificate,
      productImages: productImages,
    }
    //save
    const { error, result: productResult } = productId === DEFAULT_VALUE.GUID_EMPTY ? await saveProduct(productSave) : await updateProduct(productSave)
    if (_.isEmpty(error) && productResult) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)

      if (productId === DEFAULT_VALUE.GUID_EMPTY) {
        navigate(`/eco/product/${productResult.id}/edit`)
      }
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  const handleImageChange = async (e) => {
    const selectedFiles = e.currentTarget.files
    var files = []
    if (selectedFiles) {
      const formData = new FormData()
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('files', selectedFiles[i])
      }
      const response = await uploadFiles(formData)
      if (response) {
        files.push(response[0])
        setLogo(files)
      }
    }
  }

  const handleAddPropertyColumns = (number) => {
    const newColumns = []

    for (let i = 1; i <= number; i++) {
      const newProperty = countProperties + i
      const accessor = `propertyValue${newProperty}`

      newColumns.push({
        Header: `${intl.formatMessage({ id: 'Product.Column.PropertyValue' })} ${newProperty}`,
        accessor: accessor,
        width: 50,
        editable: true,
      })
    }

    setPropertyColumns([...propertyColumns, ...newColumns])

    const updatedRows = propertyRows.map((row) => {
      let updatedRow = { ...row }
      newColumns.forEach((column) => {
        updatedRow[column.accessor] = ''
      })
      return updatedRow
    })

    setPropertyRows(updatedRows)
    setCountProperties(countProperties + number)
  }

  const handleRemovePropertyColumns = () => {
    if (propertyColumns.length > 2) {
      setPropertyColumns(propertyColumns.slice(0, -1))

      const lastColumnAccessor = propertyColumns[propertyColumns.length - 1].accessor
      const updatedRows = propertyRows.map((row) => {
        const { [lastColumnAccessor]: _, ...newRow } = row
        return newRow
      })

      setPropertyRows(updatedRows)

      setCountProperties(countProperties - 1)
    }
  }

  const createNewRow = (item) => {
    return [...Array(item.details?.length ?? countProperties).keys()].reduce(
      (acc, index) => {
        acc[`propertyValue${index + 1}`] = _.isEmpty(item.details) ? '' : item.details[index]?.value ?? ''
        return acc
      },
      { id: item.value, property: item.label }
    )
  }

  const handleCellValueChange = (cell, value) => {
    const updatedRows = [...propertyRows]
    const rowIndex = updatedRows.findIndex((row) => row.id === cell.row.original.id)

    if (rowIndex === -1) {
      return
    }
    updatedRows[rowIndex][cell.column.id] = value

    setPropertyRows(updatedRows)
  }

  const handleReject = async () => {
    if (productStatus !== ProductStatusEnum.PendingApproval || currentUser.roleName !== ROLE_NAME.ADMIN) return
    try {
      const { error } = await deleteProduct(productId)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
        setConfirmReject(false)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    }
  }

  const handleApprove = async () => {
    if (productStatus !== ProductStatusEnum.PendingApproval || currentUser.roleName !== ROLE_NAME.ADMIN) return
    try {
      const { error } = await approveProduct(productId)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
        setConfirmApprove(false)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <form id='add_product_form' className='form'>
      <div className='d-flex flex-column flex-lg-row mb-4'>
        <div className='d-flex flex-column gap-4 col-4 me-4'>
          <div className='card card-flush py-4'>
            <div className='card-header'>
              <div className='card-title required'>
                <h2>{intl.formatMessage({ id: 'Product.Column.ImageUrl' })}</h2>
              </div>
            </div>
            <div className='card-body text-center pt-0'>
              <div className='image-input image-input-empty image-input-outline image-input-placeholder mb-3' data-kt-image-input='true'>
                <div
                  className='image-input-wrapper w-150px h-150px'
                  style={{
                    backgroundImage: `url(${DEFAULT_VALUE.BASE_URL + logo[0]?.filePath + DEFAULT_VALUE.SLASH + logo[0]?.fileNameServer})`,
                  }}
                ></div>
                <label
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  title='Change avatar'
                >
                  <i className='ki-outline ki-pencil fs-7'></i>
                  <input type='file' name='avatar' accept='.png, .jpg, .jpeg' onChange={handleImageChange} />
                  <input type='hidden' name='avatar_remove' />
                </label>
              </div>
              <div className='text-muted fs-7'>{intl.formatMessage({ id: 'Product.ThumbnailNote' })}</div>
              {isValid === false && (
                <p className='mt-0 mb-0' style={{ color: 'red', whiteSpace: 'pre-line' }}>
                  {errorMessageImageUrl}
                </p>
              )}
            </div>
          </div>

          <div className='card card-flush py-4'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>
                  {intl.formatMessage({
                    id: 'Product.Title.ProductCategory',
                  })}
                </h2>
              </div>
            </div>
            <div className='card-body pt-0'>
              <div className='fv-row w-100 flex-md-root pb-4'>
                <FormControl
                  ref={(ref) => (componentRefs.current.categoryId = ref)}
                  tabIndex={9}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({
                    id: 'Product.Column.Category',
                  })}
                  value={categoryId}
                  options={categories}
                  isRequired={true}
                  onChange={(e) => setCategoryId(e.value)}
                />
              </div>
              <div className='fv-row w-100 flex-md-root pb-4'>
                <FormControl
                  ref={(ref) => (componentRefs.current.subCategoryId = ref)}
                  tabIndex={10}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({ id: 'Product.Column.SubCategory' })}
                  value={subCategoryId}
                  options={subCategories}
                  isRequired={true}
                  onChange={(e) => setSubCategoryId(e.value)}
                />
              </div>
              <div className='fv-row w-100 flex-md-root'>
                <FormControl
                  ref={(ref) => (componentRefs.current.groupSelected = ref)}
                  tabIndex={11}
                  controlType={ControlType.TagInput}
                  onChange={(e) => setGroupSelected(e)}
                  value={groupSelected}
                  label={intl.formatMessage({ id: 'Product.Column.Group' })}
                  options={groups}
                />
              </div>
            </div>
          </div>

          <div className='card card-flush py-4'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>
                  {intl.formatMessage({
                    id: 'Product.Column.Supplier',
                  })}
                </h2>
              </div>
            </div>
            <div className='card-body pt-0'>
              <div className='col-12'>
                <FormControl
                  ref={(ref) => (componentRefs.current.supplierId = ref)}
                  tabIndex={8}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({
                    id: 'Product.Column.Supplier',
                  })}
                  value={supplierId}
                  isRequired={true}
                  options={suppliers}
                  onChange={(e) => setSupplierId(e.value)}
                  isDisabled={!(currentUser.roleName === ROLE_NAME.ADMIN)}
                />
              </div>

              <div className='col-12 pt-4'>
                <FormControl
                  ref={(ref) => (componentRefs.current.warehouseId = ref)}
                  tabIndex={9}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({
                    id: 'Product.Column.Warehouse',
                  })}
                  value={warehouseId}
                  isRequired={true}
                  options={warehouses}
                  onChange={(e) => setWarehouseId(e.value)}
                />
              </div>

              <div className='col-12 pt-4 d-flex justify-content-between align-items-end'>
                <div className={productId !== DEFAULT_VALUE.GUID_EMPTY ? 'col-10' : 'col-12'}>
                  <FormControl
                    ref={(ref) => (componentRefs.current.qtyRemain = ref)}
                    tabIndex={10}
                    controlType={ControlType.NumberBox}
                    label={intl.formatMessage({
                      id: 'Product.Column.Inventory',
                    })}
                    value={qtyRemain}
                    type='number'
                    readOnly={true}
                  />
                </div>
                {productId !== DEFAULT_VALUE.GUID_EMPTY && (
                  <div className='col-2 ms-2'>
                    <a
                      className='btn btn-icon btn-success btn-sm w-100'
                      onClick={() => setShowReceivingModel(true)}
                      title={intl.formatMessage({ id: 'Menu.Eco.WarehouseReceiving' })}
                    >
                      <i className='fa-solid fa-plus'></i>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card card-flush py-4'>
            <div className='card-header'>
              <div className='card-title'>
                <h2>
                  {intl.formatMessage({
                    id: 'Product.Column.Status',
                  })}
                </h2>
              </div>
            </div>
            <div className='card-body pt-0'>
              <div className='col-12'>
                <FormControl
                  ref={(ref) => (componentRefs.current.productStatus = ref)}
                  tabIndex={11}
                  controlType={ControlType.Select}
                  label={intl.formatMessage({
                    id: 'Product.Column.Status',
                  })}
                  value={productStatus}
                  isRequired={true}
                  options={status.filter(
                    (x) => x.value === productStatus || (productStatus === ProductStatusEnum.Approved && x.value === ProductStatusEnum.StopSelling)
                  )}
                  isDisabled={productStatus === ProductStatusEnum.PendingApproval ? true : false}
                  onChange={(e) => setProductStatus(e.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column flex-row-fluid gap-4 col-8'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li className='nav-item'>
              <a className='nav-link text-active-primary active' data-bs-toggle='tab' href='#add_product_general'>
                {intl.formatMessage({ id: 'Product.Title.Product' })}
              </a>
            </li>
            {productId !== DEFAULT_VALUE.GUID_EMPTY && (
              <>
                <li className='nav-item'>
                  <a className='nav-link text-active-primary' data-bs-toggle='tab' href='#add_product_reviews'>
                    {intl.formatMessage({ id: 'Product.Title.Reviews' })}
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link text-active-primary' data-bs-toggle='tab' href='#add_product_receiving'>
                    {intl.formatMessage({ id: 'Menu.Eco.WarehouseReceiving' })}
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link text-active-primary' data-bs-toggle='tab' href='#add_product_dispatch'>
                    {intl.formatMessage({ id: 'Menu.Eco.WarehouseDispatch' })}
                  </a>
                </li>
              </>
            )}
          </ul>
          <div className='tab-content'>
            <div className='tab-pane fade show active' id='add_product_general' role='tab-panel'>
              <div className='d-flex flex-column gap-4'>
                <div className='card card-flush py-4'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h2>
                        {intl.formatMessage({ id: 'Product.Title.Product' })} {productCode ? `- ${productCode}` : ''}
                      </h2>
                    </div>
                  </div>
                  <div className='card-body pt-0'>
                    <div className='col-12'>
                      <FormControl
                        ref={(ref) => (componentRefs.current.productName = ref)}
                        tabIndex={2}
                        controlType={ControlType.Input}
                        label={intl.formatMessage({
                          id: 'Product.Column.ProductName',
                        })}
                        isRequired={true}
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                      />
                    </div>
                    <div className='col-12 pt-4'>
                      <FormControl
                        ref={(ref) => (componentRefs.current.description = ref)}
                        tabIndex={3}
                        controlType={ControlType.CkEditor}
                        label={intl.formatMessage({
                          id: 'Product.Column.Description',
                        })}
                        value={description}
                        onChange={(e) => setDescription(e)}
                      />
                    </div>

                    <div className='col-12 pt-4'>
                      <FormControl
                        ref={(ref) => (componentRefs.current.usage = ref)}
                        tabIndex={3}
                        controlType={ControlType.CkEditor}
                        label={intl.formatMessage({
                          id: 'Product.Column.UserGuide',
                        })}
                        value={usage}
                        onChange={(e) => setUsage(e)}
                      />
                    </div>

                    <div className='col-12 pt-4'>
                      <FormControl
                        ref={(ref) => (componentRefs.current.productCertificate = ref)}
                        tabIndex={4}
                        controlType={ControlType.AttachFile}
                        onChange={handleChangeFileAttachment}
                        value={productCertificate}
                        isMultiple={true}
                        label={intl.formatMessage({
                          id: 'Product.Column.FileAttachment',
                        })}
                      />
                    </div>
                  </div>
                </div>

                <div className='card card-flush py-4'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h2>{intl.formatMessage({ id: 'Product.Title.Media' })}</h2>
                    </div>
                  </div>
                  <div className='card-body pt-0'>
                    <FormControl
                      ref={(ref) => (componentRefs.current.productImages = ref)}
                      tabIndex={5}
                      controlType={ControlType.AttachFile}
                      onChange={handleChangeImages}
                      value={productImages}
                      isMultiple={true}
                      label={intl.formatMessage({
                        id: 'Product.Column.Images',
                      })}
                      isDropzone={true}
                    />
                  </div>
                </div>

                <div className='card card-flush py-4'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h2>{intl.formatMessage({ id: 'Product.Title.Pricing' })}</h2>
                    </div>
                  </div>
                  <div className='card-body pt-0'>
                    <div className='d-flex flex-wrap'>
                      <div className='col-12'>
                        <div className='mb-3'>
                          <FormControl
                            ref={(ref) => (componentRefs.current.basePrice = ref)}
                            tabIndex={6}
                            controlType={ControlType.NumberBox}
                            label={intl.formatMessage({
                              id: 'Product.Column.BasePrice',
                            })}
                            value={basePrice}
                            isRequired={true}
                            type='number'
                            onChange={(e) => setBasePrice(e.target.value)}
                          />
                        </div>

                        <div className='mb-3'>
                          <FormControl
                            ref={(ref) => (componentRefs.current.supplierCommission = ref)}
                            tabIndex={6}
                            controlType={ControlType.NumberBox}
                            label={intl.formatMessage({
                              id: 'Product.Column.SupplierCommission',
                            })}
                            value={supplierCommission}
                            isRequired={true}
                            type='number'
                            onChange={(e) => setSupplierCommission(e.target.value)}
                          />
                        </div>

                        {currentUser.roleName === ROLE_NAME.ADMIN && (
                          <div className='mb-3'>
                            <FormControl
                              ref={(ref) => (componentRefs.current.commissionMoney = ref)}
                              tabIndex={6}
                              controlType={ControlType.NumberBox}
                              label={intl.formatMessage({
                                id: 'Product.Column.CommissionMoney',
                              })}
                              value={commissionMoney}
                              isRequired={true}
                              type='number'
                              onChange={(e) => setCommissionMoney(e.target.value)}
                            />
                          </div>
                        )}
                      </div>

                      <div className='col-12 pt-4'>
                        <label className='fs-6 fw-semibold'>
                          <FormattedMessage id='Product.Title.DiscountType' />
                          <span
                            className='ms-1'
                            data-bs-toggle='tooltip'
                            aria-label='Select a discount type that will be applied to this product'
                            data-bs-original-title='Select a discount type that will be applied to this product'
                            data-kt-initialized='1'
                          >
                            <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>
                          </span>{' '}
                        </label>
                        <div className='row row-cols-lg-1 row-cols-xl-2 row-cols-xxl-3'>
                          {Object.keys(DiscountTypeEnum)
                            .filter(
                              (key) =>
                                DiscountTypeEnum[key] === DiscountTypeEnum.NoDiscount ||
                                DiscountTypeEnum[key] === DiscountTypeEnum.Percentage ||
                                DiscountTypeEnum[key] === DiscountTypeEnum.FixedPrice
                            )
                            .map((key) => {
                              return (
                                <div key={key} className='pe-2 pt-2'>
                                  <label
                                    className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-4 ${
                                      discountType === DiscountTypeEnum[key] ? 'active' : ''
                                    }`}
                                    onChange={(e) => setDiscountType(parseInt(e.target.value))}
                                  >
                                    <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start me-2'>
                                      <input
                                        className='form-check-input'
                                        type='radio'
                                        name='discount_option'
                                        value={DiscountTypeEnum[key]}
                                        checked={discountType === DiscountTypeEnum[key]}
                                      />
                                    </span>

                                    <span className='fw-bold d-block'>
                                      <FormattedMessage id={`Enum.${key}`} />
                                    </span>
                                  </label>
                                </div>
                              )
                            })}
                        </div>
                      </div>

                      <div className={`col-12 pt-4 ${discountType === DiscountTypeEnum.Percentage ? '' : 'd-none'}`}>
                        <label className='form-label'>
                          <FormattedMessage id='Product.Column.DiscountedPercent' />
                        </label>
                        <div className='d-flex flex-column text-center border'>
                          <div className='d-flex align-items-start justify-content-center'>
                            <span className='fw-bold fs-3x' contentEditable='true' onBlur={(e) => setDiscountedPercent(e.target.textContent)}>
                              {discountedPercent}
                            </span>
                            <span className='fw-bold fs-4 mt-1 ms-2'>%</span>
                          </div>
                        </div>
                      </div>

                      <div className={`col-12 pt-4 ${discountType === DiscountTypeEnum.FixedPrice ? '' : 'd-none'}`}>
                        <FormControl
                          ref={(ref) => (componentRefs.current.discountedPrice = ref)}
                          tabIndex={6}
                          controlType={ControlType.NumberBox}
                          label={intl.formatMessage({
                            id: 'Product.Column.DiscountedPrice',
                          })}
                          value={discountedPrice}
                          type='number'
                          onChange={(e) => setDiscountedPrice(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='card card-flush py-4'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h2>{intl.formatMessage({ id: 'Product.Title.Property' })}</h2>
                    </div>
                  </div>
                  <div className='card-body pt-0'>
                    <FormControl
                      ref={(ref) => (componentRefs.current.productPropertyId = ref)}
                      tabIndex={11}
                      controlType={ControlType.TagInput}
                      onChange={(e) => setPropertiesSelected(e)}
                      value={propertiesSelected}
                      label={intl.formatMessage({ id: 'Product.Column.Property' })}
                      options={standardProductProperties}
                    />

                    <div className='mt-2'>
                      <div className='d-flex justify-content-end mb-1'>
                        <a
                          className='btn btn-icon btn-light-success btn-sm'
                          onClick={() => handleAddPropertyColumns(1)}
                          title={intl.formatMessage({ id: 'AddNew' })}
                        >
                          <i className='fa-solid fa-plus'></i>
                        </a>

                        {propertyColumns.length > 2 && (
                          <a
                            className='btn btn-icon btn-light-danger btn-sm ms-2'
                            onClick={() => handleRemovePropertyColumns()}
                            title={intl.formatMessage({ id: 'Delete' })}
                          >
                            <i className='fa-solid fa-minus'></i>
                          </a>
                        )}
                      </div>
                      <Table columns={propertyColumns} data={propertyRows} onCellValueChange={handleCellValueChange} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='tab-pane fade' id='add_product_reviews'>
              <div className='d-flex flex-column gap-4'>
                <ProductRating />
              </div>
            </div>
            <div className='tab-pane fade' id='add_product_receiving'>
              <div className='d-flex flex-column gap-4'>
                <Receiving productId={productId} />
              </div>
            </div>
            <div className='tab-pane fade' id='add_product_dispatch'>
              <div className='d-flex flex-column gap-4'>
                <Dispatch productId={productId} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-center bg-white p-4 col-12 rounded gap-3'>
        <BackButton tabIndex={14} link={'/eco/product/list'} />
        {productStatus === ProductStatusEnum.PendingApproval && currentUser.roleName === ROLE_NAME.ADMIN && productId !== DEFAULT_VALUE.GUID_EMPTY && (
          <>
            <Button
              onClick={(e) => {
                e.preventDefault()
                setConfirmApprove(true)
              }}
              title={intl.formatMessage({ id: 'Approve' })}
              className='btn btn-success'
              icon='fa-check-double'
            />
            <Button
              onClick={(e) => {
                e.preventDefault()
                setConfirmReject(true)
              }}
              title={intl.formatMessage({ id: 'Ignore' })}
              className='btn btn-danger'
              icon='fa-xmark'
            />
          </>
        )}
        <SaveButton handleSave={handleSave} tabIndex={15} />
      </div>

      {showReceivingModel && (
        <ReceivingModel
          closeModal={() => setShowReceivingModel(false)}
          productId={productId}
          supplierId={supplierId}
          warehouseId={warehouseId}
          reloadData={() => loadProduct()}
        />
      )}
      {confirmReject && (
        <Confirm
          onConfirm={handleReject}
          onCancel={() => setConfirmReject(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Reject' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Ignore' })}
        />
      )}
      {confirmApprove && (
        <Confirm
          onConfirm={handleApprove}
          onCancel={() => setConfirmApprove(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Approval' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Approve' })}
        />
      )}
    </form>
  )
}

export default ProductEdit
