import { useEffect, useState, useRef } from 'react'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import { DEFAULT_VALUE, ROLE_NAME } from '../../../../utilities/constant'
import { Icon } from '../../../../helpers'
import Loading from '../../components/Loading'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import TableSearch from '../../components/TableSearch'
import AffiliateModal from './AffiliateModal'
import { getAffiliates, approveAffiliate, ignoreAffiliate } from '../../../../redux/actions/aff/affiliateActions'
import { AffiliateStatusEnum, getKeyByValue } from '../../../../utilities/enums'
import Confirm from '../../components/Confirm'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import { Auth } from '../../../auth/Auth'
import { ControlType, FormControl } from '../../components/FormControl'
import AffiliateFilter from './AffiliateFilter'
import ExportExcel from '../../components/ExportExcel'

const Affiliate = () => {
  const intl = useIntl()
  const currentUser = Auth.getUserData() || {}
  const componentRefs = useRef({})

  const [affiliates, setAffiliates] = useState([])
  const [affiliatesOriginal, setAffiliatesOriginal] = useState([])
  const [affiliate, setAffiliate] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [confirmApprove, setConfirmApprove] = useState(false)
  const [confirmReject, setConfirmReject] = useState(false)

  const [approveStatusId, setApproveStatusId] = useState(null)
  const [status] = useState(
    Object.keys(AffiliateStatusEnum).map((key) => ({
      value: AffiliateStatusEnum[key],
      name: <FormattedMessage id={`Enum.${key}`} />,
    }))
  )

  const fetchAffiliates = async (afflicateCode, affiliateName, email, phone) => {
    setIsLoading(true)
    try {
      const { result } = await getAffiliates({
        pageIndex: currentPage,
        pageSize: pageSize,
        isAffiliate: 1,
        approveStatusId,
        afflicateCode,
        affiliateName,
        email,
        phone
      })

      if (result) {
        setAffiliates(result.items)
        setAffiliatesOriginal(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAffiliates()
  }, [currentPage, pageSize, approveStatusId])

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'Affiliate.Column.AfflicateCode' })}`,
      accessor: 'afflicateCode',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Menu.Eco.Affiliate' })}`,
      accessor: 'affiliateName',
    },
    {
      Header: `${intl.formatMessage({ id: 'Affiliate.Column.AffiliateParentName' })}`,
      accessor: 'affiliateParentName',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Affiliate.Column.Address' })}`,
      accessor: 'address',
    },
    {
      Header: `${intl.formatMessage({ id: 'Affiliate.Column.RegisterDate' })}`,
      accessor: 'registerDate',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Affiliate.Column.Action' })}`,
      accessor: 'action',
      width: 60,
    },
  ]

  const rows = affiliates.map((item) => {
    return {
      afflicateCode: item.afflicateCode,
      affiliateName: (
        <>
          <div>
            <b>
              <FormattedMessage id='Affiliate.Column.AffiliateName' />:{' '}
            </b>
            {item.affiliateName}
          </div>
          <div>
            <b>
              <FormattedMessage id='Affiliate.Column.Email' />:{' '}
            </b>
            {item.email}
          </div>
          <div>
            <b>
              <FormattedMessage id='Affiliate.Column.Phone' />:{' '}
            </b>
            {item.phone}
          </div>
          <div>
            <b>
              <FormattedMessage id='Affiliate.Column.ApproveStatusName' />:{' '}
            </b>
            <span
              onClick={() => handleConfirmApprove(item)}
              className={`badge badge-light-${
                item.approveStatusId === AffiliateStatusEnum.PendingApproval
                  ? 'primary'
                  : item.approveStatusId === AffiliateStatusEnum.Approved
                  ? 'success'
                  : 'secondary'
              } me-1 fs-7 cursor-pointer`}
            >
              <FormattedMessage id={`Enum.${getKeyByValue(AffiliateStatusEnum, item.approveStatusId)}`} />
            </span>
          </div>
        </>
      ),
      email: item.email,
      phone: item.phone,
      affiliateParentName: item.affiliateParentName,
      approveStatusName: item.approveStatusName,
      address: item.address,
      registerDate: item.registerDateStr,
      action: (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          {item.approveStatusId === AffiliateStatusEnum.PendingApproval && currentUser.roleName === ROLE_NAME.ADMIN && (
            <>
              <a onClick={() => handleConfirmApprove(item)} className='btn btn-icon btn-primary btn-sm' title={intl.formatMessage({ id: 'Approve' })}>
                <i className='fa fa-check' />
              </a>

              <a onClick={() => handleConfirmReject(item)} className='btn btn-icon btn-danger btn-sm' title={intl.formatMessage({ id: 'Ignore' })}>
                <i className='fa fa-xmark' />
              </a>
            </>
          )}

          <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm me-1'>
            <i className='fa-solid fa-pen-to-square' />
          </a>
        </div>
      ),
    }
  })

  const handleCreate = () => {
    setIsEdit(true)
    setAffiliate({ id: DEFAULT_VALUE.GUID_RANDOM, isAdded: true })
  }

  const handleEdit = (item) => {
    setAffiliate(item)
    setIsEdit(true)
  }

  const handleConfirmApprove = (item) => {
    if (item.approveStatusId === AffiliateStatusEnum.PendingApproval) {
      setConfirmApprove(true)
      setAffiliate(item)
    }
  }

  const handleConfirmReject = (item) => {
    setConfirmReject(true)
    setAffiliate(item)
  }

  const handleApprove = async () => {
    if (affiliate.approveStatusId !== AffiliateStatusEnum.PendingApproval || currentUser.roleName !== ROLE_NAME.ADMIN) return
    setIsLoading(true)
    try {
      const { error } = await approveAffiliate(affiliate?.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        setConfirmApprove(false)
        fetchAffiliates()
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleReject = async () => {
    if (affiliate.approveStatusId !== AffiliateStatusEnum.PendingApproval || currentUser.roleName !== ROLE_NAME.ADMIN) return
    setIsLoading(true)
    try {
      const { error, result } = await ignoreAffiliate(affiliate?.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        setConfirmReject(false)
        fetchAffiliates()
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='card-toolbar gap-3'>
          <a onClick={() => handleCreate()} className='btn btn-sm btn-success'>
            <Icon iconName='plus' className='fs-3' />
            {intl.formatMessage({ id: 'AddNew' })}
          </a>

          <ExportExcel fileName={'suppliers'} columns={columns} data={rows} numberOfLastColumnRemove={1} />
          <AffiliateFilter fetchData={fetchAffiliates} />

        </div>

        <div className='card-toolbar gap-3'>
          <div className='min-w-200px'>
            <FormControl
              ref={(ref) => (componentRefs.current.approveStatusId = ref)}
              controlType={ControlType.Select}
              label={intl.formatMessage({ id: 'Status' })}
              hideLabel={true}
              value={approveStatusId}
              onChange={(e) => setApproveStatusId(e.value)}
              options={status}
            />
          </div>

          <TableSearch onSearch={setAffiliates} originalData={affiliatesOriginal} />
        </div>
      </div>
      <div className='card-body py-2 pt-0'>
        <div className='table-responsive'>
          <Table columns={columns} data={rows} />

          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={(e) => setCurrentPage(e)}
            onItemsPerPageChange={(e) => setPageSize(e)}
          />

          {isLoading && <Loading />}
        </div>
      </div>

      {isEdit && <AffiliateModal editAffiliate={affiliate} closeModal={() => setIsEdit(false)} fetchAffiliates={fetchAffiliates} />}
      {confirmApprove && (
        <Confirm
          onConfirm={handleApprove}
          onCancel={() => setConfirmApprove(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Approve' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Approve' })}
        />
      )}
      {confirmReject && (
        <Confirm
          onConfirm={handleReject}
          onCancel={() => setConfirmReject(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Reject' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Ignore' })}
        />
      )}
    </div>
  )
}

export default Affiliate
