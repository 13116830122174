import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class FeaturedProductAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.FEATUREDPRODUCT)
    this.adapter = this.initAdapter(conf)
  }

  async getFeaturedProductById(id) {
    return await this.adapter.getAsync(`getFeaturedProductById?id=${id}`)
  }

  async getFeaturedProductsByType(payload) {
    return await this.adapter.getAsync('getFeaturedProductsByType', { payload })
  }

  async addFeaturedProducts(payload) {
    return await this.adapter.postAsync('addFeaturedProducts', { payload })
  }

  async deleteFeaturedProducts(payload) {
    return await this.adapter.postAsync('deleteFeaturedProducts', { payload })
  }

  async getProducts(payload) {
    return await this.adapter.getAsync('getProducts', { payload })
  }

  async modifyFromDate(payload) {
    return await this.adapter.putAsync('modifyFromDate', { payload })
  }

  async modifyTodate(payload) {
    return await this.adapter.putAsync('modifyTodate', { payload })
  }

  async modifyOrder(payload) {
    return await this.adapter.putAsync('modifyOrder', { payload })
  }
}

export default FeaturedProductAPI
