import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { MenuComponent } from '../../../assets/ts/components/index.ts'
import { PageDataProvider } from '../../pages/PageData'
import Navigation from './Navigation'
import TopNavbar from './TopNavbar'
import ScrollTop from '../ScrollTop'
import InitializeSignalRConnection from '../../../redux/hub/signalRHub.js'

const AdminLayout = ({ user }) => {
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  return (
    <PageDataProvider>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Navigation />
          <div id="kt_wrapper" className="wrapper d-flex flex-column flex-row-fluid">
            <TopNavbar />
            <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
              <div id="kt_content_container" className="container-xxl">
                <Outlet />
              </div>
            </div>
            <div id="kt_footer" className="footer py-4 d-flex flex-lg-column">
              <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                <div className="text-dark order-2 order-md-1">
                  <span className="text-gray-400 fw-semibold me-1">Created by</span>
                  <a href="/" target="_blank" className="text-muted text-hover-primary fw-semibold me-2 fs-6">
                    AZTech &#169; 2023
                  </a>
                </div>
                <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                  <li className="menu-item">
                    <a href="/" target="_blank" className="menu-link px-2">
                      About
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href="/" target="_blank" className="menu-link px-2">
                      Support
                    </a>
                  </li>
                  <li className="menu-item">
                    <a href="/" target="_blank" className="menu-link px-2">
                      Purchase
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <InitializeSignalRConnection /> */}
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export default AdminLayout
