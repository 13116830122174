import React from 'react'
import ThemeMode from './ThemeMode'
import { Icon } from '../../../../helpers'
import { Search } from './Search'
import { Notification } from './Notification'

const TopBar = () => {
  return (
    <div className="d-flex flex-shrink-0">
      <Search className="w-lg-250px" mobileToggleBtnClass="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px" />

      <div className={'d-flex align-items-center ms-1 ms-lg-3'}>
        <div
          className={'btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px'}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <Icon iconName='notification' className='fs-2' />
        </div>
        <Notification />
      </div>

      <div className="d-flex align-items-center ms-3">
        <ThemeMode />
      </div>
    </div>
  )
}

export default TopBar
