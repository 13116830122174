import Button from './Button'
import { useIntl } from 'react-intl'

function SaveButton({ handleSave, className, ...restProps }) {
  const intl = useIntl()

  return (
    <Button
      type='save'
      onClick={(e) => {
        e.preventDefault()
        handleSave()
      }}
      className={`btn btn-primary ${className}`}
      icon='fa fa-save'
      {...restProps}
    >
      {intl.formatMessage({ id: 'Save' })}
    </Button>
  )
}

export default SaveButton
