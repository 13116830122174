import React, { useEffect, useState } from 'react'
import Table from '../components/Table'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import { TOAST_TYPES, showToast } from '../../../helpers/Toastify'
import { Icon } from '../../../helpers'
import Pagination from '../components/Pagination'
import Loading from '../components/Loading'
import ConfirmRemove from '../components/Confirm'
import * as userApi from '../../../redux/actions/auth/userActions'
import * as roleApi from '../../../redux/actions/auth/roleActions'
import UserEdit from './UserEdit'
import { ROLE_NAME } from '../../../utilities/constant'

const UserManagement = () => {
  const intl = useIntl()
  const [users, setUsers] = useState([])
  const [user, setUser] = useState([])
  const [roles, setRoles] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const [isEdit, setIsEdit] = useState(false)
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)

  useEffect(() => {
    getRoles()
  }, [])

  useEffect(() => {
    getUsers()
  }, [currentPage, pageSize])

  const getUsers = async () => {
    setIsLoading(true)
    try {
      const { result } = await userApi.getAllUser({ pageIndex: currentPage, pageSize: pageSize })
      if (result) {
        setUsers(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const getRoles = async () => {
    try {
      const { result } = await roleApi.getAllRole()
      setRoles(result ?? [])
    } catch (error) {
      console.error('getRoles:', error)
    }
  }

  const resetPassword = async (item) => {
    try {
      const { error } = await userApi.resetPassword(item)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      } else {
        console.error('resetPassword:', error)
        showToast(error.message, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      console.error('getRoles:', error)
    }
  }
  const handleCreate = () => {
    setUser({
      id: DEFAULT_VALUE.GUID_RANDOM,
      isAdded: true,
    })
    setIsEdit(true)
  }

  const handleEdit = (item) => {
    item.isUpdated = true
    setUser(item)
    setIsEdit(true)
  }

  const confirmDelete = (item) => {
    setIsConfirmationVisible(true)
    item.isDeleted = true
    setUser(item)
  }

  const getUserColumns = () => {
    const columns = [
      {
        Header: `${intl.formatMessage({ id: 'User.FullName' })}`,
        accessor: 'fullName',
        width: 200,
      },
      {
        Header: `${intl.formatMessage({ id: 'User.Account' })}`,
        accessor: 'userName',
        width: 150,
      },
      {
        Header: `${intl.formatMessage({ id: 'User.Email' })}`,
        accessor: 'email',
        width: 150,
      },
      {
        Header: `${intl.formatMessage({ id: 'User.PhoneNumber' })}`,
        accessor: 'phoneNumber',
        width: 100,
      },
      {
        Header: `${intl.formatMessage({ id: 'User.Role' })}`,
        accessor: 'role',
        width: 100,
      },
      {
        Header: `${intl.formatMessage({ id: 'Action' })}`,
        accessor: 'action',
        width: 100,
      },
    ]

    return columns
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize)
  }

  const handleRemove = async () => {
    setIsLoading(true)

    try {
      const { error } = await userApi.saveUser(user)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        setUsers(users.filter((item) => item.id !== user.id))
        setIsConfirmationVisible(false)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancelRemove = () => {
    setIsConfirmationVisible(false)
  }

  const columns = getUserColumns()

  const rows = users.map((item) => {
    return {
      id: item.id,
      fullName: item.fullName,
      userName: item.userName,
      email: item.email,
      phoneNumber: item.phoneNumber,
      role: item.role?.name,
      action: (
        <div className='d-flex justify-content-center flex-shrink-0'>
          <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm me-1' title={intl.formatMessage({ id: 'Edit' })}>
            <i className='fa-solid fa-pen-to-square'></i>
          </a>
          <a
            onClick={() => resetPassword(item)}
            className='btn btn-icon btn-light-success btn-sm me-1'
            title={intl.formatMessage({ id: 'User.ResetPassword' })}
          >
            <i className='fa-solid fa-refresh'></i>
          </a>
          {item.role?.name !== ROLE_NAME.ADMIN && (
            <a onClick={() => confirmDelete(item)} className='btn btn-icon btn-light-danger btn-sm' title={intl.formatMessage({ id: 'Delete' })}>
              <i className='fa-solid fa-trash'></i>
            </a>
          )}
        </div>
      ),
    }
  })

  return (
    <div className={`card mb-5 mb-xl-8`}>
      <div className='card-header border-0 pt-5'>
        <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover' title='Click to add a Ticket'>
          <a onClick={() => handleCreate()} className='btn btn-sm btn-success'>
            <Icon iconName='plus' className='fs-3' />
            {intl.formatMessage({ id: 'AddNew' })}
          </a>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <Table columns={columns} data={rows} />

          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handlePageSizeChange}
          />

          {isLoading && <Loading />}
        </div>
      </div>

      {isEdit && <UserEdit closeModal={setIsEdit} user={user} roles={roles} getUsers={getUsers} />}

      {isConfirmationVisible && (
        <ConfirmRemove
          onConfirm={handleRemove}
          onCancel={handleCancelRemove}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Confirm' })}
        />
      )}
    </div>
  )
}

export default UserManagement
