import { OrderStatusEnum } from '../../../../utilities/enums'

export const statusColor = {
  [OrderStatusEnum.WaitForConfirm]: 'success text-light',
  [OrderStatusEnum.PreparingGoods]: 'info text-light',
  [OrderStatusEnum.WaitForDelivery]: 'warning text-light',
  [OrderStatusEnum.Complete]: 'primary text-light',
  [OrderStatusEnum.Cancel]: 'danger text-light',
  [OrderStatusEnum.ReturnRefund]: 'secondary text-dark',
}

export const btnText = {
  [OrderStatusEnum.WaitForConfirm]: 'Purchase.ConfirmWaitForConfirm',
  [OrderStatusEnum.PreparingGoods]: 'Purchase.ConfirmPreparingGoods',
  [OrderStatusEnum.WaitForDelivery]: 'Purchase.ConfirmWaitForDelivery'
}

export const orderRoutes = [
  {
    title: 'Purchase.All',
    type: OrderStatusEnum.All,
  },
  {
    title: 'Purchase.WaitForConfirm',
    type: OrderStatusEnum.WaitForConfirm,
  },
  {
    title: 'Purchase.PreparingGoods',
    type: OrderStatusEnum.PreparingGoods,
  },
  {
    title: 'Purchase.WaitForDelivery',
    type: OrderStatusEnum.WaitForDelivery,
  },
  {
    title: 'Purchase.Complete',
    type: OrderStatusEnum.Complete,
  },
  {
    title: 'Purchase.Cancel',
    type: OrderStatusEnum.Cancel,
  },
]
