import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import { ControlType, FormControl } from '../../components/FormControl'
import Button from '../../components/Button'
import BackButton from '../../components/BackButton'
import { approveSupplier, getSupplierById, ignoreSupplier, updateSupplier } from '../../../../redux/actions/eco/supplierActions'
import { getIntoCombobox } from '../../../../redux/actions/gnr/danhMucActions'
import { FILE_TYPE, TABLE_NAME } from '../../../../utilities/constant'
import { SupplierStatusEnum } from '../../../../utilities/enums'
import Confirm from '../../components/Confirm'
import Loading from '../../components/Loading'
import useGetFiles from '../../../../helpers/useGetFiles'

const SupplierEdit = () => {
  const intl = useIntl()
  const { supplierId } = useParams()
  const componentRefs = useRef({})

  const [partnerTypes, setPartnerTypes] = useState([])
  const [businessIndustries, setBusinessIndustries] = useState([])
  const [serviceFees, setServiceFees] = useState([])
  const [supplierMembershipFees, setSupplierMembershipFees] = useState([])
  const [productLicenseFiles, setProductLicenseFiles] = useGetFiles(TABLE_NAME.SUPPLIER, supplierId, FILE_TYPE.PRODUCT_LICENSE_FILE)
  const [businessLicenseFiles, setBusinessLicenseFiles] = useGetFiles(TABLE_NAME.SUPPLIER, supplierId, FILE_TYPE.BUSINESS_LICENSE_FILE)

  const isPersonalOptions = [
    { value: 'a-0', label: intl.formatMessage({ id: 'Supplier.Radio.Company' }) },
    { value: 'a-1', label: intl.formatMessage({ id: 'Supplier.Radio.Personal' }) },
  ]

  const isAgreeOptions = [
    { value: 'b-1', label: intl.formatMessage({ id: 'Supplier.Radio.Yes' }) },
    { value: 'b-0', label: intl.formatMessage({ id: 'Supplier.Radio.No' }) },
  ]

  const [fullName, setFullName] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [email, setEmail] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [website, setWebsite] = useState(null)
  const [businessCode, setBusinessCode] = useState(null)
  const [partnerTypeId, setPartnerTypeId] = useState(null)
  const [isPersonal, setIsPersonal] = useState(null)
  const [businessAddress, setBusinessAddress] = useState(null)
  const [businessIndustryId, setBusinessIndustryId] = useState(null)
  const [businessIndustryOther, setBusinessIndustryOther] = useState(null)
  const [serviceFeeId, setServiceFeeId] = useState(null)
  const [supplierMembershipFeeId, setSupplierMembershipFeeId] = useState(null)
  const [isAgree, setIsAgree] = useState(null)
  const [approveStatusId, setApproveStatusId] = useState(null)

  const [confirmApproveVisible, setConfirmApproveVisible] = useState(false)
  const [confirmIgnoreVisible, setConfirmIgnoreVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { result: partnerResult } = await getIntoCombobox({ tableName: TABLE_NAME.PARTNER_TYPE, isActive: true })
        if (partnerResult) {
          setPartnerTypes(partnerResult)
        }

        const { result: biResult } = await getIntoCombobox({ tableName: TABLE_NAME.BUSINESS_INDUSTRY, isActive: true })
        if (biResult) {
          setBusinessIndustries(biResult)
        }

        const { result: sfResult } = await getIntoCombobox({ tableName: TABLE_NAME.SERVICE_FEE, isActive: true })
        if (sfResult) {
          setServiceFees(sfResult)
        }

        const { result: mbResult } = await getIntoCombobox({ tableName: TABLE_NAME.SUPPLIER_MEMBERSHIP_FEE, isActive: true })
        if (mbResult) {
          setSupplierMembershipFees(mbResult)
        }

        const { result: sup } = await getSupplierById(supplierId)
        if (sup) {
          setFullName(sup.fullName)
          setCompanyName(sup.companyName)
          setEmail(sup.email)
          setPhoneNumber(sup.phoneNumber)
          setWebsite(sup.website)
          setBusinessCode(sup.businessCode)
          setPartnerTypeId(sup.partnerTypeId)
          setIsPersonal(sup.isPersonal ? 'a-1' : 'a-0')
          setBusinessAddress(sup.businessAddress)
          setBusinessIndustryId(sup.businessIndustryId.toString())
          setBusinessIndustryOther(sup.businessIndustryOther)
          setServiceFeeId(sup.serviceFeeId)
          setSupplierMembershipFeeId(sup.supplierMembershipFeeId)
          setIsAgree(sup.isAgree ? 'b-1' : 'b-0')
          setApproveStatusId(sup.approveStatusId)
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  const handleChangeProductLicenseFile = (files) => {
    setProductLicenseFiles(files)
  }

  const handleChangeBusinessLicenseFile = (files) => {
    setBusinessLicenseFiles(files)
  }

  const handleIsPesonalOptionChange = (value) => {
    setIsPersonal(value)
  }

  const handleIsAgreeOptionChange = (value) => {
    setIsAgree(value)
  }

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return
    setIsLoading(true)

    const newSupplier = {
      fullName,
      companyName,
      email,
      phoneNumber,
      website,
      businessCode,
      partnerTypeId,
      isPersonal: isPersonal === 'a-1' ? true : false,
      businessIndustryId,
      businessIndustryOther,
      serviceFeeId,
      supplierMembershipFeeId,
      isAgree: isAgree === 'b-1' ? true : false,
      businessAddress,
      businessLicenseFiles,
      productLicenseFiles,
      id: supplierId,
      approveStatusId,
    }
    const { error, result } = await updateSupplier(newSupplier)
    if (_.isEmpty(error) && result) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
    setIsLoading(false)
  }

  const confirmApprove = (e) => {
    e.preventDefault()
    setConfirmApproveVisible(true)
  }

  const handleApprove = async () => {
    setIsLoading(true)

    try {
      const { error, result } = await approveSupplier(supplierId)
      if (_.isEmpty(error)) {
        setApproveStatusId(result.approveStatusId)
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        setConfirmApproveVisible(false)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const confirmIgnore = (e) => {
    e.preventDefault()
    setConfirmIgnoreVisible(true)
  }

  const handleIgnore = async () => {
    setIsLoading(true)

    try {
      const { error, result } = await ignoreSupplier(supplierId)
      if (_.isEmpty(error)) {
        setApproveStatusId(result.approveStatusId)
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        setConfirmIgnoreVisible(false)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    setConfirmApproveVisible(false)
    setConfirmIgnoreVisible(false)
  }

  return (
    <div className={`card mb-5 mb-xl-8`}>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'Update' })}</h3>
        </div>
      </div>
      <div className='card-header border-0 pt-5'>
        <form id='form' className='form'>
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='form_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#form_header'
            data-kt-scroll-wrappers='#form_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='row'>
              <div className='col-md-6'>
                <div className='col-md-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.companyName = ref)}
                    controlType={ControlType.Input}
                    label={intl.formatMessage({ id: 'Supplier.Label.CompanyName' })}
                    value={companyName}
                    isRequired={true}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>

                <div className='col-md-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.partnerTypeId = ref)}
                    controlType={ControlType.Select}
                    label={intl.formatMessage({ id: 'Supplier.Label.PartnerType' })}
                    value={partnerTypeId}
                    isRequired={true}
                    onChange={(e) => setPartnerTypeId(e.value)}
                    options={partnerTypes}
                  />
                </div>

                <div className='col-md-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.businessAddress = ref)}
                    controlType={ControlType.TextArea}
                    label={intl.formatMessage({ id: 'Supplier.Label.BusinessAddress' })}
                    value={businessAddress}
                    isRequired={true}
                    onChange={(e) => setBusinessAddress(e.target.value)}
                  />
                </div>

                <div className='col-md-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.businessIndustryId = ref)}
                    controlType={ControlType.Select}
                    label={intl.formatMessage({ id: 'Supplier.Label.BusinessIndustry' })}
                    value={businessIndustryId}
                    isRequired={true}
                    onChange={(e) => setBusinessIndustryId(e.value)}
                    options={businessIndustries}
                  />
                </div>

                {businessIndustryId === 9 && (
                  <div className='col-md-12 mb-3'>
                    <FormControl
                      ref={(ref) => (componentRefs.current.businessIndustryOther = ref)}
                      controlType={ControlType.Input}
                      label={intl.formatMessage({ id: 'Supplier.Label.BusinessIndustryOther' })}
                      value={businessIndustryOther}
                      isRequired={true}
                      onChange={(e) => setBusinessIndustryOther(e.target.value)}
                    />
                  </div>
                )}

                <div className='col-md-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.phoneNumber = ref)}
                    controlType={ControlType.Input}
                    label={intl.formatMessage({ id: 'Supplier.Label.PhoneNumber' })}
                    value={phoneNumber}
                    isRequired={true}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>

                <div className='col-md-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.serviceFeeId = ref)}
                    controlType={ControlType.Select}
                    label={intl.formatMessage({ id: 'Supplier.Label.ServiceFee' })}
                    value={serviceFeeId}
                    isRequired={true}
                    onChange={(e) => setServiceFeeId(e.value)}
                    options={serviceFees}
                  />
                </div>
              </div>

              <div className='col-md-6'>
                <div className='col-md-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.businessCode = ref)}
                    controlType={ControlType.Input}
                    label={intl.formatMessage({ id: 'Supplier.Label.BusinessCode' })}
                    value={businessCode}
                    isRequired={true}
                    onChange={(e) => setBusinessCode(e.target.value)}
                  />
                </div>

                <div className='col-md-12 mb-6'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.isPersonal = ref)}
                    controlType={ControlType.RadioButton}
                    label={intl.formatMessage({ id: 'Supplier.Label.IsPersonal' })}
                    value={isPersonal}
                    isRequired={true}
                    onChange={handleIsPesonalOptionChange}
                    options={isPersonalOptions}
                    isHorizontal={true}
                  />
                </div>

                <div className='col-md-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.fullName = ref)}
                    controlType={ControlType.Input}
                    label={intl.formatMessage({ id: 'Supplier.Label.FullName' })}
                    value={fullName}
                    isRequired={true}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>

                <div className='col-md-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.email = ref)}
                    controlType={ControlType.Input}
                    label={intl.formatMessage({ id: 'Supplier.Label.Email' })}
                    value={email}
                    isEmail={true}
                    isRequired={true}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className='col-md-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.website = ref)}
                    controlType={ControlType.Input}
                    label={intl.formatMessage({ id: 'Supplier.Label.Website' })}
                    value={website}
                    isRequired={true}
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </div>

                <div className='col-md-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.supplierMembershipFeeId = ref)}
                    controlType={ControlType.Select}
                    label={intl.formatMessage({ id: 'Supplier.Label.SupplierMembershipFee' })}
                    value={supplierMembershipFeeId}
                    isRequired={true}
                    onChange={(e) => setSupplierMembershipFeeId(e.value)}
                    options={supplierMembershipFees}
                  />
                </div>
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.businessLicenseFiles = ref)}
                  controlType={ControlType.AttachFile}
                  label={intl.formatMessage({ id: 'Supplier.Label.BusinessLicense' })}
                  value={businessLicenseFiles}
                  onChange={handleChangeBusinessLicenseFile}
                  isRequired={true}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.productLicenseFiles = ref)}
                  controlType={ControlType.AttachFile}
                  label={intl.formatMessage({ id: 'Supplier.Label.ProductLicense' })}
                  value={productLicenseFiles}
                  onChange={handleChangeProductLicenseFile}
                  isRequired={true}
                />
              </div>

              <div className='col-md-12 mb-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isAgree = ref)}
                  controlType={ControlType.RadioButton}
                  label={intl.formatMessage({ id: 'Supplier.Label.IsAgree' })}
                  value={isAgree}
                  isRequired={true}
                  onChange={handleIsAgreeOptionChange}
                  options={isAgreeOptions}
                  isHorizontal={true}
                />
              </div>
            </div>
          </div>

          <div className='text-center pt-5 pb-5'>
            <BackButton className='me-3' />
            {approveStatusId === SupplierStatusEnum.PendingApproval && (
              <>
                <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Update' })} className='btn btn-primary me-3' icon='fa-save' />
                <Button
                  onClick={(e) => confirmApprove(e)}
                  title={intl.formatMessage({ id: 'Approve' })}
                  className='btn btn-success me-3'
                  icon='fa-check-double'
                />
                <Button onClick={(e) => confirmIgnore(e)} title={intl.formatMessage({ id: 'Ignore' })} className='btn btn-danger' icon='fa-xmark' />
              </>
            )}
          </div>
        </form>

        {isLoading && <Loading />}
      </div>

      {confirmApproveVisible && (
        <Confirm
          onConfirm={handleApprove}
          onCancel={handleCancel}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Confirm' })}
        />
      )}

      {confirmIgnoreVisible && (
        <Confirm
          onConfirm={handleIgnore}
          onCancel={handleCancel}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Ignore' })}
        />
      )}
    </div>
  )
}

export default SupplierEdit
