import { useNavigate } from 'react-router-dom'
import Button from './Button'
import { useIntl } from 'react-intl'

function BackButton({ children, className, link, ...restProps }) {
  const navigate = useNavigate()
  const intl = useIntl()

  return (
    <Button
      type='back'
      onClick={(e) => {
        e.preventDefault()
        navigate(link ?? -1)
      }}
      className={`btn btn-light ${className}`}
      icon='fa-arrow-left'
      {...restProps}
    >
      {children ?? `${intl.formatMessage({ id: 'Back' })}`}
    </Button>
  )
}

export default BackButton
