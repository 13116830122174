import { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { DEFAULT_VALUE, GUID_NEWID } from '../../../../utilities/constant'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import _ from 'lodash'
import ExportExcel from '../../components/ExportExcel'
import TableSearch from '../../components/TableSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import CouponModal from './CouponModal'
import { Icon } from '../../../../helpers'
import ConfirmRemove from '../../components/Confirm'
import { deleteCoupon, getCoupons } from '../../../../redux/actions/eco/couponActions'
import { CouponStatusEnum, CouponTypeEnum, DiscountTypeEnum, getEnums } from '../../../../utilities/enums'
import NumberBox from '../../components/NumberBox'

const Coupon = () => {
  const intl = useIntl()
  const [coupons, setCoupons] = useState([])
  const [couponOriginals, setCouponOriginals] = useState([])
  const [coupon, setCoupon] = useState(null)

  const [discountTypes] = useState(() => getEnums(DiscountTypeEnum, intl))
  const [couponStatus] = useState(() => getEnums(CouponStatusEnum, intl))
  const [couponTypes] = useState(() => getEnums(CouponTypeEnum, intl))

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const [isEdit, setIsEdit] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)

  const fetchCoupons = async () => {
    setIsLoading(true)
    try {
      const { result } = await getCoupons({ pageIndex: currentPage, pageSize: pageSize })
      if (result) {
        setCoupons(result.items)
        setCouponOriginals(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchCoupons()
  }, [currentPage, pageSize])

  const calculatePercentage = (usedNumber, maxUsesNumber) => {
    if (maxUsesNumber <= 0) return 0
    return ((usedNumber ?? 0) / maxUsesNumber) * 100
  }

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'Coupon.Column.CouponCode' })}`,
      accessor: 'couponCode',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Coupon.Column.DiscountType' })}`,
      accessor: 'discountType',
    },
    {
      Header: `${intl.formatMessage({ id: 'Coupon.Column.CouponStatus' })}`,
      accessor: 'couponStatus',
      width: 60,
    },
    {
      Header: `${intl.formatMessage({ id: 'Coupon.Column.Action' })}`,
      accessor: 'action',
      width: 50,
    },
  ]
  const rows = coupons.map((item) => {
    return {
      couponCode: (
        <>
          <div>
            <b>
              <FormattedMessage id='Coupon.Column.CouponCode' />:{' '}
            </b>
            {item.couponCode}
          </div>
          <div>
            <b>
              <FormattedMessage id='Coupon.Label.CouponName' />:{' '}
            </b>
            {item.couponName}
          </div>
          <div>
            <b>
              <FormattedMessage id='Coupon.Column.CouponType' />:{' '}
            </b>
            {_.find(couponTypes, { value: item.couponType })?.name || ''}
          </div>
        </>
      ),
      discountType: (
        <div>
          <div className='shipping-type'>
            {item.discountType === DiscountTypeEnum.FixedPrice ? (
              <FormattedMessage
                id='Coupon.MaxDiscountAmount'
                values={{ maxDiscountAmount: <NumberBox value={item.maxDiscountAmount} className='' type='text' suffix={DEFAULT_VALUE.VND} /> }}
              />
            ) : item.discountType === DiscountTypeEnum.Percentage ? (
              <>
                <FormattedMessage id='Coupon.DiscountPercent' values={{ discountValue: item.discountValue }} />{' '}
                <FormattedMessage
                  id='Coupon.MaxDiscountAmount'
                  values={{ maxDiscountAmount: <NumberBox value={item.maxDiscountAmount} className='' type='text' suffix={DEFAULT_VALUE.VND} /> }}
                />
              </>
            ) : (
              <>
                <FormattedMessage
                  id='Coupon.MaxDiscountAmount'
                  values={{ maxDiscountAmount: <NumberBox value={item.maxDiscountAmount} className='' type='text' suffix={DEFAULT_VALUE.VND} /> }}
                />
              </>
            )}
            <br />
            <FormattedMessage
              id='Coupon.MinAmount'
              values={{ minAmount: <NumberBox value={item.minAmount ?? 0} className='' type='text' suffix={DEFAULT_VALUE.VND} /> }}
            />
          </div>
          <div className='shipping-time gray-color'>
            {item.maxUsesNumber && (
              <FormattedMessage id='Coupon.UsedNumber' values={{ usedNumber: calculatePercentage(item.couponUsedNumber, item.maxUsesNumber) }} />
            )}{' '}
            {item.toDate && <FormattedMessage id='Coupon.ToDate' values={{ toDate: item.toDateStr }} />}
          </div>
        </div>
      ),
      couponStatus: _.find(couponStatus, { value: item.couponStatus })?.name || '',
      action: (
        <div className='d-flex justify-content-center flex-shrink-0'>
          <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm me-1'>
            <i className='fa-solid fa-pen-to-square' />
          </a>
          {item.couponUsedNumber < 1 && (
            <a onClick={() => confirmRemove(item)} className='btn btn-icon btn-light-danger btn-sm'>
              <i className='fa-solid fa-trash' />
            </a>
          )}
        </div>
      ),
    }
  })

  const handleCreate = () => {
    setIsEdit(true)
    setCoupon({ id: GUID_NEWID(), isAdded: true })
  }

  const handleEdit = (item) => {
    setCoupon(item)
    setIsEdit(true)
  }

  const confirmRemove = (item) => {
    setConfirmVisible(true)
    setCoupon(item)
  }

  const handleRemove = async () => {
    setIsLoading(true)
    if (coupon.couponUsedNumber > 0) {
      return
    }
    try {
      const { error } = await deleteCoupon(coupon.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        setCoupons(coupons.filter((item) => item.id !== coupon.id))
        setCouponOriginals(coupons.filter((item) => item.id !== coupon.id))
        setConfirmVisible(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancelRemove = () => {
    setConfirmVisible(false)
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize)
  }

  const handleSearch = (query) => {
    query = query.trim().toLowerCase()
    if (!_.isEmpty(query)) {
      const filteredData = couponOriginals.filter((item) => item.couponCode.toLowerCase().includes(query))
      setCoupons(filteredData)
    } else {
      setCoupons(couponOriginals)
    }
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='card-title'>
          <a onClick={() => handleCreate()} className='btn btn-sm btn-success me-3'>
            <Icon iconName='plus' className='fs-3' />
            {intl.formatMessage({ id: 'AddNew' })}
          </a>
        </div>

        <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover'>
          <TableSearch onSearch={handleSearch} />
        </div>
      </div>

      <div className='card-body py-2 pt-0'>
        <div className='table-responsive'>
          <Table columns={columns} data={rows} />
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handlePageSizeChange}
          />
          {isLoading && <Loading />}
        </div>
      </div>

      {isEdit && <CouponModal editCoupon={coupon} closeModal={setIsEdit} fetchCoupons={fetchCoupons} />}

      {confirmVisible && (
        <ConfirmRemove
          onConfirm={handleRemove}
          onCancel={handleCancelRemove}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}
    </div>
  )
}

export default Coupon
