import React, { useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import { ControlType, FormControl } from '../components/FormControl'
import Button from '../components/Button'
import { validationAllComponents } from '../../../utilities/shareFunction'
import { changePassword } from '../../../redux/actions/auth/userActions'
import { Auth } from '../../../components/auth/Auth'
import { showToast, TOAST_TYPES } from '../../../helpers/Toastify'
import _ from 'lodash'

const ChangePassword = () => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const currentUser = Auth.getUserData() || {}

  const [currentPassword, setCurrentPassword] = useState(null)
  const [newPassword, setNewPassword] = useState(null)
  const [confirmNewPassword, setConfirmNewPassword] = useState(null)
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(null)
  const [error, setError] = useState('')

  const handleSave = async (e) => {
    e.preventDefault()
    let isValid = await validationAllComponents(componentRefs)
    if (!isValid || !isValidConfirmPassword) return

    const password = {
      id: currentUser.id,
      currentPassword: currentPassword,
      newPassword: newPassword,
    }
    const { error } = await changePassword(password)
    if (_.isEmpty(error)) {
      setError('')
      setCurrentPassword('')
      setNewPassword('')
      setConfirmNewPassword('')
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
    } else {
      setError(error.message)
    }
  }

  const validatorConfirmNewPassword = () => {
    setIsValidConfirmPassword(true)
    if (_.isEmpty(newPassword) || _.isEmpty(confirmNewPassword)) return
    if (newPassword !== confirmNewPassword) {
      setIsValidConfirmPassword(false)
      return { error: true, message: `${intl.formatMessage({ id: 'User.ResetPassword.ValidatorConfirmPassword' })}` }
    }
  }

  const formattedError = error?.includes('\n')
    ? error.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))
    : error

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'User.ResetPassword' })}</h3>
        </div>
      </div>
      <div id='kt_account_settings_signin_method' className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='flex-row-fluid'>
            <form id='kt_signin_change_password' className='form' novalidate='novalidate'>
              <div className='d-flex flex-column justify-content-center align-items-center'>
                {error && (
                  <div className='alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formattedError}</div>
                  </div>
                )}
                <div className='mb-4 col-6'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.currentPassword = ref)}
                    tabIndex={1}
                    className={'form-control form-control-lg'}
                    type={'password'}
                    controlType={ControlType.Input}
                    label={intl.formatMessage({ id: 'User.ResetPassword.CurrentPassword' })}
                    value={currentPassword}
                    isRequired={true}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </div>
                <div className='mb-4 col-6'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.newPassword = ref)}
                    tabIndex={2}
                    className={'form-control form-control-lg'}
                    type={'password'}
                    controlType={ControlType.Input}
                    label={intl.formatMessage({
                      id: 'User.ResetPassword.NewPassword',
                    })}
                    value={newPassword}
                    isRequired={true}
                    isPassword={true}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <div className='form-text'>{intl.formatMessage({ id: 'User.ResetPassword.Note' })}</div>
                </div>
                <div className='mb-8 col-6'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.confirmNewPassword = ref)}
                    tabIndex={3}
                    className={'form-control form-control-lg'}
                    type={'password'}
                    controlType={ControlType.Input}
                    label={intl.formatMessage({
                      id: 'User.ResetPassword.ConfirmNewPassword',
                    })}
                    value={confirmNewPassword}
                    isRequired={true}
                    isPassword={true}
                    validator={() => validatorConfirmNewPassword()}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                </div>

                <Button onClick={(e) => handleSave(e)} icon='fa-save' title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
