import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'
import { Auth } from '../../components/auth/Auth'
import * as featureApi from '../../redux/actions/auth/featureAction'

class AuthApi extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.ACCOUNT)
    this.adapter = this.initAdapter(conf)
  }

  async login(payload) {
    const { error, result: authResult } = await this.adapter.postAsync('Login', { payload })
    if (authResult) {
      const { result: modules } = await featureApi.getFeauturesByUserId({ userId: authResult.user.id })
      Auth.saveUserData(authResult.user, authResult.token, modules)
      return authResult
    }
    return { error: true, message: error.message }
  }

  async logout() {
    await this.adapter.postAsync('account/Logout')
    Auth.clearUserData()
  }

  async resetPassword(payload) {
    payload =  { ...payload, loginPage: 88889999 }
    return await this.adapter.postAsync('resetPassword', { payload })
  }

  async newPassword(payload) {
    return await this.adapter.postAsync('newPassword', { payload })
  }
}

export default AuthApi
