import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { getIntoCombobox } from '../../../redux/actions/gnr/danhMucActions'
import { TABLE_NAME } from '../../../utilities/constant'
import { validationAllComponents } from '../../../utilities/shareFunction'
import _ from 'lodash'
import { TOAST_TYPES, showToast } from '../../../helpers/Toastify'
import { ControlType, FormControl } from '../../pages/components/FormControl'
import BackButton from '../../pages/components/BackButton'
import Button from '../../pages/components/Button'
import { customerRegister } from '../../../redux/actions/aff/affiliateActions'
import Loading from '../../pages/components/Loading'

const CustomerForm = () => {
  const intl = useIntl()
  const componentRefs = useRef({})

  const [genders, setGenders] = useState([])

  const [affiliateName, setAffiliateName] = useState(null)
  const [birthday, setBirthday] = useState(null)
  const [genderId, setGenderId] = useState(null)
  const [isVietnamese, setIsVietnamese] = useState(true)
  const [phone, setPhone] = useState(null)
  const [email, setEmail] = useState(null)
  const [address, setAddress] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isSaveSuccess, setIsSaveSuccess] = useState(false)

  useEffect(() => {
    const fetchDataCombobox = async () => {
      try {
        const { result } = await getIntoCombobox({ tableName: TABLE_NAME.GENDER, isActive: true })
        setGenders(result ?? [])
      } catch (error) {
        console.error(error)
      }
    }

    fetchDataCombobox()
  }, [])

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    setIsLoading(true)

    try {
      const affSave = {
        affiliateName,
        birthday,
        genderId,
        isVietnamese,
        phone,
        email,
        address,
        isActive: true,
        isAffiliate: false,
      }

      const { error } = await customerRegister(affSave)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        setIsSaveSuccess(true)
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
        console.log(error)
      }
    } catch (error) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={`card mb-5 mb-xl-8`}>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'Customer.Modal.Add' })}</h3>
        </div>
        <p className='mt-2'>{intl.formatMessage({ id: 'Customer.Modal.Description' })}</p>
      </div>
      <div className='card-header border-0 pt-5'>
        <form id='form' className='form'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.affiliateName = ref)}
                  tabIndex={1}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({ id: 'Affiliate.Label.AffiliateName' })}
                  value={affiliateName}
                  isRequired={true}
                  onChange={(e) => setAffiliateName(e.target.value)}
                />
              </div>
            </div>

            <div className='col-md-12'>
              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.birthday = ref)}
                  tabIndex={2}
                  controlType={ControlType.DatePicker}
                  label={intl.formatMessage({ id: 'Affiliate.Label.Birthday' })}
                  value={birthday}
                  isRequired={true}
                  onChange={(date) => setBirthday(date)}
                />
              </div>
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.genderId = ref)}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Affiliate.Label.Gender' })}
                value={genderId}
                isRequired={true}
                onChange={(e) => setGenderId(e.value)}
                options={genders}
              />
            </div>

            <div className='col-md-12 mb-3 p-0'>
              <FormControl
                ref={(ref) => (componentRefs.current.isVietnamese = ref)}
                tabIndex={6}
                controlType={ControlType.Checkbox}
                label={intl.formatMessage({ id: 'Affiliate.Label.IsVietnamese' })}
                value={isVietnamese}
                onChange={(e) => setIsVietnamese(e.target.checked)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.phone = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.Label.Phone' })}
                value={phone}
                isRequired={true}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.email = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Affiliate.Label.Email' })}
                value={email}
                isEmail={true}
                isRequired={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.address = ref)}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Affiliate.Label.Address' })}
                value={address}
                isRequired={true}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>

          <div className='text-center pt-5 pb-5'>
            <BackButton className='me-3' />
            {!isSaveSuccess && (
              <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} icon='fa-save' />
            )}
          </div>

          {isLoading && <Loading />}
        </form>
      </div>
    </div>
  )
}

export default CustomerForm
