import AuthApi from './auth/authApi'
import DanhMucAPI from './gnr/danhMucApi'
import RoleApi from './auth/roleApi'
import UserApi from './auth/userApi'
import FeatureApi from './auth/featureApi'
import FileAttachmentAPI from './gnr/fileAttachmentApi'
import BannerAPI from './eco/bannerApi'
import SupplierAPI from './eco/supplierApi'
import ProductAPI from './eco/productApi'
import AffiliateAPI from './aff/affiliateApi'
import CategoryAPI from './eco/categoryApi'
import SubCategoryAPI from './eco/subCategoryApi'
import CouponAPI from './eco/couponApi'
import NewsAPI from './eco/newsApi'
import OrderAPI from './eco/orderApi'
import QuestionAPI from './eco/questionApi'
import NotificationAPI from './eco/notificationApi'
import FeaturedProductAPI from './eco/featuredProductApi'
import BlogApi from './eco/blogApi'
import PromotionApi from './eco/promotionApi'
import ProductPromotionApi from './eco/productPromotionApi'
import WarehouseAPI from './eco/warehouseApi'
import WarehouseReceiveApi from './eco/warehouseReceiveApi'
import WarehouseDispatchApi from './eco/warehouseDispatchApi'
import ProvinceAPI from './gnr/provinceApi'
import LevelApi from './aff/levelApi'
import LevelGroupApi from './aff/levelGroupApi'
import ProductPropertyApi from './gnr/productPropertyApi'

const DanhMuc = new DanhMucAPI()
const Auth = new AuthApi()
const Role = new RoleApi()
const User = new UserApi()
const Feature = new FeatureApi()
const FileAttachment = new FileAttachmentAPI()
const Banner = new BannerAPI()
const Coupon = new CouponAPI()
const Order = new OrderAPI()
const News = new NewsAPI()
const Supplier = new SupplierAPI()
const Product = new ProductAPI()
const Affiliate = new AffiliateAPI()
const Category = new CategoryAPI()
const SubCategory = new SubCategoryAPI()
const Question = new QuestionAPI()
const Notification = new NotificationAPI()
const FeaturedProduct = new FeaturedProductAPI()
const Blog = new BlogApi()
const Promotion = new PromotionApi()
const ProductPromotion = new ProductPromotionApi()
const Warehouse = new WarehouseAPI()
const WarehouseReceive = new WarehouseReceiveApi()
const WarehouseDispatch = new WarehouseDispatchApi()
const Province = new ProvinceAPI()
const Level = new LevelApi()
const LevelGroup = new LevelGroupApi()
const ProductProperty = new ProductPropertyApi()

export {
  DanhMuc,
  Auth,
  Role,
  User,
  Feature,
  FileAttachment,
  Banner,
  Coupon,
  News,
  Supplier,
  Product,
  Affiliate,
  Category,
  SubCategory,
  Question,
  Order,
  Notification,
  FeaturedProduct,
  Blog,
  Promotion,
  ProductPromotion,
  Warehouse,
  WarehouseReceive,
  WarehouseDispatch,
  Province,
  Level,
  LevelGroup,
  ProductProperty
}
