import { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import TableSearch from '../../components/TableSearch'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import { getByProductId } from '../../../../redux/actions/eco/warehouseDispatchActions'

const Dispatch = ({ productId }) => {
  const intl = useIntl()
  const [warehouseReceives, setWarehouseReceives] = useState([])
  const [warehouseReceivesOriginal, setWarehouseReceivesOriginal] = useState([])
  const [pageIndex, setPageIndex] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const { result } = await getByProductId(productId, pageIndex, pageSize)
      if (result) {
        setWarehouseReceives(result.items)
        setWarehouseReceivesOriginal(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'SerialNumber' })}`,
      accessor: 'sno',
      width: 40,
    },
    {
      Header: `${intl.formatMessage({ id: 'Dispatch.Column.Date' })}`,
      accessor: 'receivedDate',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Receiving.Column.Quantity' })}`,
      accessor: 'quantity',
      width: 60,
    },
    {
      Header: `${intl.formatMessage({ id: 'Receiving.Column.Description' })}`,
      accessor: 'description'
    },
  ]
  const rows = warehouseReceives?.map((item, index) => {
    return {
      sno: index + 1,
      code: item.code,
      receivedDate: item.receivedDateStr,
      quantity: item.quantity,
      description: item.description
    }
  })

  return (
    <div className='card card-flush py-4'>
      <div className='card-header'>
        <div className='card-title'>
          <h2>{intl.formatMessage({ id: 'Menu.Eco.WarehouseReceiving' })}</h2>
        </div>
        <div className='card-toolbar'>
          <TableSearch onSearch={setWarehouseReceives} originalData={warehouseReceivesOriginal} />
        </div>
      </div>
      <div className='card-body pt-0'>
        <Table columns={columns} data={rows} />

        <Pagination
          totalPages={totalPages}
          pageIndex={pageIndex}
          itemsPerPage={pageSize}
          onPageChange={(e) => setPageIndex(e)}
          onItemsPerPageChange={(e) => setPageSize(e)}
        />
      </div>
    </div>
  )
}

export default Dispatch
