import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { getIntoCombobox } from '../../../redux/actions/gnr/danhMucActions'
import { TABLE_NAME } from '../../../utilities/constant'
import { validationAllComponents } from '../../../utilities/shareFunction'
import { saveSupplier } from '../../../redux/actions/eco/supplierActions'
import _ from 'lodash'
import { TOAST_TYPES, showToast } from '../../../helpers/Toastify'
import { ControlType, FormControl } from '../../pages/components/FormControl'
import BackButton from '../../pages/components/BackButton'
import Button from '../../pages/components/Button'

const SupplierForm = () => {
  const intl = useIntl()
  const componentRefs = useRef({})

  const [partnerTypes, setPartnerTypes] = useState([])
  const [businessIndustries, setBusinessIndustries] = useState([])
  const [serviceFees, setServiceFees] = useState([])
  const [supplierMembershipFees, setSupplierMembershipFees] = useState([])
  const [productLicenseFiles, setProductLicenseFiles] = useState([])
  const [businessLicenseFiles, setBusinessLicenseFiles] = useState([])
  const [isSaveSuccess, setIsSaveSuccess] = useState(false)

  const isPersonalOptions = [
    { value: 'a-0', label: intl.formatMessage({ id: 'Supplier.Radio.Company' }) },
    { value: 'a-1', label: intl.formatMessage({ id: 'Supplier.Radio.Personal' }) },
  ]

  const isAgreeOptions = [
    { value: 'b-1', label: intl.formatMessage({ id: 'Supplier.Radio.Yes' }) },
    { value: 'b-0', label: intl.formatMessage({ id: 'Supplier.Radio.No' }) },
  ]

  const [fullName, setFullName] = useState(null)
  const [companyName, setCompanyName] = useState(null)
  const [email, setEmail] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [website, setWebsite] = useState(null)
  const [businessCode, setBusinessCode] = useState(null)
  const [partnerTypeId, setPartnerTypeId] = useState(null)
  const [isPersonal, setIsPersonal] = useState(null)
  const [businessAddress, setBusinessAddress] = useState(null)
  const [businessIndustryId, setBusinessIndustryId] = useState(null)
  const [businessIndustryOther, setBusinessIndustryOther] = useState(null)
  const [serviceFeeId, setServiceFeeId] = useState(null)
  const [supplierMembershipFeeId, setSupplierMembershipFeeId] = useState(null)
  const [isAgree, setIsAgree] = useState(null)

  useEffect(() => {
    const fetchDataCombobox = async () => {
      try {
        const { result: partnerResult } = await getIntoCombobox({ tableName: TABLE_NAME.PARTNER_TYPE, isActive: true })
        if (partnerResult) {
          setPartnerTypes(partnerResult)
        }

        const { result: biResult } = await getIntoCombobox({ tableName: TABLE_NAME.BUSINESS_INDUSTRY, isActive: true })
        if (biResult) {
          setBusinessIndustries(biResult)
        }

        const { result: sfResult } = await getIntoCombobox({ tableName: TABLE_NAME.SERVICE_FEE, isActive: true })
        if (sfResult) {
          setServiceFees(sfResult)
        }

        const { result: mbResult } = await getIntoCombobox({ tableName: TABLE_NAME.SUPPLIER_MEMBERSHIP_FEE, isActive: true })
        if (mbResult) {
          setSupplierMembershipFees(mbResult)
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchDataCombobox()
  }, [])

  const handleChangeProductLicenseFile = (files) => {
    setProductLicenseFiles(files)
  }

  const handleChangeBusinessLicenseFile = (files) => {
    setBusinessLicenseFiles(files)
  }

  const handleIsPesonalOptionChange = (value) => {
    setIsPersonal(value)
  }

  const handleIsAgreeOptionChange = (value) => {
    setIsAgree(value)
  }

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const newSupplier = {
      fullName,
      companyName,
      email,
      phoneNumber,
      website,
      businessCode,
      partnerTypeId,
      isPersonal: isPersonal === 'a-1' ? true : false,
      businessIndustryId,
      businessIndustryOther,
      serviceFeeId,
      supplierMembershipFeeId,
      isAgree: isAgree === 'b-1' ? true : false,
      businessAddress,
      businessLicenseFiles,
      productLicenseFiles,
    }
    const { error, result } = await saveSupplier(newSupplier)
    if (_.isEmpty(error) && result) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      setIsSaveSuccess(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <div className={`card mb-5 mb-xl-8 pt-5`}>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'Supplier.Modal.Add' })}</h3>
        </div>
        <p className='mt-2'>{intl.formatMessage({ id: 'Supplier.Modal.Description' })}</p>
      </div>
      <div className='card-header border-0 pt-5'>
        <form id='form' className='form'>
          <div className='row'>
            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.companyName = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Supplier.Label.CompanyName' })}
                value={companyName}
                isRequired={true}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.businessCode = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Supplier.Label.BusinessCode' })}
                value={businessCode}
                isRequired={true}
                onChange={(e) => setBusinessCode(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.partnerTypeId = ref)}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Supplier.Label.PartnerType' })}
                value={partnerTypeId}
                isRequired={true}
                onChange={(e) => setPartnerTypeId(e.value)}
                options={partnerTypes}
              />
            </div>

            <div className='col-md-12 mb-6'>
              <FormControl
                ref={(ref) => (componentRefs.current.isPersonal = ref)}
                controlType={ControlType.RadioButton}
                label={intl.formatMessage({ id: 'Supplier.Label.IsPersonal' })}
                value={isPersonal}
                isRequired={true}
                onChange={handleIsPesonalOptionChange}
                options={isPersonalOptions}
                isHorizontal={true}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.businessAddress = ref)}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Supplier.Label.BusinessAddress' })}
                value={businessAddress}
                isRequired={true}
                onChange={(e) => setBusinessAddress(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.fullName = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Supplier.Label.FullName' })}
                value={fullName}
                isRequired={true}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.businessIndustryId = ref)}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Supplier.Label.BusinessIndustry' })}
                value={businessIndustryId}
                isRequired={true}
                onChange={(e) => setBusinessIndustryId(e.value)}
                options={businessIndustries}
              />
            </div>

            {businessIndustryId === 9 && (
              <div className='col-md-12 mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.businessIndustryOther = ref)}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({ id: 'Supplier.Label.BusinessIndustryOther' })}
                  value={businessIndustryOther}
                  isRequired={true}
                  onChange={(e) => setBusinessIndustryOther(e.target.value)}
                />
              </div>
            )}

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.email = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Supplier.Label.Email' })}
                value={email}
                isEmail={true}
                isRequired={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.phoneNumber = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Supplier.Label.PhoneNumber' })}
                value={phoneNumber}
                isRequired={true}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.website = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Supplier.Label.Website' })}
                value={website}
                isRequired={true}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.serviceFeeId = ref)}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Supplier.Label.ServiceFee' })}
                value={serviceFeeId}
                isRequired={true}
                onChange={(e) => setServiceFeeId(e.value)}
                options={serviceFees}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.supplierMembershipFeeId = ref)}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Supplier.Label.SupplierMembershipFee' })}
                value={supplierMembershipFeeId}
                isRequired={true}
                onChange={(e) => setSupplierMembershipFeeId(e.value)}
                options={supplierMembershipFees}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.businessLicenseFiles = ref)}
                controlType={ControlType.AttachFile}
                label={intl.formatMessage({ id: 'Supplier.Label.BusinessLicense' })}
                value={businessLicenseFiles}
                onChange={handleChangeBusinessLicenseFile}
                isRequired={true}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.productLicenseFiles = ref)}
                controlType={ControlType.AttachFile}
                label={intl.formatMessage({ id: 'Supplier.Label.ProductLicense' })}
                value={productLicenseFiles}
                onChange={handleChangeProductLicenseFile}
                isRequired={true}
              />
            </div>

            <div className='col-md-12 mb-6'>
              <FormControl
                ref={(ref) => (componentRefs.current.isAgree = ref)}
                controlType={ControlType.RadioButton}
                label={intl.formatMessage({ id: 'Supplier.Label.IsAgree' })}
                value={isAgree}
                isRequired={true}
                onChange={handleIsAgreeOptionChange}
                options={isAgreeOptions}
                isHorizontal={true}
              />
            </div>
          </div>

          <div className='text-center pt-5 pb-5'>
            <BackButton className='me-3' />
            {!isSaveSuccess && (
              <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} icon='fa-save' />
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default SupplierForm
