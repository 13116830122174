import { DEFAULT_VALUE } from './constant'
import _ from 'lodash'

const htmlDecode = (input) => {
  let doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

const deepEqual = (valueA, valueB) => _.isEqual(_.isEmpty(valueA) ? null : valueA, _.isEmpty(valueB) ? null : valueB)

const isValidEmail = (email) => {
  const emailRegex = DEFAULT_VALUE.REGEX_EMAIL
  return emailRegex.test(email)
}

const isValidPassword = (password) => {
  const passRegex = DEFAULT_VALUE.REGEX_PASSWORD
  return passRegex.test(password)
}

const isValidPhoneNumber = (phoneNumber) => {
  const phoneNumberRegex = DEFAULT_VALUE.REGEX_PHONENUMBER
  return phoneNumberRegex.test(phoneNumber)
}

function formatString(template, ...args) {
  return template.replace(/{(\d+)}/g, (match, index) => {
    const argIndex = parseInt(index, 10)
    return args[argIndex] !== undefined ? args[argIndex] : match
  })
}

function formatDateTime(date) {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)

  if (isSameDay(date, today)) {
    return `Today at ${formatTime(date)}`
  } else if (isSameDay(date, yesterday)) {
    return `Yesterday at ${formatTime(date)}`
  } else {
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${String(year).padStart(4, '0')} at ${formatTime(date)}`
  }
}

function formatDate(date) {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${String(year).padStart(4, '0')}`
}

function formatTime(date) {
  const hours = date.getHours()
  const minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 || 12
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
  return `${String(formattedHours).padStart(2, '0')}:${String(formattedMinutes).padStart(2, '0')} ${ampm}`
}

function isSameDay(date1, date2) {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()
}

const validationAllComponents = async (componentRefs) => {
  let isValid = true
  await _.map(componentRefs.current, async (ref) => {
    if (ref && !(await ref.validateData())) {
      isValid = false
    }
  })
  return isValid
}

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1
  return len > 0 ? new Array(len).join(chr || '0') + this : this
}

const getNewDateString = (separator) => {
  const rightNow = new Date()
  separator = separator ?? '_'
  const res =
    [rightNow.getFullYear(), (rightNow.getMonth() + 1).padLeft(), rightNow.getDate().padLeft()].join(separator) +
    separator +
    [rightNow.getHours().padLeft(), rightNow.getMinutes().padLeft(), rightNow.getSeconds().padLeft()].join(separator)
  return res
}

const parseHTML = (htmlString) => {
  if (_.isEmpty(htmlString)) {
    return htmlString
  }
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  const content = doc.body.firstChild.innerText
  return content
}

export {
  htmlDecode,
  deepEqual,
  isValidEmail,
  isValidPassword,
  isValidPhoneNumber,
  getNewDateString,
  formatString,
  formatDateTime,
  validationAllComponents,
  parseHTML,
  formatDate,
}
