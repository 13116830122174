import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class CouponAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.COUPON)
    this.adapter = this.initAdapter(conf)
  }

  async getCoupons(payload) {
    return await this.adapter.postAsync('getCoupons', { payload })
  }

  async saveCoupon(payload) {
    return await this.adapter.postAsync('saveCoupon', { payload })
  }

  async updateCoupon(payload) {
    return await this.adapter.putAsync('updateCoupon', { payload })
  }

  async deleteCoupon(couponId) {
    return await this.adapter.deleteAsync(`deleteCoupon?id=${couponId}`)
  }

  async getCouponById(couponId) {
    return await this.adapter.getAsync(`getById?id=${couponId}`)
  }

  async getNewCouponCode() {
    return await this.adapter.getAsync(`getNewCouponCode`)
  }
}

export default CouponAPI
