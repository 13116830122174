import React from 'react';


class NotFound extends React.Component {
    state = {  } 
    render() { 
        return <h1>Not Found</h1>;
    }
}
 
export default NotFound;