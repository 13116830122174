/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { getOrderById, preparingGoods, waitForDelivery, complete } from '../../../../redux/actions/eco/orderActions'
import { PageTitle } from '../../PageData'
import { useIntl, FormattedMessage } from 'react-intl'
import { useParams, Link } from 'react-router-dom'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import NumberBox from '../../components/NumberBox'
import { Icon } from '../../../../helpers'
import { statusColor, btnText } from '.'
import _ from 'lodash'
import BackButton from '../../components/BackButton'
import { OrderStatusEnum } from '../../../../utilities/enums'
import Confirm from '../../components/Confirm'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import '../../../../assets/sass/purchase.scss'

const OrderDetail = () => {
  const intl = useIntl()
  const { orderId } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [order, setOrder] = useState(null)

  const breadCrumbs = [
    { title: intl.formatMessage({ id: 'Menu.Eco.OrderManagement' }), path: '#' },
    { title: intl.formatMessage({ id: 'Menu.Eco.Order' }), path: 'eco/order/list' },
  ]

  const fetchOrder = async () => {
    setIsLoading(true)
    try {
      const { result } = await getOrderById(orderId)
      if (result) {
        setOrder(result)
        console.log(result)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchOrder()
  }, [orderId])

  const updateStatus = async () => {
    const req = { orderId: order.id }

    const { error } =
      order.orderStatus === OrderStatusEnum.WaitForConfirm
        ? await preparingGoods(req)
        : order.orderStatus === OrderStatusEnum.PreparingGoods
        ? await waitForDelivery(req)
        : order.orderStatus === OrderStatusEnum.WaitForDelivery
        ? await complete(req)
        : null

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.Success' })}`, TOAST_TYPES.SUCCESS)
      setConfirm(false)
      fetchOrder()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{intl.formatMessage({ id: 'Order.Column.OrderDetail' })}</PageTitle>

      <div className='d-flex flex-column flex-xl-row gap-5'>
        <div className='card card-flush py-4 flex-row-fluid'>
          <div className='card-header'>
            <div className='card-title gap-2'>
              <h2>
                <FormattedMessage id='Order.Column.OrderDetail' /> (#{order?.orderCode})
              </h2>
              <div className={`badge bg-${statusColor[order?.orderStatus]}`}>
                <FormattedMessage id={`Purchase.${order?.orderStatusName}`} />
              </div>
            </div>
          </div>

          <div className='card-body pt-0'>
            <div className='table-responsive'>
              <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                <tbody className='fw-semibold'>
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <Icon iconName='calendar' className='fs-2 me-2' />
                        <FormattedMessage id='Order.Column.OrderDate' />
                      </div>
                    </td>
                    <td className='fw-bold text-end'>{order?.orderDateStr}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <Icon iconName='wallet' className='fs-2 me-2' />
                        <FormattedMessage id='Order.Column.PaymentMethodName' />
                      </div>
                    </td>
                    <td className='fw-bold text-end'>
                      <FormattedMessage id={`Enum.${order?.paymentMethodName}`} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <Icon iconName='truck' className='fs-2 me-2' />
                        <FormattedMessage id='Order.Column.ShippingMethod' />
                      </div>
                    </td>
                    <td className='fw-bold text-end'>
                      <FormattedMessage id={`Enum.${order?.shippingMethodName}`} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <Icon iconName='geolocation' className='fs-2 me-2' />
                        <FormattedMessage id='Order.Column.ShippingAddress' />
                      </div>
                    </td>
                    <td className='fw-bold text-end'>{order?.address}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='card card-flush py-4 flex-row-fluid'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>
                <FormattedMessage id='Order.Column.CustomerDetails' />
              </h2>
            </div>
          </div>

          <div className='card-body pt-0'>
            <div className='table-responsive'>
              <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                <tbody className='fw-semibold'>
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <Icon iconName='profile-circle' className='fs-2 me-2' />
                        <FormattedMessage id='Order.Column.Customer' />
                      </div>
                    </td>

                    <td className='fw-bold text-end'>
                      <div className='d-flex align-items-center justify-content-end'>
                        <div className='symbol symbol-circle symbol-25px overflow-hidden me-3'>
                          <div className='symbol-label'>
                            <img
                              src={_.isEmpty(order?.customer.avatar) ? '/media/no-avatar.png' : DEFAULT_VALUE.BASE_URL + order?.customer.avatar}
                              alt={order?.fullName}
                              className='w-100'
                            />
                          </div>
                        </div>
                        <span>{order?.customer.fullName} </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <Icon iconName='sms' className='fs-2 me-2' />
                        <FormattedMessage id='User.Email' />
                      </div>
                    </td>
                    <td className='fw-bold text-end'>
                      <span>{order?.customer.email} </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='d-flex align-items-center'>
                        <Icon iconName='phone' className='fs-2 me-2' />
                        <FormattedMessage id='User.PhoneNumber' />
                      </div>
                    </td>
                    <td className='fw-bold text-end'>{order?.customer.phoneNumber}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className='card card-flush my-5'>
        <div className='card-body py-2 pt-0'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-dashed gy-5 mb-0'>
              <thead>
                <tr className='text-start fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-175px'>
                    <FormattedMessage id='Product' />
                  </th>
                  <th className='min-w-100px text-end'>
                    <FormattedMessage id='Product.Column.ProductCode' />
                  </th>
                  <th className='min-w-70px text-end'>
                    <FormattedMessage id='Quantity' />
                  </th>
                  <th className='min-w-100px text-end'>
                    <FormattedMessage id='Price' />
                  </th>
                  <th className='min-w-100px text-end'>
                    <FormattedMessage id='Total' />
                  </th>
                </tr>
              </thead>
              <tbody className='fw-semibold'>
                {order?.orderDetails?.map((detail) => {
                  return (
                    <tr key={detail.id}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <Link to={`/eco/product/${detail.productId}/edit`} className='symbol symbol-50px'>
                            <span className='symbol-label' style={{ backgroundImage: `url(${DEFAULT_VALUE.BASE_URL}${detail.productImage})` }}></span>
                          </Link>
                          <div className='ms-5'>
                            <Link to={`/eco/product/${detail.productId}/edit`} className='fw-bold text-gray-600 text-hover-primary purchase-product-name'>
                              {detail.productName}
                            </Link>
                            <div className='fs-7 text-muted purchase-product-name'>{detail.productPropertyName}</div>
                          </div>
                        </div>
                      </td>
                      <td className='text-end'>{detail.productCode}</td>
                      <td className='text-end'>
                        <NumberBox type='text' value={detail.quantity} className='' />
                      </td>
                      <td className='text-end'>
                        <NumberBox type='text' value={detail.discountedPrice} className='' suffix='đ' />
                      </td>
                      <td className='text-end'>
                        <NumberBox type='text' value={detail.totalAmount} className='' suffix='đ' />
                      </td>
                    </tr>
                  )
                })}
                <tr>
                  <td colspan='4' className='text-end'>
                    <FormattedMessage id='Total' />
                  </td>
                  <td className='text-end'>
                    <NumberBox type='text' value={order?.totalAmount} className='' suffix='đ' />
                  </td>
                </tr>
                <tr>
                  <td colspan='4' className='text-end'>
                    <FormattedMessage id='TotalDiscountVoucher' />
                  </td>
                  <td className='text-end'>
                    <NumberBox type='text' value={order?.totalDiscount} className='' suffix='đ' />
                  </td>
                </tr>
                <tr>
                  <td colspan='4' className='text-end'>
                    <FormattedMessage id='ShippingRate' />
                  </td>
                  <td className='text-end'>
                    <NumberBox type='text' value={order?.shippingRate} className='' suffix='đ' />
                  </td>
                </tr>
                <tr>
                  <td colspan='4' className='fs-3 text-gray-900 text-end'>
                    <FormattedMessage id='GrandTotal' />
                  </td>
                  <td className='text-gray-900 fs-3 fw-bolder text-end'>
                    <NumberBox type='text' value={order?.grandTotal} className='' suffix='đ' />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className='d-flex justify-content-center bg-white p-4 rounded gap-3'>
        <BackButton link={'/eco/order/list'} />
        {(order?.orderStatus === OrderStatusEnum.WaitForConfirm ||
          order?.orderStatus === OrderStatusEnum.PreparingGoods ||
          order?.orderStatus === OrderStatusEnum.WaitForDelivery) && (
          <a
            onClick={() => setConfirm(true)}
            className={`btn btn-${statusColor[order?.orderStatus]} btn-sm`}
            title={intl.formatMessage({ id: btnText[order?.orderStatus] })}
          >
            <i className='fa fa-check' />
            <FormattedMessage id={btnText[order?.orderStatus]} />
          </a>
        )}
      </div>

      {confirm && (
        <Confirm
          onConfirm={updateStatus}
          onCancel={() => setConfirm(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Confirm' })}
        />
      )}
    </>
  )
}

export default OrderDetail
