import { Promotion as PromotionAPI } from '../../../services'

// Async actions

const getPromotions = async (payload) => {
    return await PromotionAPI.getPromotions(payload)
  }
  
  const getPromotionById = async (promotionId) => {
    return await PromotionAPI.getPromotionById(promotionId)
  }
  
  const savePromotion = async (payload) => {
    return await PromotionAPI.savePromotion(payload)
  }
  
  const updatePromotion = async (payload) => {
    return await PromotionAPI.updatePromotion(payload)
  }
  
  const deletePromotion = async (promotionId) => {
    return await PromotionAPI.deletePromotion(promotionId)
  }

  const getCurrentPromotions = async () => {
    return await PromotionAPI.getCurrentPromotions()
  }

  const getNewsPromotions = async (newsId) => {
    return await PromotionAPI.getNewsPromotions({newsId})
  }

export { getPromotions, getPromotionById, savePromotion, updatePromotion, deletePromotion, getCurrentPromotions, getNewsPromotions }
