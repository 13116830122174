import { useEffect, useState } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { useRef } from 'react'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { ControlType, FormControl } from '../../components/FormControl'
import { saveWarehouse, updateWarehouse, getWarehouseCode } from '../../../../redux/actions/eco/warehouseActions'
import Loading from '../../components/Loading'
import { Auth } from '../../../../components/auth/Auth'
import { ROLE_NAME } from '../../../../utilities/constant'
import { getDistrictsByProvinceId, getProvinces, getWardsByDistrictId } from '../../../../redux/actions/gnr/provinceActions'

const WarehouseModal = ({ editWarehouse, closeModal, fetchWarehouses }) => {
  const intl = useIntl()
  const currentUser = Auth.getUserData() || {}
  const componentRefs = useRef({})
  const [isLoading, setIsLoading] = useState(false)

  const [name, setName] = useState(editWarehouse?.name ?? null)
  const [code, setCode] = useState(editWarehouse?.code ?? null)
  const [isPrimary, setIsPrimary] = useState(editWarehouse?.isPrimary ?? null)
  const [provinceId, setProvinceId] = useState(editWarehouse?.provinceId ?? null)
  const [districtId, setDistrictId] = useState(editWarehouse?.districtId ?? null)
  const [wardId, setWardId] = useState(editWarehouse?.wardId ?? null)
  const [address, setAddress] = useState(editWarehouse?.address ?? null)

  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [wards, setWards] = useState([])

  useEffect(() => {
    if (editWarehouse.isAdded) {
      fetchGetWarehouseCode()
    }

    fetchProvinces()
  }, [])

  useEffect(() => {
    fetchDistricts()
  }, [provinceId])

  useEffect(() => {
    fetchWards()
  }, [districtId])

  const fetchGetWarehouseCode = async () => {
    try {
      const { result: rsCode } = await getWarehouseCode()
      setCode(rsCode)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchProvinces = async () => {
    const { result } = await getProvinces()
    if (result) {
      setProvinces(result)
    }
  }

  const fetchDistricts = async () => {
    const { result } = await getDistrictsByProvinceId({ provinceId, pageIndex: null, pageSize: null })
    if (result?.items) {
      setDistricts(result.items)
    }
  }

  const fetchWards = async () => {
    const { result } = await getWardsByDistrictId({ districtId, pageIndex: null, pageSize: null })
    if (result?.items) {
      setWards(result.items)
    }
  }

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return
    setIsLoading(true)

    const dataSave = {
      id: editWarehouse?.id,
      wareHouseSupplierId: editWarehouse?.wareHouseSupplierId,
      supplierId: currentUser.supplierId,
      code: editWarehouse?.code,
      name,
      isPrimary,
      provinceId,
      districtId,
      wardId,
      address,
    }

    const { error } = editWarehouse.isAdded ? await saveWarehouse(dataSave) : await updateWarehouse(dataSave)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      editWarehouse.isAdded = false
      fetchWarehouses()
      closeModal(false)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
    setIsLoading(false)
  }

  return (
    <Modal title={intl.formatMessage({ id: editWarehouse.isAdded ? 'AddNew' : 'Update' })} closeModal={closeModal} modalSize='mw-650px'>
      <form id='kt_modal_add_coupon_form' className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='mb-3 col-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.code = ref)}
                tabIndex={1}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Warehouse.Label.Code' })}
                value={code}
                readOnly={true}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div className='mb-3 col-9'>
              <FormControl
                ref={(ref) => (componentRefs.current.name = ref)}
                tabIndex={2}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Warehouse.Label.Name' })}
                value={name}
                isRequired={true}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.provinceId = ref)}
                tabIndex={3}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Warehouse.Label.Province' })}
                value={provinceId}
                isRequired={true}
                onChange={(e) => setProvinceId(e.value)}
                options={provinces}
              />
            </div>
            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.districtId = ref)}
                tabIndex={4}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Warehouse.Label.District' })}
                value={districtId}
                isRequired={true}
                onChange={(e) => setDistrictId(e.value)}
                options={districts}
              />
            </div>
            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.wardId = ref)}
                tabIndex={5}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Warehouse.Label.Ward' })}
                value={wardId}
                isRequired={true}
                onChange={(e) => setWardId(e.value)}
                options={wards}
              />
            </div>
            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.address = ref)}
                tabIndex={6}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Warehouse.Label.Street' })}
                value={address}
                isRequired={true}
                onChange={(e) => setAddress(e.target.value)}
                rows={2}
              />
            </div>
          </div>
        </div>
        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} icon='fa-times' tabIndex={7} />
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} icon='fa-save' tabIndex={8} />
        </div>
        {isLoading && <Loading />}
      </form>
    </Modal>
  )
}

export default WarehouseModal
