import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import _ from 'lodash'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import TableSearch from '../../components/TableSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import NewsModal from './NewsModal'
import ConfirmRemove from '../../components/Confirm'
import CKEditorDisplay from '../../components/CKEditorDisplay'
import { Icon } from '../../../../helpers'
import Checkbox from '../../components/Checkbox'
import { getNews, deleteNews } from '../../../../redux/actions/eco/newsActions'

const News = () => {
  const intl = useIntl()
  const [lstNews, setLstNews] = useState([])
  const [newsOriginals, setNewsOriginals] = useState([])
  const [news, setNews] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const [isEdit, setIsEdit] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)

  const fetchNews = async () => {
    setIsLoading(true)
    try {
      const { result } = await getNews({ pageIndex: currentPage, pageSize: pageSize })
      if (result) {
        setLstNews(result.items)
        setNewsOriginals(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchNews()
  }, [currentPage, pageSize])

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'News.Column.Title' })}`,
      accessor: 'title',
    },
    {
      Header: `${intl.formatMessage({ id: 'News.Promotion' })}`,
      accessor: 'promotionName',
    },
    {
      Header: `${intl.formatMessage({ id: 'News.Column.PublishDate' })}`,
      accessor: 'publishDate',
      with: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'News.Column.IsActive' })}`,
      accessor: 'isActive',
      width: 50,
    },
    {
      Header: `${intl.formatMessage({ id: 'News.Column.Action' })}`,
      accessor: 'action',
      with: 60,
    },
  ]
  const rows = lstNews.map((item) => {
    return {
      title: item.title,
      promotionName: item.promotionName,
      content: <CKEditorDisplay content={item.content} />,
      publishDate: item.publishDateStr,
      isActive: (
        <div className='d-flex justify-content-center'>
          <Checkbox checked={item.isActive} readOnly />
        </div>
      ),
      action: (
        <div className='d-flex justify-content-center flex-shrink-0'>
          <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm me-1'>
            <i className='fa-solid fa-pen-to-square' />
          </a>
          <a onClick={() => confirmRemove(item)} className='btn btn-icon btn-light-danger btn-sm'>
            <i className='fa-solid fa-trash' />
          </a>
        </div>
      ),
    }
  })

  const handleCreate = () => {
    setIsEdit(true)
    setNews({ id: DEFAULT_VALUE.GUID_RANDOM, isAdded: true })
  }

  const handleEdit = (item) => {
    setNews(item)
    setIsEdit(true)
  }

  const confirmRemove = (item) => {
    setConfirmVisible(true)
    setNews(item)
  }

  const handleRemove = async () => {
    setIsLoading(true)

    try {
      const { error } = await deleteNews(news.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        setLstNews(lstNews.filter((item) => item.id !== news.id))
        setNewsOriginals(lstNews.filter((item) => item.id !== news.id))
        setConfirmVisible(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancelRemove = () => {
    setConfirmVisible(false)
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize)
  }

  const handleSearch = (query) => {
    query = query.trim().toLowerCase()
    if (!_.isEmpty(query)) {
      const filteredData = newsOriginals.filter((item) => item.title.toLowerCase().includes(query))
      setLstNews(filteredData)
    } else {
      setLstNews(newsOriginals)
    }
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='card-title'>
          <a onClick={() => handleCreate()} className='btn btn-sm btn-success me-3'>
            <Icon iconName='plus' className='fs-3' />
            {intl.formatMessage({ id: 'AddNew' })}
          </a>
        </div>

        <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover'>
          <TableSearch onSearch={handleSearch} />
        </div>
      </div>

      <div className='card-body py-2 pt-0'>
        <div className='table-responsive'>
          <Table columns={columns} data={rows} />
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handlePageSizeChange}
          />
          {isLoading && <Loading />}
        </div>
      </div>

      {isEdit && <NewsModal editNews={news} closeModal={setIsEdit} fetchNews={fetchNews} />}

      {confirmVisible && (
        <ConfirmRemove
          onConfirm={handleRemove}
          onCancel={handleCancelRemove}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}
    </div>
  )
}

export default News
