import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useIntl } from 'react-intl'

const TableSearch = ({ onSearch, originalData }) => {
  const intl = useIntl()
  const [searchQuery, setSearchQuery] = useState('')
  const [timer, setTimer] = useState(null)

  useEffect(() => {
    clearTimeout(timer)
  }, [])

  const handleSearch = (searchValue) => {
    if (!_.isEmpty(searchValue)) {
      const filteredData = originalData?.filter((item) => {
        return Object.values(item).some((value) => {
          return !_.isEmpty(value) && _.deburr(_.toLower(value.toString())).includes(_.deburr(_.toLower(searchValue)))
        })
      })
      onSearch(filteredData)
    } else {
      onSearch(originalData)
    }
  }

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value)
    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      handleSearch(e.target.value)
    }, 1000)

    setTimer(newTimer)
  }

  return (
    <div className='d-flex justify-content-end'>
      <div className='d-flex align-items-center position-relative my-1'>
        <i className='fs-2 ms-3 position-absolute fa-solid fa-magnifying-glass pointer' onClick={(e) => handleSearch()}></i>
        <input
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-solid w-250px ps-14'
          placeholder={`${intl.formatMessage({ id: 'Search' })}...`}
          value={searchQuery}
          onChange={handleInputChange}
        />
      </div>
    </div>
  )
}

export default TableSearch
