import React, { useEffect, lazy, useState } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { App } from '../App'
import { AdminLayout } from '../components/layout'
import { AuthPage } from '../components/auth'
import Logout from '../components/auth/components/Logout'
import { loadRoutes, getFeatures } from './index'
import NotFound from '../components/pages/NotFound'
import { Auth } from '../components/auth/Auth'
import { SETTING } from '../utilities/constant'
import SupplierPage from '../components/pages/eco/Supplier/SupplierPage'
import ProductPage from '../components/pages/eco/Product/ProductPage'
import SupplierProfile from '../components/layout/SupplierLayout/SupplierRegister'
import AffiliateRegister from '../components/layout/AffiliateLayout/AffiliateRegister'
import CustomerRegister from '../components/layout/CustomerLayout/CustomerRegister'
import OrderDetail from '../components/pages/eco/Order/OrderDetail'

const UserProfile = lazy(() => import('../components/pages/user/UserProfile'))

const AppRoutes = () => {
  const intl = useIntl()
  const user = useSelector((state) => state.auth.user)
  const returnUrl = useSelector((state) => state.auth.returnUrl)
  const [features, setFeatures] = useState([])
  const [supplier, setSupplier] = useState([])
  const [product, setProduct] = useState([])
  const [userSetting, setUserSetting] = useState([])
  const isAuthenticated = !!Auth.getToken()
  const loginUrl = `/auth/login?returnUrl=${encodeURIComponent(window.location.href)}`

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userFeatures = await getFeatures()
        setFeatures(userFeatures?.filter((x) => x.id !== SETTING.USERGROUP) ?? [])
        setUserSetting(userFeatures?.find((x) => x.id === SETTING.USERGROUP) ?? [])
        setSupplier(userFeatures?.find((x) => x.id === SETTING.SUPPLIERGROUP) ?? [])
        setProduct(userFeatures?.find((x) => x.id === SETTING.PRODUCTGROUP) ?? [])
      } catch (error) {
        console.error('getFeatures:', error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (isAuthenticated && !_.isEmpty(returnUrl)) {
      window.location.href = returnUrl
    }
  }, [user, returnUrl])

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path='/notfound' element={<NotFound />} />
          <Route path='/logout' element={<Logout />} />
          {isAuthenticated ? (
            <Route element={<AdminLayout />}>
              <Route path='*' element={<UserProfile userSetting={userSetting} />} />
              <Route path='eco/supplier/:supplierId/*' element={<SupplierPage supplierRoute={supplier} />} />
              <Route path='eco/product/:productId/*' element={<ProductPage productRoute={product} />} />
              <Route path='eco/order/:orderId/*' element={<OrderDetail />} />
              <Route path='*' element={<Navigate to='/eco/dashboard' />} />
              <Route path='/' element={<Navigate to='/eco/dashboard' />} />
              {loadRoutes(features, intl)}
            </Route>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='supplier/register' element={<SupplierProfile />} />
              <Route path='affiliate/register' element={<AffiliateRegister />} />
              <Route path='customer/register' element={<CustomerRegister />} />
              <Route path='*' element={<Navigate to={loginUrl} />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
