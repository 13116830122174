import { Province as ProvinceAPI } from '../../../services'

// Async actions

const getProvinces = async () => {
  return await ProvinceAPI.getProvinces()
}

const getDistrictsByProvinceId = async (payload) => {
  return await ProvinceAPI.getDistrictsByProvinceId(payload)
}

const getWardsByDistrictId = async (payload) => {
  return await ProvinceAPI.getWardsByDistrictId(payload)
}

export { getProvinces, getDistrictsByProvinceId, getWardsByDistrictId }
