import React, { useState, useEffect } from 'react'
import UserMenu from './UserMenu'
import { getUserById } from '../../../../../redux/actions/auth/userActions'
import { DEFAULT_VALUE } from '../../../../../utilities/constant'
import _ from 'lodash'
import { Auth } from '../../../../auth/Auth'

const Footer = () => {
  const currentUser = Auth.getUserData() || {}
  const [user, setUser] = useState({})

  useEffect(() => {
    fetchUser()
  }, [])

  const fetchUser = async () => {
    try {
      const { result } = await getUserById({ id: currentUser?.id })
      if (result) {
        setUser(result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='aside-footer d-flex flex-column align-items-center flex-column-auto' id='kt_aside_footer'>
      <div className='d-flex align-items-center mb-10' id='kt_header_user_menu_toggle'>
        <div
          className='cursor-pointer symbol symbol-40px'
          data-kt-menu-trigger='click'
          data-kt-menu-overflow='false'
          data-kt-menu-placement='top-start'
          title='User profile'
        >
          <img src={!_.isEmpty(user?.avatar) ? DEFAULT_VALUE.BASE_URL + user?.avatar : '/media/no-avatar.png'} alt='avatar' />
        </div>
        <UserMenu />
      </div>
    </div>
  )
}

export default Footer
