import {Route, Routes} from 'react-router-dom'
import { AuthLayout } from '../../components/layout'
import Login from './components/Login'
import ForgotPassword from './components/ForgotPassword'
import NewPassword from './components/NewPassword'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='new-password' element={<NewPassword />} />
    </Route>
  </Routes>
)

export default AuthPage
