import { DanhMuc as DanhMucAPI } from '../../../services'

// Async actions

const getAll = async (payload) => {
  return await DanhMucAPI.getAll(payload)
}

const getIntoCombobox = async (payload) => {
  return await DanhMucAPI.getIntoCombobox(payload)
}

export { getAll, getIntoCombobox }