import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class WarehouseAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.WAREHOUSE)
    this.adapter = this.initAdapter(conf)
  }

  async getWarehouses(payload) {
    return await this.adapter.postAsync('getWarehouses', { payload })
  }

  async saveWarehouse(payload) {
    return await this.adapter.postAsync('saveWarehouse', { payload })
  }

  async updateWarehouse(payload) {
    return await this.adapter.putAsync('updateWarehouse', { payload })
  }

  async deleteWarehouse(warehouseId) {
    return await this.adapter.deleteAsync(`deleteWarehouse?id=${warehouseId}`)
  }

  async getWarehouseById(warehouseId) {
    return await this.adapter.getAsync(`getById?id=${warehouseId}`)
  }

  async getWarehousesBySupplierId(payload) {
    return await this.adapter.getAsync('getWarehousesBySupplierId', {payload})
  }

  async getAdminWarehouse(payload) {
    return await this.adapter.postAsync('getAdminWarehouse', { payload })
  }

  async saveWarehouseSupplier(payload) {
    return await this.adapter.postAsync('saveWarehouseSupplier', { payload })
  }

  async getWarehouseCode(payload) {
    return await this.adapter.getAsync('getWarehouseCode', { payload })
  }
}

export default WarehouseAPI
