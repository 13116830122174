import { useEffect, useState } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { useRef } from 'react'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { ControlType, FormControl } from '../../components/FormControl'
import { saveCoupon, updateCoupon, getNewCouponCode } from '../../../../redux/actions/eco/couponActions'
import Loading from '../../components/Loading'
import { CouponStatusEnum, CouponTypeEnum, DiscountTypeEnum, getEnums } from '../../../../utilities/enums'
import { useSelector } from 'react-redux'
import { ROLE_NAME } from '../../../../utilities/constant'

const CouponModal = ({ editCoupon, closeModal, fetchCoupons }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const [isLoading, setIsLoading] = useState(false)
  const userLogin = useSelector((state) => state.auth.user)
  const isSupplier = userLogin?.roleName === ROLE_NAME.SUPPLIER
  const filteredDiscountTypeEnum = Object.fromEntries(
    Object.entries(DiscountTypeEnum).filter(([_, value]) =>
      isSupplier || editCoupon?.couponType === CouponTypeEnum.Supplier
        ? [DiscountTypeEnum.Percentage, DiscountTypeEnum.FixedPrice].includes(value)
        : [DiscountTypeEnum.Percentage, DiscountTypeEnum.FixedPrice, DiscountTypeEnum.Freeship].includes(value)
    )
  )

  const [discountTypes] = useState(() => getEnums(filteredDiscountTypeEnum, intl))

  const [couponCode, setCouponCode] = useState(editCoupon?.couponCode ?? null)
  const [couponName, setCouponName] = useState(editCoupon?.couponName ?? null)
  const [description, setDescription] = useState(editCoupon?.description ?? null)
  const [couponTypeId, setCouponTypeId] = useState(isSupplier ? CouponTypeEnum.Supplier : CouponTypeEnum.System)
  const [discountTypeId, setDiscountTypeId] = useState(editCoupon?.discountType ?? null)
  const [discountValue, setDiscountValue] = useState(editCoupon?.discountValue ?? null)
  const [maxDiscountAmount, setMaxDiscountAmount] = useState(editCoupon?.maxDiscountAmount ?? null)
  const [couponStatusId, setCouponStatusId] = useState(editCoupon?.couponStatus ?? CouponStatusEnum.InUse)
  const [minAmount, setMinAmount] = useState(editCoupon?.minAmount ?? null)
  const [maxUsesNumber, setMaxUsesNumber] = useState(editCoupon?.maxUsesNumber ?? null)
  const [fromDate, setFromDate] = useState(editCoupon?.fromDate != null ? new Date(editCoupon?.fromDate) : null)
  const [toDate, setToDate] = useState(editCoupon?.toDate != null ? new Date(editCoupon?.toDate) : null)

  useEffect(() => {
    if (editCoupon?.isAdded) {
      getNewCode()
    }
  }, [])

  useEffect(() => {
    if (discountTypeId === DiscountTypeEnum.Freeship) setCouponTypeId(CouponTypeEnum.Ship)
    else setCouponTypeId(isSupplier || editCoupon?.couponType === CouponTypeEnum.Supplier ? CouponTypeEnum.Supplier : CouponTypeEnum.System)
  }, [discountTypeId])

  useEffect(() => {
    if (discountTypeId !== DiscountTypeEnum.Percentage) {
      setMaxDiscountAmount(discountValue)
    }
  }, [discountValue])

  const getNewCode = async () => {
    const { result } = await getNewCouponCode()
    if (result) {
      setCouponCode(result)
    }
  }

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return
    setIsLoading(true)

    const couponSave = {
      couponCode,
      couponName,
      description,
      couponType: couponTypeId,
      discountType: discountTypeId,
      discountValue,
      couponStatus: couponStatusId,
      fromDate,
      toDate,
      id: editCoupon?.id,
      minAmount,
      maxUsesNumber,
      isActive: editCoupon?.isActive ? editCoupon.isActive : true,
      maxDiscountAmount,
    }

    const { error } = editCoupon.isAdded ? await saveCoupon(couponSave) : await updateCoupon(couponSave)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      editCoupon.isAdded = false
      fetchCoupons()
      closeModal(false)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
    setIsLoading(false)
  }

  return (
    <Modal title={intl.formatMessage({ id: editCoupon.isAdded ? 'AddNew' : 'Update' })} closeModal={closeModal} modalSize='mw-950px'>
      <form id='kt_modal_add_coupon_form' className='form'>
        <div className='d-flex flex-column scroll-y me-n7 pe-7' id='kt_modal_add_coupon_scroll'>
          <div className='row'>
            <div className='mb-3 col-6'>
              <FormControl
                ref={(ref) => (componentRefs.current.couponCode = ref)}
                tabIndex={1}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Coupon.Label.CouponCode' })}
                value={couponCode}
                isRequired={true}
                readOnly={true}
              />
            </div>

            <div className='mb-3 col-6'>
              <FormControl
                ref={(ref) => (componentRefs.current.discountTypeId = ref)}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Coupon.Label.DiscountType' })}
                value={discountTypeId}
                isRequired={true}
                onChange={(e) => setDiscountTypeId(e.value)}
                options={discountTypes}
              />
            </div>

            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.couponName = ref)}
                tabIndex={1}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Coupon.Label.CouponName' })}
                value={couponName}
                isRequired={true}
                onChange={(e) => setCouponName(e.target.value)}
              />
            </div>

            <div className='mb-3 col-6'>
              <FormControl
                ref={(ref) => (componentRefs.current.discountValue = ref)}
                tabIndex={4}
                controlType={ControlType.NumberBox}
                type='number'
                label={intl.formatMessage({ id: 'Coupon.Label.DiscountValue' })}
                value={discountValue}
                isRequired={true}
                onChange={(e) => setDiscountValue(e.target.value)}
              />
            </div>

            <div className='mb-3 col-6'>
              <FormControl
                ref={(ref) => (componentRefs.current.maxDiscountAmount = ref)}
                tabIndex={4}
                controlType={ControlType.NumberBox}
                type='number'
                label={intl.formatMessage({ id: 'Coupon.Label.MaxDiscountAmount' })}
                value={maxDiscountAmount}
                isRequired={true}
                onChange={(e) => setMaxDiscountAmount(e.target.value)}
                readOnly={discountTypeId !== DiscountTypeEnum.Percentage}
              />
            </div>

            <div className='mb-3 col-6'>
              <FormControl
                ref={(ref) => (componentRefs.current.fromDate = ref)}
                tabIndex={2}
                controlType={ControlType.DatetimePicker}
                label={intl.formatMessage({ id: 'Coupon.Label.FromDate' })}
                value={fromDate}
                isRequired={true}
                onChange={(date) => setFromDate(date)}
              />
            </div>

            <div className='mb-3 col-6'>
              <FormControl
                ref={(ref) => (componentRefs.current.toDate = ref)}
                tabIndex={2}
                controlType={ControlType.DatetimePicker}
                label={intl.formatMessage({ id: 'Coupon.Label.ToDate' })}
                value={toDate}
                onChange={(date) => setToDate(date)}
              />
            </div>

            <div className='mb-3 col-6'>
              <FormControl
                ref={(ref) => (componentRefs.current.minAmount = ref)}
                tabIndex={2}
                controlType={ControlType.NumberBox}
                label={intl.formatMessage({ id: 'Coupon.Label.MinAmount' })}
                value={minAmount}
                type='number'
                onChange={(e) => setMinAmount(e.target.value)}
              />
            </div>

            <div className='mb-3 col-6'>
              <FormControl
                ref={(ref) => (componentRefs.current.maxUsesNumber = ref)}
                tabIndex={2}
                controlType={ControlType.NumberBox}
                label={intl.formatMessage({ id: 'Coupon.Label.MaxUsesNumber' })}
                value={maxUsesNumber}
                type='number'
                onChange={(e) => setMaxUsesNumber(e.target.value)}
              />
            </div>
          </div>

          <div className='col-md-12'>
            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.description = ref)}
                tabIndex={1}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Coupon.Label.Description' })}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={2}
              />
            </div>
          </div>

          {!editCoupon.isAdded && (couponStatusId === CouponStatusEnum.InUse || couponStatusId === CouponStatusEnum.NotUsed) && (
            <div className='col-md-12 mb-3 p-0'>
              <FormControl
                ref={(ref) => (componentRefs.current.couponStatusId = ref)}
                tabIndex={6}
                controlType={ControlType.Checkbox}
                label={intl.formatMessage({ id: 'Coupon.Label.IsActive' })}
                value={couponStatusId === CouponStatusEnum.InUse ? true : false}
                onChange={(e) => setCouponStatusId(e.target.checked ? CouponStatusEnum.InUse : CouponStatusEnum.NotUsed)}
                isFromSwitch={true}
              />
            </div>
          )}
        </div>

        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} icon='fa-close' title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={8} />
          <Button onClick={(e) => handleSave(e)} icon='fa-save' title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={9} />
        </div>

        {isLoading && <Loading />}
      </form>
    </Modal>
  )
}

export default CouponModal
