import { useEffect, useState } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { useRef } from 'react'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { ControlType, FormControl } from '../../components/FormControl'
import { saveBlog, updateBlog } from '../../../../redux/actions/eco/blogActions'
import Loading from '../../components/Loading'

const BlogModal = ({ editBlog, closeModal, fetchBlogs }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const [isLoading, setIsLoading] = useState(false)

  const [title, setTitle] = useState(editBlog?.title ?? null)
  const [content, setContent] = useState(editBlog?.content ?? null)
  const [publishDate, setPublishDate] = useState(editBlog?.publishDate != null ? new Date(editBlog?.publishDate) : new Date())
  const [isActive, setIsActive] = useState(editBlog?.isActive ?? true)

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return
    setIsLoading(true)

    const dataSave = {
      title,
      content,
      publishDate,
      id: editBlog?.id,
      isActive: editBlog?.isActive ? isActive : true,
    }

    const { error } = editBlog.isAdded ? await saveBlog(dataSave) : await updateBlog(dataSave)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      editBlog.isAdded = false
      fetchBlogs()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
    setIsLoading(false)
  }

  return (
    <Modal title={intl.formatMessage({ id: editBlog.isAdded ? 'AddNew' : 'Update' })} closeModal={closeModal} modalSize="mw-750px">
      <form id="kt_modal_add_news_form" className="form">
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_news_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_news_header"
          data-kt-scroll-wrappers="#kt_modal_add_news_scroll"
          data-kt-scroll-offset="300px"
        >
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <FormControl
                  ref={(ref) => (componentRefs.current.title = ref)}
                  tabIndex={1}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({ id: 'Blog.Label.Title' })}
                  value={title}
                  isRequired={true}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <FormControl
                  ref={(ref) => (componentRefs.current.content = ref)}
                  controlType={ControlType.CkEditor}
                  label={intl.formatMessage({ id: 'Blog.Label.Content' })}
                  isRequired={true}
                  value={content}
                  onChange={(e) => setContent(e)}
                />
              </div>
              <div className="mb-3">
                <FormControl
                  ref={(ref) => (componentRefs.current.publishDate = ref)}
                  controlType={ControlType.DatePicker}
                  label={intl.formatMessage({ id: 'Blog.Label.PublishDate' })}
                  value={publishDate}
                  isRequired={true}
                  onChange={(date) => setPublishDate(date)}
                />
              </div>
              {!editBlog.isAdded && (
                <div className="mb-3 p-0">
                  <FormControl
                    ref={(ref) => (componentRefs.current.isActive = ref)}
                    tabIndex={6}
                    controlType={ControlType.Checkbox}
                    label={intl.formatMessage({ id: 'Blog.Label.IsActive' })}
                    value={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                    isFromSwitch={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-center pt-10">
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} />
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} />
        </div>
        {isLoading && <Loading />}
      </form>
    </Modal>
  )
}

export default BlogModal
