import { Question as QuestionAPI } from '../../../services'

// Async actions

const getQuestions = async (payload) => {
  return await QuestionAPI.getQuestions(payload)
}

const getQuestionById = async (questionId) => {
  return await QuestionAPI.getQuestionById(questionId)
}

export { getQuestionById, getQuestions }
