import { WarehouseDispatch as WarehouseDispatchApi } from '../../../services'

const getById = async (id) => {
  return await WarehouseDispatchApi.GetById({ id })
}

const getByProductId = async (productId, pageIndex, pageSize) => {
  return await WarehouseDispatchApi.getByProductId({ productId, pageIndex, pageSize })
}

const getBySupplierId = async (productCode, productName, pageIndex, pageSize) => {
  return await WarehouseDispatchApi.getBySupplierId({ productCode, productName, pageIndex, pageSize })
}

const add = async (payload) => {
  return await WarehouseDispatchApi.add(payload)
}

export { getById, add, getByProductId, getBySupplierId }
