import React, { useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const Confirm = ({ onConfirm, onCancel, message, title, btnCancelText, btnConfirmText }) => {
  const [isConfirming, setIsConfirming] = useState(false)

  const handleConfirm = async () => {
    setIsConfirming(true)
    try {
      await onConfirm()
      onCancel()
    } catch (error) {
    } finally {
      setIsConfirming(false)
    }
  }

  const showAlert = () => {
    MySwal.fire({
      title: title,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: btnConfirmText,
      cancelButtonText: btnCancelText,
      confirmButtonDisabled: isConfirming,
      preConfirm: handleConfirm,
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.cancel && onCancel) {
        onCancel()
      }
    })
  }

  React.useEffect(() => {
    showAlert()
  }, [])

  return null
}

export default Confirm
