import { News as NewsAPI } from '../../../services'

// Async actions

const getNews = async (payload) => {
  return await NewsAPI.getNews(payload)
}

const getNewsById = async (newsId) => {
  return await NewsAPI.getNewsById(newsId)
}

const saveNews = async (payload) => {
  return await NewsAPI.saveNews(payload)
}

const updateNews = async (payload) => {
  return await NewsAPI.updateNews(payload)
}

const deleteNews = async (newsId) => {
  return await NewsAPI.deleteNews(newsId)
}

export { saveNews, updateNews, getNewsById, deleteNews, getNews }
