import { Icon } from '../../../../../helpers'
import { useIntl } from 'react-intl'
import _ from 'lodash'

const Tabs = ({ currentTab, setTab, modules }) => {
  const intl = useIntl()

  const hasVisibleFeature = (features) => {
    if (_.isEmpty(features)) return false

    if (features.some((feature) => !feature.isHidden)) {
      return true
    }

    return features.some((feature) => !_.isEmpty(feature.features) && hasVisibleFeature(feature.features))
  }

  return (
    <div
      className='hover-scroll-y mb-10'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-wrappers='#kt_aside_nav'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-offset='0px'
    >
      <ul className='nav flex-column' id='kt_aside_nav_tabs'>
        {modules.map((t) => {
          return (
            hasVisibleFeature(t.features) && (
              <li key={t.id} className='pb-4'>
                <a
                  className={`nav-link btn btn-icon btn-active-color-primary btn-color-gray-400 btn-active-light ${t.id === currentTab ? 'active' : ''}`}
                  title={intl.formatMessage({ id: t.title })}
                  onClick={() => setTab(t.id)}
                >
                  <Icon iconName={t.icon} className='fs-2x' />
                </a>
              </li>
            )
          )
        })}
      </ul>
    </div>
  )
}

export default Tabs
