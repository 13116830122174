import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { DrawerComponent, MenuComponent, ScrollComponent, ToggleComponent } from '../../../../../assets/ts/components/index.ts'
import { useLocation } from 'react-router'
import { loadMenu, getFeatures } from '../../../../../routes'
import { useIntl } from 'react-intl'
import { MODULE } from '../../../../../utilities/constant'

const SettingTab = () => {
  const intl = useIntl()
  const location = useLocation()
  const [settingFeatures, setSettingFeatures] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const features = await getFeatures()
        const setting = features?.filter(x => x.moduleId === MODULE.SETTING)
        if (!_.isEmpty(setting)) {
          setSettingFeatures(setting)
        }
      } catch (error) {
        console.error('getFeauturesByUserId:', error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
    }, 50)
  }, [])

  return (
    <div
      className="menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-5 px-6 my-5 my-lg-0"
      id="setting_menu"
      data-kt-menu="true"
    >
      <div id="setting_menu_wrapper" className="menu-fit">
        <div className="menu-item">
          <div className="menu-content pt-8 pb-2">
            <span className="menu-section text-muted text-uppercase fs-8 ls-1">{intl.formatMessage({ id: 'Menu.Settings' })}</span>
          </div>
        </div>
        {loadMenu(settingFeatures, intl, location.pathname)}
      </div>
    </div>
  )
}

export default SettingTab
