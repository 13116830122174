import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class SupplierAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.SUPPLIER)
    this.adapter = this.initAdapter(conf)
  }

  async getSuppliers(payload) {
    return await this.adapter.postAsync('getSuppliers', { payload })
  }

  async getSupplierApprovedByUserId(payload) {
    return await this.adapter.getAsync('getSupplierApprovedByUserId', { payload })
  }

  async saveSupplier(payload) {
    return await this.adapter.postAsync('saveSupplier', { payload })
  }

  async updateSupplier(payload) {
    return await this.adapter.putAsync('updateSupplier', { payload })
  }

  async approveSupplier(supplierId) {
    return await this.adapter.putAsync(`approveSupplier?id=${supplierId}`)
  }

  async ignoreSupplier(supplierId) {
    return await this.adapter.putAsync(`ignoreSupplier?id=${supplierId}`)
  }

  async getSupplierById(supplierId) {
    return await this.adapter.getAsync(`getById?id=${supplierId}`)
  }
}

export default SupplierAPI
