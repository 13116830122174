import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class UserApi extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.USER)
    this.adapter = this.initAdapter(conf)
  }

  async getAllUser(payload) {
    return await this.adapter.getAsync('getAllUser', {payload})
  }

  async saveUser(payload) {
    return await this.adapter.putAsync('saveUser', {payload})
  }

  async resetPassword(payload) {
    return await this.adapter.postAsync('resetPassword', {payload})
  }

  async checkDuplicateEmail(payload) {
    return await this.adapter.postAsync('checkDuplicateEmail', {payload})
  }

  async checkDuplicateUserName(payload) {
    return await this.adapter.postAsync('checkDuplicateUserName', {payload})
  }

  async getRoleByUserLogin() {
    return await this.adapter.getAsync('getRoleByUserLogin', {})
  }

  async getUserById(payload) {
    return await this.adapter.getAsync('getUserById', {payload})
  }

  async changePassword(payload) {
    return await this.adapter.postAsync('changePassword', {payload})
  }
}

export default UserApi
