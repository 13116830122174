import ApiConnection from './apiConnection'

class BaseApi {
  initAdapter(conf) {
    if (!conf) throw new Error('api config required')
    return new ApiConnection(conf)
  }

  handleError(err, res) {
    if (err) {
      return err
    } else if (res.result.status && res.result.status.code !== 'success') {
      return res.result.status
    }
    return null
  }

  getApiUrl(url, params) {
    if (params) {
      let paramUrl = Object.keys(params)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&')
      url += `?` + paramUrl
    }
    return url
  }
}

export default BaseApi
