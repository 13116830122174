import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class NewsAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.NEWS)
    this.adapter = this.initAdapter(conf)
  }

  async getNews(payload) {
    return await this.adapter.postAsync('getNews', { payload })
  }

  async saveNews(payload) {
    return await this.adapter.postAsync('saveNews', { payload })
  }

  async updateNews(payload) {
    return await this.adapter.putAsync('updateNews', { payload })
  }

  async deleteNews(newsId) {
    return await this.adapter.deleteAsync(`deleteNews?id=${newsId}`)
  }

  async getNewsById(newsId) {
    return await this.adapter.getAsync(`getById?id=${newsId}`)
  }
}

export default NewsAPI
