import { ProductPromotion as ProductPromotionAPI } from '../../../services'

// Async actions

const getProductPromotions = async (payload) => {
    return await ProductPromotionAPI.getProductPromotions(payload)
  }
   
  const addProductPromotion = async (payload) => {
    return await ProductPromotionAPI.addProductPromotion(payload)
  }
  
  const deleteProductPromotion = async (productPromotionId) => {
    return await ProductPromotionAPI.deleteProductPromotion(productPromotionId)
  }

  const getProducts = async (payload) => {
    return await ProductPromotionAPI.getProducts(payload)
  }

export { getProductPromotions, addProductPromotion, deleteProductPromotion, getProducts }
