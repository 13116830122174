import { DEFAULT_VALUE } from '../../utilities/constant'
import { PRAGMA, CACHE_CONTROL_FIELD, REMOVE_PRAGMA } from '../constants'
import _ from 'lodash'

class ApiConnection {
  constructor(conf) {
    this._conf = {
      ...conf,
      credentials: 'include',
      endpoint: `${conf.endpoint}/`,
    }
    this._conf.headers['is-api'] = true
    this._defaultHeaders = {
      [PRAGMA]: 'no-cache',
      [CACHE_CONTROL_FIELD]: 'no-cache',
    }
  }

  async getAsync(event, data = {}) {
    let queryObject = data?.payload || {}
    let payload = data?.payload || {}
    const urlParts = event.split('?')
    const beforePrefixApiDocs = data?.beforePrefix === 'openapi/' ? data?.beforePrefix : ''
    let url = `${this._conf.endpoint}${this._conf.prefix}${beforePrefixApiDocs}${data?.afterPrefix || ''}${urlParts[0]}`

    url = this.buildQuery(url, queryObject)
    if (urlParts.length > 1) url += url.indexOf('?') !== -1 ? `&${urlParts[1]}` : `?${urlParts[1]}`

    this._conf.headers = _.merge(this._defaultHeaders, this._conf.headers, data?.headers)
    this._conf.headers = this.removePragma(this._conf.headers, data?.headers)

    return await this._fetchAsync({ method: 'GET', url, headers: this._conf.headers }, payload)
  }

  async postAsync(event, data = {}) {
    let payload = data?.payload || {}

    let url = `${this._conf.endpoint}${this._conf.prefix}${data?.afterPrefix || ''}${event}`
    url = this.buildQuery(url, {})

    this._conf.headers = _.merge(this._defaultHeaders, this._conf.headers, data?.headers)
    this._conf.headers = this.removePragma(this._conf.headers, data?.headers)

    return await this._fetchAsync(
      {
        method: 'POST',
        url,
        headers: this._conf.headers,
        body: JSON.stringify(payload),
      },
      payload
    )
  }

  async putAsync(event, data = {}) {
    let payload = data?.payload || {}

    let url = `${this._conf.endpoint}${this._conf.prefix}${data?.afterPrefix || ''}${event}`
    url = this.buildQuery(url, {})

    this._conf.headers = _.merge(this._defaultHeaders, this._conf.headers, data?.headers)
    this._conf.headers = this.removePragma(this._conf.headers, data?.headers)

    return await this._fetchAsync(
      {
        method: 'PUT',
        url,
        headers: this._conf.headers,
        body: JSON.stringify(payload),
      },
      payload
    )
  }

  async deleteAsync(event, data = {}) {
    let payload = data?.payload || {}
    let url = `${this._conf.endpoint}${this._conf.prefix}${data?.afterPrefix || ''}${event}`
    url = this.buildQuery(url, {})
    this._conf.headers = _.merge(this._defaultHeaders, this._conf.headers, data?.headers)
    return await this._fetchAsync(
      {
        method: 'DELETE',
        url,
        headers: this._conf.headers,
        body: JSON.stringify(payload),
      },
      payload
    )
  }

  buildQuery(url, queryObject) {
    let separatedBy = '?'
    if (!_.isEmpty(queryObject.payload)) {
      queryObject = queryObject.payload
    }
    if (url.indexOf('?') !== -1) separatedBy = '&'

    let queryParams = _.cloneDeep(queryObject)

    const params = this.serializeParams(this.cleanParams(queryParams))
    url += params ? separatedBy + params : ''

    return url
  }

  serializeParams(obj) {
    return Object.keys(obj)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&')
  }

  cleanParams(obj) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === undefined || obj[key] === null) delete obj[key]
    })
    return obj
  }

  removePragma(header, value) {
    if (value === REMOVE_PRAGMA) {
      delete header.pragma
    }
    return header
  }

  async _fetchAsync(config, payload) {
    config = _.merge(config, {
      headers: this._conf.headers,
      credentials: 'include',
    })

    try {
      const response = await fetch(config.url, config)
      const logoutUrl = `/logout?returnUrl=${encodeURIComponent(window.location.href)}`

      if (response.status === 401) {
        window.location.href = logoutUrl
      }

      if (!response.ok) {
        let { error } = await response.json()
        if (error) {
          console.error(response, error)
          return { error }
        }
        console.error(response)
        return { error: { code: response.status } }
      }

      let result
      const contentType = response.headers.get('content-type')
      const acceptMimeTypes = ['application/zip', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      const etag = response.headers.get('ETag')

      if (etag) window.etag = JSON.parse(etag)

      if (contentType && _.includes(acceptMimeTypes, contentType)) {
        result = await response.arrayBuffer()
      } else {
        result = await response.json()
      }

      return { payload, result }
    } catch (error) {
      return { error: { message: error.message } }
    }
  }

  async postFilesAsync(event, data = {}) {
    let payload = data?.payload || {}

    let url = `${this._conf.endpoint}${this._conf.prefix}${data?.afterPrefix || ''}${event}`
    url = this.buildQuery(url, {})

    this._conf.headers = _.merge(this._defaultHeaders, this._conf.headers, data?.headers)
    this._conf.headers = this.removePragma(this._conf.headers, data?.headers)

    const response = await fetch(url, {
      method: 'POST',
      headers: this._conf.headers,
      body: payload,
    })

    return await response.json()
  }

  async downloadFileAsync(event, data = {}) {
    let url = `${this._conf.endpoint}${this._conf.prefix}${data?.afterPrefix || ''}${event}`
    url = this.buildQuery(url, {})
    this._conf.headers = _.merge(this._defaultHeaders, this._conf.headers, data?.headers)

    const response = await fetch(url, {
      method: 'GET',
      headers: this._conf.headers,
    })
    if (!response.ok) {
      throw new Error(`Download file error! Status: ${response.status}`)
    }
    const blob = await response.blob()
    const urlDownload = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = urlDownload
    link.setAttribute('download', data?.fileNameLocal)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  async postCKEditorImagesAsync(event, data = {}, resolve, reject) {
    let payload = data?.payload || {}

    let url = `${this._conf.endpoint}${this._conf.prefix}${data?.afterPrefix || ''}${event}`
    url = this.buildQuery(url, {})

    this._conf.headers = _.merge(this._defaultHeaders, this._conf.headers, data?.headers)
    this._conf.headers = this.removePragma(this._conf.headers, data?.headers)

    await fetch(url, {
      method: 'POST',
      headers: this._conf.headers,
      body: payload,
    })
      .then((response) => response.json())
      .then((data) => {
        resolve({
          default: DEFAULT_VALUE.BASE_URL + data.result,
        })
      })
      .catch((error) => {
        console.error('Error uploading file:', error)
        reject('Error uploading file')
      })
  }
}

export default ApiConnection
