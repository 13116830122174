import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tab } from 'bootstrap'
import {
    DrawerComponent,
    MenuComponent,
    ScrollComponent,
    ScrollTopComponent,
    StickyComponent,
    SwapperComponent,
    ToggleComponent,
} from '../../assets/ts/components/index.ts'
import { ThemeModeComponent } from '../../assets/ts/layout/index.ts'
import { firstLoad } from '../../redux/actions/commonAction'
import { Auth } from '../../components/auth/Auth'

class InitLayout extends Component {
    pluginsInitialization = () => {
        this.props.firstLoad()
        ThemeModeComponent.init()
        setTimeout(() => {
            ToggleComponent.bootstrap()
            ScrollTopComponent.bootstrap()
            DrawerComponent.bootstrap()
            StickyComponent.bootstrap()
            MenuComponent.bootstrap()
            ScrollComponent.bootstrap()
            SwapperComponent.bootstrap()
            document.querySelectorAll('[data-bs-toggle="tab"]').forEach((tab) => {
                Tab.getOrCreateInstance(tab)
            })
        }, 500)
    }

    componentDidMount() {
        const user = Auth.getToken()
        let isAuthenticated = !!user
        if (this.props.isFirstLoad && isAuthenticated) {
            this.pluginsInitialization()
        }
    }

    componentDidUpdate() {
        const user = Auth.getToken()
        let isAuthenticated = !!user
        if (this.props.isFirstLoad && isAuthenticated) {
            this.pluginsInitialization()
        }
    }

    render() {
        return <></>
    }
}

const mapStateToProps = (state) => ({
    isFirstLoad: state.common.isFirstLoad,
    user: state.auth.user
})

const mapDispatchToProps = {
    firstLoad
}

export default connect(mapStateToProps, mapDispatchToProps)(InitLayout)
