class Auth {
  static AUTH_LOCAL_USER = process.env.REACT_APP_AUTH_LOCAL_USER
  static AUTH_LOCAL_TOKEN = process.env.REACT_APP_AUTH_LOCAL_TOKEN
  static AUTH_LOCAL_MODULE = process.env.REACT_APP_AUTH_LOCAL_MODULE

  static saveUserData(user, token, modules) {
    if (!localStorage) {
      return;
    }

    try {
      localStorage.setItem(Auth.AUTH_LOCAL_USER, JSON.stringify(user))
      localStorage.setItem(Auth.AUTH_LOCAL_TOKEN, token)
      localStorage.setItem(Auth.AUTH_LOCAL_MODULE, JSON.stringify(modules))
    } catch (error) {
      console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
    }
  }

  static getUserData() {
    if (!localStorage) {
      return
    }

    try {
      const user = localStorage.getItem(Auth.AUTH_LOCAL_USER)
      return user ? JSON.parse(user) : null
    } catch (error) {
      console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
  }

  static getToken() {
    if (!localStorage) {
      return
    }

    try {
      return localStorage.getItem(Auth.AUTH_LOCAL_TOKEN)
    } catch (error) {
      console.error('AUTH LOCAL STORAGE GET TOKEN ERROR', error)
    }
  }

  static getModule() {
    if (!localStorage) {
      return
    }

    try {
      const modules = localStorage.getItem(Auth.AUTH_LOCAL_MODULE)
      return modules ? JSON.parse(modules) : []
    } catch (error) {
      console.error('AUTH LOCAL STORAGE GET TOKEN ERROR', error)
    }
  }

  static clearUserData() {
    if (!localStorage) {
      return
    }

    try {
      localStorage.removeItem(Auth.AUTH_LOCAL_USER)
      localStorage.removeItem(Auth.AUTH_LOCAL_TOKEN)
      localStorage.removeItem(Auth.AUTH_LOCAL_MODULE)
    } catch (error) {
      console.error('AUTH LOCAL STORAGE CLEAR ERROR', error)
    }
  }
}

export { Auth }
