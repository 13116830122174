import { useEffect, useState, useRef } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { ControlType, FormControl } from '../../components/FormControl'
import { saveSubCategory, updateSubCategory, getGroups } from '../../../../redux/actions/eco/subCategoryActions'
import { DEFAULT_VALUE, GUID_NEWID } from '../../../../utilities/constant'

const SubCategoryModal = ({ editSubCategory, closeModal, fetchSubCategory }) => {
  const intl = useIntl()
  const componentRefs = useRef({})

  const [categoryName] = useState(editSubCategory?.categoryName)
  const [allGroups, setAllGroups] = useState([])
  const [categoryId] = useState(editSubCategory?.categoryId ?? null)
  const [subCategoryName, setSubCategoryName] = useState(editSubCategory?.subCategoryName ?? null)
  const [description, setDescription] = useState(editSubCategory?.description ?? null)
  const [isActive, setIsActive] = useState(editSubCategory?.isActive ?? false)
  const [groups, setgroups] = useState(
    editSubCategory?.groups?.map((item) => ({
      value: item.id,
      label: item.subCategoryName,
    }))
  )

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const { result } = await getGroups(categoryId, editSubCategory?.id ?? DEFAULT_VALUE.GUID_EMPTY)
        if (result) {
          setAllGroups(
            result.map((item) => ({
              value: item.id === DEFAULT_VALUE.GUID_EMPTY ? GUID_NEWID() : item.id,
              label: item.subCategoryName,
              __isNew__: item.id === DEFAULT_VALUE.GUID_EMPTY,
            }))
          )
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchGroups()
  }, [])

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const subCategorySave = {
      id: editSubCategory?.id,
      categoryId: categoryId,
      subCategoryName,
      description,
      isActive: editSubCategory.isAdded ? true : isActive,
      groups: groups?.map((item) => ({
        id: item.__isNew__ ? null : item.value,
        subCategoryName: item.label,
        categoryId: categoryId,
      })),
    }

    const { error } = editSubCategory.isAdded ? await saveSubCategory(subCategorySave) : await updateSubCategory(subCategorySave)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      closeModal(false)
      fetchSubCategory()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: editSubCategory.isAdded ? 'AddNew' : 'Update' })} closeModal={closeModal} modalSize='mw-650px'>
      <form id='kt_modal_add_SubCategory_form' className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_SubCategory_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_SubCategory_header'
          data-kt-scroll-wrappers='#kt_modal_add_SubCategory_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.categoryId = ref)}
                tabIndex={1}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Menu.Eco.Category' })}
                value={categoryName}
                readOnly={true}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.subCategoryName = ref)}
                tabIndex={2}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'SubCategory.Column.SubCategoryName' })}
                value={subCategoryName}
                isRequired={true}
                onChange={(e) => setSubCategoryName(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.description = ref)}
                tabIndex={4}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'SubCategory.Column.Description' })}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.groups = ref)}
                tabIndex={5}
                controlType={ControlType.TagInput}
                onChange={(e) => setgroups(e)}
                value={groups}
                label={intl.formatMessage({ id: 'SubCategory.Column.GroupProductName' })}
                options={allGroups}
              />
            </div>

            {!editSubCategory.isAdded && (
              <div className='col-md-12 mb-3 p-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.isActive = ref)}
                  tabIndex={5}
                  controlType={ControlType.Checkbox}
                  label={intl.formatMessage({ id: 'SubCategory.Column.IsActvive' })}
                  value={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  isFromSwitch={true}
                />
              </div>
            )}
          </div>
        </div>

        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={4}>
            <i className='fa fa-times'></i>
            <FormattedMessage id='Close' />
          </Button>
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={5}>
            <i className='fa fa-save'></i>
            <FormattedMessage id='Save' />
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default SubCategoryModal
