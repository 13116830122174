import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { toAbsoluteUrl, Icon } from '../../../../helpers'
import Tabs from './tabs/Tabs'
import TabsBase from './tabs/TabsBase'
import Footer from './footer/Footer'
import { MODULE } from '../../../../utilities/constant'
import { getModules } from '../../../../routes'
import _ from 'lodash'

const Navigation = () => {
  const pathname = useLocation().pathname
  const tab = pathname && pathname.substring(1).split('/')[0]
  const [currentTab, setCurrentTab] = useState(MODULE.HOME)
  const [modules, setModules] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const modules = await getModules()
        if (!_.isEmpty(modules)) {
          const hasTab = modules.some((x) => x.id === tab)
          setCurrentTab(hasTab ? tab : 'eco')
          setModules(modules)
        }
      } catch (error) {
        console.error('getModules:', error)
      }
    }

    fetchData()
  }, [pathname])

  return (
    <div
      id='kt_aside'
      className='aside aside-extended'
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='false'
      data-kt-drawer-width='auto'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      <div className='aside-primary d-flex flex-column align-items-lg-center flex-row-auto'>
        <div id='kt_aside_logo' className='aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10'>
          <Link to='/'>
            <img src={toAbsoluteUrl('/media/logos/logo.png')} alt='logo' className='h-35px' />
          </Link>
        </div>
        <div id='kt_aside_nav' className='aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0'>
          <Tabs currentTab={currentTab} setTab={setCurrentTab} modules={modules} />
        </div>
        <Footer />
      </div>
      <div className='aside-secondary d-flex flex-row-fluid'>
        <div className='aside-workspace my-5 p-5' id='kt_aside_wordspace'>
          <TabsBase currentTab={currentTab} modules={modules} />
        </div>
      </div>
      <button
        id='kt_aside_toggle'
        className='btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex '
        data-kt-toggle='true'
        data-kt-toggle-state='active'
        data-kt-toggle-target='body'
        data-kt-toggle-name='aside-minimize'
        style={{ marginBottom: '1.35rem' }}
      >
        <Icon iconName='arrow-left' className='fs-2 rotate-180' />
      </button>
    </div>
  )
}

export default Navigation
