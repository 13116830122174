import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class PromotionAPI extends BaseApi {
    constructor() {
        super()

        const conf = new Config().getConfig(CONFIG_TYPE.PROMOTION)
        this.adapter = this.initAdapter(conf)
    }

    async getPromotions(payload) {
        return await this.adapter.postAsync('getPromotions', { payload })
      }
    
      async savePromotion(payload) {
        return await this.adapter.postAsync('savePromotion', { payload })
      }
    
      async updatePromotion(payload) {
        return await this.adapter.putAsync('updatePromotion', { payload })
      }
    
      async deletePromotion(promotionId) {
        return await this.adapter.deleteAsync(`deletePromotion?id=${promotionId}`)
      }
    
      async getPromotionById(promotionId) {
        return await this.adapter.getAsync(`getPromotionById?id=${promotionId}`)
      }

      async getCurrentPromotions() {
        return await this.adapter.getAsync(`getCurrentPromotions`)
      }

      async getNewsPromotions(payload) {
        return await this.adapter.getAsync(`getNewsPromotions`, { payload })
      }
}

export default PromotionAPI
