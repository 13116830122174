import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class FeatureApi extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.FEATURE)
    this.adapter = this.initAdapter(conf)
  }

  async getAllModulesWithFeatures() {
    return await this.adapter.getAsync('getAllModulesWithFeatures', {})
  }

  async getFeauturesByUserId(payload) {
    return await this.adapter.getAsync('getFeauturesByUserId', {payload})
  }
}

export default FeatureApi
