import React from 'react'
import ExcelJs from 'exceljs'
import { useSortBy, useTable } from 'react-table'
import { formatDateTime, getNewDateString } from '../../../utilities/shareFunction'
import { Icon } from '../../../helpers'
import { useIntl } from 'react-intl'

const ExportExcel = ({ fileName, numberOfLastColumnRemove, columns, data, classNameExt }) => {
  const intl = useIntl()
  const { headerGroups, rows } = useTable({ columns, data }, useSortBy)

  numberOfLastColumnRemove = numberOfLastColumnRemove ?? 2
  fileName = (fileName ?? '') + '_' + getNewDateString()

  const exportToExcel = () => {
    let sheetName = fileName + '.xlsx'
    let headerName = 'exportList'

    // Get the sheet object and set the style of the current sheet
    // showGridLines: false  //Indicates that the table border is not displayed
    let workbook = new ExcelJs.Workbook()
    let sheet = workbook.addWorksheet(sheetName, {
      views: [{ showGridLines: true }],
    })

    // Get the header of each column
    let headerRows = []
    headerGroups.forEach((headerGroup) => {
      const headerRow = []
      if (headerGroup.headers) {
        for (let i = 0; i < headerGroup.headers.length - numberOfLastColumnRemove; i++) {
          headerRow.push(...getHeader(headerGroup.headers[i]))
        }
      }
      headerRows.push(headerRow)
    })

    let columnArr = []
    headerRows[0].forEach((row) => {
      let tempObj = { name: '' }
      tempObj.name = row.value
      columnArr.push(tempObj)
    })

    // Get data rows
    const dataRows = []
    if (rows.length > 0) {
      rows.forEach((row) => {
        const dataRow = []
        Object.values(row.values).forEach((value) => {
          if (typeof value !== 'object') {
            dataRow.push({
              value,
              type: typeof value === 'number' ? 'number' : 'string',
            })
          }
        })
        dataRows.push(dataRow)
      })
    } else {
      dataRows.push([
        {
          value: 'No data',
          type: 'string',
        },
      ])
    }

    // Set the header information of the table, which can be used to set the title, description or notes.
    sheet.addTable({
      name: `Header`,
      ref: 'A1', // Header information is displayed starting from cell A1
      headerRow: true,
      totalsRow: false,
      style: {
        theme: '',
        showRowStripes: false,
        showFirstColumn: true,
        width: 200,
      },
      columns: [{ name: `${intl.formatMessage({ id: 'Company' })}` }],
      rows: [[`${intl.formatMessage({ id: 'ExportTime' })}: ${formatDateTime(new Date())}`]],
    })

    // Set up the main data section of the table
    sheet.addTable({
      name: headerName,
      ref: 'A4', // The main data starts in cell A4
      headerRow: true,
      totalsRow: false,
      style: {
        theme: 'TableStyleMedium2',
        showRowStripes: false,
        width: 200,
      },
      columns: columnArr ? columnArr : [{ name: '' }],
      rows: dataRows.map((e) => {
        let arr = []
        for (let i in e) {
          arr.push(e[i].value)
        }
        return arr
      }),
    })

    sheet.getCell('A1').font = { size: 20, bold: true } // Set cell text style

    // Set the width of each column
    sheet.columns = sheet.columns.map((e) => {
      const expr = e.values[5]
      switch (expr) {
        // case 'ColumnName':
        //   return { width: 50 }
        default:
          return { width: 20 }
      }
    })

    const table = sheet.getTable(headerName)
    for (let i = 0; i < table.table.columns.length; i++) {
      // The main data of the table is drawn starting from A4, with a total of three columns. Here is to get cells A4, B4, C4, ... and define the header style of the table
      sheet.getCell(`${String.fromCharCode(65 + i)}4`).font = { size: 12 }
      sheet.getCell(`${String.fromCharCode(65 + i)}4`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'c5d9f1' },
      }

      // Get the table data part and define its style
      for (let j = 0; j < table.table.rows.length; j++) {
        let rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + 5}`)
        rowCell.alignment = { wrapText: true }
        rowCell.border = {
          bottom: {
            style: 'thin',
            color: { argb: 'a6a6a6' },
          },
        }
      }
    }
    table.commit()

    const writeFile = (fileName, content) => {
      const link = document.createElement('a')
      const blob = new Blob([content], {
        type: 'application/vnd.ms-excel;charset=utf-8;',
      })
      link.download = fileName
      link.href = URL.createObjectURL(blob)
      link.click()
    }

    // The data drawing of the table is completed, the download method is defined, and the data is exported to an Excel file.
    workbook.xlsx.writeBuffer().then((buffer) => {
      writeFile(sheetName, buffer)
    })
  }

  function getHeader(column) {
    return [
      {
        value: column.Header,
        type: 'string',
      },
    ]
  }

  return (
    <a onClick={exportToExcel} className={`btn btn-sm btn-primary ${classNameExt ? classNameExt : ''}`}>
      <i className='ki-outline ki-exit-up fs-2 me-1'></i>
      <span>{intl.formatMessage({ id: 'ExportExcel' })}</span>
    </a>
  )
}

export default ExportExcel
