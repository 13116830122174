import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class SubCategoryAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.SUBCATEGORY)
    this.adapter = this.initAdapter(conf)
  }

  async getById(subCategoryId) {
    return await this.adapter.getAsync(`getById?id=${subCategoryId}`)
  }

  async getByCategoryIds(categoryIds) {
    return await this.adapter.getAsync(`getByCategoryIds?categoryIds=${categoryIds}`)
  }

  async getSubCategoriesDropdown() {
    return await this.adapter.getAsync('getSubCategoriesDropdown')
  }

  async getByCategoryIdDropdown(categoryId) {
    return await this.adapter.getAsync(`getByCategoryIdDropdown?categoryId=${categoryId}`)
  }

  async getSubCategories(payload) {
    return await this.adapter.postAsync('getSubCategories', { payload })
  }

  async saveSubCategory(payload) {
    return await this.adapter.postAsync('saveSubCategory', { payload })
  }

  async updateSubCategory(payload) {
    return await this.adapter.putAsync('updateSubCategory', { payload })
  }

  async deleteSubCategory(subCategoryId) {
    return await this.adapter.deleteAsync(`deleteSubCategory?id=${subCategoryId}`)
  }

  async getByCategoryId(payload) {
    return await this.adapter.postAsync('getByCategoryId', { payload })
  }

  async getGroups(categoryId, parentId) {
    return await this.adapter.getAsync(`getGroups?categoryId=${categoryId}&parentId=${parentId}`)
  }

  async updateGroup(payload) {
    return await this.adapter.putAsync('updateGroup', { payload })
  }
}

export default SubCategoryAPI
