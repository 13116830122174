import { toAbsoluteUrl } from '../helpers'
import viMessages from './messages/vi.json'
import enMessages from './messages/en.json'

const languages = [
  {
    lang: 'vi',
    name: 'Việt Nam',
    flag: toAbsoluteUrl('/media/flags/vietnam.svg'),
    messages: viMessages,
  },
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    messages: enMessages,
  },
]

export { languages }
