import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class ProductAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.PRODUCT)
    this.adapter = this.initAdapter(conf)
  }

  async getById(productId) {
    return await this.adapter.getAsync(`getById?id=${productId}`)
  }

  async deleteProduct(id) {
    return await this.adapter.deleteAsync(`deleteProduct?id=${id}`)
  }

  async getProducts(payload) {
    return await this.adapter.getAsync('getProducts', { payload })
  }

  async saveProduct(payload) {
    return await this.adapter.postAsync('saveProduct', { payload })
  }

  async updateProduct(payload) {
    return await this.adapter.putAsync('updateProduct', { payload })
  }

  async getProductCode(payload) {
    return await this.adapter.getAsync('getProductCode', { payload })
  }

  async getProductDropdown(payload) {
    return await this.adapter.getAsync('getProductDropdown', { payload })
  }

  async approveProduct(payload) {
    return await this.adapter.putAsync('approveProduct', { payload })
  }

  async rejectProduct(payload) {
    return await this.adapter.putAsync('rejectProduct', { payload })
  }
}

export default ProductAPI
