import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import _ from 'lodash'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import TableSearch from '../../components/TableSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import BlogModal from './BlogModal'
import ConfirmRemove from '../../components/Confirm'
import CKEditorDisplay from '../../components/CKEditorDisplay'
import { Icon } from '../../../../helpers'
import Checkbox from '../../components/Checkbox'
import { getBlogs, deleteBlog } from '../../../../redux/actions/eco/blogActions'

const Blog = () => {
  const intl = useIntl()
  const [lstBlogs, setLstBlogs] = useState([])
  const [blogOriginals, setBlogOriginals] = useState([])
  const [blogs, setBlogs] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const [isEdit, setIsEdit] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)

  const fetchBlogs = async () => {
    setIsLoading(true)
    try {
      const { result } = await getBlogs({ pageIndex: currentPage, pageSize: pageSize })
      if (result) {
        setLstBlogs(result.blogs)
        setBlogOriginals(result.blogs)
        setTotalPages(result.totalPages)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchBlogs()
  }, [currentPage, pageSize])

  const columns = getColumns(intl)
  const rows = lstBlogs.map((item) => {
    return {
      title: item.title,
      content: <CKEditorDisplay content={item.content} />,
      publishDate: item.publishDateStr,
      isActive: <Checkbox checked={item.isActive} readOnly />,
      action: (
        <div className="d-flex justify-content-center flex-shrink-0">
          <a onClick={() => handleEdit(item)} className="btn btn-icon btn-light-success btn-sm me-1">
            <i className="fa-solid fa-pen-to-square" />
          </a>
          <a onClick={() => confirmRemove(item)} className="btn btn-icon btn-light-danger btn-sm">
            <i className="fa-solid fa-trash" />
          </a>
        </div>
      ),
    }
  })

  const handleCreate = () => {
    setIsEdit(true)
    setBlogs({ id: DEFAULT_VALUE.GUID_RANDOM, isAdded: true })
  }

  const handleEdit = (item) => {
    setBlogs(item)
    setIsEdit(true)
  }

  const confirmRemove = (item) => {
    setConfirmVisible(true)
    setBlogs(item)
  }

  const handleRemove = async () => {
    setIsLoading(true)

    try {
      const { error } = await deleteBlog(blogs.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        setLstBlogs(lstBlogs.filter((item) => item.id !== blogs.id))
        setBlogOriginals(lstBlogs.filter((item) => item.id !== blogs.id))
        setConfirmVisible(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancelRemove = () => {
    setConfirmVisible(false)
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize)
  }

  const handleSearch = (query) => {
    query = query.trim().toLowerCase()
    if (!_.isEmpty(query)) {
      const filteredData = blogOriginals.filter((item) => item.title.toLowerCase().includes(query))
      setLstBlogs(filteredData)
    } else {
      setLstBlogs(blogOriginals)
    }
  }

  return (
    <div className="card mb-5 mb-xl-8">
      <div className="card-header border-0 pt-5">
        <div className="card-title">
          <a onClick={() => handleCreate()} className="btn btn-sm btn-light-primary me-3">
            <Icon iconName="plus" className="fs-3" />
            {intl.formatMessage({ id: 'AddNew' })}
          </a>
        </div>

        <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover">
          <TableSearch onSearch={handleSearch} />
        </div>
      </div>

      <div className="card-body py-3">
        <div className="table-responsive">
          <Table columns={columns} data={rows} />
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={handlePageChange}
            onItemsPerPageChange={handlePageSizeChange}
          />
          {isLoading && <Loading />}
        </div>
      </div>

      {isEdit && <BlogModal editBlog={blogs} closeModal={setIsEdit} fetchBlogs={fetchBlogs} />}

      {confirmVisible && (
        <ConfirmRemove
          onConfirm={handleRemove}
          onCancel={handleCancelRemove}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}
    </div>
  )
}

const getColumns = (intl) => {
  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'Blog.Column.Title' })}`,
      accessor: 'title',
    },
    {
      Header: `${intl.formatMessage({ id: 'Blog.Column.PublishDate' })}`,
      accessor: 'publishDate',
    },
    {
      Header: `${intl.formatMessage({ id: 'Blog.Column.Content' })}`,
      accessor: 'content',
    },
    {
      Header: `${intl.formatMessage({ id: 'Blog.Column.IsActive' })}`,
      accessor: 'isActive',
    },
    {
      Header: `${intl.formatMessage({ id: 'Blog.Column.Action' })}`,
      accessor: 'action',
    },
  ]

  return columns
}

export default Blog
