import React from 'react'
import { Icon } from '../../../../helpers'
import { FormattedMessage  } from 'react-intl'

class ThemeMode extends React.Component {
  render() {
    return (
      <div>
        <a
          href="#"
          className="btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline w-40px h-40px"
          data-kt-menu-trigger="{default:'click', lg: 'hover'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <Icon iconName="night-day" className="theme-light-show fs-2" />
          <Icon iconName="moon" className="theme-dark-show fs-2" />
        </a>
        <div
          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
          data-kt-menu="true"
          data-kt-element="theme-mode-menu"
        >
          <div className="menu-item px-3 my-0">
            <a href="#" className="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="light">
              <span className="menu-icon" data-kt-element="icon">
                <Icon iconName="night-day" className="fs-2" />
              </span>
              <span className="menu-title"><FormattedMessage id='Light'/></span>
            </a>
          </div>
          <div className="menu-item px-3 my-0">
            <a href="#" className="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="dark">
              <span className="menu-icon" data-kt-element="icon">
                <Icon iconName="moon" className="fs-2" />
              </span>
              <span className="menu-title"><FormattedMessage id='Dark'/></span>
            </a>
          </div>
          <div className="menu-item px-3 my-0">
            <a href="#" className="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="system">
              <span className="menu-icon" data-kt-element="icon">
                <Icon iconName="screen" className="fs-2" />
              </span>
              <span className="menu-title"><FormattedMessage id='System'/></span>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default ThemeMode
