import { WarehouseReceive as WarehouseReceiveApi } from '../../../services'

const getById = async (id) => {
  return await WarehouseReceiveApi.GetById({ id })
}

const getByProductId = async (productId, pageIndex, pageSize) => {
  return await WarehouseReceiveApi.getByProductId({ productId, pageIndex, pageSize })
}

const getBySupplierId = async (receiveCode, productCode, productName, pageIndex, pageSize) => {
  return await WarehouseReceiveApi.getBySupplierId({ receiveCode, productCode, productName, pageIndex, pageSize })
}

const getCode = async () => {
  return await WarehouseReceiveApi.getCode()
}

const add = async (payload) => {
  return await WarehouseReceiveApi.add(payload)
}

export { getById, getCode, add, getByProductId, getBySupplierId }
