import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class LevelGroupApi extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.LEVEL_GROUP)
    this.adapter = this.initAdapter(conf)
  }

  async getById({ payload }) {
    return await this.adapter.getAsync('getById', { payload })
  }

  async getAll() {
    return await this.adapter.getAsync('getAll')
  }

  async add(payload) {
    return await this.adapter.postAsync('add', { payload })
  }

  async update(payload) {
    return await this.adapter.putAsync('update', { payload })
  }

  async softDelete(payload) {
    return await this.adapter.deleteAsync('softDelete', { payload })
  }
}

export default LevelGroupApi
