import { Order as OrderAPI } from '../../../services'

const getOrders = async (payload) => {
  return await OrderAPI.getOrders(payload)
}

const getOrderById = async (orderId) => {
  return await OrderAPI.getOrderById(orderId)
}

const preparingGoods = async (payload) => {
  return await OrderAPI.preparingGoods(payload)
}

const waitForDelivery = async (payload) => {
  return await OrderAPI.waitForDelivery(payload)
}

const complete = async (payload) => {
  return await OrderAPI.complete(payload)
}

export { getOrderById, getOrders, preparingGoods, waitForDelivery, complete }
