import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class ProductPropertyApi extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.PRODUCT_PROPERTY)
    this.adapter = this.initAdapter(conf)
  }

  async getAllStandardProductProperty() {
    return await this.adapter.getAsync('getAll')
  }

  async getStandardProductProperty(payload) {
    return await this.adapter.getAsync('Get', { payload })
  }

  async insertStandardProductProperty(payload) {
    return await this.adapter.putAsync('insertStandardProductProperty', { payload })
  }

  async updateStandardProductProperty(payload) {
    return await this.adapter.postAsync('updateStandardProductProperty', { payload })
  }

  async saveStandardProductPropertyDetail(payload) {
    return await this.adapter.postAsync('saveStandardProductPropertyDetail', { payload })
  }

  async deleteStandardProductProperty(id) {
    return await this.adapter.deleteAsync(`deleteStandardProductProperty?id=${id}`)
  }
}

export default ProductPropertyApi
