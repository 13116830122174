import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Input from '../components/Input'
import Button from '../components/Button'
import { bindValidationMessages } from '../../../utilities/validationMessage'
import { formatString, isValidEmail, isValidPhoneNumber } from '../../../utilities/shareFunction'
import { uploadFiles } from '../../../redux/actions/gnr/fileAttachmentActions'
import { getUserById, saveUser } from '../../../redux/actions/auth/userActions'
import { Auth } from '../../../components/auth/Auth'
import { DEFAULT_VALUE, ROLE_NAME } from '../../../utilities/constant'
import { FILE_TYPE, TABLE_NAME } from '../../../utilities/constant'
import { showToast, TOAST_TYPES } from '../../../helpers/Toastify'
import useGetFiles from '../../../helpers/useGetFiles'
import _, { toUpper } from 'lodash'

const Settings = () => {
  const currentUser = Auth.getUserData() || {}
  const isDisplay = !(toUpper(currentUser.roleName) === toUpper(ROLE_NAME.ADMIN))
  const intl = useIntl()
  const validationMessage = bindValidationMessages()
  //
  const [isValidFirstName, setIsValidFirstName] = useState(true)
  const [isValidLastName, setIsValidLastName] = useState(true)
  const [isValidFieldPhoneNumber, setIsValidPhoneNumber] = useState(true)
  const [isValidFieldEmail, setIsValidEmail] = useState(true)
  const [errorMessageFirstName, setErrorMessageFirstName] = useState('')
  const [errorMessageLastName, setErrorMessageLastName] = useState('')
  const [errorMessagePhoneNumber, setErrorMessagePhoneNumber] = useState('')
  const [errorMessageEmail, setErrorMessageEmail] = useState('')
  //
  const [avatars, setAvatars] = useGetFiles(TABLE_NAME.USER, currentUser.id, FILE_TYPE.USER_AVATAR)
  const [avatar, setAvatar] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [company, setCompany] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [website, setWebsite] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        // //Load data
        const { result: userData } = await getUserById({ id: currentUser.id })
        if (!_.isNil(userData)) {
          setFirstName(userData.firstName)
          setLastName(userData.lastName)
          setCompany(userData.company)
          setPhoneNumber(userData.phoneNumber)
          setEmail(userData.email)
          setWebsite(userData.website)
          setAvatar(userData.avatar)
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])

  const validateText = (e, controlName, displayControlName) => {
    const text = e.target.value
    let hasValue = !_.isNaN(text) && !_.isEmpty(text) && !_.isNil(text)
    let error = false
    let message = []

    //Check required
    if (!hasValue) {
      error = true
      message = [...message, formatString(validationMessage.required, displayControlName)]
    } else {
      if (controlName === 'email') {
        if (!isValidEmail(text)) {
          error = true
          message = [...message, formatString(validationMessage.invalid, displayControlName)]
        }
      } else if (controlName === 'phoneNumber') {
        if (!isValidPhoneNumber(text)) {
          error = true
          message = [...message, formatString(validationMessage.invalid, displayControlName)]
        }
      }
    }
    updateState(message, controlName, !error)

    return !error
  }

  const updateState = (message, controlName, error) => {
    switch (controlName) {
      case 'firstName':
        setErrorMessageFirstName(message.join('\n'))
        setIsValidFirstName(error)
        break
      case 'lastName':
        setErrorMessageLastName(message.join('\n'))
        setIsValidLastName(error)
        break
      case 'phoneNumber':
        setErrorMessagePhoneNumber(message.join('\n'))
        setIsValidPhoneNumber(error)
        break
      case 'email':
        setErrorMessageEmail(message.join('\n'))
        setIsValidEmail(error)
        break
      default:
        return
    }
  }

  const handleChangeAvatar = async (e) => {
    const selectedFiles = e.currentTarget.files
    if (selectedFiles) {
      const formData = new FormData()
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('files', selectedFiles[i])
      }
      const response = await uploadFiles(formData)
      if (response) {
        var file = response[0]
        setAvatars([file])
        setAvatar(file?.fullPath)
      }
    }
  }

  const handleRemoveAvatar = () => {
    if (_.isEmpty(avatar)) return
    setAvatars([])
    setAvatar('')
  }

  const handleSave = async (e) => {
    e.preventDefault()
    if (!isValidFirstName || !isValidLastName || !isValidFieldEmail || !isValidFieldPhoneNumber) return

    const userSave = {
      ...currentUser,
      avatars: avatars,
      firstName: firstName,
      lastName: lastName,
      company: company,
      phoneNumber: phoneNumber,
      email: email,
      website: website,
      isUpdated: true,
    }
    const { error } = await saveUser(userSave)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <div className='card mb-5' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({ id: 'User.Profile.EditProfile' })}</h3>
        </div>
      </div>

      <div id='kt_account_settings_profile_details' className='collapse show'>
        <form id='kt_account_profile_details_form' className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>{intl.formatMessage({ id: 'User.Profile.Avata' })}</label>
              <div className='col-lg-8'>
                <div className='image-input image-input-outline' data-kt-image-input='true'>
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{
                      backgroundImage: !_.isEmpty(avatar) ? `url(${DEFAULT_VALUE.BASE_URL + avatar})` : [],
                    }}
                  ></div>
                  <label
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='change'
                    data-bs-toggle='tooltip'
                    title='Change avatar'
                  >
                    <i className='ki-outline ki-pencil fs-7'></i>
                    <input type='file' name='avatar' accept='.png, .jpg, .jpeg' onChange={handleChangeAvatar} />
                    <input type='hidden' name='avatar_remove' />
                  </label>
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='cancel'
                    data-bs-toggle='tooltip'
                    title='Cancel avatar'
                  >
                    <i className='ki-outline ki-cross fs-2'></i>
                  </span>
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='remove'
                    data-bs-toggle='tooltip'
                    title='Remove avatar'
                    onClick={handleRemoveAvatar}
                  >
                    <i className='ki-outline ki-cross fs-2'></i>
                  </span>
                </div>
                <div className='form-text'>{intl.formatMessage({ id: 'User.Profile.NoteAvata' })}</div>
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-semibold fs-6'>{intl.formatMessage({ id: 'User.Profile.FullName' })}</label>
              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <Input
                      onChange={(e) => setFirstName(e.target.value)}
                      onBlur={(e) =>
                        validateText(
                          e,
                          'firstName',
                          intl.formatMessage({
                            id: 'User.Profile.FirstName',
                          })
                        )
                      }
                      value={firstName}
                      errorMessage={errorMessageFirstName}
                      placeholder={intl.formatMessage({
                        id: 'User.Profile.FirstName',
                      })}
                      isValid={isValidFirstName}
                      tabIndex={2}
                    />
                  </div>
                  <div className='col-lg-6 fv-row'>
                    <Input
                      onChange={(e) => setLastName(e.target.value)}
                      onBlur={(e) =>
                        validateText(
                          e,
                          'lastName',
                          intl.formatMessage({
                            id: 'User.Profile.LastName',
                          })
                        )
                      }
                      value={lastName}
                      errorMessage={errorMessageLastName}
                      placeholder={intl.formatMessage({
                        id: 'User.Profile.LastName',
                      })}
                      isValid={isValidLastName}
                      tabIndex={3}
                    />
                  </div>
                </div>
              </div>
            </div>
            {isDisplay && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label  fw-semibold fs-6'>{intl.formatMessage({ id: 'User.Profile.Company' })}</label>
                <div className='col-lg-8 fv-row'>
                  <Input
                    onChange={(e) => setCompany(e.target.value)}
                    value={company}
                    placeholder={intl.formatMessage({ id: 'User.Profile.Company' })}
                    tabIndex={4}
                  />
                </div>
              </div>
            )}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                <span className='required'>{intl.formatMessage({ id: 'User.Profile.PhoneNumber' })}</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <Input
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  onBlur={(e) => validateText(e, 'phoneNumber', intl.formatMessage({ id: 'User.Profile.PhoneNumber' }))}
                  value={phoneNumber}
                  errorMessage={errorMessagePhoneNumber}
                  placeholder={intl.formatMessage({ id: 'User.Profile.PhoneNumber' })}
                  isValid={isValidFieldPhoneNumber}
                  tabIndex={5}
                />
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-semibold fs-6'>{intl.formatMessage({ id: 'User.Profile.Email' })}</label>
              <div className='col-lg-8 fv-row'>
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={(e) =>
                    validateText(
                      e,
                      'email',
                      intl.formatMessage({
                        id: 'User.Profile.Email',
                      })
                    )
                  }
                  value={email}
                  errorMessage={errorMessageEmail}
                  placeholder={intl.formatMessage({
                    id: 'User.Profile.Email',
                  })}
                  isValid={isValidFieldEmail}
                  tabIndex={6}
                />
              </div>
            </div>
            {isDisplay && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>{intl.formatMessage({ id: 'User.Profile.Website' })}</label>
                <div className='col-lg-8 fv-row'>
                  <Input
                    onChange={(e) => setWebsite(e.target.value)}
                    value={website}
                    placeholder={intl.formatMessage({
                      id: 'User.Profile.Website',
                    })}
                    tabIndex={7}
                  />
                </div>
              </div>
            )}
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <Button onClick={(e) => handleSave(e)} icon='fa-save' title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={8} />
          </div>
        </form>
      </div>
    </div>
  )
}

export default Settings
