import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class BannerAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.BANNER)
    this.adapter = this.initAdapter(conf)
  }

  async getBanners(payload) {
    return await this.adapter.postAsync('getBanners', { payload })
  }

  async saveBanner(payload) {
    return await this.adapter.postAsync('saveBanner', { payload })
  }

  async updateBanner(payload) {
    return await this.adapter.putAsync('updateBanner', { payload })
  }

  async deleteBanner(bannerId) {
    return await this.adapter.deleteAsync(`deleteBanner?id=${bannerId}`)
  }

  async getBannerById(bannerId) {
    return await this.adapter.getAsync(`getById?id=${bannerId}`)
  }
}

export default BannerAPI
