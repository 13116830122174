import React, { forwardRef, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable'

const TagInput = forwardRef((props, ref) => {
  let { value, options, onChange, onBlur, className, tabIndex, isValid, errorMessage, readOnly, placeholder, menuPosition } = props

  return (
    <div>
      <CreatableSelect
        ref={ref}
        isMulti
        onChange={onChange}
        onBlur={onBlur}
        options={options} // Chưa có gợi ý nào
        value={value ?? ''}
        placeholder={placeholder}
        tabIndex={tabIndex}
        className={className ?? ''}
        readOnly={readOnly}
        menuPlacement={menuPosition ?? 'bottom'}
      />
      {isValid === false && (
        <p className='mt-0 mb-0' style={{ color: 'red', whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </div>
  )
})

export default TagInput
