import { Route } from 'react-router-dom'
import _ from 'lodash'
import { Auth } from '../components/auth/Auth'
import { PageTitle } from '../components/pages/PageData'
import { tabComponent } from '../components/layout/AdminLayout/Navigation/tabs'
import { settingComponent } from '../components/pages/settings'
import { ecoComponent } from '../components/pages/eco'
import { userComponent } from '../components/pages/user'
import { homeComponent } from '../components/pages/home'
import MenuItemWithSub from '../components/layout/AdminLayout/Navigation/tabs/MenuItemWithSub'
import MenuItem from '../components/layout/AdminLayout/Navigation/tabs/MenuItem'

const getFeatures = async () => {
  try {
    let modules = Auth.getModule()
    let features = []
    if (!_.isEmpty(modules)) {
      features = modules.reduce((accumulator, currentObject) => {
        accumulator.push(...currentObject.features)
        return accumulator
      }, [])
    }
    return features
  } catch (error) {
    console.error('getFeatures:', error)
  }
}

const getModules = async () => {
  try {
    let modules = Auth.getModule()
    if (!_.isEmpty(modules)) {
      modules.forEach((item) => {
        item.component = getComponentByName(item.component)
      })
    }
    return modules
  } catch (error) {
    console.error('getModules:', error)
  }
}

const loadRoutes = (features, intl, breadCrumbs = []) => {
  return features.map((feature) => {
    let currentBreadCrumbs = [...breadCrumbs]
    if (!_.isEmpty(feature.features)) {
      currentBreadCrumbs.push({
        title: intl.formatMessage({ id: feature.title }),
        path: feature.path,
      })
      return loadRoutes(feature.features, intl, currentBreadCrumbs)
    } else if (!feature.groupName) {
      const Page = getComponentByName(feature.component)
      return (
        <Route
          key={feature.id}
          path={feature.path}
          element={
            <>
              <PageTitle breadcrumbs={currentBreadCrumbs}>{intl.formatMessage({ id: feature.title })}</PageTitle>
              <Page />
            </>
          }
        />
      )
    }
  })
}

const loadMenu = (features, intl, currentLink) => {
  return features.map((item) => {
    if (item.groupName) {
      const subItems = item.features.filter((child) => !child.isParent)
      if (item.isHidden) {
        return loadMenu(subItems, intl, currentLink)
      }
      return (
        <MenuItemWithSub key={item.id} icon={item.icon} title={intl.formatMessage({ id: item.title })} currentLink={currentLink} to={item.path}>
          {loadMenu(subItems, intl, currentLink)}
        </MenuItemWithSub>
      )
    } else if (!item.isHidden) {
      return item.icon ? (
        <MenuItem key={item.id} icon={item.icon} title={intl.formatMessage({ id: item.title })} currentLink={currentLink} to={item.path} />
      ) : (
        <MenuItem key={item.id} hasBullet={true} title={intl.formatMessage({ id: item.title })} currentLink={currentLink} to={item.path} />
      )
    }
  })
}

const getComponentByName = (componentName) => {
  if (tabComponent.hasOwnProperty(componentName)) {
    return tabComponent[componentName]
  } else if (homeComponent.hasOwnProperty(componentName)) {
    return homeComponent[componentName]
  } else if (ecoComponent.hasOwnProperty(componentName)) {
    return ecoComponent[componentName]
  } else if (settingComponent.hasOwnProperty(componentName)) {
    return settingComponent[componentName]
  } else if (userComponent.hasOwnProperty(componentName)) {
    return userComponent[componentName]
  }
}

export { getComponentByName, loadMenu, loadRoutes, getModules, getFeatures }
