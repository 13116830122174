import { Auth } from '../../../services'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'

const loginRequest = () => ({ type: LOGIN_REQUEST })
const loginSuccess = (user, returnUrl) => ({ type: LOGIN_SUCCESS, payload: user, returnUrl: returnUrl })
const loginFailure = (error) => ({ type: LOGIN_FAILURE, payload: error })
const logoutRequest = () => ({ type: LOGOUT_REQUEST })

export const login = (username, password, returnUrl) => {
  return async (dispatch) => {
    dispatch(loginRequest())
    try {
      const user = await Auth.login({ username, password, loginPage: 88889999 })
      if (user.error) {
        dispatch(loginFailure(user.message))
      } else {
        dispatch(loginSuccess(user, returnUrl))
      }
    } catch (error) {
      dispatch(loginFailure(error.message))
    }
  }
}

export const logout = () => {
  return async (dispatch) => {
    dispatch(loginRequest())
    await Auth.logout()
    dispatch(logoutRequest())
  }
}

export const resetPassword = async (payload) => {
  return await Auth.resetPassword(payload)
}

export const newPassword = async (payload) => {
  return await Auth.newPassword(payload)
}