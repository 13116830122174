import React, { forwardRef } from 'react'

const RadioButton = forwardRef((props, ref) => {
  const { options, selectedOption, onChange, isHorizontal, onBlur, isValid, errorMessage } = props
  return (
    <>
      <div className={isHorizontal ? 'radio-group' : ''}>
        {options.map((option, index) => (
          <div key={option.value} className={`form-check form-check-custom form-check-solid  ${index > 0 && isHorizontal ? 'ms-2' : 'pt-1'}`}>
            <input
              type="radio"
              id={option.value}
              value={option.value ?? false}
              checked={selectedOption === option.value}
              onChange={() => onChange(option.value)}
              className="form-check-input"
              data-isvalid={isValid}
              ref={ref}
              onBlur={onBlur}
            />
            <label className="form-check-label" htmlFor={option.value}>
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {isValid === false && (
        <p className="mt-0 mb-0" style={{ color: 'red', whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default RadioButton
