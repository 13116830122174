import { ProductProperty as ProductPropertyAPI } from '../../../services'

// Async actions

const getStandardProductProperty = async (payload) => {
  return await ProductPropertyAPI.getStandardProductProperty(payload)
}

const getAllStandardProductProperty = async () => {
  return await ProductPropertyAPI.getAllStandardProductProperty()
}

const insertStandardProductProperty = async (payload) => {
  return await ProductPropertyAPI.insertStandardProductProperty(payload)
}

const updateStandardProductProperty = async (payload) => {
  return await ProductPropertyAPI.updateStandardProductProperty(payload)
}

const saveStandardProductPropertyDetail = async (payload) => {
  return await ProductPropertyAPI.saveStandardProductPropertyDetail(payload)
}

const deleteStandardProductProperty = async (id) => {
  return await ProductPropertyAPI.deleteStandardProductProperty(id)
}

export {
  getStandardProductProperty,
  getAllStandardProductProperty,
  insertStandardProductProperty,
  updateStandardProductProperty,
  saveStandardProductPropertyDetail,
  deleteStandardProductProperty
}
