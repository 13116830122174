import { useState, useEffect } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import { useRef } from 'react'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { ControlType, FormControl } from '../../components/FormControl'
import Loading from '../../components/Loading'
import { add, update } from '../../../../redux/actions/aff/LevelGroupActions'

const LevelGroupModal = ({ item, closeModal, fetchItems }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const [isLoading, setIsLoading] = useState(false)
  const [isEdit] = useState(!_.isEmpty(item))

  const [fromRevenueAmount, setFromRevenueAmount] = useState(item?.fromRevenueAmount ?? null)
  const [toRevenueAmount, setToRevenueAmount] = useState(item?.toRevenueAmount ?? null)
  const [percent, setPercent] = useState(item?.percent ?? null)
  const [levelNumber, setLevelNumber] = useState(item?.levelNumber ?? null)

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return
    setIsLoading(true)

    const payload = {
      id: item?.id,
      fromRevenueAmount,
      toRevenueAmount: toRevenueAmount === '' ? null : toRevenueAmount,
      percent,
      levelNumber,
      isActive: true,
    }

    const { error } = isEdit ? await update(payload) : await add(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      fetchItems()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
    setIsLoading(false)
    closeModal(false)
  }

  return (
    <Modal title={intl.formatMessage({ id: isEdit ? 'Update' : 'AddNew' })} closeModal={closeModal} modalSize='mw-750px'>
      <form className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_Banner_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.levelNumber = ref)}
                controlType={ControlType.NumberBox}
                type='input'
                label={intl.formatMessage({ id: 'LevelGroup.Column.Level' })}
                value={levelNumber}
                onChange={(e) => setLevelNumber(e.target.value)}
                tabIndex={1}
                isRequired={true}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.fromRevenueAmount = ref)}
                controlType={ControlType.NumberBox}
                type='number'
                label={intl.formatMessage({ id: 'LevelGroup.Column.FromRevenueAmount' })}
                value={fromRevenueAmount}
                onChange={(e) => setFromRevenueAmount(e.target.value)}
                tabIndex={2}
                isRequired={true}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.toRevenueAmount = ref)}
                controlType={ControlType.NumberBox}
                type='number'
                label={intl.formatMessage({ id: 'LevelGroup.Column.ToRevenueAmount' })}
                value={toRevenueAmount}
                onChange={(e) => setToRevenueAmount(e.target.value)}
                tabIndex={3}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.percent = ref)}
                controlType={ControlType.NumberBox}
                type='number'
                label={intl.formatMessage({ id: 'LevelGroup.Column.Percent' })}
                value={percent}
                onChange={(e) => setPercent(e.target.value)}
                tabIndex={4}
                isRequired={true}
              />
            </div>
          </div>
        </div>

        <div className='text-center p-4'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className='btn btn-light me-3' tabIndex={5}>
            <i className='fa fa-times'></i>
            <FormattedMessage id='Close' />
          </Button>
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className='btn btn-primary' tabIndex={6}>
            <i className='fa fa-save'></i>
            <FormattedMessage id='Save' />
          </Button>
        </div>

        {isLoading && <Loading />}
      </form>
    </Modal>
  )
}

export default LevelGroupModal
