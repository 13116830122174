import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import { logout } from '../../../../../redux/actions/auth/authActions'
import { toAbsoluteUrl } from '../../../../../helpers'
import { FormattedMessage } from 'react-intl'
import { Auth } from '../../../../../components/auth/Auth'
import { Languages } from './Languages'
import { getUserById } from '../../../../../redux/actions/auth/userActions'
import { DEFAULT_VALUE } from '../../../../../utilities/constant'

class UserMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {},
    }

    this.handleLogout = this.handleLogout.bind(this)
  }

  async componentDidMount() {
    await this.fetchUser()
  }

  fetchUser = async () => {
    try {
      const currentUser = Auth.getUserData() || {}
      const { result } = await getUserById({ id: currentUser?.id })
      if (result) {
        this.setState({ user: result })
      }
    } catch (error) {
      console.error(error)
    }
  }

  async handleLogout() {
    await this.props.logout()
    if (_.isEmpty(this.props.user)) {
      document.location.reload()
    }
  }

  render() {
    
    const { user } = this.state;
    return (
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              {/* <img alt='Logo' src={toAbsoluteUrl('/media/no-avatar.png')} /> */}
              <img src={!_.isEmpty(user?.avatar) ? DEFAULT_VALUE.BASE_URL + user?.avatar : '/media/no-avatar.png'} alt='avatar' />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {user?.fullName}
                <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>{user?.roleName}</span>
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {user?.email}
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <Link to='/user/user-profile/overview' className='menu-link px-5'>
            <FormattedMessage id='User.UserProfile' />
          </Link>
        </div>
        <div className='menu-item px-5 my-1'>
          <Link to='/user/user-profile/settings' className='menu-link px-5'>
            <FormattedMessage id='User.UserSetting' />
          </Link>
        </div>
        <div className='menu-item px-5 my-1'>
          <Link to='/user/user-profile/change-password' className='menu-link px-5'>
            <FormattedMessage id='User.ChangePassword' />
          </Link>
        </div>
        <Languages />
        <div className='separator my-2'></div>
        <div className='menu-item px-5'>
          <a onClick={this.handleLogout} className='menu-link px-5'>
            <FormattedMessage id='Logout' />
          </a>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
})

const mapDispatchToProps = {
  logout,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
