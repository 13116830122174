import { Affiliate as AffiliateAPI } from '../../../services'

// Async actions

const getAffiliates = async (payload) => {
  return await AffiliateAPI.getAffiliates(payload)
}

const getAffiliateById = async (affiliateId) => {
  return await AffiliateAPI.getAffiliateById(affiliateId)
}

const getByAffiliateCode = async (id, affiliateCode) => {
  return await AffiliateAPI.getByAffiliateCode(id, affiliateCode)
}

const saveAffiliate = async (payload) => {
  return await AffiliateAPI.saveAffiliate(payload)
}

const customerRegister = async (payload) => {
  return await AffiliateAPI.customerRegister(payload)
}

const updateAffiliate = async (payload) => {
  return await AffiliateAPI.updateAffiliate(payload)
}

const approveAffiliate = async (affiliateId) => {
  return await AffiliateAPI.approveAffiliate(affiliateId)
}

const ignoreAffiliate = async (affiliateId) => {
  return await AffiliateAPI.ignoreAffiliate(affiliateId)
}

export { saveAffiliate, updateAffiliate, getAffiliateById, approveAffiliate, getAffiliates, ignoreAffiliate, getByAffiliateCode, customerRegister }
