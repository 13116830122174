/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import _, { set } from 'lodash'
import TableSearch from '../../components/TableSearch'
import Table from '../../components/Table'
import {
  getStandardProductProperty,
  deleteStandardProductProperty,
  saveStandardProductPropertyDetail,
} from '../../../../redux/actions/gnr/productPropertyActions'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import { DEFAULT_VALUE, GUID_NEWID } from '../../../../utilities/constant'
import Pagination from '../../components/Pagination'
import ProductPropertyModal from './ProductPropertyModal'
import Confirm from '../../components/Confirm'

const ProductProperty = () => {
  const intl = useIntl()

  const [properties, setProperties] = useState([])
  const [propertiesOriginals, setPropertiesOriginals] = useState([])
  const [selectedItem, setSelectedItem] = useState({})
  const [selectedPropertyDetailId, setSelectedPropertyDetailId] = useState({})
  const [propertyDetails, setPropertyDetails] = useState([])

  const [isEdit, setIsEdit] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmDeletePropertyDetail, setConfirmDeletePropertyDetail] = useState(false)
  const [pageIndex, setPageIndex] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)

  const propertyDetailColumns = [
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.Property' })}`,
      accessor: 'property',
    },
    {
      Header: `${intl.formatMessage({ id: 'Product.Column.PropertyValue' })}`,
      accessor: 'value',
      width: 60,
      editable: true,
    },
    {
      Header: `${intl.formatMessage({ id: 'Action' })}`,
      accessor: 'action',
      width: 25,
    },
  ]

  useEffect(() => {
    fetchItems()
  }, [pageIndex, pageSize])

  useEffect(() => {
    if (!_.isEmpty(selectedItem?.productPropertyDetails)) {
      setPropertyDetails(
        selectedItem.productPropertyDetails.map((x) => {
          return {
            id: x.id,
            standardProductPropertyId: selectedItem.id,
            property: selectedItem.name,
            value: x.value,
            action: (
              <div className='d-flex justify-content-center'>
                <a onClick={() => handleConfirmRemovePropertyDetail(x.id)} className='btn btn-icon btn-light-danger btn-sm'>
                  <i className='fa-solid fa-trash' />
                </a>
              </div>
            ),
          }
        })
      )
    } else {
      setPropertyDetails([])
    }
  }, [selectedItem])

  const fetchItems = async (item) => {
    try {
      const { result } = await getStandardProductProperty({ pageIndex, pageSize })
      if (result) {
        setProperties(result.items)
        setPropertiesOriginals(result.items)
        setTotalPages(result.totalCount)
        setSelectedItem(_.isEmpty(item) ? result.items[0] : item)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleCreate = () => {
    setSelectedItem(null)
    setIsEdit(true)
  }

  const handleCreatePropertyDetail = (productProperty) => {
    const newItem = [...propertyDetails]
    let newId = GUID_NEWID()
    newItem.push({
      id: newId,
      standardProductPropertyId: productProperty.id,
      property: productProperty.name,
      value: '',
      action: (
        <div className='d-flex justify-content-center'>
          <a onClick={() => handleConfirmRemovePropertyDetail(newId)} className='btn btn-icon btn-light-danger btn-sm'>
            <i className='fa-solid fa-trash' />
          </a>
        </div>
      ),
    })
    setPropertyDetails(newItem)
  }

  const handleEdit = (item) => {
    setSelectedItem(item)
    setIsEdit(true)
  }

  const handleConfirmRemove = (item) => {
    setSelectedItem(item)
    setConfirmDelete(true)
  }

  const handleConfirmRemovePropertyDetail = (item) => {
    setSelectedPropertyDetailId(item)
    setConfirmDeletePropertyDetail(true)
  }

  const handleRemove = async () => {
    try {
      const { error } = await deleteStandardProductProperty(selectedItem?.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        fetchItems()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleRemovePropertyDetail = async () => {
    try {
      const indexToRemove = propertyDetails.findIndex((item) => item.id === selectedPropertyDetailId)
      if (indexToRemove !== -1) {
        const newItems = [...propertyDetails]
        newItems.splice(indexToRemove, 1)
        setPropertyDetails(newItems)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSavePropertyDetail = async () => {
    const { error } = saveStandardProductPropertyDetail(propertyDetails)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      fetchItems(selectedItem)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  const handleCellValueChange = (cell, value) => {
    const updatedRows = [...propertyDetails]
    const rowIndex = updatedRows.findIndex((row) => row.id === cell.row.original.id)

    if (rowIndex === -1) {
      return
    }
    updatedRows[rowIndex][cell.column.id] = value

    setPropertyDetails(updatedRows)
  }

  return (
    <div className='d-flex flex-column flex-lg-row'>
      <div className='flex-column flex-lg-row-auto col-sm-4 min-w-450px'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title fw-bold text-dark'>{intl.formatMessage({ id: 'Product.Column.Property' })}</h3>

            <div className='card-toolbar gap-4'>
              <a className='btn btn-sm btn-success' onClick={() => handleCreate()} title={intl.formatMessage({ id: 'AddNew' })}>
                <i className='fa-solid fa-plus'></i>
                {intl.formatMessage({ id: 'AddNew' })}
              </a>

              <TableSearch onSearch={setProperties} originalData={propertiesOriginals} />
            </div>
          </div>
          <div className='card-body pt-5'>
            <div className='scroll-y me-n5 pe-5'>
              {properties?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className='d-flex align-items-center'>
                      <div
                        className={`flex-grow-1 py-2 me-1 pointer rounded ${selectedItem?.id === item.id ? 'selected' : ''}`}
                        onClick={() => setSelectedItem(item)}
                      >
                        <span className={`text-gray-900 text-hover-primary ms-4`}>{item.name}</span>
                      </div>
                      <div className='d-flex align-items-center'>
                        <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm me-1'>
                          <i className='fa-solid fa-pen-to-square'></i>
                        </a>
                        <a onClick={() => handleConfirmRemove(item)} className='btn btn-icon btn-light-danger btn-sm'>
                          <i className='fa-solid fa-trash'></i>
                        </a>
                      </div>
                    </div>
                    <div className='separator my-2'></div>
                  </React.Fragment>
                )
              })}
            </div>
            <Pagination
              totalPages={totalPages}
              currentPage={pageIndex}
              itemsPerPage={pageSize}
              onPageChange={(e) => setPageIndex(e)}
              onItemsPerPageChange={(e) => setPageSize(e)}
            />
          </div>
        </div>
      </div>

      <div className='flex-lg-row-fluid ms-4'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title fw-bold text-dark'>{selectedItem?.name ?? intl.formatMessage({ id: 'Product.Column.PropertyValue' })}</h3>

            <div className='card-toolbar'>
              {!_.isEmpty(selectedItem) && (
                <>
                  <a className='btn btn-sm btn-primary me-2' onClick={() => handleSavePropertyDetail()} title={intl.formatMessage({ id: 'Save' })}>
                    <i className='fa-solid fa-save'></i>
                    {intl.formatMessage({ id: 'Save' })}
                  </a>

                  <a
                    className='btn btn-sm btn-success me-2'
                    onClick={() => handleCreatePropertyDetail(selectedItem)}
                    title={intl.formatMessage({ id: 'AddNew' })}
                  >
                    <i className='fa-solid fa-plus'></i>
                    {intl.formatMessage({ id: 'AddNew' })}
                  </a>
                </>
              )}
            </div>
          </div>

          <div className='card-body py-2 pt-0'>
            <Table columns={propertyDetailColumns} data={propertyDetails} onCellValueChange={handleCellValueChange}/>
          </div>
        </div>
      </div>

      {isEdit && <ProductPropertyModal item={selectedItem} closeModal={setIsEdit} fetchItems={fetchItems} />}
      {confirmDelete && (
        <Confirm
          onConfirm={handleRemove}
          onCancel={() => setConfirmDelete(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}

      {confirmDeletePropertyDetail && (
        <Confirm
          onConfirm={handleRemovePropertyDetail}
          onCancel={() => setConfirmDeletePropertyDetail(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}
    </div>
  )
}

export default ProductProperty
