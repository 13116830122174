import { useState, useRef, useEffect } from 'react'
import { Icon } from '../../../../helpers'
import { useIntl } from 'react-intl'
import { ControlType, FormControl } from '../../components/FormControl'

const ReceivingFilter = ({ fetchData }) => {
  const intl = useIntl()
  const filterMenuRef = useRef(null)
  const componentRefs = useRef({})
  const [isOpenDropdown, setIsOpenDropdown] = useState(false)

  const [receiveCode, setReceiveCode] = useState(null)
  const [productCode, setProductCode] = useState(null)
  const [productName, setProductName] = useState(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterMenuRef.current && !filterMenuRef.current.contains(event.target) && isOpenDropdown) {
        setIsOpenDropdown(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpenDropdown])

  const toggleFilter = () => {
    setIsOpenDropdown(!isOpenDropdown)
  }

  const handleApply = () => {
    setIsOpenDropdown(!isOpenDropdown)
    fetchData(receiveCode, productCode, productName)
  }

  const handleReset = () => {
    setReceiveCode('')
    setProductCode('')
    setProductName('')
  }

  return (
    <div style={{ position: 'relative', display: 'inline-block' }} ref={filterMenuRef}>
      <a onClick={toggleFilter} className='btn btn-sm btn-light-info me-3'>
        <Icon iconName='filter' className='fs-3' />
        {intl.formatMessage({ id: 'Filter' })}
      </a>

      {isOpenDropdown && (
        <div className='filter-options'>
          <div className='w-300px w-md-325px'>
            <div className='px-7 py-2 d-flex justify-content-between align-items-center'>
              <div className='fs-5 text-dark fw-bold'>{intl.formatMessage({ id: 'FilterOptions' })}</div>
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-customers-modal-action='close'
                onClick={toggleFilter}
                style={{ cursor: 'pointer' }}
              >
                <Icon iconName='cross' className='fs-1' />
              </div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-4 gap-5'>
              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.receiveCode = ref)}
                  tabIndex={1}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({
                    id: 'Receiving.Column.Code',
                  })}
                  value={receiveCode}
                  onChange={(e) => setReceiveCode(e.target.value)}
                />
              </div>

              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.productCode = ref)}
                  tabIndex={1}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({
                    id: 'Product.Column.ProductCode',
                  })}
                  value={productCode}
                  onChange={(e) => setProductCode(e.target.value)}
                />
              </div>

              <div className='mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.productName = ref)}
                  tabIndex={2}
                  controlType={ControlType.Input}
                  label={intl.formatMessage({
                    id: 'Product.Column.ProductName',
                  })}
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </div>

              <div className='d-flex justify-content-end gap-3'>
                <a type='reset' className='btn btn-light btn-active-light-primary btn-sm' onClick={handleReset}>
                  <i className='fa fa-eraser' aria-hidden='true'></i>
                  {intl.formatMessage({ id: 'Reset' })}
                </a>
                <a type='submit' className='btn btn-success btn-sm' onClick={handleApply}>
                  <i className='fa fa-check' aria-hidden='true'></i>
                  {intl.formatMessage({ id: 'Apply' })}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReceivingFilter
