import { Role as RoleAPI } from '../../../services'

const getAllRole = async () => {
    return await RoleAPI.getAllRole()
}

const saveRole = async (payload) => {
    return await RoleAPI.saveRole(payload)
}

const updateRole = async (payload) => {
    return await RoleAPI.updateRole(payload)
}

const getFeaturePermissionsByRoleId = async (payload) => {
    return await RoleAPI.getFeaturePermissionsByRoleId(payload)
}

const saveFeaturePermissions = async (payload) => {
    return await RoleAPI.saveFeaturePermissions(payload)
}

export { getAllRole, saveRole, updateRole, getFeaturePermissionsByRoleId, saveFeaturePermissions}