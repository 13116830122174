import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class RoleApi extends BaseApi {
    constructor() {
        super()

        const conf = new Config().getConfig(CONFIG_TYPE.ROLE)
        this.adapter = this.initAdapter(conf)
    }

    async getAllRole() {
        return await this.adapter.getAsync('getAll', {})
    }

    async saveRole(payload) {
        return await this.adapter.postAsync('saveRole', { payload })
    }

    async updateRole(payload) {
        return await this.adapter.putAsync('updateRole', { payload })
    }

    async getFeaturePermissionsByRoleId(payload) {
        return await this.adapter.getAsync('getFeaturePermissionsByRoleId', { payload })
    }

    async saveFeaturePermissions(payload) {
        return await this.adapter.postAsync('saveFeaturePermissions', { payload })
    }

}


export default RoleApi