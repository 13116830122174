import React, { Component } from 'react'
import { ScrollTopComponent } from '../../assets/ts/components/index.ts'
import { Icon } from '../../helpers'

class ScrollTop extends Component {
    scrollTop() {
        ScrollTopComponent.goTop()
    }

    componentDidMount() {
        setTimeout(() => {
            this.scrollTop()
        }, 0)
    }

    render() {
        return (
            <div id="kt_scrolltop" className="scrolltop" data-kt-scrolltop="true">
                <Icon iconName="arrow-up" />
            </div>
        )
    }
}

export default ScrollTop
