import { FIRSTLOAD } from '../actions/commonAction'

const initialState = {
    isFirstLoad: true
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case FIRSTLOAD:
            return { ...state, isFirstLoad : false };
        default:
            return state;
    }
};
export default commonReducer