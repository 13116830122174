import { v4 as uuidv4 } from 'uuid'

const TABLE_NAME = {
  BANNER: 'Banner',
  CUSTOMER: 'Customer',
  PRODUCT: 'Product',
  SUPPLIER: 'Supplier',
  PARTNER_TYPE: 'PartnerType',
  GENDER: 'Gender',
  BUSINESS_INDUSTRY: 'BusinessIndustry',
  SERVICE_FEE: 'ServiceFee',
  SUPPLIER_MEMBERSHIP_FEE: 'SupplierMembershipFee',
  PRODUCT_STATUS: 'ProductStatus',
  PRODUCT_PROPERTY: 'ProductProperty',
  CATEGORY: 'Category',
  COUPON_TYPE: 'CouponType',
  DISCOUNT_TYPE: 'DiscountType',
  CATEGORY_NEWS: 'CategoryNews',
  COUPON_STATUS: 'CouponStatus',
  USER: 'User',
  SUBCATEGORY: 'SubCategory',
  WAREHOUSE: 'Warehouse',
  PROMOTION: 'Promotion',
  PROMOTION_TYPE: 'PromotionType',
  FEATURE_PRODUCT: 'FeatureProduct',
  PRODUCT_PROMOTION: 'ProductPromotion',
  NEWS: 'News'
}

export const FILE_TYPE = {
  BUSINESS_LICENSE_FILE: 'BusinessLicense',
  PRODUCT_LICENSE_FILE: 'ProductLicense',
  PRODUCT_MAIN_IMAGE: 'ProductMainImage',
  PRODUCT_FILE_ATTACHMENT: 'ProductFileAttachment',
  PRODUCT_SUB_IMAGE: 'ProductSubImage',
  USER_AVATAR: 'UserAvatar',
  SUBCATEGORY_LOGO: 'SubCategoryLogo',
  SUBCATEGORY_BANNER: 'SubCategoryBanner',
  CATEGORY_LOGO: 'CategoryLogo',
  CATEGORY_BANNER: 'CategoryBanner',
  PROMOTION_BANNER: 'PromotionBanner',
}

export const MODULE = {
  HOME: 'home',
  ECO: 'eco',
  SETTING: 'setting',
}

export const SETTING = {
  SETTINGGROUP: 'setting-group',
  USERGROUP: 'user-group',
  SUPPLIERGROUP: 'eco-group-supplier',
  PRODUCTGROUP: 'eco-group-product',
}

export const ROLE_NAME = {
  ADMIN: 'Admin',
  SUPPLIER: 'Supplier',
  AFFILIATE: 'Affiliate',
  CUSTOMER: 'Customer',
}

export const DEFAULT_STATUS_ID = {
  PRODUCT_STATUS_ID: '1',
}

export const BANNER_POSITION_ENUM = [
  { value: 1, name: 'Banner.Position.Top' },
  { value: 2, name: 'Banner.Position.Middle' },
  { value: 3, name: 'Banner.Position.Bottom' },
]

const DATETIME_FORMAT = {
  ddMMyyyy: 'dd/MM/yyyy',
  ddMMyyyyHHmm: 'dd/MM/yyyy HH:mm',
}

const GUID_NEWID = () => uuidv4()

const DEFAULT_VALUE = {
  AddNew: '36ceb109d49a43efa512224fb8957ee1',
  PAGE_INDEX: 1,
  PAGE_SIZE: 10,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  REGEX_EMAIL: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  GUID_EMPTY: '00000000-0000-0000-0000-000000000000',
  GUID_RANDOM: uuidv4(),
  REGEX_PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$_%\-\^&*(),.?":{}|<>])[A-Za-z\d!@#$_%\-\^&*(),.?":{}|<>]{8,}$/,
  REGEX_PHONENUMBER: /^\+?\d{10,11}$/,
  VND: '₫',
  PERCENT: '%',
  SLASH: '/',
}

const LEVEL_WAREHOUSE = {
  ADMIN: 1,
  SUPPLIER: 2,
}

export { TABLE_NAME, DATETIME_FORMAT, GUID_NEWID, DEFAULT_VALUE, LEVEL_WAREHOUSE }
