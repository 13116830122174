import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Input from '../components/Input'
import { useIntl } from 'react-intl'
import { Icon } from '../../../helpers'
import { getModules } from '../../../routes/index'
import * as roleApi from '../../../redux/actions/auth/roleActions'
import { showToast, TOAST_TYPES } from '../../../helpers/Toastify'
import { GUID_NEWID, DEFAULT_VALUE, ROLE_NAME } from '../../../utilities/constant'

const FeaturePermissions = () => {
  const intl = useIntl()

  const loadFeatures = (modules, parent = {}, level = 0) => {
    let currentFeatures = []
    modules
      .filter((x) => !x.isDefault)
      .forEach((item) => {
        if (item.features && item.features.length > 0) {
          currentFeatures.push({
            parentId: parent?.id ?? DEFAULT_VALUE.GUID_RANDOM,
            id: item.id,
            title: intl.formatMessage({ id: item.title }),
            path: item.path,
            level: level,
            hasChild: true,
            isSelected: false,
            isExpanded: true,
            ChildExpanded: true,
            isHidden: false,
            allwaysHidden: item.isHidden || false,
          })

          currentFeatures = currentFeatures.concat(loadFeatures(item.features, item, item.isHidden ? level : level + 1))
        } else if (!item.isHidden) {
          currentFeatures.push({
            parentId: parent?.id ?? DEFAULT_VALUE.GUID_RANDOM,
            id: item.id,
            title: intl.formatMessage({ id: item.title }),
            path: item.path,
            level: level,
            hasChild: false,
            isSelected: false,
            isExpanded: true,
            isHidden: false,
            allwaysHidden: false,
          })
        }
      })
    return currentFeatures
  }
  const [initialRoles, setInitialRoles] = useState([])
  const [roles, setRoles] = useState([])
  const [searchRole, setSearchRole] = useState('')
  const [editingRole, setEditingRole] = useState(null)
  const [selectedRole, setSelectedRole] = useState(null)
  const [isSaveRole, setIsSaveRole] = useState(false)

  const [initialFeatures, setInitialFeatures] = useState([])
  const [features, setFeatures] = useState([])
  const [searchFeatures, setSearchFeatures] = useState('')
  const [isSaveFeatures, setIsSaveFeatures] = useState(false)
  const [isExpandAll, setIsExpandAll] = useState(true)
  const [isSelectedAll, setIsSelectedAll] = useState(false)

  useEffect(() => {
    getFeatures()
    getRoles()
  }, [])

  const filteredRoles = roles?.filter((role) => !role.isDeleted && _.deburr(_.toLower(role.name)).includes(_.deburr(_.toLower(searchRole))))
  const filteredFeatures = features?.filter((item) => _.deburr(_.toLower(item.title)).includes(_.deburr(_.toLower(searchFeatures))))

  const getFeatures = async () => {
    try {
      const modules = await getModules()
      const result = loadFeatures(modules)

      setInitialFeatures(result ?? [])
      setFeatures(result ?? [])
    } catch (error) {
      console.error('getModules:', error)
    }
  }
  const getRoles = async () => {
    try {
      const { result } = await roleApi.getAllRole()
      let currentRoles = result?.filter((x) => x.name !== ROLE_NAME.ADMIN)
      setInitialRoles(currentRoles ?? [])
      setRoles(currentRoles ?? [])
    } catch (error) {
      console.error('getRoles:', error)
    }
  }

  const getFeaturePermissionsByRoleId = async (roleId) => {
    try {
      const payload = { roleId }
      const { result } = await roleApi.getFeaturePermissionsByRoleId(payload)
      const selectFeatures = initialFeatures.map((item) => ({
        ...item,
        isSelected: result?.some((x) => x.featureId === item.id) ? true : false,
      }))

      const checkAllFeatures = selectFeatures.filter((x) => x.parentId === DEFAULT_VALUE.GUID_RANDOM)
      if (checkAllFeatures.every((x) => x.isSelected)) {
        setIsSelectedAll(true)
      } else if (_.uniqBy(checkAllFeatures, 'isSelected').length === 2) {
        setIsSelectedAll(false)
      }
      setInitialFeatures(selectFeatures)
      setFeatures(selectFeatures)
    } catch (error) {
      console.error('Error fetching initial roles:', error)
    }
  }

  useEffect(() => {
    const stateChanged = JSON.stringify(initialRoles) !== JSON.stringify(roles)
    if (stateChanged) {
      setIsSaveRole(true)
    } else {
      setIsSaveRole(false)
    }
  }, [roles])

  useEffect(() => {
    const stateChanged = JSON.stringify(initialFeatures) !== JSON.stringify(features)
    if (stateChanged) {
      setIsSaveFeatures(true)
    } else {
      setIsSaveFeatures(false)
    }
  }, [features])

  const addRole = () => {
    const newUuid = GUID_NEWID()
    const role = { id: newUuid, name: '', isAdded: true }
    setRoles([...roles, role])
    setEditingRole(newUuid)
  }

  const editRole = (id, newName) => {
    setRoles((prevRoles) => prevRoles.map((role) => (role.id === id ? { ...role, name: newName, isUpdated: true } : role)))
  }

  const removeRole = (id) => {
    setRoles((prevRoles) => prevRoles.map((role) => (role.id === id ? { ...role, isDeleted: true } : role)))
  }

  const refeshRole = () => {
    setRoles(initialRoles)
  }

  const saveRole = async () => {
    const payload = roles.filter((role) => role.isAdded || role.isUpdated || role.isDeleted)
    const { error, result } = await roleApi.saveRole(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Customer.Notification.Success' })}`, TOAST_TYPES.SUCCESS)
      getRoles()
    } else {
      showToast(`${intl.formatMessage({ id: 'Customer.Notification.Error' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleSearchRoles = (event) => {
    setSearchRole(event.target.value)
  }

  const handleEditRole = (id) => {
    setEditingRole(id)
  }

  const handleCancelEdit = () => {
    setEditingRole(null)
  }

  const handleSelectedRole = (item) => {
    setSelectedRole(item)
    getFeaturePermissionsByRoleId(item.id)
  }

  const handleSearchFeatures = (event) => {
    setSearchFeatures(event.target.value)
  }

  const findChildFeatures = (features, id, level = 0) => {
    let childs = []
    if (level === 0) {
      childs = features.filter((item) => item.id === id)
    } else {
      childs = features.filter((item) => item.parentId === id)
    }

    if (!_.isEmpty(childs)) {
      childs.forEach((item) => {
        childs = childs.concat(findChildFeatures(features, item.id, level + 1))
      })
    }
    return childs
  }

  const findParentFeatures = (features, parentId) => {
    let parents = features.filter((item) => item.id === parentId)

    if (!_.isEmpty(parents) && parents.length > 0) {
      parents = parents.concat(findParentFeatures(features, parents[0].parentId))
    }
    return parents
  }

  const handleSelectedFeatures = (id) => {
    setFeatures((prevFeatures) => {
      const selectedItem = prevFeatures.find((item) => item.id === id)
      const childFeatures = findChildFeatures(prevFeatures, id)
      const parentFeatures = findParentFeatures(prevFeatures, selectedItem.parentId)

      if (selectedItem?.parentId === DEFAULT_VALUE.GUID_RANDOM) {
        const otherFeatures = prevFeatures.filter((x) => x.parentId === DEFAULT_VALUE.GUID_RANDOM && x.id !== id)

        if (otherFeatures.every((x) => x.isSelected === !selectedItem.isSelected)) {
          setIsSelectedAll(!selectedItem.isSelected)
          const updatedFeatures = prevFeatures.map((item) => ({
            ...item,
            isSelected: !selectedItem.isSelected,
          }))

          return updatedFeatures
        } else if (otherFeatures.some((x) => x.isSelected === selectedItem.isSelected)) {
          setIsSelectedAll(false)
        }
      }

      if (!_.isEmpty(parentFeatures)) {
        parentFeatures.forEach((item) => {
          const childs = prevFeatures.filter((x) => x.parentId === item.id && x.id !== id)
          item.isSelected = !selectedItem.isSelected && childs.every((x) => x.isSelected === !selectedItem.isSelected)
        })

        const checkAllFeatures = prevFeatures.filter((x) => x.parentId === DEFAULT_VALUE.GUID_RANDOM)
        if (checkAllFeatures.every((x) => x.isSelected)) {
          setIsSelectedAll(true)
        } else if (_.uniqBy(checkAllFeatures, 'isSelected').length === 2) {
          setIsSelectedAll(false)
        }
      }

      const updatedFeatures = prevFeatures.map((item) => {
        return {
          ...item,
          isSelected: childFeatures.some((selectedItem) => selectedItem.id === item.id) ? !selectedItem.isSelected : item.isSelected,
        }
      })

      return updatedFeatures
    })
  }

  const handleSelectedAll = () => {
    setIsSelectedAll(!isSelectedAll)
    setFeatures((prevFeatures) => {
      const updatedFeatures = prevFeatures.map((item) => ({
        ...item,
        isSelected: !isSelectedAll,
      }))

      return updatedFeatures
    })
  }

  const handleExpandFeatures = (id) => {
    setFeatures((prevFeatures) => {
      const selectedItem = prevFeatures.find((x) => x.id === id)
      const selectedFeatures = getChildFeatures(features, id)
      const updatedFeatures = prevFeatures.map((item) => {
        const hasUpdate = selectedFeatures.some((x) => x.id === item.id)
        return {
          ...item,
          isExpanded: hasUpdate ? !selectedItem.isExpanded : item.isExpanded,
          isHidden: hasUpdate ? item.id !== id && selectedItem.isExpanded : item.isHidden,
        }
      })

      return updatedFeatures
    })
  }

  const getChildFeatures = (features, id, isParent = false) => {
    let childs = []
    const items = features.filter((item) => id === (isParent ? item.parentId : item.id))
    items.map((item) => {
      childs.push(item)
      if (item?.hasChild) {
        childs = childs.concat(getChildFeatures(features, item.id, true))
      }
    })
    return childs
  }

  const handleExpandAll = () => {
    setIsExpandAll(!isExpandAll)
    setFeatures((prevFeatures) => {
      const updatedFeatures = prevFeatures.map((item) => ({
        ...item,
        isExpanded: !isExpandAll,
        isHidden: item.level > 0 ? isExpandAll : item.isHidden,
      }))
      return updatedFeatures
    })
  }

  const refeshFeatures = () => {
    setFeatures(initialFeatures)
  }

  const saveFeaturePermissions = async () => {
    var featurePermissions = features.reduce((result, item) => {
      if (item.isSelected) {
        result.push({
          roleId: selectedRole.id,
          featureId: item.id,
        })
      }
      return result
    }, [])
    var payload = {
      id: selectedRole.id,
      featurePermissions,
    }
    const { error } = await roleApi.saveFeaturePermissions(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      getFeaturePermissionsByRoleId(selectedRole.id)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <div className='d-flex flex-column flex-lg-row'>
      <div className='flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title fw-bold text-dark'>{intl.formatMessage({ id: 'Settings.UserRoles' })}</h3>
            <div className='card-toolbar'>
              <a className='btn btn-sm btn-success' onClick={() => addRole()} title={intl.formatMessage({ id: 'AddNew' })}>
                <i className='fa-solid fa-plus'></i>
              </a>
              {isSaveRole && (
                <>
                  <a className='btn btn-sm btn-primary ms-1' onClick={() => refeshRole()} title={intl.formatMessage({ id: 'Save' })}>
                    <i className='fa-solid fa-refresh'></i>
                  </a>
                  <a className='btn btn-sm btn-primary ms-1' onClick={() => saveRole()} title={intl.formatMessage({ id: 'Refesh' })}>
                    <i className='fa-solid fa-save'></i>
                  </a>
                </>
              )}
            </div>
            <div className='w-100 position-relative' autoComplete='off'>
              <Icon iconName='magnifier' className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y' />
              <input
                type='text'
                className='form-control form-control-solid px-15'
                name='search'
                placeholder={intl.formatMessage({ id: 'Search' })}
                value={searchRole}
                onChange={(e) => handleSearchRoles(e)}
              />
            </div>
          </div>

          <div className='card-body pt-5'>
            <div
              className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
              data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
              data-kt-scroll-offset='0px'
            >
              {filteredRoles?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className='d-flex align-items-center'>
                      {editingRole === item.id ? (
                        <>
                          <div className={`flex-grow-1 py-2 me-3`}>
                            <Input onChange={(e) => editRole(item.id, e.target.value)} onBlur={() => handleCancelEdit()} value={item.name} />
                          </div>
                          <div className='align-items-center'>
                            <a onClick={() => handleCancelEdit()} className='btn btn-icon btn-light-primary btn-sm'>
                              <i className='fa-solid fa-check'></i>
                            </a>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={`flex-grow-1 py-2 me-1 ${selectedRole?.id === item.id ? 'selected' : ''}`} style={{ borderRadius: '0.425rem' }}>
                            <a href='#' onClick={() => handleSelectedRole(item)} className={`text-gray-900 text-hover-primary ms-4`}>
                              {item.name}
                            </a>
                          </div>
                          <div className='align-items-center'>
                            <a onClick={() => handleEditRole(item.id)} className='btn btn-icon btn-light-success btn-sm me-1'>
                              <i className='fa-solid fa-pen-to-square'></i>
                            </a>
                            {!item.isDefault && (
                              <a onClick={() => removeRole(item.id)} className='btn btn-icon btn-light-danger btn-sm'>
                                <i className='fa-solid fa-trash'></i>
                              </a>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    <div className='separator my-2'></div>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title fw-bold text-dark'>{selectedRole?.name ?? intl.formatMessage({ id: 'Settings.FeaturePermissions.Features' })}</h3>

            <div className='card-toolbar'>
              <a
                className='btn btn-sm btn-default'
                onClick={() => handleExpandAll()}
                title={isExpandAll ? intl.formatMessage({ id: 'CollapseAll' }) : intl.formatMessage({ id: 'ExpandAll' })}
              >
                <i className={`fa-solid ${isExpandAll ? 'fa-down-left-and-up-right-to-center' : 'fa-up-right-and-down-left-from-center'}`}></i>
              </a>
              {isSaveFeatures && selectedRole && (
                <>
                  <a className='btn btn-sm btn-primary ms-1' onClick={() => refeshFeatures()} title={intl.formatMessage({ id: 'Refesh' })}>
                    <i className='fa-solid fa-refresh'></i>
                  </a>
                  <a className='btn btn-sm btn-primary ms-1' onClick={() => saveFeaturePermissions()} title={intl.formatMessage({ id: 'Save' })}>
                    <i className='fa-solid fa-save'></i>
                  </a>
                </>
              )}
            </div>
            <div className='w-100 position-relative' autoComplete='off'>
              <Icon iconName='magnifier' className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y' />
              <input
                type='text'
                className='form-control form-control-solid px-15'
                name='search'
                placeholder={intl.formatMessage({ id: 'Search' })}
                value={searchFeatures}
                onChange={(e) => handleSearchFeatures(e)}
              />
            </div>
          </div>

          <div className='card-body pt-5'>
            <div
              className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
              data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
              data-kt-scroll-offset='0px'
            >
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='w-25px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input onChange={() => handleSelectedAll()} className='form-check-input pointer' type='checkbox' checked={isSelectedAll} value='1' />
                      </div>
                    </th>
                    <th className='min-w-100px'>{intl.formatMessage({ id: 'Settings.FeaturePermissions.Features' }).toUpperCase()}</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {filteredFeatures
                      ?.filter((x) => !x.isHidden && !x.allwaysHidden)
                      .map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  onChange={() => handleSelectedFeatures(item.id)}
                                  className='form-check-input pointer'
                                  checked={item.isSelected}
                                  type='checkbox'
                                  value='1'
                                />
                              </div>
                            </td>
                            <td>
                              <div className={`ps-${item.level * 6}`}>
                                {item.hasChild && (
                                  <i
                                    onClick={() => handleExpandFeatures(item.id)}
                                    className={`me-2 fa-solid pointer ${item.isExpanded ? 'fa-minus-square' : 'fa-plus-square'}`}
                                  ></i>
                                )}
                                <span>{item.title}</span>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                  </>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturePermissions
