import { useEffect, useState } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl } from 'react-intl'
import { getAdminWarehouse, saveWarehouseSupplier } from '../../../../redux/actions/eco/warehouseActions'
import Loading from '../../components/Loading'
import { Auth } from '../../../../components/auth/Auth'
import Pagination from '../../components/Pagination'
import Table from '../../components/Table'
import TableSearch from '../../components/TableSearch'

const UseAdminWarehouseModal = ({ editWarehouse, wareHouseIds, closeModal, fetchWarehouses }) => {
  const intl = useIntl()
  const currentUser = Auth.getUserData() || {}

  const [isSelectedAll, setIsSelectedAll] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [isLoading, setIsLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [warehouses, setWarehouses] = useState([])
  const [warehousesOriginal, setWarehousesOriginal] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { result } = await getAdminWarehouse({ pageIndex: currentPage, pageSize: pageSize, supplierId: currentUser.supplierId })
        const data = result?.items
        if (data) {
          const filterData = _.filter(result?.items, (x) => !_.includes(wareHouseIds, x.id))
          setWarehouses(filterData)
          setTotalPages(result.totalCount)
          setWarehousesOriginal(filterData)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleSave = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const filterDataSve = _.filter(warehouses, (item) => item.isSelected).map((x) => x.id)
    const { error } = await saveWarehouseSupplier(filterDataSve)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      editWarehouse.isAdded = false
      closeModal(false)
      fetchWarehouses()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
    setIsLoading(false)
  }

  const handleSelectedAll = () => {
    setWarehouses(
      warehouses.map((item) => ({
        ...item,
        isSelected: !isSelectedAll,
      }))
    )

    setIsSelectedAll(!isSelectedAll)
  }

  const handleSelected = (id) => {
    setWarehouses((prev) => {
      const updated = [...prev]
      const selectedItem = updated.find((item) => item.id === id)
      selectedItem.isSelected = !selectedItem.isSelected

      if (updated.every((x) => x.isSelected === selectedItem.isSelected)) {
        setIsSelectedAll(selectedItem.isSelected)
      } else {
        setIsSelectedAll(false)
      }
      return updated
    })
  }

  const columns = [
    {
      Header: (
        <div className='form-check form-check-sm form-check-custom form-check-solid justify-content-center'>
          <input onChange={handleSelectedAll} className='form-check-input pointer' type='checkbox' checked={isSelectedAll} value='1' />
        </div>
      ),
      accessor: 'isSelected',
      width: 25,
      disableSort: true,
    },
    {
      Header: `${intl.formatMessage({ id: 'Warehouse.Column.Code' })}`,
      accessor: 'code',
      width: 66,
    },
    {
      Header: `${intl.formatMessage({ id: 'Warehouse.Column.Name' })}`,
      accessor: 'name',
      width: 120,
    },
    {
      Header: `${intl.formatMessage({ id: 'Warehouse.Column.Address' })}`,
      accessor: 'fullAddress',
    },
  ]

  const rows = warehouses?.map((item) => {
    return {
      isSelected: (
        <div className='form-check form-check-sm form-check-custom form-check-solid justify-content-center'>
          <input onChange={() => handleSelected(item.id)} className='form-check-input pointer' checked={item.isSelected} type='checkbox' value='1' />
        </div>
      ),
      code: item.code,
      name: item.name,
      fullAddress: item.fullAddress,
    }
  })

  return (
    <Modal title={intl.formatMessage({ id: editWarehouse.isAdded ? 'AddNew' : 'Update' })} closeModal={closeModal} modalSize='mw-900px'>
      <form id='kt_modal_add_coupon_form' className='form'>
        <div className='card-body p-0'>
          <div
            className='scroll-y me-n5 pe-5 h-200px h-lg-auto'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-offset='0px'
          >
            <div className='card-toolbar'>
              <TableSearch onSearch={setWarehouses} originalData={warehousesOriginal} />
            </div>
            <Table columns={columns} data={rows} />
          </div>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={(e) => setCurrentPage(e)}
            onItemsPerPageChange={(e) => setPageSize(e)}
          />
          {isLoading && <Loading />}
        </div>
        <div className='text-center pt-5'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} icon='fa-times' tabIndex={8} />
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} icon='fa-save' tabIndex={9} />
        </div>
        {isLoading && <Loading />}
      </form>
    </Modal>
  )
}

export default UseAdminWarehouseModal
