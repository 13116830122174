import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import authReducer from '../reducers/authReducer'
import commonReducer from '../reducers/commonReducer'
import signalRReducer from '../reducers/signalRReducer'

const rootReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  signalR: signalRReducer,
})

const store = createStore(rootReducer, applyMiddleware(thunk))

export default store
