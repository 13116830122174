import { useLang, setLanguage } from '../../../../../language/Appi18nProvider'
import { FormattedMessage } from 'react-intl'
import { languages } from '../../../../../language/constant'

const Languages = () => {
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  return (
    <div className="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start" data-kt-menu-flip="bottom">
      <a href="#" className="menu-link px-5">
        <span className="menu-title position-relative">
          <FormattedMessage id="Language" />
          <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
            {currentLanguage?.name} <img className="w-15px h-15px rounded-1 ms-2" src={currentLanguage?.flag} alt="flag" />
          </span>
        </span>
      </a>

      <div className="menu-sub menu-sub-dropdown w-175px py-4">
        {languages.map((l) => (
          <div
            className="menu-item px-3"
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a href="#" className={`menu-link d-flex px-5 ${l.lang === currentLanguage?.lang ? 'active' : ''}`}>
              <span className="symbol symbol-20px me-4">
                <img className="rounded-1" src={l.flag} alt="flag" />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export { Languages }
