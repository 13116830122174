import { useState, useEffect } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import { useRef } from 'react'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { ControlType, FormControl } from '../../components/FormControl'
import { BANNER_POSITION_ENUM, TABLE_NAME } from '../../../../utilities/constant'
import { saveBanner, updateBanner } from '../../../../redux/actions/eco/bannerActions'
import useGetFiles from '../../../../helpers/useGetFiles'
import Loading from '../../components/Loading'

const BannerModal = ({ editBanner, closeModal, fetchBanners, promotions }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const [isLoading, setIsLoading] = useState(false)

  const [promotionId, setPromotionId] = useState(editBanner?.promotionId ?? null)
  const [title, setTitle] = useState(editBanner?.title ?? null)
  const [subTitle, setSubTitle] = useState(editBanner?.subTitle ?? null)
  const [displaySeconds, setDisplaySeconds] = useState(editBanner?.displaySeconds ?? 5)
  const [fromDate, setFromDate] = useState(editBanner?.fromDate != null ? new Date(editBanner?.fromDate) : new Date())
  const [toDate, setToDate] = useState(editBanner?.toDate != null ? new Date(editBanner?.toDate) : null)
  const [sort, setSort] = useState(editBanner?.sort ?? null)
  const [isActive, setIsActive] = useState(editBanner?.isActive ?? true)
  const [selectedFiles, setSelectedFiles] = useGetFiles(TABLE_NAME.BANNER, editBanner.id)
  const [positionId, setPositionId] = useState(editBanner?.positionId ?? null)
  const positions = BANNER_POSITION_ENUM.map((el) => {
    return { ...el, name: intl.formatMessage({ id: el.name }) }
  })

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return
    setIsLoading(true)

    const bannerSave = {
      promotionId,
      title,
      subTitle,
      displaySeconds,
      fromDate,
      toDate,
      sort,
      id: editBanner?.id,
      images: selectedFiles,
      isActive: editBanner?.isActive ? isActive : true,
      positionId,
    }

    const { error } = editBanner.isAdded ? await saveBanner(bannerSave) : await updateBanner(bannerSave)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      editBanner.isAdded = false
      fetchBanners()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
    setIsLoading(false)
    closeModal(false)
  }

  const handleChangeFile = (files) => {
    setSelectedFiles(files)
  }

  return (
    <Modal title={intl.formatMessage({ id: editBanner.isAdded ? 'AddNew' : 'Update' })} closeModal={closeModal} modalSize='mw-750px'>
      <form id='kt_modal_add_Banner_form' className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_Banner_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.promotionId = ref)}
                tabIndex={1}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Banner.Label.Promotion' })}
                value={promotionId}
                onChange={(e) => setPromotionId(e.value)}
                options={promotions}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.title = ref)}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Banner.Label.Title' })}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.subTitle = ref)}
                controlType={ControlType.TextArea}
                label={intl.formatMessage({ id: 'Banner.Label.SubTitle' })}
                value={subTitle}
                rows={3}
                onChange={(e) => setSubTitle(e.target.value)}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.positionId = ref)}
                controlType={ControlType.Select}
                label={intl.formatMessage({ id: 'Banner.Label.Position' })}
                value={positionId}
                isRequired={true}
                onChange={(e) => setPositionId(e.value)}
                options={positions}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.fromDate = ref)}
                tabIndex={2}
                controlType={ControlType.DatePicker}
                label={intl.formatMessage({ id: 'Banner.Label.FromDate' })}
                value={fromDate}
                isRequired={true}
                onChange={(date) => setFromDate(date)}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.toDate = ref)}
                tabIndex={3}
                controlType={ControlType.DatePicker}
                label={intl.formatMessage({ id: 'Banner.Label.ToDate' })}
                value={toDate}
                onChange={(date) => setToDate(date)}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.displaySeconds = ref)}
                tabIndex={4}
                controlType={ControlType.NumberBox}
                type='number'
                label={intl.formatMessage({ id: 'Banner.Label.DisplaySeconds' })}
                value={displaySeconds}
                onChange={(e) => setDisplaySeconds(e.target.value)}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.sort = ref)}
                tabIndex={5}
                controlType={ControlType.NumberBox}
                type='number'
                label={intl.formatMessage({ id: 'Banner.Label.Sort' })}
                value={sort}
                onChange={(e) => setSort(e.target.value)}
              />
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.selectedFiles = ref)}
                tabIndex={7}
                controlType={ControlType.AttachFile}
                label={intl.formatMessage({ id: 'Banner.Label.Images' })}
                value={selectedFiles}
                onChange={handleChangeFile}
                isRequired={true}
              />
            </div>
          </div>

          {!editBanner.isAdded && (
            <div className='col-md-12 mb-3 p-0'>
              <FormControl
                ref={(ref) => (componentRefs.current.isActive = ref)}
                tabIndex={6}
                controlType={ControlType.Checkbox}
                label={intl.formatMessage({ id: 'Banner.Label.IsActive' })}
                value={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
                isFromSwitch={true}
              />
            </div>
          )}
        </div>

        <div className='text-center p-4'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={8}>
            <i className='fa fa-times'></i>
            <FormattedMessage id='Close' />
          </Button>
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={9}>
            <i className='fa fa-save'></i>
            <FormattedMessage id='Save' />
          </Button>
        </div>

        {isLoading && <Loading />}
      </form>
    </Modal>
  )
}

export default BannerModal
