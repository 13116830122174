import BaseApi from '../base/baseApi'
import Config from '../base/config'
import { CONFIG_TYPE } from '../constants'

class ProvinceAPI extends BaseApi {
  constructor() {
    super()

    const conf = new Config().getConfig(CONFIG_TYPE.PROVINCE)
    this.adapter = this.initAdapter(conf)
  }

  async getProvinces() {
    return await this.adapter.getAsync('getProvinces')
  }

  async getDistrictsByProvinceId(payload) {
    return await this.adapter.postAsync('getDistrictsByProvinceId', { payload })
  }

  async getWardsByDistrictId(payload) {
    return await this.adapter.postAsync('getWardsByDistrictId', { payload })
  }
}

export default ProvinceAPI
