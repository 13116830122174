import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { DEFAULT_VALUE } from '../../../../utilities/constant'
import { TOAST_TYPES, showToast } from '../../../../helpers/Toastify'
import _ from 'lodash'
import ExportExcel from '../../components/ExportExcel'
import TableSearch from '../../components/TableSearch'
import Table from '../../components/Table'
import Pagination from '../../components/Pagination'
import Loading from '../../components/Loading'
import PromotionModal from './PromotionModal'
import { Icon } from '../../../../helpers'
import ConfirmRemove from '../../components/Confirm'
import { deletePromotion, getPromotions } from '../../../../redux/actions/eco/promotionActions'
import { DiscountTypeEnum } from '../../../../utilities/enums'
import NumberBox from '../../components/NumberBox'
import Checkbox from '../../components/Checkbox'

const Promotion = () => {
  const intl = useIntl()
  const [promotions, setPromotions] = useState([])
  const [promotionsOriginal, setPromotionsOriginal] = useState([])
  const [promotion, setPromotion] = useState(null)

  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const [confirmVisible, setConfirmVisible] = useState(false)

  const fetchPromotions = async () => {
    setIsLoading(true)
    try {
      const { result } = await getPromotions({ pageIndex: currentPage, pageSize: pageSize })
      if (result) {
        setPromotions(result.items)
        setPromotionsOriginal(result.items)
        setTotalPages(result.totalCount)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchPromotions()
  }, [])

  const columns = [
    {
      Header: `${intl.formatMessage({ id: 'Promotion.Label.Title' })}`,
      accessor: 'name',
    },
    {
      Header: `${intl.formatMessage({ id: 'Promotion.Label.StartDate' })}`,
      accessor: 'fromDate',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Promotion.Label.EndDate' })}`,
      accessor: 'toDate',
      width: 100,
    },
    {
      Header: `${intl.formatMessage({ id: 'Promotion.Label.DiscountType' })}`,
      accessor: 'discountType',
      width: 120,
    },
    {
      Header: `${intl.formatMessage({ id: 'Promotion.Label.PromotionType' })}`,
      accessor: 'promotionType',
      width: 80,
    },
    {
      Header: `${intl.formatMessage({ id: 'Banner.Column.IsActive' })}`,
      accessor: 'isActive',
      width: 50,
    },
    {
      Header: `${intl.formatMessage({ id: 'Promotion.Label.Action' })}`,
      accessor: 'action',
      width: 60,
    },
  ]
  const rows = promotions?.map((item) => {
    return {
      name: item.name,
      fromDate: item.fromDateStr,
      toDate: item.toDateStr,
      discountType: (
        <div>
          <b>{intl.formatMessage({ id: `Enum.${item.discountTypeCode}` })}</b>
          {item.discountType === DiscountTypeEnum.FixedPrice ? (
            <>
              {' - '}
              <NumberBox type='text' value={item.discountedPrice} className='' suffix='đ' />
            </>
          ) : item.discountType === DiscountTypeEnum.Percentage ? (
            <>
              {' - '}
              <NumberBox type='text' value={item.discountedPercent} className='' suffix='%' />
            </>
          ) : (
            ''
          )}
        </div>
      ),
      promotionType: `${intl.formatMessage({ id: `Enum.${item.promotionTypeCode}` })}`,
      isActive: <Checkbox checked={item.isActive} readOnly className='justify-content-center' />,
      action: (
        <div className='d-flex justify-content-center flex-shrink-0'>
          <a onClick={() => handleEdit(item)} className='btn btn-icon btn-light-success btn-sm me-1'>
            <i className='fa-solid fa-pen-to-square' />
          </a>
          <a onClick={() => confirmRemove(item)} className='btn btn-icon btn-light-danger btn-sm'>
            <i className='fa-solid fa-trash' />
          </a>
        </div>
      ),
    }
  })

  const handleCreate = () => {
    setIsEdit(true)
    setPromotion(null)
  }

  const handleEdit = (item) => {
    setPromotion(item)
    setIsEdit(true)
  }

  const confirmRemove = (item) => {
    setConfirmVisible(true)
    setPromotion(item)
  }

  const handleRemove = async () => {
    setIsLoading(true)

    try {
      const { error } = await deletePromotion(promotion.id)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
        setPromotions(promotions.filter((item) => item.id !== promotion.id))
        setPromotionsOriginal(promotions.filter((item) => item.id !== promotion.id))
        setConfirmVisible(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='card mb-5 mb-xl-8'>
      <div className='card-header border-0 pt-5'>
        <div className='card-title'>
          <a onClick={() => handleCreate()} className='btn btn-sm btn-success me-3'>
            <Icon iconName='plus' className='fs-3' />
            {intl.formatMessage({ id: 'AddNew' })}
          </a>

          <ExportExcel fileName={'promotions'} columns={columns} data={rows} numberOfLastColumnRemove={1} />
        </div>

        <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover'>
          <TableSearch onSearch={setPromotions} originalData={promotionsOriginal} />
        </div>
      </div>

      <div className='card-body py-2 pt-0'>
        <div className='table-responsive'>
          <Table columns={columns} data={rows} />
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            itemsPerPage={pageSize}
            onPageChange={(e) => setCurrentPage(e)}
            onItemsPerPageChange={(e) => setPageSize(e)}
          />
          {isLoading && <Loading />}
        </div>
      </div>

      {isEdit && <PromotionModal promotion={promotion} closeModal={setIsEdit} fetchPromotions={fetchPromotions} />}

      {confirmVisible && (
        <ConfirmRemove
          onConfirm={handleRemove}
          onCancel={() => setConfirmVisible(false)}
          message={`${intl.formatMessage({ id: 'Confirm.Message' })}?`}
          title={intl.formatMessage({ id: 'Confirm.Title' })}
          btnCancelText={intl.formatMessage({ id: 'Confirm.Button.Cancel' })}
          btnConfirmText={intl.formatMessage({ id: 'Confirm.Button.Remove' })}
        />
      )}
    </div>
  )
}

export default Promotion
