import { useState, useRef } from 'react'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import { showToast, TOAST_TYPES } from '../../../../helpers/Toastify'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'
import { validationAllComponents } from '../../../../utilities/shareFunction'
import { ControlType, FormControl } from '../../components/FormControl'
import { updateGroup } from '../../../../redux/actions/eco/subCategoryActions'

const GroupModal = ({ group, closeModal, fetchSubCategory }) => {
  const intl = useIntl()
  const componentRefs = useRef({})

  const [categoryName] = useState(group?.categoryName)
  const [subCategoryName] = useState(group?.parentName)
  const [groupName, setgroupName] = useState(group?.subCategoryName ?? null)

  const handleSave = async (e) => {
    e.preventDefault()

    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const groupSave = {
      id: group?.id,
      name: groupName,
    }

    const { error } = await updateGroup(groupSave)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      closeModal(false)
      fetchSubCategory()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: 'Update' })} closeModal={closeModal} modalSize='mw-650px'>
      <form id='kt_modal_add_SubCategory_form' className='form'>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_SubCategory_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_SubCategory_header'
          data-kt-scroll-wrappers='#kt_modal_add_SubCategory_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.categoryName = ref)}
                tabIndex={1}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'Menu.Eco.Category' })}
                value={categoryName}
                readOnly={true}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.subCategoryName = ref)}
                tabIndex={2}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'SubCategory.Column.SubCategoryName' })}
                value={subCategoryName}
                readOnly={true}
              />
            </div>

            <div className='col-md-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.groupName = ref)}
                tabIndex={2}
                controlType={ControlType.Input}
                label={intl.formatMessage({ id: 'SubCategory.Column.GroupProductName' })}
                value={groupName}
                isRequired={true}
                onChange={(e) => setgroupName(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='text-center pt-10'>
          <Button onClick={() => closeModal(false)} title={intl.formatMessage({ id: 'Close' })} className={'btn btn-light me-3'} tabIndex={4}>
            <i className='fa fa-times'></i>
            <FormattedMessage id='Close' />
          </Button>
          <Button onClick={(e) => handleSave(e)} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={5}>
            <i className='fa fa-save'></i>
            <FormattedMessage id='Save' />
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default GroupModal
