import { Link, Outlet } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../helpers'

const AuthLayout = () => {
  return (
    <div
      className='d-flex flex-column flex-lg-row flex-column-fluid'
      style={{ backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg9.jpg')})`, backgroundSize: 'cover' }}
    >
      <div className='d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10'>
        <div className='d-flex flex-center flex-lg-start flex-column'>
          <a href='index.html' className='mb-7'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')} className='h-80px' />
          </a>
          <h2 className='text-white fw-normal m-0'>Green24h.com</h2>
        </div>
      </div>
      <div className='d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20'>
        <div className='bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20'>
          <div className='d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20'>
            <Outlet />
          </div>
          <div className='d-flex justify-content-center align-items-center flex-stack px-lg-10'>
            <div className='d-flex fw-semibold text-primary fs-base gap-5 '>
              <a href='#' target='_blank'>
                Điều khoản
              </a>
              <a href='#' target='_blank'>
                Liên hệ
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
