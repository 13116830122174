import React, { forwardRef } from 'react'
import CurrencyFormat from 'react-currency-format'

const NumberBox = forwardRef((props, ref) => {
  const { type, value, placeholder, onChange, className, tabIndex, suffix, prefix, onBlur, readOnly, isValid, errorMessage } = props
  return (
    <>
      <CurrencyFormat
        ref={ref}
        displayType={type === 'text' ? 'text' : 'input'}
        value={value ?? ''}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        className={className ?? 'form-control mb-3 mb-lg-0 px-2'}
        tabIndex={tabIndex ?? 0}
        readOnly={readOnly === true}
        data-isvalid={isValid}
        thousandSeparator={true}
        suffix={suffix}
        prefix={prefix}
      />
      {isValid === false && (
        <p className='mt-0 mb-0' style={{ color: 'red', whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default NumberBox
