import { FileAttachment as FileAttachmentAPI } from '../../../services'

// Async actions

const getFiles = async (payload) => {
  return await FileAttachmentAPI.getFiles(payload)
}

const uploadFiles = async (payload) => {
  return await FileAttachmentAPI.uploadFiles(payload)
}

const downloadFile = async (filePath, fileNameLocal, fileNameServer) => {
  return await FileAttachmentAPI.downloadFile(filePath, fileNameLocal, fileNameServer)
}

const uploadImageCKEditor = async (payload, resolve, reject) => {
  return await FileAttachmentAPI.uploadImageCKEditor(payload, resolve, reject)
}

export { getFiles, uploadFiles, downloadFile, uploadImageCKEditor }
