import { Product as ProductAPI } from '../../../services'

// Async actions

const getById = async (productId) => {
    return await ProductAPI.getById(productId)
}

const deleteProduct = async (id) => {
    return await ProductAPI.deleteProduct(id)
}

const getProducts = async (payload) => {
    return await ProductAPI.getProducts(payload)
}

const saveProduct = async (payload) => {
    return await ProductAPI.saveProduct(payload)
}

const updateProduct = async (payload) => {
    return await ProductAPI.updateProduct(payload)
}

const getProductCode = async (payload) => {
    return await ProductAPI.getProductCode(payload)
}

const getProductDropdown = async (payload) => {
    return await ProductAPI.getProductDropdown(payload)
}

const approveProduct = async (payload) => {
    return await ProductAPI.approveProduct(payload)
}

const rejectProduct = async (payload) => {
    return await ProductAPI.rejectProduct(payload)
}

export { getById, deleteProduct, getProducts, saveProduct, updateProduct, getProductCode, getProductDropdown, approveProduct, rejectProduct }
